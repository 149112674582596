import React, { Component } from "react";
import { Modal, Typography } from "@material-ui/core";
import ActionButton from "../../../components/ActionButton";

const styles = {
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "scroll",
    height: "200px",
    display: "flex",
  },
  miniModalContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "35%",
    marginRight: "35%",
    //height: '85%'
    overflowY: "scroll",
    height: "150px",
    display: "flex",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  buttoncontainer: {
    justifyContent: "center",
    padding: "10px 0px",
  },
  modalTitle: {
    fontSize: 20,
    alignSelf: "center",
  },
};

export default class ModalDoppiaConfermaDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <div style={styles.miniModalContainer}>
          <div style={styles.modalsectioncontainer}>
            <Typography style={styles.modalTitle}>
              Si desidera eliminare l'elemento selezionato?
            </Typography>
            <div style={styles.buttoncontainer}>
              <ActionButton
                label="Si"
                onClick={() => {
                  this.props.handleDeleteElement(
                    this.props.index,
                    this.props.index2,
                    this.props.index3,
                    this.props.index4
                  );
                }}
              />
              <ActionButton isRed label="No" onClick={this.props.onClose} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
