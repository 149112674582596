import React, {Fragment} from "react";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';

import FormModificaConsulente from './FormModificaConsulente';
import RegistraConsulente from './RegistraConsulente';
import EserciziXConsulenteComponent from './EserciziXConsulenteComponent';

import { clearToken } from '../../../utils/storage';
import { getAbbonamenti } from '../../../utils/api/abbonamenti_api';
import { getConsulenti, updateConsulente } from '../../../utils/api/consulenti_api';
import { getGruppi } from '../../../utils/api/gruppi_api';

import theme from '../../../theme.js';
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import Background from '../../../Sfondo.png';
const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        paddingTop: '26px',
        paddingBottom: '26px',
        paddingLeft: '10px',
        paddingRight: '10px',
        backgroundImage: `url("${Background}"),url("${Background}")`,
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'border-box,border-box',
        backgroundAttachment: 'fixed',
        backgroundPositionX: "140%, -40%"
    },
    paper: {
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    tabsbar: {
        color: 'black',
        //boxShadow: '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)'
    },
    registrationMessage: {
        paddingTop:'10px',
        color: theme.palette.primary.main,
        paddingBottom:'10px'
    }
}
const themeTab = createMuiTheme({
    overrides: {
        MuiTabs: {
            root: {
                paddingTop:20
            },
            indicator:{
                display:"none",
            },
          },
          MuiTab: {
            root: {
                "&$selected": {
                    border:'1px solid #e17414',
                },
                border: `1px solid ${theme.palette.disabled.main}`,
                marginRight:15,
                marginLeft:15,
                borderRadius: '50px',
                backgroundColor:'transparent'
            },
            
          },
    }})

export default class DettaglioConsulente extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            secondTabEnabled: false,
            showRegistrationForm: true,
            showRegistrationMessage: false,
            showChangesSavedMessage: false,
            consulente: {
                id: null,
                email: null,
                nome: null,
                cognome: null,
                telefono: null,
                activated: null,
                userId: null,
                validated: null,
                dataRegistrazione: null,
                terminiUsoAccettati: null,
                privacyPolicyAccettata: null,
                partitaIva: null,
                codiceSdi: null,
                pec: null,
                gruppoId: null,
                superconsulente: null
            },
            notfound: true,
            nomeGruppo: '',
            abbonamenti: [],
            loading: true,
            errorDialogVisible: false,
            errorDialogMessage: '',
            width:window.innerWidth,
            height:window.innerHeight,
            saveButtonEnabled: false
        };
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }

    componentDidMount () {
        window.addEventListener('resize', this.updateDimensions);
        if (this.props.consulenteId === 'nuovo') {
            this.setState({
                secondTabEnabled: false,
                showRegistrationForm: true
            });
        } else {
            const ID = Number.parseInt(this.props.consulenteId, 10);
            if (isNaN(ID)) {
                this.setState({
                    secondTabEnabled: true,
                    showRegistrationForm: false,
                    loading: false,
                    notfound: true
                });
            } else {
                this.setState({
                    secondTabEnabled: true,
                    showRegistrationForm: false
                });
                this.fetchRecord(ID);
            }
        }
    }

    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        });
    };

    fetchRecord = (consulenteId) => {
        this.setState({
            loading: true,
        });
        getConsulenti(consulenteId, this.props.gruppoId, 0, 1, "id")
        .then(result => {
            if (result.length > 0) {
                this.setState({
                    consulente: result[0]
                });
                this.fetchAbbonamenti(consulenteId);
            } else {
                this.setState({
                    loading: false,
                    notfound: true
                });
            }
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    errorDialogVisible: true,
                    errorDialogMessage: error.message,
                    notfound: true
                });
            }
        });
    }

    fetchAbbonamenti = (consulenteId) => {
        getAbbonamenti(null, consulenteId, null, 0, 100, "id")
        .then(result => {
            if (!this.props.superconsulenteView && !this.props.consulenteView) {
                this.setState({
                    abbonamenti: result
                });
                this.fetchGruppo();
            } else {
                this.setState({
                    abbonamenti: result,
                    loading: false,
                    notfound: false
                });
            }
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    errorDialogVisible: true,
                    errorDialogMessage: error.message,
                });
            }  
        });
    }

    fetchGruppo = () => {
        getGruppi(this.state.consulente.gruppoId, null, 0, 1, "id")
        .then(result => {
            let nomeGruppo = '';
            if (result.length > 0 ) {
                nomeGruppo = result[0].nome;
            }
            this.setState({
                loading: false,
                nomeGruppo,
            });
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    errorDialogVisible: true,
                    errorDialogMessage: error.message,
                });
            } 
        });
    }

    onFormSubmitted = () => {
        this.setState({
            loading: true
        });
        let isAdmin = false;
        if (!this.props.superconsulenteView 
            && !this.props.consulenteView) {
            isAdmin = true;
        }
        updateConsulente(isAdmin, this.state.consulente)
        .then(result => {
            this.setState({
                loading: false,
                saveButtonEnabled: false,
                showChangesSavedMessage: true
            });
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    errorDialogVisible: true,
                    errorDialogMessage: error.message
                });
            } 
        });
    }

    onRecordSaved= () => {
        this.setState({
            secondTabEnabled: false,
            showRegistrationMessage: true
        });
    }

    onFormModified= (fieldName, value) => {
        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let consulente = this.state.consulente;
        consulente[fieldName] = value;
        this.setState({
            showRegistrationMessage: false,
            showChangesSavedMessage: false,
            consulente
        });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    render() {
        const showRegistrationForm = this.state.showRegistrationForm;
        const secondTabDisabled = !this.state.secondTabEnabled || (this.props.consulenteView && !this.props.superconsulenteView);
        const title = showRegistrationForm ? "Nuovo consulente" : "Dettagli consulente" ;
        return (
            <div style={{
                 textAlign: 'center',
        color: 'black',
        paddingTop: '26px',
        paddingBottom: '26px',
        paddingLeft: '10px',
        paddingRight: '10px',
        backgroundImage: this.state.width > 1240 ? `url("${Background}"),url("${Background}")` : `url(""),url("")`,
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'border-box,border-box',
        backgroundAttachment: 'fixed',
        backgroundPositionX:this.state.width > 1799 ? "140%, -40%" : this.state.width > 1599 ? "160%, -60%" : this.state.width > 1399 ? "180%, -80%" : this.state.width > 1299 ? "200%, -100%" : "220%, -120%"
            }} >
                <Typography variant="h4" >{title}</Typography>
                {
                this.props.consulenteView && !this.props.superconsulenteView ? 
                <>
                    <Box style={{ paddingTop:'20px' }} >
                        <Typography 
                            variant="body1" 
                            style={{
                                color: theme.palette.primary.main,
                                visibility: this.state.showChangesSavedMessage ? 'inherit' : 'hidden'
                            }}
                        >
                        Dati aggiornati con successo.
                        </Typography>
                    </Box>
                    <FormModificaConsulente 
                        loading={this.state.loading}
                        notfound={this.state.notfound}
                        consulente={this.state.consulente}
                        nomeGruppo={this.state.nomeGruppo}
                        abbonamenti={this.state.abbonamenti}
                        superconsulenteView={this.props.superconsulenteView}
                        consulenteView={this.props.consulenteView}
                        onFormModified={this.onFormModified} 
                        onFormSubmitted={this.onFormSubmitted}
                        onErrorDialogClose={this.closeErrorDialog}
                        saveButtonEnabled={this.state.saveButtonEnabled}
                        showError={this.state.errorDialogVisible}
                        errorMessage={this.state.errorDialogMessage}
                    />
                </>
                :
                <>
                {/*<Paper style={styles.paper} >*/}
                <ThemeProvider theme={themeTab}>
                    <Tabs
                    value={ this.state.tabValue }
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    variant="fullWidth"
                    style={styles.tabsbar}
                        //centered
                    >
                        <Tab label="Dati consulente" value={0} />
                        <Tab label="Punti vendita" value={1} 
                            disabled={secondTabDisabled}
                            style={{
                                backgroundColor: secondTabDisabled ?  theme.palette.disabled.main : 'transparent'
                            }}
                        />
                    </Tabs>
                    <div hidden={ this.state.tabValue !== 0 }>
                        <Box style={{ paddingTop:'20px' }} >
                            { (this.state.tabValue === 0 && showRegistrationForm ) ? 
                                <Fragment>
                                    <Typography 
                                        variant="body1" 
                                        style={{
                                            color: theme.palette.primary.main,
                                            visibility: this.state.showRegistrationMessage ? 'inherit' : 'hidden'
                                        }}
                                    >
                                    Registrazione effettuata con successo.
                                    </Typography>
                                    <RegistraConsulente 
                                        gruppoId={this.props.gruppoId}
                                        superconsulenteView={this.props.superconsulenteView}
                                        onFormModified={this.onFormModified} 
                                        onFormSubmitted={this.onRecordSaved} 
                                    />
                                </Fragment> 
                                : null 
                            }
                            { (this.state.tabValue === 0 && !showRegistrationForm) ? 
                                <Fragment>
                                    <Typography 
                                        variant="body1" 
                                        style={{
                                            color: theme.palette.primary.main,
                                            visibility: this.state.showChangesSavedMessage ? 'inherit' : 'hidden'
                                        }}
                                    >
                                        Dati aggiornati con successo.
                                    </Typography>
                                    <FormModificaConsulente 
                                        loading={this.state.loading}
                                        consulente={this.state.consulente}
                                        notfound={this.state.notfound}
                                        nomeGruppo={this.state.nomeGruppo}
                                        abbonamenti={this.state.abbonamenti}
                                        superconsulenteView={this.props.superconsulenteView}
                                        consulenteView={this.props.consulenteView}
                                        onFormModified={this.onFormModified} 
                                        onFormSubmitted={this.onFormSubmitted}
                                        onErrorDialogClose={this.closeErrorDialog}
                                        saveButtonEnabled={this.state.saveButtonEnabled}
                                        showError={this.state.errorDialogVisible}
                                        errorMessage={this.state.errorDialogMessage}
                                    />
                                </Fragment>
                                : null 
                            }
                        </Box>
                    </div>
                    <div hidden={ this.state.tabValue !== 1 }>
                        <Box style={{ paddingTop:'44px' }} >
                            { this.state.tabValue === 1 
                                && 
                                <EserciziXConsulenteComponent 
                                    consulente={this.state.consulente}
                                />
                            }
                        </Box>
                    </div>
                    </ThemeProvider>
                    </>
                //</Paper>
                }
            </div>
        );
    }

}

DettaglioConsulente.propTypes = {
    consulenteId: PropTypes.string.isRequired,
    gruppoId: PropTypes.number,
    consulenteView: PropTypes.bool.isRequired,
    superconsulenteView: PropTypes.bool.isRequired
}