import React, { Component, Fragment } from "react";
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import UploadLogo from './UploadLogo';
import LoghiBrand from './LoghiBrand';
import GoToButton from "../components/GoToButton";

import { getBrand, updateLoghiBrand } from "../../../utils/api/brand_api";
import { uploadLogoImage, updateLogo } from "../../../utils/api/logo_api";
import { clearToken } from '../../../utils/storage';

import PropTypes from 'prop-types';

const styles = {
    root: {
        textAlign: 'center',
        color: 'black',
        paddingTop: '26px',
        paddingLeft: '4px', 
        paddingRight: '4px',
        minHeight: '86vh',
    },
    buttonContainer: {
        margin: '38px 8px 8px 8px',
    },
}

export default class BrandPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            uploadingLogo: false,
            brand: {
                id: null,
                nome: '',
                loghi: []
            },
            loghiBrand: [],
            uploadDisabled: true,
            changesNotSaved: false,
            disableSubmitButton: true,
            errorDialogVisible: false,
            errorDialogTitle: null,
            errorDialogMessage: '',
        }
    }

    componentDidMount() {
        this.fetchBrand();
    }

    handleInvalidToken = () => {
        const errorMessage = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        this.openErrorDialog(errorMessage);
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    openErrorDialog = (errorMessage) => {
        this.setState({
            errorDialogTitle: null,
            errorDialogVisible: true,
            errorDialogMessage: errorMessage
        });
    }

    closeErrorDialog = () => {
        this.setState({
          errorDialogVisible: false
        });
    }

     // Using the ErrorDialog to display a message if operation is successful.
    displayMessage = (title, message) => {
        this.setState({
            errorDialogTitle: title,
            errorDialogVisible: true,
            errorDialogMessage: message
        });
    }

    fetchBrand = () => {
        let brandId = this.props.brandId;
        getBrand(brandId)
        .then(result => {
            let uploadDisabled = false;
            let loghiBrand = result.loghi;
            if (loghiBrand.length === 2) {
                uploadDisabled = true;
            }
            this.setState({
                brand: result,
                loghiBrand,
                uploadDisabled,
                loading: false,
                uploadingLogo: false,
                changesNotSaved: false,
            });
        })
        .catch(error => {
            this.setState({
                loading: false,
            });
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.openErrorDialog(error.message);
            } 
        });
    }

    uploadImage = (file, event) => {
        this.setState({
            uploadingLogo: true,
        });
        uploadLogoImage(file)
        .then(result => {
            let id = result;
            this.confirmLogoRegistration(id);
        })
        .catch(error => {
            this.setState({
                loading: false,
                uploadingLogo: false,
            });
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.openErrorDialog(error.message);
            }
        });
    }

    confirmLogoRegistration = (id) => {
        updateLogo(id, "")
        .then(result => {
            let loghiBrand = this.state.loghiBrand;
            loghiBrand.push(id);
            let uploadDisabled = false;
            if (loghiBrand.length === 2) {
                uploadDisabled = true;
            }
            this.setState({
                loghiBrand,
                uploadDisabled,
                uploadingLogo: false,
                changesNotSaved: true,
                disableSubmitButton: false
            });
        })
        .catch(error => {
            this.setState({
                uploadingLogo: false,
            });
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.openErrorDialog(error.message);
            }
        });
    }

    deleteLogo = (id, clickEvent) => {
        let loghi = this.state.loghiBrand;
        let newarray = loghi.filter((logoId) => logoId !== id);
        this.setState({
            loghiBrand: newarray,
            uploadDisabled: false,
            changesNotSaved: true,
            disableSubmitButton: false,
        });
    }

    updateBrand = () => {
        this.setState({
            loading: true,
        });
        updateLoghiBrand(this.state.brand.id, this.state.brand.nome, this.state.loghiBrand)
        .then(result => {
            let disableSubmitButton = false;
            if (this.state.loghiBrand.length === 0) {
                disableSubmitButton = true;
            } else if (this.state.loghiBrand.length === 2) {
                disableSubmitButton = true;
            }
            this.setState({
                disableSubmitButton,
            });
            this.fetchBrand();
        })
        .catch(error => {
            this.setState({
                loading: false
            });
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.openErrorDialog(error.message);
            }
        });
    }
    
    render () {
        return(
            <Container maxWidth={false} style={styles.root} >
                { this.state.loading ?
                    <SpinnerComponent size={24} />
                    :
                    <Fragment>
                        { this.state.brand.id === null ? 
                        <Typography variant="h6" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Record non trovato</Typography>
                        :
                        <Fragment>
                            <Typography variant="h4" style={styles.texts} >
                                {"Loghi del brand " + this.state.brand.nome}
                            </Typography>
                            <UploadLogo
                                disabled={this.state.uploadDisabled}
                                loading={this.state.uploadingLogo}
                                onUpload={this.uploadImage}
                            />
                            <LoghiBrand 
                                loading={this.state.uploadingLogo}
                                loghiIds={this.state.loghiBrand}
                                onRimuoviLogo={this.deleteLogo}
                                onSubmit={this.updateBrand}
                                submitButtonDisabled={this.state.disableSubmitButton}
                            />
                        </Fragment>
                        }
                        <Box p={1} style={styles.buttonContainer}>
                            <GoToButton
                                showAlert={this.state.changesNotSaved} 
                                path="/amministrazione/gruppi"
                                label="Torna alla tabella dei gruppi"
                                redMode={true}
                                disabled={false}
                                grayVersion={false}
                            />
                        </Box>
                    </Fragment>
                }
                <ErrorDialog
                    open={this.state.errorDialogVisible} 
                    title={this.state.errorDialogTitle}
                    message={this.state.errorDialogMessage} 
                    onCloseButtonClicked={this.closeErrorDialog} 
                />
            </Container>
        );
    }
}

BrandPage.propTypes = {
    brandId: PropTypes.string.isRequired,
}
