import React, { Component } from 'react';
import IconButton from "@material-ui/core/IconButton";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import SpinnerComponent from '../../../components/SpinnerComponent';
import ModalResetPsw from '../../../components/ModalResetPsw';
import EditRecordButton from '../components/EditRecordButton';
import NewRecordButton from '../components/NewRecordButton';
import ErrorDialog from '../../../components/ErrorDialog';
import UserActivationDialog from '../components/UserActivationDialog';
//import SelezioneGruppo from '../components/SelezioneGruppo';

import { retrieveToken, clearToken } from '../../../utils/storage';
import { getConsulenti } from '../../../utils/api/consulenti_api';
import { getGruppi } from '../../../utils/api/gruppi_api';

import theme from '../../../theme.js';

import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const styles = {
  mainContainer: {
    textAlign: 'center',
    color: 'black'
  }
};

export default class ConsulentiTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      gruppoId: null,
      gruppi: [],
      loading: false,
      errorDialogVisible: false,
      errorDialogMessage: '',
      isUserActivationDialogOpen: false,
      isActivatingUser: false,
      selectedRecord: null,
      selectedEmail: '',
      openResetPswModal: false
    };
  }

  componentDidMount() {
    let id = this.props.gruppoId;
    this.setState({
      gruppoId: id
    });
    if (this.props.consulenteId === null) {
      // user is admin
      this.fetchGruppi();
    } else {
      this.fetchRecords(101);
    }
  }

  handleInvalidToken = () => {
    this.handleError(true, 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');;
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  }

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage
    });
  }

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false
    });
  }

  fetchRecords = (gruppoId) => {
    this.setState({
      loading: true,
    });
    let consulenteId = this.props.consulenteId;
    if (consulenteId === null
      || this.props.superconsulenteView) {
      // Admin and superconsulente can search records without filtering on consulenteId
      consulenteId = null;
    }
    getConsulenti(consulenteId, gruppoId, 0, 3000, "id")
      .then(result => {
        this.setState({
          records: result,
          loading: false
        });
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  }

  fetchGruppi = () => {
    this.setState({
      loading: true,
    });
    getGruppi(null, null, 0, 1000, "id")
      .then(result => {
        let newarray = [{ id: -1, nome: "Tutti i gruppi" }];
        newarray.push(...result);
        let gruppoId = this.state.gruppoId;
        if (gruppoId === null) {
          gruppoId = -1;
        }
        this.setState({
          gruppi: newarray,
          gruppoId
        });
        this.fetchRecords(101);
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  }

  updateUserStatus = () => {
    this.setState({
      loading: true
    });
    let token = retrieveToken();
    if (token === null) {
      // If token was deleted, redirect to home page:
      this.handleInvalidToken();
    } else {
      let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      let authtoken = 'Bearer '.concat(token);
      let userid = this.state.selectedRecord['userId'];
      let path = '/api/utenti/' + userid + '/attiva'
      if (!this.state.isActivatingUser) {
        path = '/api/utenti/' + userid + '/disattiva'
      }
      fetch(ENDPOINT + path, {
        method: 'POST',
        headers: {
          'Authorization': authtoken,
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        credentials: 'include'
      })
        .then(response => {
          let status = response.status;
          if (status === 200) {
            return response;
          } else {
            if (status === 401 || status === 403) {
              let statusToString = "" + status;
              throw new Error(statusToString);
            } else {
              throw new Error(response.message);
            }
          }
        })
        .then(result => {
          this.setState({
            loading: false
          });
          this.fetchRecords(101);
        })
        .catch(error => {
          //Reset activated flag to origina value:
          this.resetUserActivatedFlag();
          //Display error:
          const msg = error.message;
          if (msg === "401" || msg === "403") {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, "Si è verificato un errore. Operazione non riuscita.");
          }
        });
    }
  }

  onStatusChanged = (record, event) => {
    let flag = event.target.value;
    const elementIndex = this.state.records.findIndex(element => element.id === record.id)
    let newArray = [...this.state.records];
    newArray[elementIndex] = { ...newArray[elementIndex], activated: !newArray[elementIndex].activated };
    this.setState({
      records: newArray,
      selectedRecord: newArray[elementIndex],
      isUserActivationDialogOpen: true,
      isActivatingUser: flag
    });
  }

  onStatusChangeConfirmed = (hasConfirmed) => {
    this.closeUserActivationDialog();
    // Proceed with POST only if user confirmed the operation:
    if (hasConfirmed) {
      this.updateUserStatus();
    } else {
      //otherwise reset activated flag to the original value:
      this.resetUserActivatedFlag();
    }
  }

  resetUserActivatedFlag = () => {
    const elementIndex = this.state.records.findIndex(element => element.id === this.state.selectedRecord.id)
    let newArray = [...this.state.records];
    newArray[elementIndex] = { ...newArray[elementIndex], activated: !newArray[elementIndex].activated };
    this.setState({
      records: newArray
    });
  }

  closeUserActivationDialog = () => {
    this.setState({
      isUserActivationDialogOpen: false,
    })
  }

  onResetPswButtonClicked = (email) => {
    this.setState({
      selectedEmail: email,
      openResetPswModal: true
    });
  }

  onResetPswModalClosed = (isOperationCancelled) => {
    this.setState({
      openResetPswModal: false
    });
  }

  onResetPswFailedForAuthError = () => {
    this.setState({
      openResetPswModal: false
    });
    this.handleInvalidToken();
  }

  handleSelectedGruppo = (gruppoId) => {
    this.setState({
      gruppoId
    });
    this.fetchRecords(101);
  }

  getColumns = () => {
    let isSuperConsulente = this.props.superconsulenteView;
    let isAdmin = !this.props.superconsulenteView && this.props.consulenteId === null;
    if (isAdmin) {
      return [
        {
          Header: 'Numero',
          id: 'numero',
          accessor: row => row,
          width: 120,
          Cell: props => props.index
        },
        {
          Header: 'ID consulente',
          id: 'id',
          accessor: d => d.id,
          filterable: true,
          minWidth: 124,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
        },
        {
          Header: 'ID gruppo',
          id: 'gruppoId',
          accessor: d => d.gruppoId,
          filterable: true,
          minWidth: 100,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
        },
        {
          Header: 'Superconsulente',
          id: 'superconsulente',
          accessor: d => d.superconsulente,
          width: 140,
          Cell: props =>
            props.value ? 'Sì' : 'No'
        },
        {
          Header: 'Email',
          id: 'email',
          accessor: d => d.email,
          filterable: true,
          minWidth: 180,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
        },
        {
          Header: 'Nome',
          id: 'nome',
          accessor: d => d.nome,
          filterable: true,
          width: 300,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
        },
        {
          Header: 'Cognome',
          id: 'cognome',
          accessor: d => d.cognome,
          filterable: true,
          width: 300,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
        },
        {
          Header: 'Telefono',
          id: 'telefono',
          accessor: d => d.telefono,
          filterable: true,
          width: 120,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
        },
        {
          Header: 'Stato',
          id: 'activated',
          accessor: row => row,
          width: 120,
          Cell: props =>
            <FormControl>
              <Select
                value={props.value.activated}
                name="activated"
                onChange={(event) => { this.onStatusChanged(props.value, event) }}
              >
                <MenuItem value={true}>Attivo</MenuItem>
                <MenuItem value={false}>Non attivo</MenuItem>
              </Select>
            </FormControl>
        },
        {
          id: 'editBtn',
          Header: 'Modifica',
          accessor: row => row,
          width: 80,
          Cell: props =>
            <EditRecordButton recordId={props.value.id} disabled={false} />
        },
        {
          id: 'resetPswBtn',
          Header: 'Reset password',
          accessor: row => row,
          width: 130,
          Cell: props =>
            <IconButton
              style={{ color: theme.palette.cndColors.yellow }}
              aria-label="reset psw"
              size="small"
              //onClick={ () => {this.onResetButtonClicked(props.value)} } 
              onClick={() => { this.onResetPswButtonClicked(props.value.email) }}
            >
              <VpnKeyIcon />
            </IconButton>
        }
      ];
    } else if (isSuperConsulente) {
      return [
        {
          Header: 'Numero',
          id: 'numero',
          accessor: row => row,
          width: 120,
          Cell: props => props.index
        },
        {
          Header: 'ID consulente',
          id: 'id',
          accessor: d => d.id,
          filterable: true,
          minWidth: 124,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
        },
        {
          Header: 'Superconsulente',
          id: 'superconsulente',
          accessor: d => d.superconsulente,
          width: 140,
          Cell: props =>
            props.value ? 'Sì' : 'No'
        },
        {
          Header: 'Email',
          id: 'email',
          accessor: d => d.email,
          filterable: true,
          minWidth: 180,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
        },
        {
          Header: 'Nome',
          id: 'nome',
          accessor: d => d.nome,
          filterable: true,
          width: 300,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
        },
        {
          Header: 'Cognome',
          id: 'cognome',
          accessor: d => d.cognome,
          filterable: true,
          width: 300,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
        },
        {
          Header: 'Telefono',
          id: 'telefono',
          accessor: d => d.telefono,
          filterable: true,
          width: 120,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
        },
        {
          Header: 'Stato',
          id: 'activated',
          accessor: row => row,
          width: 120,
          Cell: props =>
            <FormControl>
              <Select
                value={props.value.activated}
                name="activated"
                onChange={(event) => { this.onStatusChanged(props.value, event) }}
              >
                <MenuItem value={true}>Attivo</MenuItem>
                <MenuItem value={false}>Non attivo</MenuItem>
              </Select>
            </FormControl>
        },
        {
          id: 'editBtn',
          Header: 'Modifica',
          accessor: row => row,
          width: 80,
          Cell: props =>
            <EditRecordButton recordId={props.value.id} disabled={false} />
        },
        {
          id: 'resetPswBtn',
          Header: 'Reset password',
          accessor: row => row,
          width: 130,
          Cell: props =>
            <IconButton
              style={{ color: theme.palette.cndColors.yellow }}
              aria-label="reset psw"
              size="small"
              onClick={() => { this.onResetPswButtonClicked(props.value.email) }}
            >
              <VpnKeyIcon />
            </IconButton>
        }
      ];
    }
    return [
      {
        Header: 'Numero',
        id: 'numero',
        accessor: row => row,
        width: 120,
        Cell: props => props.index
      },
      {
        Header: 'ID consulente',
        id: 'id',
        accessor: d => d.id,
        filterable: true,
        minWidth: 124,
        Filter: ({filter, onChange}) => (
          <TextField
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{width:"100%"}}
            InputProps={{
              disableUnderline:true,
              endAdornment: (<InputAdornment position="end">
              <IconButton size="small">
              <Search>

              </Search>
              </IconButton>
              </InputAdornment>)
            }}
          />
        ),
      },
      {
        Header: 'Superconsulente',
        id: 'superconsulente',
        accessor: d => d.superconsulente,
        width: 140,
        Cell: props =>
          props.value ? 'Sì' : 'No'
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: d => d.email,
        filterable: true,
        minWidth: 180,
        Filter: ({filter, onChange}) => (
          <TextField
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{width:"100%"}}
            InputProps={{
              disableUnderline:true,
              endAdornment: (<InputAdornment position="end">
              <IconButton size="small">
              <Search>

              </Search>
              </IconButton>
              </InputAdornment>)
            }}
          />
        ),
      },
      {
        Header: 'Nome',
        id: 'nome',
        accessor: d => d.nome,
        filterable: true,
        width: 300,
        Filter: ({filter, onChange}) => (
          <TextField
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{width:"100%"}}
            InputProps={{
              disableUnderline:true,
              endAdornment: (<InputAdornment position="end">
              <IconButton size="small">
              <Search>

              </Search>
              </IconButton>
              </InputAdornment>)
            }}
          />
        ),
      },
      {
        Header: 'Cognome',
        id: 'cognome',
        accessor: d => d.cognome,
        filterable: true,
        width: 300,
        Filter: ({filter, onChange}) => (
          <TextField
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{width:"100%"}}
            InputProps={{
              disableUnderline:true,
              endAdornment: (<InputAdornment position="end">
              <IconButton size="small">
              <Search>

              </Search>
              </IconButton>
              </InputAdornment>)
            }}
          />
        ),
      },
      {
        Header: 'Telefono',
        id: 'telefono',
        accessor: d => d.telefono,
        filterable: true,
        width: 120,
        Filter: ({filter, onChange}) => (
          <TextField
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{width:"100%"}}
            InputProps={{
              disableUnderline:true,
              endAdornment: (<InputAdornment position="end">
              <IconButton size="small">
              <Search>

              </Search>
              </IconButton>
              </InputAdornment>)
            }}
          />
        ),
      },
      {
        Header: 'Stato',
        id: 'activated',
        accessor: row => row,
        width: 120,
        Cell: props =>
          props.value.activated ? 'ATTIVO' : 'NON ATTIVO'
      },
      {
        id: 'editBtn',
        Header: 'Modifica',
        accessor: row => row,
        width: 80,
        Cell: props =>
          <EditRecordButton recordId={props.value.id} disabled={false} />
      }
    ];
  }

  render() {
    const columns = this.getColumns();
    const isConsulente = this.props.consulenteId !== null && !this.props.superconsulenteView;
    //const isAdmin = this.props.consulenteId === null && !this.props.superconsulenteView;
    return (
      <div style={{ paddingTop: "30px", minHeight: '400px' }}>
        {this.state.loading ?
          <SpinnerComponent size={24} />
          :
          <div style={styles.mainContainer}>
            {/*isAdmin ?
              <SelezioneGruppo
                gruppoId={this.state.gruppoId}
                gruppi={this.state.gruppi}
                disabled={false}
                description="Selezionare il gruppo su cui filtrare i risultati:"
                onGruppoSelected={this.handleSelectedGruppo}
                onGruppoAdded={this.fetchGruppi}
                onError={this.handleError}
                onAuthError={this.handleInvalidToken}
              />
              : null
            */}
            {!isConsulente ?
              <div style={{ paddingTop: '10px', paddingBottom: '10px', textAlign: 'right' }}>
                <NewRecordButton disabled={false} />
              </div>
              : null
            }
            <ReactTable
              filterable={false}
              resizable={true}
              sortable={false}
              showPageSizeOptions={true}
              showPageJump={true}
              defaultPageSize={10}
              data={this.state.records}
              columns={columns}
              defaultFilterMethod={(filter, row,column) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
              }}
              previousText='Precedente'
              nextText='Successivo'
              noDataText='Nessun record'
              pageText='Pagina'
              ofText='di'
              rowsText='righe'
              pageJumpText='Vai a pagina'
              rowsSelectorText='righe per pagina'
            />
          </div>
        }
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        <UserActivationDialog
          isOpen={this.state.isUserActivationDialogOpen}
          isActivatingUser={this.state.isActivatingUser}
          onButtonClicked={this.onStatusChangeConfirmed}
        />
        {this.state.openResetPswModal ?
          <ModalResetPsw
            email={this.state.selectedEmail}
            open={this.state.openResetPswModal}
            onClose={this.onResetPswModalClosed}
            onAuthError={this.onResetPswFailedForAuthError}
          />
          :
          null
        }
      </div>
    );
  }

}

ConsulentiTable.propTypes = {
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired
}