import React, { Component } from "react";
import ActionButton from "../../../components/ActionButton";
import SelectTable from "../components/SelectTable";
import theme from "../../../theme";
import { Box, Modal, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = {
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "white",
    padding: "20px 10px",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: " 60%",
    minWidth: "250px",
    borderRadius: "10px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px " +
      theme.palette.primary.main,
    overflowY: "hidden",
  },
  modalSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evely",
    gap: "10px",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginBottom: "10px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
};

class ModalSelezionePuntoVendita extends Component {
  static propTypes = {
    puntiVendita: PropTypes.arrayOf(PropTypes.object),
    selectedValue: PropTypes.object,
    onSaveSelection: PropTypes.func,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      record: props.puntiVendita,
      columns: [],
      selectedPdV: props.selectedValue || null,
    };
  }

  componentDidMount() {
    this.setState({
      columns: this.getColumns(),
    });
  }

  getColumns = () => {
    return [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        filterable: true,
      },
      {
        Header: "Ragione sociale",
        id: "ragioneSociale",
        accessor: (d) => d.ragioneSociale,
        filterable: true,
      },
      {
        Header: "Codice MURO",
        id: "codiceMuro",
        accessor: (d) => d.codiceMuro,
        filterable: true,
      },
    ];
  };

  onPuntoVenditaSelected = (selected) => {
    this.setState({ selectedPdV: selected });
  };

  handleProseguiClick = () => {
    if (this.state.selectedPdV) {
      this.props.onSaveSelection(this.state.selectedPdV);
    }
    this.props.onClose();
  };

  render() {
    const { open, onClose } = this.props;

    return (
      <Modal open={open} onClose={onClose}>
        <Box style={styles.modalContainer}>
          <div style={styles.modalSectionContainer}>
            <div style={styles.textWrapper}>
              <Typography variant="h5">Dati manuale sicurezza</Typography>
              <Typography>
                Scegli il punto vendita da associare al nuovo manuale
              </Typography>
            </div>
            <SelectTable
              data={this.state.record}
              columns={this.state.columns}
              defaultPageSize={10}
              onSelectionChange={this.onPuntoVenditaSelected}
              selectedValue={this.state.selectedPdV}
            />
            <div style={styles.buttonWrapper}>
              <ActionButton
                label="Indietro"
                isRed={true}
                onClick={onClose}
                disabled={false}
                grayVersion={false}
              />
              <ActionButton
                label="Prosegui"
                disabled={!this.state.selectedPdV}
                grayVersion={false}
                onClick={this.handleProseguiClick}
              />
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default ModalSelezionePuntoVendita;
