import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import queryString from 'query-string';

import SpinnerComponent from '../../components/SpinnerComponent';
import theme from '../../theme.js';

const styles = {
    // header: {
    //     backgroundColor: '#183780',
    //     padding: '10px',
    //     color: 'white'
    // },
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        padding: '10px'
    },
}

// This page URL is <base_url>/reset_password?key=<resetPswKey>
export default class ResetPswPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                password: "",
                confirmPsw: "",
                resetPasswordKey: null
            },
            validationErrors: {
                password: "",
                confirmPsw: ""
            },
            showPassword1: false,
            showPassword2: false,
            loading: false,
            sentRequest: false,
            displayErrorMessage: false,
            message: ""
        }
    }

    componentDidMount() {
        //console.log(this.props.location.search) // "?key=key"
        const values = queryString.parse(this.props.location.search);
        if ((values.key !== undefined) && (values.key !== null)) {
            let data = this.state.data;
            data.resetPasswordKey = values.key
            this.setState({
                data
            });
        }
    }

    handleClickShowPassword1 = () => {
        let flag = !(this.state.showPassword1);
        this.setState({
            showPassword1: flag
        });
    }
    handleClickShowPassword2 = () => {
        let flag = !(this.state.showPassword2);
        this.setState({
            showPassword2: flag
        });
    }

    validateForm = () => {
        //Validate every property:
        let data = this.state.data;
        Object.keys(data).forEach(key => {
            this.validateField(key, data[key]);
        });
        //Return false if there are validation errors:
        let valid = true;
        let errorMessages = this.state.validationErrors;
        Object.values(errorMessages).forEach(val => {
            val.length > 0 && (valid = false);
        });
        return valid;
    }

    validateField = (key, value) => {
        let insertPsw = this.state.data.password;
        let validationErrors = this.state.validationErrors;
        switch (key) {
            case "password":
                if (value.length >= 8) {
                    validationErrors.password = "";
                } else {
                    validationErrors.password = "Inserire una password di almeno 8 caratteri";
                }
                break;
            case "confirmPsw":
                if (value.length === 0) {
                    validationErrors.confirmPsw = "Ripetere la password";
                } else {
                    if (value === insertPsw) {
                        validationErrors.confirmPsw = "";
                    } else {
                        validationErrors.confirmPsw = "Le due password non corrispondono";
                    }
                }
                break;
            default:
                break;
        }
        this.setState({
            validationErrors
        });
    }

    recoverPassword = () => {
        let isFormValid = this.validateForm();
        if (isFormValid) {
            this.setState({
                loading: true,
                sentRequest: true,
                displayErrorMessage: false
            });
            let requestBody = {
                password: this.state.data.password,
                resetPasswordKey: this.state.data.resetPasswordKey
            };
            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            fetch(ENDPOINT + '/api/account/reimposta_password_final', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            }).then(response => {
                let statusCode = response.status;
                let msg = "";
                if (statusCode === 200) {
                    this.setState({
                        loading: false,
                        displayErrorMessage: false
                    });
                    this.goToSuccessPage();
                } else {
                    if (statusCode === 400) {
                        msg = "Richiesta non valida.";
                    } else if (statusCode === 401 || statusCode === 403) {
                        msg = "Richiesta non autorizzata.";
                    } else if (statusCode === 404) {
                        msg = "Codice non valido.";
                    } else {
                        msg = "Errore di servizio. Per favore, riprovare più tardi.";
                    }
                    this.setState({
                        loading: false,
                        displayErrorMessage: true,
                        message: msg
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    displayErrorMessage: true,
                    message: "Servizio non raggiungibile. Per favore, riprovare più tardi."
                });
            });
        } //if form is not valid no op
    }

    handleChangeEvent = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        let validationErrors = this.state.validationErrors;
        //this.validateField(name, value);
        let data = this.state.data;
        data[name] = value;
        validationErrors[name] = "";
        this.setState({
            data,
            validationErrors
        });
    }

    goToSuccessPage = () => {
        window.location.href = "/reset_password_success";
    }

    render() {
        if (this.state.data.resetPasswordKey === null) {
            return null;
        }
        return (
            <div style={styles.mainContainer}>
                {/* <header style={styles.header}>
                    <h1>{'Benvenuto'}</h1>
                </header> */}
                    <Paper style={{ padding: '10px', minHeight: '400px' }}>
                        <Typography variant="h4" style={{ padding: '10px' }} >
                            Ripristina password
                        </Typography>
                        <Typography variant="h6" style={{ padding: '10px' }} >
                            Inserisci qui la tua nuova password per accedere al servizio BS-CONAD.
                        </Typography>
                        <div style={{ margin: '20px' }}>
                            <TextField
                                label="Nuova password"
                                placeholder=""
                                value={this.state.data.password}
                                name="password"
                                onChange={this.handleChangeEvent}
                                variant="outlined"
                                fullWidth
                                helperText={this.state.validationErrors.password}
                                error={this.state.validationErrors.password.length > 0 ? true : false}
                                type={this.state.showPassword1 ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Mostra password">
                                                <IconButton
                                                    label="Mostra password"
                                                    onClick={this.handleClickShowPassword1}
                                                >
                                                    {this.state.showPassword1 ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div style={{ margin: '20px' }}>
                            <TextField
                                label="Digita nuovamente la nuova password"
                                placeholder=""
                                value={this.state.data.confirmPsw}
                                name="confirmPsw"
                                onChange={this.handleChangeEvent}
                                variant="outlined"
                                fullWidth
                                helperText={this.state.validationErrors.confirmPsw}
                                error={this.state.validationErrors.confirmPsw.length > 0 ? true : false}
                                type={this.state.showPassword2 ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Mostra password">
                                                <IconButton
                                                    label="Mostra password"
                                                    onClick={this.handleClickShowPassword2}
                                                >
                                                    {this.state.showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <Grid
                            container
                            spacing={8}
                            direction="row"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item xs={12} sm={12} ml={12} >
                                <Button variant="contained" style={{ 
                                    color: theme.palette.secondary.main,
                                    backgroundColor: this.state.sentRequest? theme.palette.disabled.main :  theme.palette.primary.main,
                                    }} onClick={this.recoverPassword} disabled={this.state.sentRequest} >Salva nuova password</Button>
                            </Grid>
                            <Grid item xs={12} sm={12} ml={12} >
                                {this.state.loading ? 
                                    <SpinnerComponent size={24} /> 
                                    : ''
                                }
                                {this.state.displayErrorMessage ? <label style={{ color: 'black', fontSize: 16, wordBreak: 'break-word' }}>{this.state.message}</label> : ''}
                            </Grid>
                        </Grid>
                    </Paper>
            </div>
        );
    }
}