import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Settings from '@material-ui/icons/Settings';
import Save from '@material-ui/icons/Save';
import RestoreIcon from '@material-ui/icons/Restore';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import CancelRounded from '@material-ui/icons/CancelRounded';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';

// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import SpinnerComponent from '../../../components/SpinnerComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import TwoActionsAlertDialog from '../../../components/TwoActionsAlertDialog';
import ActionButton from '../../../components/ActionButton';
import ModalSelezioneGruppo from '../components/ModalSelezioneGruppo';
import ModalSelezioneConsulente from '../components/ModalSelezioneConsulente';
import ModalSelezioneEsercizioCommerciale from '../components/ModalSelezioneEsercizioCommerciale';
import GoToPageModal from '../components/GoToPageModal';
import ModificaModelloManualeButton from '../components/ModificaModelloManualeButton';
import ModalSelezioneModalitaModello from '../components/ModalSelezioneModalitaModello';

import { clearToken } from '../../../utils/storage';
import { getBrands } from '../../../utils/api/brand_api';
import { getGruppi } from '../../../utils/api/gruppi_api';

import { getModelli, updateModello } from '../../../utils/api/modelli_and_manuali_api';

import { creaModelloPersonalizzato, cancellaModello } from "../../../utils/api/modelli_and_manuali_api";

import { getCapitoliCount } from '../../../utils/api/capitoli_api';
import { creaManualePersonalizzato }  from '../../../utils/api/modelli_and_manuali_api';

import theme from '../../../theme.js';

import moment from 'moment';
import 'moment/locale/it'

import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import LoadingModal from '../components/LoadingModal';
import {ReactComponent as SettingLogo} from "../../../Modifica.svg";
import {ReactComponent as CreaManualeDa} from "../../../CreaManualeDa.svg";
import {ReactComponent as Clona} from "../../../Clona.svg";
import { InputAdornment, InputBase, TextField, ThemeProvider, createMuiTheme, styled } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const styles = {
  mainContainer: {
    textAlign: 'center',
    color: 'black'
  },
  vediButton: {
    color: theme.palette.secondary.main,
    padding: '0px',
    margin: '0px',
    backgroundColor: theme.palette.primary.main,
  }
};

const dateFormat = "DD-MM-YYYY HH:mm";

export const Component = withRouter(({ history, location }) =>{
});
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    background: "linear-gradient(white, white) padding-box, linear-gradient(to right, #ff4600, #f7a616,#ff4600) border-box;",
    borderRadius: "50em",
    border: "1px solid transparent",
    padding: "1em 2em 1em 1em",
  },
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
  }
}));

class ModelliPersonalizzatiTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modelli: [],
      brands: [],
      gruppoId: null,
      gruppi: [],
      esercizioId: null,
      selectedGruppoId: null,
      consulenteId: null,
      loading: true,
      modelloBaseId: null,
      modelloCreatoId: null,
      errorDialogVisible: false,
      errorDialogMessage: '',
      edit: false,
      showHiddenModels: false,
      twoActionsAlertVisible: false,
      modalTitle: '',
      modalMessage: '',
      modelloSelezionato: null,
      newModelloId: null,
      showModalSelezioneGruppo: false,
      showModalSelezioneConsulente: false,
      showModalSelezioneEsercizio: false,
      showModalSelezioneModalita:false,
      loadingModal:false,
      showGoToPageModal: false,
      goToPageMessage: '',
      goToPagePath: '',
      isModello: false,
      origin:"Manuale",
    };
  }

  componentDidMount() {
    let gruppoId = this.props.gruppoId; // Can be null if user has role "ADMIN"
    this.setState({
      gruppoId,
      selectedGruppoId: gruppoId
    });
    if (this.props.consulenteId === null) { // User is admin
      this.fetchGruppi();
    } else {
      this.fetchBrands(gruppoId);
    }
  }

  handleInvalidToken = () => {
    this.handleError(true, 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  }

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage
    });
  }

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false
    });
  }

  fetchGruppi = () => {
    getGruppi(null, null, 0, 3000, "id")
      .then(result => {
        let newarray = [{ id: -1, nome: "Tutti i gruppi" }];
        newarray.push(...result);
        let gruppoId = this.state.gruppoId;
        if (gruppoId === null) {
          gruppoId = -1;
        }
        this.setState({
          gruppi: newarray,
          gruppoId,
        });
        this.fetchBrands();
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  }

  fetchBrands = () => {
    getBrands(0, 1000, "id")
      .then(result => {
        this.setState({
          brands: result
        });
        this.fetchModelli(this.props.gruppoId, this.state.showHiddenModels);
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  }

  fetchModelli = (gruppoId, hidden) => {
    const isConsulente = this.props.consulenteId !== null && !this.props.superconsulenteView;
    if (this.props.consulenteId !== null) {
      gruppoId = this.props.gruppoId;
    }
    getModelli(hidden, 101, isConsulente, null, 0, 3000, "id")
      .then(result => {
        this.setState({
          loading: false,
          modelli: result
        });
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false
          });
          this.handleError(true, error.message);
        }
      });
  }

  updateModello = () => {
    updateModello(this.state.modelloSelezionato)
      .then(result => {
        // TEMPORARY DISABLED FETCH REQUEST AFTER UPDATE:
        //this.fetchModelli(this.state.gruppoId);
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false
          });
          this.handleError(true, error.message);
        }
      });
  }

  executeCreationModelloPersonalizzato = (manualeBaseId, gruppoId, hasChapters, isModello) => {
    if (!isModello) {
      this.setState({
        showModalSelezioneModalita:false,
        loadingModal:true,
      },() => {
        creaManualePersonalizzato(this.state.esercizioId, manualeBaseId, this.state.consulenteId, hasChapters)
      .then(result => {
        this.setState({
          loadingModal:false,
        },() => {
          this.openGoToModal(false, result.manuale.id,hasChapters,result.id);
        })
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            loadingModal:false,
          });
          this.handleError(true, error.message);
        }
      });
      })
    } else {
      this.setState({
        showModalSelezioneModalita:false,
        loadingModal:true,
      },() => {
        creaModelloPersonalizzato(manualeBaseId, gruppoId,hasChapters)
      .then(result => {
        this.setState({
          loadingModal:false,
        },() => {
          this.openGoToModal(true, result.id,hasChapters,null);
        }) 
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            loadingModal:false,
          });
          this.handleError(true, error.message);
        }
      });
      })
      
    }
  }

  openGoToModal = (isModello, modelloId,hasChapters,revisioneId) => {
    let message = "Clicca su Avanti per accedere alla pagina di costruzione del contenuto del modello.";
    let path = "/amministrazione/manuali/nuovomanuale/" + modelloId + "/modello/"+hasChapters+"/"+revisioneId;
    if (!isModello) {
      message = "Clicca su Avanti per accedere alla pagina di costruzione del contenuto del manuale.";
      path = "/amministrazione/manuali/nuovomanuale/" + modelloId + "/manuale/"+hasChapters+"/"+revisioneId;
    }
    this.setState({
      showGoToPageModal: true,
      showModalSelezioneModalita: false,
      goToPageMessage: message,
      goToPagePath: path
    });
  }

  onGoToPageCanceled = () => {
    let isAdmin = !this.props.superconsulenteView && this.props.consulenteId === null;
    if(isAdmin){
    this.setState({
      showGoToPageModal: false,
      loading: true,
      gruppoId:-1,
    },() => {
      this.fetchModelli(this.state.gruppoId, this.state.showHiddenModels);
    });
    }else{
      this.setState({
        showGoToPageModal:false,
        loading:true,
      },() => {
        this.fetchModelli(this.state.gruppoId, this.state.showHiddenModels);
      })
    }
  }

  handleSelectedGruppo = (gruppoId) => {
    this.setState({
      gruppoId,
      loading: true
    });
    this.fetchModelli(gruppoId, this.state.showHiddenModels);
  }

  onNewGruppoAdded = () => {
    this.setState({
      loading: true
    });
    this.fetchGruppi();
  }

  onHiddenManualiSelection = (e) => {
    let hidden = e.target.value;
    this.setState({
      showHiddenModels: hidden,
      loading: true,
    });
    this.fetchModelli(this.state.gruppoId, hidden);
  }

  onCreaModelloButtonClicked = () => {
    const isAdmin = this.props.consulenteId === null;
    if (isAdmin) {
      this.setState({
        //showModalSelezioneGruppo: true,
        modelloBaseId:null,
        origin:"Modello",
        isModello:true,
      },() => {
        this.executeCreationModelloPersonalizzato(null, 101,true,true);
      });
    } else {
      this.setState({
        isModello: true,
        origin:"Modello",
        modelloBaseId:null,
      }, () => {
        //this.onOpenModalModalita();
        this.executeCreationModelloPersonalizzato(null, 101,true,true);
      });
      //this.executeCreationModelloPersonalizzato(null, this.props.gruppoId);
    }
  }

  onCancelGruppoSelection = () => {
    let currentGruppoId = this.state.gruppoId;
    this.setState({
      showModalSelezioneGruppo: false,
      loading:false,
      selectedGruppoId: currentGruppoId,
    });
  }

  saveSelectedGruppoForModello = (gruppoId) => {
    this.setState({
      selectedGruppoId: gruppoId,
      gruppoId: gruppoId,
    });
  }

  onCreaButtonInModalClicked = (origin) => {
    if(origin === "Modello"){
    let gruppoId = this.state.selectedGruppoId;
    this.setState({
      showModalSelezioneGruppo: false,
      selectedGruppoId: gruppoId,
      loading: true,
    },
    this.onOpenModalModalita());
  }else{
    let gruppoId = this.state.selectedGruppoId;
    this.setState({
      showModalSelezioneGruppo: false,
      selectedGruppoId:gruppoId,
      loading:true,
      showModalSelezioneConsulente:true,
    })
  }
    //this.executeCreationModelloPersonalizzato(this.state.modelloBaseId, gruppoId);
  }

  onPublicationButtonClicked = () => {
    let modelloSelezionato = this.state.modelloSelezionato;
    modelloSelezionato.stato = 'PUBBLICATO';
    this.setState({
      modelloSelezionato,
      modalTitle: "Pubblicazione bozza",
      modalMessage: "Cliccare su OK per confermare la richiesta di pubblicazione oppure Indietro per annullare la richiesta.",
      twoActionsAlertVisible: true,
      edit: false
    });
  }

  onPubblicationCancelled = () => {
    let modelloSelezionato = this.state.modelloSelezionato;
    if (modelloSelezionato.stato === 'PUBBLICATO') {
      modelloSelezionato.stato = 'BOZZA';
      this.setState({
        modelloSelezionato,
        twoActionsAlertVisible: false
      });
    }
  }

  onAlertOkButtonPressed = () => {
    this.setState({
      twoActionsAlertVisible: false
    });
    this.updateModello();
  }

  onModificaClicked = (record, event) => {
    if (!this.state.edit) {
      this.setState({
        edit: true,
        modelloSelezionato: record,
      });
    } else {
      this.setState({
        edit: false,
      });
      this.updateModello();
    }
  }

  onClonaClicked = (record, event) => {
    let selectgruppo = this.props.consulenteId === null ? true : false; // If admin, open modal to select gruppo
    if (selectgruppo) {
      this.setState({
        modelloBaseId: record.id,
        //showModalSelezioneGruppo: true,
        origin:"Modello",
        isModello:true,
      },() => {
        this.executeCreationModelloPersonalizzato(this.state.modelloBaseId, 101, true,true);
      });
    } else {
      this.setState({
        loading: true,
        modelloBaseId: record.id,
        selectedGruppoId: record.gruppoId,
      }, () => {
        getCapitoliCount(this.state.modelloBaseId)
        .then(result => {
          if (result === 0) {
            this.setState({
              isModello: true,
            },() => {
              this.executeCreationModelloPersonalizzato(this.state.modelloBaseId, 101, true,true);
            });
          } else {
            this.setState({
              loadingModal:true,
            },() => {
              creaModelloPersonalizzato(this.state.modelloBaseId, record.gruppoId, false)
              .then(result => {
                this.setState({
                  loadingModal:false
                },() => {
                  this.openGoToModal(true, result.id,true,null);
                })
              })
              .catch(error => {
                if (error.status === 403) {
                  this.handleInvalidToken();
                } else {
                  this.setState({
                    loading: false,
                    loadingModal:false,
                  });
                  this.handleError(true, error.message);
                }
              });
            })
          }
        })
        .catch(error => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          }
        });

      })
    }
  }

  /*
  onOpenModalModalita = (consulenteId, esercizioId) => {
    const { history } = this.props;
    if (this.state.modelloBaseId === null) {
      this.setState({
        consulenteId,
        esercizioId,
        showModalSelezioneEsercizio: false,
        showModalSelezioneModalita: true,
      });
    } else {
      getCapitoliCount(this.state.modelloBaseId)
      .then(result => {
        if (result === 0) {
          this.setState({
            consulenteId,
            esercizioId,
            showModalSelezioneEsercizio: false,
            showModalSelezioneModalita: true,
          });
        } else {
          this.setState({
            consulenteId,
            esercizioId,
            showModalSelezioneEsercizio:false,
            showModalSelezioneModalita: false,
          }, () => creaManualePersonalizzato(esercizioId, this.state.modelloBaseId, consulenteId, false)
            .then(result => {
              this.openGoToModal(false, this.state.modelloBaseId);
              // history.push('/amministrazione/manuali/nuovomanuale/' + this.state.modelloBaseId + '/manuale');
            })
            .catch(error => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                });
                this.handleError(true, error.message);
              }
            }));
        }
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
    }
  }*/

  onCreaManualeDaClicked = (record, event) => {
    if(this.props.gruppoId === null){
      this.setState({
        loading:true,
        modelloBaseId:record.id,
        gruppoId:record.gruppoId,
        isModello:false,
        origin:"Manuale",
        showModalSelezioneConsulente:true,
      })
    } 
    else if (this.props.superconsulenteView) {
      this.setState({
        loading: true,
        modelloBaseId: record.id,
        isModello: false,
        //showModalSelezioneGruppo:true,
        origin:"Manuale",
        showModalSelezioneConsulente: true
      });
    } else { // If user is not admin nor superconsulente:
      let consulenteId = this.props.consulenteId;
      this.setState({
        loading: true,
        consulenteId,
        modelloBaseId: record.id,
        showModalSelezioneEsercizio: true,
      });
    }
  }

  handleCloseConsulentiModal = () => {
    this.setState({
      loading: false,
      showModalSelezioneConsulente: false
    });
  }

  handleOpenEserciziModal = (consulenteId) => {
    this.setState({
      consulenteId,
      showModalSelezioneConsulente: false,
      showModalSelezioneEsercizio: true,
    })
  }

  handleCloseEserciziModal = () => {
    this.setState({
      loading: false,
      showModalSelezioneEsercizio: false,
    })
  }
  onOpenModalModalita = (consulenteId, esercizioId) => {
    if (this.state.modelloBaseId === null) {
      this.setState({
        consulenteId,
        esercizioId,
        showModalSelezioneEsercizio: false,
        showModalSelezioneModalita: true,
      });
    } else {
      getCapitoliCount(this.state.modelloBaseId)
      .then(result => {
        if (result === 0) {
          this.setState({
            consulenteId,
            esercizioId,
            showModalSelezioneEsercizio: false,
            showModalSelezioneModalita: true,
          });
        } else {
          this.setState({
            consulenteId,
            esercizioId,
            showModalSelezioneEsercizio:false,
            showModalSelezioneModalita: false,
            loadingModal:true,
          }, () => {
            if(this.state.origin === "Modello"){
              creaModelloPersonalizzato(this.state.modelloBaseId,this.state.selectedGruppoId,false).then((result) => {
                this.setState({
                  loadingModal:false,
                },() => {
                  this.openGoToModal(true,result.id,true,null);
                })
                
              }).catch(error => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                  loadingModal:false,
                });
                this.handleError(true, error.message);
              }
            })
            }else{
            creaManualePersonalizzato(esercizioId, this.state.modelloBaseId, consulenteId, false)
            .then(result => {
              this.setState({
                loadingModal:false,
              },() => {
                this.openGoToModal(false, result.manuale.id,true,result.id);
              })
              // history.push('/amministrazione/manuali/nuovomanuale/' + this.state.modelloBaseId + '/manuale');
            })
            .catch(error => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                  loadingModal:false,
                });
                this.handleError(true, error.message);
              }
            })
          }
          });
        }
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
    }
  }


  onCloseModalModalita = () => {
    //Insert here the code to close the modal for admin
    let isAdmin = !this.props.superconsulenteView && this.props.consulenteId === null;
    if(isAdmin){
      this.setState({
      showModalSelezioneModalita: false,
      loading: false,
      gruppoId:-1,
    });
    }else{
    this.setState({
      showModalSelezioneModalita: false,
      loading: false
    });
    }
  }

  onCancellaOrRipristinaButtonClicked = (modello, e) => {
    this.setState({
      loading: false,
    });
    let hidden = this.state.showHiddenModels ? false : true;
    cancellaModello(modello.id, hidden)
      .then(result => {
        this.fetchModelli(this.state.gruppoId, this.state.showHiddenModels);
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false
          });
          this.handleError(true, error.message);
        }
      });
  }

  getColumns = () => {
    let isSuperConsulente = this.props.superconsulenteView;
    let isAdmin = !this.props.superconsulenteView && this.props.consulenteId === null;
    if (isAdmin) {
      return [
        {
          Header: 'Numero',
          id: 'numero',
          accessor: row => row,
          width: 120,
          Cell: props => props.index
        },
        {
          Header: 'ID modello',
          id: 'id',
          accessor: d => d.id,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          width: 120,
        },
        {
          Header: 'ID modello base',
          id: 'manualeBaseId',
          accessor: d => d.manualeBaseId,
          width: 154,
          Cell: props => props.value === null ? ' - ' : props.value
        },
        {
          Header: 'ID gruppo',
          id: 'gruppoId',
          accessor: d => d.gruppoId,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          width: 120,
        },
        {
          Header: 'Brand',
          id: 'brand',
          accessor: d => d.brandId,
          width: 120,
          Cell: props => {
            let brand = "";
            for (let i = 0; i < this.state.brands.length; i++) {
              if (this.state.brands[i].id === props.value) {
                brand = this.state.brands[i].nome;
                break;
              }
            }
            return brand;
          },
        },
        {
          Header: 'Titolo',
          id: 'titolo',
          accessor: d => d.titolo,
          minWidth: 260,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          Cell: this.renderEditable
        },
        {
          Header: 'Data aggiornamento',
          id: 'dataUltimoAggiornamento',
          accessor: d => (moment(d.dataUltimoAggiornamento).format(dateFormat)),
          width: 160
        },
        {
          Header: 'Regolamenti',
          id: 'regolamenti',
          accessor: d => d.regolamenti,
          minWidth: 260,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          Cell: this.renderEditable

        },
        {
          Header: 'Stato Pubblicazione',
          id: 'stato',
          accessor: d => d.stato,
          minWidth: 160,
          Cell: this.renderEditableStato,
        },
        /*{
          Header: 'Stato Calendario',
          id: 'statoCalendario',
          accessor: d => d.statoLogDescrizione,
          minWidth: 160,
        },*/
        {
          id: 'modifica',
          Header: 'Modifica',
          sortable: false,
          show: this.state.showHiddenModels ? false : true,
          width: 80,
          accessor: row => row,
          Cell: props =>
            <IconButton
              style={{
                color: props.value.stato === 'PUBBLICATO' ? theme.palette.disabled.main : theme.palette.cndColors.yellow,
              }}
              size="small"
              onClick={(e) => { this.onModificaClicked(props.value, e) }}
              disabled={props.value.stato === 'PUBBLICATO' ? true : false}
            >
              {this.state.edit ? <Save /> : <SettingLogo width={23} height={23} fill={props.value.stato === 'PUBBLICATO' ? theme.palette.disabled.main : theme.palette.cndColors.yellow} />}
            </IconButton>
        },
        {
          id: 'modificaContenuto',
          Header: 'Modifica Contenuto',
          show: this.state.showHiddenModels ? false : true,
          width: 180,
          accessor: row => row,
          Cell: props =>
            <ModificaModelloManualeButton
              disabled={props.value.stato === 'PUBBLICATO' ? true : false}
              modelloId={props.value.id}
              handleError={this.handleError}
              handleInvalidToken={this.handleInvalidToken}
            />
        },
        {
          id: 'clona',
          Header: 'Clona',
          show: this.state.showHiddenModels ? false : true,
          width: 80,
          accessor: row => row,
          Cell: props =>
            <IconButton
              size="small"
              disabled={props.value.stato === 'BOZZA' ? true : false}
              style={{ color: props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow }}
              onClick={(e) => { this.onClonaClicked(props.value, e) }}
            >
              <Clona fill={props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow} width={23} height={23} ></Clona>
            </IconButton>
        },
        {
          id: 'creamanuale',
          Header: 'Crea manuale da',
          show: this.state.showHiddenModels ? false : true,
          width: 152,
          accessor: row => row,
          Cell: props =>
            <IconButton
              size="small"
              disabled={props.value.stato === 'BOZZA' ? true : false}
              style={{
                color: props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow,
              }}
              onClick={(e) => { this.onCreaManualeDaClicked(props.value, e) }}
            >
              <CreaManualeDa fill={props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow} width={23} height={23} />
            </IconButton>
        },
        {
          id: 'cancella',
          Header: this.state.showHiddenModels ? 'Ripristina' : 'Elimina',
          width: 80,
          accessor: row => row,
          Cell: props =>
            <IconButton
              style={{ color: theme.palette.cndColors.red }}
              size="small"
              onClick={(e) => { this.onCancellaOrRipristinaButtonClicked(props.value, e) }}
            >
              {this.state.showHiddenModels ? <RestoreIcon /> : <CancelRounded />}
            </IconButton>
        }
      ];
    } else if (isSuperConsulente) {
      return [
        {
          Header: 'Numero',
          id: 'numero',
          accessor: row => row,
          width: 120,
          Cell: props => props.index
        },
        {
          Header: 'ID modello',
          id: 'id',
          accessor: d => d.id,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          width: 120,
        },
        {
          Header: 'ID modello base',
          id: 'manualeBaseId',
          accessor: d => d.manualeBaseId,
          width: 154,
          Cell: props => props.value === null ? ' - ' : props.value
        },
        {
          Header: 'Brand',
          id: 'brand',
          accessor: d => d.brandId,
          width: 120,
          Cell: props => {
            let brand = "";
            for (let i = 0; i < this.state.brands.length; i++) {
              if (this.state.brands[i].id === props.value) {
                brand = this.state.brands[i].nome;
                break;
              }
            }
            return brand;
          },
        },
        {
          Header: 'Titolo',
          id: 'titolo',
          accessor: d => d.titolo,
          minWidth: 260,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          Cell: this.renderEditable
        },
        {
          Header: 'Data aggiornamento',
          id: 'dataUltimoAggiornamento',
          accessor: d => (moment(d.dataUltimoAggiornamento).format(dateFormat)),
          width: 160
        },
        {
          Header: 'Regolamenti',
          id: 'regolamenti',
          accessor: d => d.regolamenti,
          minWidth: 260,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          Cell: this.renderEditable

        },
        {
          Header: 'Stato Pubblicazione',
          id: 'stato',
          accessor: d => d.stato,
          minWidth: 160,
          Cell: this.renderEditableStato,
        },
        /*{
          Header: 'Stato Calendario',
          id: 'statoCalendario',
          accessor: d => d.statoLogDescrizione,
          minWidth: 160,
        },*/
        {
          id: 'modifica',
          Header: 'Modifica',
          sortable: false,
          show: this.state.showHiddenModels ? false : true,
          width: 80,
          accessor: row => row,
          Cell: props =>
            <IconButton
              style={{
                color: props.value.stato === 'PUBBLICATO' ? theme.palette.disabled.main : theme.palette.cndColors.yellow,
              }}
              size="small"
              onClick={(e) => { this.onModificaClicked(props.value, e) }}
              disabled={props.value.stato === 'PUBBLICATO' ? true : false}
            >
              {this.state.edit ? <Save /> : <SettingLogo width={23} height={23} fill={props.value.stato === 'PUBBLICATO' ? theme.palette.disabled.main : theme.palette.cndColors.yellow} />}
            </IconButton>
        },
        {
          id: 'modificaContenuto',
          Header: 'Modifica Contenuto',
          show: this.state.showHiddenModels ? false : true,
          width: 180,
          accessor: row => row,
          Cell: props =>
            <ModificaModelloManualeButton
              disabled={props.value.stato === 'PUBBLICATO' ? true : false}
              modelloId={props.value.id}
              handleError={this.handleError}
              handleInvalidToken={this.handleInvalidToken}
            />
        },
        {
          id: 'clona',
          Header: 'Clona',
          show: this.state.showHiddenModels ? false : true,
          width: 80,
          accessor: row => row,
          Cell: props =>
            <IconButton
              size="small"
              disabled={props.value.stato === 'BOZZA' ? true : false}
              style={{ color: props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow }}
              onClick={(e) => { this.onClonaClicked(props.value, e) }}
            >
              <Clona fill={props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow} width={23} height={23} ></Clona>
            </IconButton>
        },
        {
          id: 'creamanuale',
          Header: 'Crea manuale da',
          show: this.state.showHiddenModels ? false : true,
          width: 152,
          accessor: row => row,
          Cell: props =>
            <IconButton
              size="small"
              disabled={props.value.stato === 'BOZZA' ? true : false}
              style={{
                color: props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow,
              }}
              onClick={(e) => { this.onCreaManualeDaClicked(props.value, e) }}
            >
             <CreaManualeDa fill={props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow} width={23} height={23} />
            </IconButton>
        },
        {
          id: 'cancella',
          Header: this.state.showHiddenModels ? 'Ripristina' : 'Elimina',
          width: 80,
          accessor: row => row,
          Cell: props =>
            <IconButton
              style={{ color: theme.palette.cndColors.red }}
              size="small"
              onClick={(e) => { this.onCancellaOrRipristinaButtonClicked(props.value, e) }}
            >
              {this.state.showHiddenModels ? <RestoreIcon /> : <CancelRounded />}
            </IconButton>
        }
      ];
    }
    return [
      {
        Header: 'Numero',
        id: 'numero',
        accessor: row => row,
        width: 120,
        Cell: props => props.index
      },
      {
        Header: 'ID modello',
        id: 'id',
        accessor: d => d.id,
        filterable: true,
        Filter: ({filter, onChange}) => (
          <TextField
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{width:"100%"}}
            InputProps={{
              disableUnderline:true,
              endAdornment: (<InputAdornment position="end">
              <IconButton size="small">
              <Search>

              </Search>
              </IconButton>
              </InputAdornment>)
            }}
          />
        ),
        width: 120,
      },
      {
        Header: 'ID modello base',
        id: 'manualeBaseId',
        accessor: d => d.manualeBaseId,
        width: 154,
        Cell: props => props.value === null ? ' - ' : props.value
      },
      {
        Header: 'Brand',
        id: 'brand',
        accessor: d => d.brandId,
        width: 120,
        Cell: props => {
          let brand = "";
          for (let i = 0; i < this.state.brands.length; i++) {
            if (this.state.brands[i].id === props.value) {
              brand = this.state.brands[i].nome;
              break;
            }
          }
          return brand;
        },
      },
      {
        Header: 'Titolo',
        id: 'titolo',
        accessor: d => d.titolo,
        minWidth: 260,
        filterable: true,
        Filter: ({filter, onChange}) => (
          <TextField
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{width:"100%"}}
            InputProps={{
              disableUnderline:true,
              endAdornment: (<InputAdornment position="end">
              <IconButton size="small">
              <Search>

              </Search>
              </IconButton>
              </InputAdornment>)
            }}
          />
        ),
      },
      {
        Header: 'Data aggiornamento',
        id: 'dataUltimoAggiornamento',
        accessor: d => (moment(d.dataUltimoAggiornamento).format(dateFormat)),
        width: 160
      },
      {
        Header: 'Regolamenti',
        id: 'regolamenti',
        accessor: d => d.regolamenti,
        minWidth: 260,
        filterable: true,
        Filter: ({filter, onChange}) => (
          <TextField
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{width:"100%"}}
            InputProps={{
              disableUnderline:true,
              endAdornment: (<InputAdornment position="end">
              <IconButton size="small">
              <Search>

              </Search>
              </IconButton>
              </InputAdornment>)
            }}
          />
        ),

      },
      {
        Header: 'Stato Pubblicazione',
        id: 'stato',
        accessor: d => d.stato,
        minWidth: 160
      },
      /*{
        Header: 'Stato Calendario',
        id: 'statoCalendario',
        accessor: d => d.statoLogDescrizione,
        minWidth: 160,
      },*/
      {
        id: 'creamanuale',
        Header: 'Crea manuale da',
        show: this.state.showHiddenModels ? false : true,
        width: 152,
        accessor: row => row,
        Cell: props =>
          <IconButton
            size="small"
            disabled={props.value.stato === 'BOZZA' ? true : false}
            style={{
              color: props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow,
            }}
            onClick={(e) => { this.onCreaManualeDaClicked(props.value, e) }}
          >
            <CreaManualeDa fill={props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow} width={23} height={23} />
          </IconButton>
      }
    ];
  }

  onlySpaces(str) {
    if (str !== null) {
      return str.trim().length === 0;
    }
    return 'placeholder'
  }

  renderEditable = (cellInfo) => {
    if (this.state.edit === true && this.state.modelloSelezionato.id === cellInfo.row.id) {
      return (
        <div
          style={{ backgroundColor: "#fafafa" }}
          contentEditable
          suppressContentEditableWarning
          onBlur={e => {
            const modelli = [...this.state.modelli];
            //records[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            modelli[cellInfo.index][cellInfo.column.id] = e.target.textContent;
            this.setState({ modelli });
          }}
        >
          {this.onlySpaces(cellInfo.value) ? 'placeholder' : cellInfo.value}

        </div>
      );
    }
    return (<div>{cellInfo.value}</div>);
  }

  renderEditableStato = (cellInfo) => {
    if (this.state.edit === true && this.state.modelloSelezionato.id === cellInfo.row.id) {
      return (
        <FormControl>
          <Select
            value={cellInfo.value}
            name="activated"
          >
            <MenuItem value={'PUBBLICATO'} onClick={() => this.onPublicationButtonClicked(cellInfo.original)}>PUBBLICATO</MenuItem>
            <MenuItem value={'BOZZA'}>BOZZA</MenuItem>
          </Select>
        </FormControl>
      )
    }
    else {
      return (
        <div>{cellInfo.value}</div>
      );
    }
  }

  render() {
    const columns = this.getColumns();
    const isSuperconsulente = this.props.consulenteId !== null && this.props.superconsulenteView;
    const isAdmin = this.props.consulenteId === null;
    return (
      <div style={{ paddingTop: "30px", minHeight: '400px' }}>
        {this.state.loading ?
          <SpinnerComponent size={24} />
          :
          <div style={styles.mainContainer}>
            {/*isAdmin ?
              <SelezioneGruppo
                gruppoId={this.state.gruppoId}
                gruppi={this.state.gruppi}
                disabled={false}
                description="Selezionare il gruppo su cui filtrare i risultati:"
                onGruppoSelected={this.handleSelectedGruppo}
                onGruppoAdded={this.onNewGruppoAdded}
                onError={this.handleError}
                onAuthError={this.handleInvalidToken}
              />
              : null
            */}
            {isAdmin || isSuperconsulente ?
              <div style={{ marginBottom: '30px'}}>
                <div>
                <Select
                  value={this.state.showHiddenModels}
                  name="activated"
                  onChange={(e) => { this.onHiddenManualiSelection(e) }}
                  variant='outlined'
                  input={<BootstrapInput></BootstrapInput>}
                >
                  <MenuItem value={false} >Modelli non cancellati</MenuItem>
                  <MenuItem value={true} >Modelli cancellati</MenuItem>
                </Select>
                </div>
              </div>
              : null
            }
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ActionButton
                label="MODELLO"
                startIcon={<AddIcon/>}
                disabled={!isSuperconsulente && !isAdmin}
                onClick={this.onCreaModelloButtonClicked}
                grayVersion={false}
              />
            </div>
            <ReactTable
              filterable={false}
              resizable={true}
              sortable={false}
              showPageSizeOptions={true}
              showPageJump={true}
              defaultPageSize={10}
              columns={columns}
              data={this.state.modelli}
              defaultFilterMethod={(filter, row,column) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
              }}
              previousText='Precedente'
              nextText='Successivo'
              noDataText='Nessun record'
              pageText='Pagina'
              ofText='di'
              rowsText='righe'
              pageJumpText='Vai a pagina'
              rowsSelectorText='righe per pagina'
            />
          </div>
        }
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        <TwoActionsAlertDialog
          visible={this.state.twoActionsAlertVisible}
          title={this.state.modalTitle}
          message={this.state.modalMessage}
          onIndietroClicked={this.onPubblicationCancelled}
          onOkClicked={this.onAlertOkButtonPressed}
        />
        {/* {this.state.showModalSelezioneBrand ?
          <ModalSelezioneBrand
            brands={this.state.brands}
            open={this.state.showModalSelezioneBrand}
            modello={this.state.modelloSelezionato}
            isSuperconsulente={this.props.superconsulenteView}
            onSubmit={this.onBrandsSelected}
            onCancel={this.onBrandSelectionAborted}
          />
          : null
        } */}
        {this.state.showModalSelezioneGruppo &&
          <ModalSelezioneGruppo
            gruppoId={this.state.selectedGruppoId}
            gruppi={this.state.gruppi}
            open={this.state.showModalSelezioneGruppo}
            onCancel={this.onCancelGruppoSelection}
            onGruppoSelected={this.saveSelectedGruppoForModello}
            onCreaButtonClicked={this.onCreaButtonInModalClicked}
            origin={this.state.origin}
          />
        }
        {this.state.showModalSelezioneConsulente &&
          <ModalSelezioneConsulente
            isModalOpen={this.state.showModalSelezioneConsulente}
            openEserciziModal={this.handleOpenEserciziModal}
            gruppoId={101}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
            closeModal={this.handleCloseConsulentiModal}
          />
        }
        {this.state.showModalSelezioneEsercizio &&
          <ModalSelezioneEsercizioCommerciale
            idConsulente={this.state.consulenteId}
            gruppoId={101}
            manualeBaseId={this.state.modelloBaseId}
            isModalOpen={this.state.showModalSelezioneEsercizio}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
            closeModal={this.handleCloseEserciziModal}
            openModalitaModal={this.onOpenModalModalita}
          />
        }
        {this.state.showModalSelezioneModalita &&
        <ModalSelezioneModalitaModello
        idConsulente={this.state.consulenteId}
        selectedEsercizio = {this.state.esercizioId}
        gruppoId={this.state.selectedGruppoId}
        isModello={this.state.isModello}
        manualeBaseId={this.state.modelloBaseId}
        open={this.state.showModalSelezioneModalita}
        handleError={this.handleError}
        handleInvalidToken={this.handleInvalidToken}
        onClose={this.onCloseModalModalita}
        onProseguiClicked={this.executeCreationModelloPersonalizzato}
        >
        </ModalSelezioneModalitaModello>
        }
        {this.state.showGoToPageModal &&
          <GoToPageModal
            open={this.state.showGoToPageModal}
            onCancel={this.onGoToPageCanceled}
            message={this.state.goToPageMessage}
            path={this.state.goToPagePath}
          />
        }
        {this.state.loadingModal && 
        <LoadingModal
        open={this.state.loadingModal}
        testo={this.state.isModello ? "Generazione modello in corso..." : "Generazione manuale in corso..."}
        >
        </LoadingModal>
        }
      </div>
    );
  }

}

ModelliPersonalizzatiTable.propTypes = {
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired
}

export default withRouter(ModelliPersonalizzatiTable);