import React from "react";
import Button from "@material-ui/core/Button";

import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import theme from '../../../theme';
import { IconButton } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import {ReactComponent as SettingLogo} from "../../../Modifica.svg";

export default function GoToIcon(props) {
  const history = useHistory();
  return (
    <IconButton
        size="medium"
        style={{
            color: props.disabled ? 
                theme.palette.disabled.main
                :
                (props.grayVersion? theme.palette.secondary.other : theme.palette.cndColors.yellow)
        }}
        onClick={() => {
           if(props.mode === "Scheda"){
            history.push("/amministrazione/schedaProcedura/"+props.id+"/"+props.idManuale+"/"+props.idParagrafo)
           }else if(props.mode === "Procedura"){
            history.push("/amministrazione/dettaglioProcedura/"+props.id+"/"+props.idManuale+"/"+props.idParagrafo)
           }
        }}
    >
    <SettingLogo width={23} height={23} fill={theme.palette.cndColors.yellow}></SettingLogo>
    </IconButton>
);
}