import React, { Component } from 'react';

// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import ErrorDialog from '../../../components/ErrorDialog';
import ActionButton from '../../../components/ActionButton';
import SpinnerComponent from '../../../components/SpinnerComponent';
import ModalDettagliAbbonamento from '../components/ModalDettagliAbbonamento';

import { clearToken } from '../../../utils/storage.js';
import { getAbbonamenti } from '../../../utils/api/abbonamenti_api';

import moment from 'moment';
import 'moment/locale/it';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black'
    }
};

const dateFormat = "DD-MM-YYYY";
const dateTimeFormat = "DD-MM-YYYY HH:mm";

export default class AbbonamentiTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            abbonamenti: [],
            gruppoId: 101,
            loading: false,
            errorDialogVisible: false,
            errorDialogMessage: '',
            showModalDettagli: false,
            showModalRegistrazionePagamento: false,
            Abbonamento: {
                pagamento: {
                    id: null,
                    dataCreazione: null,
                    dataAggiornamento: null,
                    stato: null,
                    totaleLordo: null,
                    totaleNetto: null,
                    tassa: null,
                    nomePayer: null,
                    cognomePayer: null,
                    emailPayer: null,

                }
            }
        };
    }

    componentDidMount() {
        this.fetchAbbonamenti(this.state.gruppoId);
    }

    handleInvalidToken = () => {
        this.handleError(true, 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    fetchAbbonamenti = (gruppoId) => {
        this.setState({
            loading: true,
        });
        getAbbonamenti(gruppoId, null, null, 0, 3000, "id")
            .then(result => {
                this.setState({
                    abbonamenti: result,
                    loading: false
                });
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                    });
                    this.handleError(true, error.message);
                }
            });
    }

    fetchAbbonamento = (row) => {
        this.setState({
            Abbonamento: row,
            showModalDettagli: true,
        });
    }

    handleClose = () => {
        this.setState({
            showModalDettagli: false,
        });
    };

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    handleError = (showModal, errorMessage) => {
        this.setState({
            errorDialogVisible: showModal,
            errorDialogMessage: errorMessage
        });
    }

    openModalRegistrazionePagamento = (abbonamento) => {
        this.setState({
            Abbonamento: abbonamento,
            showModalDettagli: false,
            showModalRegistrazionePagamento: true
        });
        // NB: THE MODAL FOR PAYMENT HAS BEEN REMOVED. 
    }

    render() {
        const columns = [
            {
                Header: 'Numero',
                id: 'numero',
                accessor: row => row,
                width: 120,
                Cell: props => props.index
            },
            {
                Header: 'ID abbonamento',
                id: 'id',
                accessor: d => d.id,
                filterable: true,
                Filter: ({filter, onChange}) => (
                    <TextField
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{width:"100%"}}
                      InputProps={{
                        disableUnderline:true,
                        endAdornment: (<InputAdornment position="end">
                        <IconButton size="small">
                        <Search>
          
                        </Search>
                        </IconButton>
                        </InputAdornment>)
                      }}
                    />
                  ),
                width: 120,
            },
            {
                Header: 'Stato',
                id: 'stato',
                accessor: d => d.stato,
                filterable: true,
                Filter: ({filter, onChange}) => (
                    <TextField
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{width:"100%"}}
                      InputProps={{
                        disableUnderline:true,
                        endAdornment: (<InputAdornment position="end">
                        <IconButton size="small">
                        <Search>
          
                        </Search>
                        </IconButton>
                        </InputAdornment>)
                      }}
                    />
                  ),
                minWidth: 90,
            },
            {
                Header: 'Trial',
                id: 'trial',
                accessor: d => d.trial ? 'Sì' : 'No',
                minWidth: 60,
            },
            {
                Header: 'Data inizio',
                id: 'dataInizio',
                accessor: d => d.dataInizio ? moment(d.dataInizio).locale("it").format(dateFormat) : '-',
                minWidth: 160,
            },
            {
                Header: 'Data fine',
                id: 'dataFine',
                accessor: d => d.dataFine ? moment(d.dataFine).locale("it").format(dateFormat) : '-',
                minWidth: 160,
            },
            {
                Header: 'ID consulente',
                id: 'consulenteId',
                accessor: d => d.consulenteId === null ? ' - ' : d.consulenteId,
                filterable: true,
                Filter: ({filter, onChange}) => (
                    <TextField
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{width:"100%"}}
                      InputProps={{
                        disableUnderline:true,
                        endAdornment: (<InputAdornment position="end">
                        <IconButton size="small">
                        <Search>
          
                        </Search>
                        </IconButton>
                        </InputAdornment>)
                      }}
                    />
                  ),
                width: 120,
            },
            {
                Header: 'ID Punto vendita',
                id: 'esercizioCommercialeId',
                accessor: d => d.esercizioCommercialeId === null ? ' - ' : d.esercizioCommercialeId,
                filterable: true,
                Filter: ({filter, onChange}) => (
                    <TextField
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{width:"100%"}}
                      InputProps={{
                        disableUnderline:true,
                        endAdornment: (<InputAdornment position="end">
                        <IconButton size="small">
                        <Search>
          
                        </Search>
                        </IconButton>
                        </InputAdornment>)
                      }}
                    />
                  ),
                width: 120,
            },
            {
                Header: 'Ragione sociale',
                id: 'ragioneSocialeEsercizio',
                accessor: d => d.ragioneSocialeEsercizio === null ? ' - ' : d.ragioneSocialeEsercizio,
                filterable: true,
                Filter: ({filter, onChange}) => (
                    <TextField
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{width:"100%"}}
                      InputProps={{
                        disableUnderline:true,
                        endAdornment: (<InputAdornment position="end">
                        <IconButton size="small">
                        <Search>
          
                        </Search>
                        </IconButton>
                        </InputAdornment>)
                      }}
                    />
                  ),
                minWidth: 120,
            },
            {
                Header: 'Data pagamento',
                id: 'dataAggiornamento',
                accessor: d => d.pagamento && (d.pagamento.stato !== 'CREATO') ? d.pagamento.dataAggiornamento ? moment(d.pagamento.dataAggiornamento).locale("it").format(dateTimeFormat) : '-' : '-',
                width: 160,
            },
            {
                Header: 'Pagamento',
                id: 'pagamento',
                sortable: false,
                filterable: false,
                width: 180,
                accessor: row => {
                    if (row.pagamento === null) {
                        return (
                            <ActionButton
                                label="Registra"
                                onClick={() => this.openModalRegistrazionePagamento(row)}
                                disabled={true}
                                grayVersion={true}
                                margin= '0px'
                            />);
                    } else {
                        if (row.pagamento.stato === 'INSERITO'
                            || row.pagamento.stato === 'CREATO'
                            || row.pagamento.stato === 'APPROVATO'
                            || row.pagamento.stato === 'PENDENTE'
                            || row.pagamento.stato === 'COMPLETATO') {
                            return (
                                <ActionButton
                                    label="DETTAGLI"
                                    onClick={() => this.fetchAbbonamento(row)}
                                    disabled={false}
                                    grayVersion={false}
                                    margin= '0px'
                                />);
                        } else {
                            return (
                                <ActionButton
                                    size="small"
                                    label="Registra"
                                    onClick={() => this.openModalRegistrazionePagamento(row)}
                                    disabled={true}
                                    grayVersion={true}
                                    margin= '0px'
                                />
                            );
                        }
                    }
                }
            }
        ];
        return (
            <div style={{ paddingTop: "30px" }}>

                {this.state.loading ?
                    <SpinnerComponent size={24} />
                    :
                    <div style={styles.mainContainer}>
                        <ReactTable
                            filterable={false}
                            resizable={true}
                            sortable={false}
                            showPageSizeOptions={true}
                            showPageJump={true}
                            defaultPageSize={10}
                            data={this.state.abbonamenti}
                            columns={columns}
                            defaultFilterMethod={(filter, row,column) => {
                                        const id = filter.pivotId || filter.id;
                                        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                                    }}
                            previousText='Precedente'
                            nextText='Successivo'
                            noDataText='Nessun record'
                            pageText='Pagina'
                            ofText='di'
                            rowsText='righe'
                            pageJumpText='Vai a pagina'
                            rowsSelectorText='righe per pagina'
                        />
                    </div>
                }
                <ModalDettagliAbbonamento
                    Abbonamento={this.state.Abbonamento}
                    handleClose={this.handleClose}
                    modal={this.state.showModalDettagli}
                    isConsulente={false}
                />
                <ErrorDialog
                    open={this.state.errorDialogVisible}
                    message={this.state.errorDialogMessage}
                    onCloseButtonClicked={this.closeErrorDialog}
                />
            </div>
        );
    }

}