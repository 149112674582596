import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

import PropTypes from 'prop-types';
import theme from '../theme.js';

const styles = {
    div_spinner: {
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    spinner: {
        color: theme.palette.cndColors.yellow
    },
}

export default function SpinnerComponent (props) {
    return(
        <div style={styles.div_spinner}>
            <CircularProgress
                style={styles.spinner}
                size={props.size}
            />
        </div>
    );
}

SpinnerComponent.propTypes = {
    size: PropTypes.number.isRequired
}

