import React, { Component, Fragment } from 'react';
// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PropTypes from 'prop-types';

import SpinnerComponent from '../../../components/SpinnerComponent';
import IndietroButton from '../../../components/IndietroButton';
import ErrorDialog from '../../../components/ErrorDialog';
import { clearToken } from '../../../utils/storage';
import { getEserciziCommerciali, getEsercizioCommerciale, updateEsercizioCommerciale } from '../../../utils/api/esercizi_commerciali_api';
import theme from '../../../theme.js';

export default class EserciziXConsulenteComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            esercizi: [],
            consulenteId: null,
            errorDialogVisible: false,
            errorDialogMessage: '',
            loading: true,
            recordNotFound: true
        };
    }

    componentDidMount() {
        if (this.props.consulente !== null) {
            this.fetchEserciziCommerciali();
        }
    }

    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    fetchEserciziCommerciali = () => {
        this.setState({
            loading: true,
        });
        getEserciziCommerciali(this.props.consulente.gruppoId, null, null, 0, 1000, "id")
        .then(result => {
            this.setState({
                loading: false,
                esercizi: result,
                recordNotFound: false
            });
        })
        .catch(error => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: error.message
            });
          }
        });
    }

    associateEsercizioCommerciale = (esercizioCommerciale) => {
        this.setState({
            loading: true,
        });
        getEsercizioCommerciale(esercizioCommerciale.id)
        .then(result => {
            let data = result;
            data.esercizio.consulenteId = this.props.consulente.id;
            this.updateEsercizio(data);
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    errorDialogVisible: true,
                    errorDialogMessage: error.message,
                });
            }
        });
    }

    updateEsercizio = (record) => {
        updateEsercizioCommerciale(record)
        .then(result => {
            this.fetchEserciziCommerciali();
        })
        .catch(error => {
            if (error.status === 403) {
               this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    errorDialogVisible: true,
                    errorDialogMessage: error.message,
                });
            }
        });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    render() {
        let consID = this.props.consulente.id;
        let esercizi = this.state.esercizi;
        const columns = [
            {
                Header: 'ID',
                id: 'id',
                accessor: d => d.id,
                minWidth: 80,
            },
            {
                Header: 'Ragione sociale',
                id: 'ragioneSociale',
                accessor: d => d.ragioneSociale,
                minWidth: 180,
            },
            {
                Header: 'Codice MURO',
                id: 'codiceMuro',
                accessor: d => d.codiceMuro,
                minWidth: 120,
            },
            {
                Header: 'Codice Socio',
                id: 'codiceSocio',
                accessor: d => d.codiceSocio,
                minWidth: 120,
            },
            {
                Header: 'Comune',
                id: 'comune',
                accessor: d => d.comuneSede,
                minWidth: 180,
            },
            {
                id: 'associaEsercizioBtn',
                Header: 'Associa a consulente',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 180,
                Cell: props => 
                    props.value.consulenteId === consID ?
                    <IconButton
                        size="small" 
                        style={{ color: theme.palette.success.main }}
                        disabled={true}
                    >
                        <CheckCircleOutlineIcon />
                    </IconButton>
                    :
                    <IconButton
                        size="small" 
                        style={{
                            color: props.value.consulenteId !== null? theme.palette.disabled.main : theme.palette.cndColors.yellow,
                        }}
                        disabled={ props.value.consulenteId !== null }
                        onClick={ () => {this.associateEsercizioCommerciale(props.value)} }
                    >
                        <AddCircleOutlineIcon />
                    </IconButton>
            }
        ];

        return (
            <Container id="mainContainer" style={{ paddingLeft: '4px', paddingRight: '4px', minHeight: '500px' }} >
                {this.state.loading ?
                    <SpinnerComponent size={24} />
                    :
                    <Fragment>
                        { this.state.recordNotFound ?
                            <Typography variant="h6" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Nessun record trovato</Typography>
                            :
                            <Fragment>
                            <Typography variant="h6" style={{ paddingTop: '6px', paddingBottom: '20px' }} >Consulente presso punti vendita:</Typography>
                            <Container id="tableContainer" style={{ paddingBottom: '20px' }} >
                                <ReactTable
                                    filterable={true}
                                    resizable={false}
                                    showPageSizeOptions={true}
                                    showPageJump={true}
                                    defaultPageSize={10}
                                    defaultFilterMethod={(filter, row,column) => {
                                        const id = filter.pivotId || filter.id;
                                        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                                    }}
                                    //pages={this.state.pages}
                                    data={esercizi}
                                    columns={columns}
                                    //manual // informs React Table that you'll be handling sorting and pagination server-side
                                    //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                    previousText='Precedente'
                                    nextText='Successivo'
                                    noDataText='Nessun record'
                                    pageText='Pagina'
                                    ofText='di'
                                    rowsText='righe'
                                    pageJumpText='Vai a pagina'
                                    rowsSelectorText='righe per pagina'
                                />
                            </Container>
                            </Fragment>
                        }
                        <Grid container justify="center" style={{ paddingBottom:'20px', paddingLeft:'24px', paddingRight:'24px' }} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                                <IndietroButton isDisabled={this.state.loading} />
                            </Grid>
                        </Grid>
                    </Fragment>
                }
                <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} ></ErrorDialog>
            </Container>
        );
    }

}

EserciziXConsulenteComponent.propTypes = {
    consulente: PropTypes.object
}