import React from "react";
import { Checkbox, Modal, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ReactTable from "react-table";
import ActionButton from "../../../components/ActionButton";
import theme from "../../../theme";
import PropTypes from "prop-types";

const styles = (theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    borderRadius: "6px",
    boxShadow: theme.shadows[6],
    //height: '85%'
    overflowY: "auto",
    width: "65%",
    maxWidth: "1100px",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
  modalSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    gap: "20px",
  },
});

class ModalSelezioneAssociazione extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      columns: [],
      isButtonsAllChecked: true,
    };
  }
  searchForIdReparto = (name) => {
    let a = null;
    this.props.reparti.map((item) => {
      if (item.nome === name) {
        a = item.id;
      }
    });
    return a;
  };

  componentDidMount = () => {
    let a = [];
    if (this.props.mode === "Attrezzature") {
      let attrezzatureRecords = [];
      this.props.attrezzature.map((item) => {
        if (
          item.tipologiaStrumentazioneId ===
          this.props.controlloSelezionato.selectedTipologiaElemento
        ) {
          let newRecord = {};
          if (this.props.controlloSelezionato.attrezzature.includes(item.id)) {
            newRecord = {
              checked: true,
              nomeReparto: item.nomeReparto,
              nomeLocale: item.nomeLocale,
              id: item.id,
              idTipologia: item.tipologiaStrumentazioneId,
              nomeElemento: item.nome,
            };
          } else {
            newRecord = {
              checked: false,
              nomeReparto: item.nomeReparto,
              nomeLocale: item.nomeLocale,
              id: item.id,
              idTipologia: item.tipologiaStrumentazioneId,
              nomeElemento: item.nome,
            };
          }
          let idToAdd = this.searchForIdReparto(item.nomeReparto);
          newRecord["repartoId"] = idToAdd;
          attrezzatureRecords.push(newRecord);
        }
      });
      this.setState(
        {
          records: attrezzatureRecords,
          isButtonsAllChecked: true,
        },
        () => {
          this.checkIfAllAreSelected();
        }
      );
      a = this.getColumnTabellaAttrezzature();
    } else {
      let repartiRecords = [];
      this.props.reparti.map((item) => {
        let newRecord = {};
        if (!item.repartoDefault) {
          if (this.props.controlloSelezionato.reparti.includes(item.id)) {
            newRecord = {
              nomeReparto: item.nome,
              checked: true,
              idReparto: item.id,
              repartoDefault: false,
            };
          } else {
            newRecord = {
              nomeReparto: item.nome,
              checked: false,
              idReparto: item.id,
              repartoDefault: false,
            };
          }
          repartiRecords.push(newRecord);
        }
      });
      if (this.props.reparti.length === 1) {
        let newRecord = {
          nomeReparto: this.props.reparti[0].nome,
          checked: true,
          idReparto: this.props.reparti[0].id,
          repartoDefault: true,
        };
        repartiRecords.push(newRecord);
      }
      this.setState(
        {
          records: repartiRecords,
          isButtonsAllChecked: true,
        },
        () => {
          this.checkIfAllAreSelected();
        }
      );
      a = this.getColumnTabellaReparti();
    }
    this.setState({
      columns: a,
    });
  };

  getColumnTabellaReparti = () => {
    return [
      {
        Header: "Reparto",
        id: "Reparto",
        width: 420,
        accessor: (d) => d,
        Cell: (props) => (
          <TextField
            contentEditable={false}
            style={{ margin: 10 }}
            InputProps={{ disableUnderline: true }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={props.row.Reparto.nomeReparto}
          />
        ),
      },
      {
        Header: "Associa",
        id: "Associa",
        width: 250,
        accessor: (d) => d,
        Cell: (props) => (
          <Checkbox
            onClick={() => {
              let array = [...this.state.records];
              array[props.index].checked = !array[props.index].checked;
              this.setState(
                {
                  records: array,
                  isButtonsAllChecked: true,
                },
                () => {
                  this.checkIfAllAreSelected();
                }
              );
            }}
            checked={props.row.Reparto.checked}
            disabled={props.row.Associa.repartoDefault}
            style={{
              margin: 10,
              color: props.row.Associa.repartoDefault
                ? theme.palette.disabled.main
                : theme.palette.cndColors.yellow,
              "&.MaterialUiChecked": {
                color: theme.palette.cndColors.yellow,
              },
            }}
          />
        ),
      },
    ];
  };

  getColumnTabellaAttrezzature = () => {
    return [
      {
        Header: "Elemento Osservato",
        id: "ElementoOsservato",
        width: 250,
        accessor: (d) => d.nomeElemento,
        Cell: (props) => (
          <TextField
            contentEditable={false}
            style={{ margin: 10 }}
            InputProps={{ disableUnderline: true }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={props.row.ElementoOsservato}
          />
        ),
      },
      {
        Header: "Reparto",
        id: "Reparto",
        width: 250,
        accessor: (d) => d.nomeReparto,
        Cell: (props) => (
          <TextField
            contentEditable={false}
            style={{ margin: 10 }}
            InputProps={{ disableUnderline: true }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={props.row.Reparto}
          />
        ),
      },
      {
        Header: "Locale",
        id: "Locale",
        width: 250,
        accessor: (d) => d.nomeLocale,
        Cell: (props) => (
          <TextField
            contentEditable={false}
            style={{ margin: 10 }}
            InputProps={{ disableUnderline: true }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={props.row.Locale}
          />
        ),
      },
      {
        Header: "Associa",
        id: "Associa",
        width: 250,
        accessor: (d) => d,
        Cell: (props) => (
          <Checkbox
            onClick={() => {
              let array = [...this.state.records];
              array[props.index].checked = !array[props.index].checked;
              this.setState(
                {
                  records: array,
                  isButtonsAllChecked: true,
                },
                () => {
                  this.checkIfAllAreSelected();
                }
              );
            }}
            checked={props.row.Associa.checked}
            style={{
              margin: 10,
              color: theme.palette.cndColors.yellow,
              "&.MaterialUiChecked": {
                color: theme.palette.cndColors.yellow,
              },
            }}
          />
        ),
      },
    ];
  };

  selectAll = () => {
    let array = [...this.state.records];
    if (array.length !== 0 && !array[0].repartoDefault) {
      if (this.state.isButtonsAllChecked === true) {
        array.map((item) => {
          item.checked = false;
        });
      } else {
        array.map((item) => {
          item.checked = true;
        });
      }
      this.setState({
        records: array,
        isButtonsAllChecked: !this.state.isButtonsAllChecked,
      });
    }
  };

  checkIfAllAreSelected = () => {
    let array = [...this.state.records];
    array.map((item) => {
      if (!item.checked) {
        this.setState({
          isButtonsAllChecked: false,
        });
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Modal
        className={classes.modal}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <div className={classes.modalContainer}>
          <div className={classes.modalSectionContainer}>
            {this.props.mode === "Attrezzature" ? (
              <Typography style={{ align: "center", fontSize: 30 }}>
                Attrezzature
              </Typography>
            ) : (
              <Typography style={{ align: "center", fontSize: 30 }}>
                Reparti
              </Typography>
            )}
            <ActionButton
              label={
                !this.state.isButtonsAllChecked
                  ? "Seleziona tutto"
                  : "Deseleziona tutto"
              }
              onClick={this.selectAll}
            />
            <ReactTable
              filterable={false}
              resizable={true}
              enableEditing={true}
              showPageSizeOptions={false}
              previousText="Precedente"
              nextText="Successivo"
              pageText="Pagina"
              ofText="di"
              showPageJump={true}
              defaultPageSize={5}
              data={this.state.records}
              columns={this.state.columns}
            />
            {this.props.mode === "Attrezzature" ? (
              <div>
                <ActionButton
                  isRed={true}
                  label="Chiudi"
                  onClick={this.props.onClose}
                />
                <ActionButton
                  label="Salva"
                  onClick={() =>
                    this.props.saveValues(this.state.records, "Attrezzatura")
                  }
                />
              </div>
            ) : (
              <div>
                <ActionButton
                  isRed={true}
                  label="Chiudi"
                  onClick={this.props.onClose}
                />
                <ActionButton
                  label="Salva"
                  onClick={() =>
                    this.props.saveValues(this.state.records, "Reparto")
                  }
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

ModalSelezioneAssociazione.propTypes = {
  attrezzature: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
  controlloSelezionato: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reparti: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveValues: PropTypes.func.isRequired,
};

export default withStyles(styles)(ModalSelezioneAssociazione);
