import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Modal from '@material-ui/core/Modal';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import PropTypes from 'prop-types';
import theme from '../../../../theme.js';

import ActionButton from '../../../../components/ActionButton';
import CustomTable from "../../components/CustomTable";

const styles = {
    modalcontainer: {
        //overflowY: 'scroll',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        //height: 454,
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
        border: '2px solid #000',
        padding: '20px',
        maxHeight: '560px',
        overflowY: 'scroll'
    },
    tableContainer: {
        padding: '12px 4px 12px 4px',
        //overflowY: 'scroll',
        //height: 520,
    }
}

export default class ModalResponsabiliPerReparto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selection: []
        };
    }

    componentDidMount() {
        let selection = this.getResponsabiliIds();
        this.setState({
            selection
        });
    }

    getResponsabiliIds = () => {
        let output = [];
        if (this.props.reparto.responsabili !== undefined && this.props.reparto.responsabili !== null) {
            for (let i=0; i<this.props.reparto.responsabili.length; i++) {
                output.push(this.props.reparto.responsabili[i]);
            }
        }
        return output;
    }

    updateSelection = (isAdded, id) => {
        let selection = [];
        if (isAdded) {
            selection = this.state.selection;
            selection.push(id);
        } else {
            selection = this.state.selection.filter(function(respId) { return respId !== id; });
        }
        this.setState({
            selection
        });
    }
    
    onConfermaClicked = () => {
        this.props.onSubmit(this.state.selection);
    }

    render () {
        const columns = [
            {
                Header: 'ID',
                id: 'id',
                accessor: d => d.id,
                minWidth: 80,
            },
            {
                Header: 'Email',
                id: 'email',
                accessor: d => d.email,
                minWidth: 120,
            },
            {
                Header: 'Nome',
                id: 'nome',
                accessor: d => d.nome,
                minWidth: 120,
            },
            {
                Header: 'Cognome',
                id: 'cognome',
                accessor: d => d.cognome,
                minWidth: 120,
            },
            {
                id: 'associaResponsabileBtn',
                Header: 'Associa a reparto',
                accessor: row => row,
                sortable: false,
                filterable: false,
                resizable: false,
                width: 140,
                Cell: props => 
                    this.state.selection.includes(props.value.id) ?
                    <IconButton
                        size="small" 
                        style={{ color: theme.palette.success.main }}
                        disabled={false}
                        onClick={ () => {this.updateSelection(false, props.value.id)} }
                    >
                        <CheckCircleOutlineIcon />
                    </IconButton>
                    :
                    <IconButton
                        size="small" 
                        style={{ color: theme.palette.cndColors.yellow}}
                        disabled={false}
                        onClick={ () => {this.updateSelection(true, props.value.id)} }
                    >
                        <AddCircleOutlineIcon />
                    </IconButton>
            }
        ];

        return(
            <Modal
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <div style={styles.modalcontainer}>
                    <Typography variant="h6" style={styles.text} >
                    {"Seleziona i responsabili per il reparto: " + this.props.reparto.nome}
                    </Typography>
                    <Container style={styles.tableContainer} >
                        <CustomTable
                            columns={columns}
                            records={this.props.responsabiliEsercizio}
                            loading={false}
                            filterable={true}
                            resizable={true}
                            sortable={true}
                            defaultPageSize={5}
                        />
                    </Container>
                    <Container style={{ textAlign: 'center' }}  >
                        <ActionButton
                            label="Indietro"
                            onClick={this.props.onClose}
                            disabled={false}
                            grayVersion={true}
                        />
                        <ActionButton
                            label="Conferma"
                            onClick={this.onConfermaClicked}
                            disabled={false}
                            grayVersion={false} 
                        />
                    </Container>
                </div>
        </Modal>
        );
    }
}

ModalResponsabiliPerReparto.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    reparto: PropTypes.object.isRequired,
    responsabiliEsercizio: PropTypes.array.isRequired,
}
