import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';
import theme from '../../../theme.js';
import {ReactComponent as SettingLogo} from "../../../Modifica.svg";
import { setChapterIndex, setOpenParti, setParagraphIndex } from "../../../utils/storage.js";

export default function DettaglioManualeButton(props) {

  let history = useHistory();
  const match = useRouteMatch();
  return (
    <IconButton 
    size="small" 
    disabled={props.disabled}
    style={{
      color: props.disabled ? theme.palette.disabled.main : theme.palette.cndColors.yellow,
    }}
    onClick={() => { 
      setChapterIndex(0);
      setParagraphIndex(0);
      setOpenParti(JSON.stringify({
          parti:[],
          capitoli:[],
          paragrafi:[]
        }))
      history.push(`${match.url}/dettagli/${props.revisioneManualeId}`) }} >
      <SettingLogo width={23} height={23} fill={props.disabled ? theme.palette.disabled.main : theme.palette.cndColors.yellow} />
    </IconButton>
  );

}

DettaglioManualeButton.propTypes = {
  revisioneManualeId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired
}