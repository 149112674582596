import { Component } from "react";
import React from "react";
import Modal from '@material-ui/core/Modal';
import {Typography } from "@material-ui/core";
import { MenuItem, Select } from '@material-ui/core';
import ActionButton from "../../../components/ActionButton";

const styles = {
    modalcontainer: {
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '10%',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '200px',
        display:"flex",
    },
    miniModalContainer:{
      flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '300px',
        display:"flex",
    },
    modalsectioncontainer: {
        flex: 1,
        display: "flex",
        //marginTop: 20,
        //marginBottom: 20,
        flexDirection: "column",
        justifyContent: 'space-evely',

    },
    buttoncontainer: {
      justifyContent: 'center',
      padding: '10px 0px',
    },
    modalTitle:{
      fontSize:20,
      alignSelf:"center",
    }
}

export default class ModalSelezioneModalitaModello extends Component{
    constructor(props){
        super(props);
        this.state = {
            tipologiaItem:true,
        }
    }
    render(){
    return(
    <Modal
            open={this.props.open}
            onClose={this.props.onClose}
            >
            <div style={styles.modalcontainer}>
                    <div style={styles.modalsectioncontainer}>
                    <Typography style={styles.modalTitle}>Si desidera inserire i capitoli nel proprio manuale?</Typography>
                    <Select style={{marginTop:"5%"}} value = {this.state.tipologiaItem} onChange={(e) => {
                        this.setState({
                            tipologiaItem: e.target.value,
                        })
                    }}>
                        <MenuItem value={true}>Si</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </Select>
                    <div style={styles.buttoncontainer}>
                    <ActionButton label="Prosegui" onClick = {() => {      
                            this.props.onProseguiClicked(this.props.manualeBaseId,this.props.gruppoId,this.state.tipologiaItem, this.props.isModello)
                    }} disabled={false}></ActionButton>
                        <ActionButton label="Indietro" onClick = {() => {this.props.onClose()}} disabled={false}
                grayVersion={false}
                showAlert={false}></ActionButton>
                    </div>
                    </div>
            </div>

    </Modal>
    )}
}