import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

import theme from "../../../theme.js";

const DialogTitle = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          {" "}
          <CloseIcon />{" "}
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

export default function ModalAvvisoDaEditare(props) {
  return (
    <Dialog open={props.open} onClose={props.onIndietroSelected}>
      <DialogTitle>ATTENZIONE</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Uno degli elementi presenti nel manuale risulta ancora da
          personalizzare.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onIndietroSelected}
          style={{ color: theme.palette.primary.main }}
        >
          Indietro
        </Button>
        <Button
          onClick={() => {
            props.onOkSelected()
            props.onIndietroSelected();
          }}
          style={{ color: theme.palette.primary.main }}
        >
          Pubblica comunque
        </Button>
      </DialogActions>
    </Dialog>
  );
}
