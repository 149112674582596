import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { DatePicker, TimePicker, MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import {ReactComponent as ClockLogo} from "../../../Clock.svg";
import {ReactComponent as CalendarLogo} from "../../../Calendario.svg";
import DateFnsUtils from "@date-io/date-fns";
import itLocale from "date-fns/locale/it";
import React,{ Component } from "react";
import theme from "../../../theme";
import { Schedule } from "@material-ui/icons";
const dateFormat = "dd-MM-yyyy";

const styles = {
   mainContainer:{
    
   },
   title:{
    fontSize: 25,
    fontWeight: 500,
    lineHeight: 1.6,
   },
   pickerContainer:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width:'50%',
    marginLeft: "25%",
   },
   pickerContainerPeriodo:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width:'100%',
    marginLeft: "0",
   },
   pickerLabel:{
    display: "flex",
    justifyContent: "space-between",
    background: "linear-gradient(white, white) padding-box, linear-gradient(to right, #ff4600, #f7a616, #ff4600) border-box",
    borderRadius: "50em",
    border: "1px solid transparent",
    padding:"1em"
   },
   pickerLabel2:{
    display:"flex",
    justifyContent:"space-between",
    background: "linear-gradient(white, white) padding-box, linear-gradient(to right, #ff4600, #f7a616, #ff4600) border-box",
    borderRadius: "50em",
    border: "1px solid transparent",
    padding:"1em",
    marginTop:20
   },
   pickerLabelPeriodo:{
    display: "flex",
    justifyContent: "space-between",
    background: "linear-gradient(white, white) padding-box, linear-gradient(to right, #ff4600, #f7a616, #ff4600) border-box",
    borderRadius: "50em",
    border: "1px solid transparent",
    padding:"1em",
    marginTop:20
   },
   labelStyle:{
    fontSize:20,
   },
   labelPartRight:{
    //justifyContent: "center",
    //display: "flex",
    //marginRight:50,
   },
   labelPartLeft:{
    //justifyContent: "center",
    //display: "flex",
    //marginLeft:50,
   }
};

export default class OrarioStraordinarioComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            checked:true,
            orarioDiInizio:null,
            orarioDiFine:null,
            dataInizio:this.props.dataInizio,
            dataFine:this.props.dataFine,
            mode:this.props.mode,
            apertura:this.props.aperto,
        }
    }
    
    handleDataInizio(data){
        this.props.handleDataInizio(data);
    }
    handleDataFine(data){
        this.props.handleDataFine(data);
    }
    handleOrarioInizio(data){
        this.props.handleOrarioInizio(data);
    }
    handleOrarioFine(data){
        this.props.handleOrarioFine(data);
    }
    handleInteraGiornata(data){
        this.props.handleInteraGiornata(data);
    }

    componentDidMount(){
        
    }

    render(){
        return(
          <div style={styles.mainContainer}>
            {(this.props.mode === "SPECIFICO" && this.state.apertura === false) && <><div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Punto vendita chiuso per l'intera giornata"
            control={
            <Checkbox
            style={{
                                                                color: theme.palette.cndColors.yellow,
                                                                '&.MaterialUiChecked': {
                                                                  color:  theme.palette.cndColors.yellow,
                                                                },
                                                            }}
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerContainer}>
            <div style={styles.pickerLabel}>
                <div style={styles.labelStyle}>Chiuso dalle</div>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                    //console.log("Orario Specifico di inizio"+newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                ></KeyboardTimePicker>
            </div>
            <div style={styles.pickerLabel2}>
                <div style={styles.labelStyle}>Alle</div>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                    //console.log("Orario specifico finale"+newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></KeyboardTimePicker>
            </div>
            </div>
            </>}
            {(this.props.mode === "SPECIFICO" && this.state.apertura === true) && <><div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Punto vendita aperto per l'intera giornata"
            control={
            <Checkbox
            style={{
                                                                color: theme.palette.cndColors.yellow,
                                                                '&.MaterialUiChecked': {
                                                                  color:  theme.palette.cndColors.yellow,
                                                                },
                                                            }} 
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerContainer}>
            <div style={styles.pickerLabel}>
                <div style={styles.labelStyle}>Aperto dalle</div>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                    //console.log("Orario Specifico di inizio"+newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></KeyboardTimePicker>
            </div>
            <div style={styles.pickerLabel2}>
                <div style={styles.labelStyle}>Alle</div>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                    //console.log("Orario specifico finale"+newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></KeyboardTimePicker>
            </div>
            </div>
            </>}
            {(this.props.mode === "PERIODO" && this.state.apertura === false) && <><div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Punto vendita chiuso per l'intera giornata"
            control={
            <Checkbox
            style={{
                                                                color: theme.palette.cndColors.yellow,
                                                                '&.MaterialUiChecked': {
                                                                  color:  theme.palette.cndColors.yellow,
                                                                },
                                                            }}
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerContainerPeriodo}>
                <div style={styles.pickerLabelPeriodo}>
                <div style={styles.labelStyle}>Chiuso dalle</div>
                <div style={{marginLeft:40}}>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></KeyboardTimePicker>
                </div>
                </div>
                <div style={styles.pickerLabelPeriodo}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{marginLeft:63}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <KeyboardDatePicker onChange={(data) => {
                    this.setState({
                        dataInizio:data,
                    })
                    this.handleDataInizio(data);
                }} 
                keyboardIcon={<CalendarLogo width={22} height={22} fill={"black"} />}
                disabled={false} 
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                format={dateFormat}
                value={this.state.dataInizio}>
                </KeyboardDatePicker>
                </MuiPickersUtilsProvider>
                </div>
            </div>
            <div style={styles.pickerLabelPeriodo}>
                <div style={styles.labelStyle}>Alle</div>
                <div style={{marginLeft:40}}>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></KeyboardTimePicker>
                </div>
                </div>
                <div style={styles.pickerLabelPeriodo}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{marginLeft:63}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <KeyboardDatePicker
                InputAdornmentProps={{ position: "start" }}
                keyboardIcon={<CalendarLogo width={22} height={22} fill={"black"} />}
                InputProps={{ disableUnderline: true }}
                onChange={(data) => {
                    this.setState({
                        dataFine:data,
                    })
                    this.handleDataFine(data);
                }}
                format={dateFormat}
                disabled={false}
                value = {this.state.dataFine}
                >

                </KeyboardDatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            </>}
            {(this.props.mode === "PERIODO" && this.state.apertura === true) && <><div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Punto vendita aperto per l'intera giornata"
            control={
            <Checkbox
            style={{
                                                                color: theme.palette.cndColors.yellow,
                                                                '&.MaterialUiChecked': {
                                                                  color:  theme.palette.cndColors.yellow,
                                                                },
                                                            }} 
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerContainerPeriodo}>
                <div style={styles.pickerLabelPeriodo}>
                <div style={styles.labelStyle}>Aperto dalle</div>
                <div style={{marginLeft:40}}>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></KeyboardTimePicker>
                </div>     
            </div>
            <div style={styles.pickerLabelPeriodo}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{marginLeft:63}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <KeyboardDatePicker onChange={(data) => {
                    this.setState({
                        dataInizio:data,
                    })
                    this.handleDataInizio(data);
                }} 
                keyboardIcon={<CalendarLogo width={22} height={22} fill={"black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                disabled={false} 
                value={this.state.dataInizio}
                format = {dateFormat}
                >
                </KeyboardDatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            <div style={styles.pickerLabelPeriodo}>
                <div style={styles.labelStyle}>Alle</div>
                <div style={{marginLeft:40}}>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                ampm={false}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></KeyboardTimePicker>
                </div>
                </div>
                <div style={styles.pickerLabelPeriodo}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{marginLeft:63}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <KeyboardDatePicker
                InputAdornmentProps={{ position: "start" }}
                keyboardIcon={<CalendarLogo width={22} height={22} fill={"black"} />}
                InputProps={{ disableUnderline: true }}
                onChange={(data) => {
                    this.setState({
                        dataFine:data,
                    })
                    this.handleDataFine(data);
                }}
                disabled={false}
                value = {this.state.dataFine}
                format = {dateFormat}
                >
                </KeyboardDatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            </>}
            {(this.props.mode === "ORARIO" && this.state.apertura === false) && 
            <>
            <div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Punto vendita chiuso per l'intera giornata"
            control={
            <Checkbox
            style={{
                                                                color: theme.palette.cndColors.yellow,
                                                                '&.MaterialUiChecked': {
                                                                  color:  theme.palette.cndColors.yellow,
                                                                },
                                                            }}
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerContainer}>
            <div style={styles.pickerLabel}>
                <div style={styles.labelStyle}>Chiuso dalle</div>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></KeyboardTimePicker>
            </div>
            <div style={styles.pickerLabel2}>
                <div style={styles.labelStyle}>Alle</div>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></KeyboardTimePicker>
            </div>
            </div>
            </>}
            {(this.props.mode === "ORARIO" && this.state.apertura === true) && 
            <>
            <div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Punto vendita aperto per l'intera giornata"
            control={
            <Checkbox
            style={{
                                                                color: theme.palette.cndColors.yellow,
                                                                '&.MaterialUiChecked': {
                                                                  color:  theme.palette.cndColors.yellow,
                                                                },
                                                            }} 
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerContainer}>
            <div style={styles.pickerLabel}>
                <div style={styles.labelStyle}>Aperto dalle</div>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></KeyboardTimePicker>
            </div>
            <div style={styles.pickerLabel2}>
                <div style={styles.labelStyle}>Alle</div>
                <KeyboardTimePicker
                ampm={false}
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true }}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></KeyboardTimePicker>
            </div>
            </div>
            </>}
            {(this.props.mode === "DATAEORARIO" && this.state.apertura === false) && <><div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Punto vendita chiuso per l'intera giornata"
            control={
            <Checkbox
            style={{
                                                                color: theme.palette.cndColors.yellow,
                                                                '&.MaterialUiChecked': {
                                                                  color:  theme.palette.cndColors.yellow,
                                                                },
                                                            }}
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerContainer}>
            <div style={styles.pickerLabel}>
                <div style={{display:"flex"}}>
                <div style={styles.labelStyle}>Chiuso dalle</div>
                <div style={{}}>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true, style: {marginTop:15} }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></KeyboardTimePicker>
                </div>
                </div>
                <div style={{display:"flex"}}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <KeyboardDatePicker onChange={(data) => {
                    this.setState({
                        dataInizio:data,
                    })
                    this.handleDataInizio(data);
                }} 
                disabled={false} 
                format = {dateFormat}
                keyboardIcon={<CalendarLogo width={22} height={22} fill={"black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true, style: {marginTop:15} }}
                value={this.state.dataInizio}>
                </KeyboardDatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            <div style={styles.pickerLabel2}>
            <div style={{display:"flex"}}>
                <div style={{fontSize:20,margin:15}}>Alle</div>
                <div style={{}}>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true, style: {marginTop:15} }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></KeyboardTimePicker>
                </div>
                </div>
                <div style={{display:"flex"}}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <KeyboardDatePicker
                InputAdornmentProps={{ position: "start" }}
                keyboardIcon={<CalendarLogo width={22} height={22} fill={"black"} />}
                InputProps={{ disableUnderline: true, style: {marginTop:15} }}
                onChange={(data) => {
                    this.setState({
                        dataFine:data,
                    })
                    this.handleDataFine(data);
                }}
                disabled={false}
                value = {this.state.dataFine}
                format = {dateFormat}
                >

                </KeyboardDatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            </div>
            </>}
            {(this.props.mode === "DATAEORARIO" && this.state.apertura === true) && <><div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Punto vendita aperto per l'intera giornata"
            control={
            <Checkbox
            style={{
                                                                color: theme.palette.cndColors.yellow,
                                                                '&.MaterialUiChecked': {
                                                                  color:  theme.palette.cndColors.yellow,
                                                                },
                                                            }} 
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerContainer}>
            <div style={styles.pickerLabel}>
                <div style={{display:"flex"}}>
                <div style={styles.labelStyle}>Aperto dalle</div>
                <div style={{}}>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true, style: {marginTop:15} }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></KeyboardTimePicker>
                </div>
                </div>
                <div style={{display:"flex"}}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <KeyboardDatePicker 
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true, style: {marginTop:15} }}
                keyboardIcon={<CalendarLogo width={22} height={22} fill={"black"} />}
                onChange={(data) => {
                    this.setState({
                        dataInizio:data,
                    })
                    this.handleDataInizio(data);
                }} 
                disabled={false} 
                value={this.state.dataInizio}
                format={dateFormat}
                >
                </KeyboardDatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            <div style={styles.pickerLabel2}>
            <div style={{display:"flex"}}>
                <div style={{fontSize:20,margin:15}}>Alle</div>
                <div style={{}}>
                <KeyboardTimePicker
                keyboardIcon={<ClockLogo width={22} height={22} fill={this.state.checked ? "#e0e0e0" : "black"} />}
                InputAdornmentProps={{ position: "start" }}
                InputProps={{ disableUnderline: true, style: {marginTop:15} }}
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></KeyboardTimePicker>
                </div>
                </div>
                <div style={{display:"flex"}}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <KeyboardDatePicker
                InputAdornmentProps={{ position: "start" }}
                keyboardIcon={<CalendarLogo width={22} height={22} fill={"black"} />}
                InputProps={{ disableUnderline: true, style: {marginTop:15} }}
                onChange={(data) => {
                    this.setState({
                        dataFine:data,
                    })
                    this.handleDataFine(data);
                }}
                disabled={false}
                value = {this.state.dataFine}
                format={dateFormat}
                >
                </KeyboardDatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            </div>
            </>}
          </div>
        );
    }
}