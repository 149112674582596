import { retrieveToken } from '../storage';
import { wrapFetch, ApiError } from './api';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getCapitoliCount = (id) => {
    const token = retrieveToken();
    const authtoken = 'Bearer '.concat(token);
    const PATH = "/api/capitoli/count";
    let query = PATH+"?manualeId.equals="+id;
    const URL = BASE_URL + query;
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
            message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        }
        return new ApiError(status, message);
    };
    return wrapFetch(
        fetch(URL, {
            method: 'GET',
            headers: {
                'Authorization': authtoken
            },
            withCredentials: true,
            credentials: 'include'
        }), errorFunction
    );
}
export const getCapitoli = (manualeId) => {
    const token = retrieveToken();
    const authtoken = 'Bearer '.concat(token);
    const PATH = "/api/capitoli";
    let query = "?manualeId.equals=" + manualeId;
    query = query + "&page=0&size=1000&sort=numeroCapitolo,ASC"
    const URL = BASE_URL + PATH + query;
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
            message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        }
        return new ApiError(status, message);
    };
    return wrapFetch(
        fetch(URL, {
            method: 'GET',
            headers: {
                'Authorization': authtoken
            },
            withCredentials: true,
            credentials: 'include'
        }), errorFunction
    );
}
export const putCapitoli = (body) => {
    const token = retrieveToken();
    const PATH = '/api/cnd/capitoli';
    const URL = BASE_URL + PATH;
    const authtoken = 'Bearer '.concat(token);
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
          message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        } else if (status === 400) {
          message = 'Errore salvataggio: i dati non sono corretti.';
        } else if (status === 404) {
          message = 'Errore salvataggio: responsabile punto vendita non trovato.';
        }
        return new ApiError(status, message);
      };
      return wrapFetch(
        fetch(URL, {
          method: 'PUT',
          headers: {
            'Authorization' : authtoken,
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          credentials: 'include',
          body: JSON.stringify(body)
        }), errorFunction
      );
}
export const postCreaCapitoli = (body) => {
  const token = retrieveToken();
    const authtoken = 'Bearer '.concat(token);
    const PATH = "/api/cnd/capitoli";
    const URL = BASE_URL + PATH;
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
          message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        } else if (status === 400 || status === 404) {
          message = 'Operazione non riuscita: dati non corretti.';
        }
        return new ApiError(status, message);
    };
      return wrapFetch(
        fetch(URL, {
          method: 'POST',
          headers: {
            'Authorization' : authtoken,
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          credentials: 'include',
          body: JSON.stringify(body)
        }), errorFunction
      );
}
export const deleteCapitoli = (id) => {
  const token = retrieveToken();
    const PATH = "/api/cnd/capitoli/"+id;
    const URL = BASE_URL + PATH ;
    const authtoken = 'Bearer '.concat(token);
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
          message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        } else if (status === 400) {
          message = 'Errore salvataggio: i dati non sono corretti.';
        } else if (status === 404) {
          message = 'Errore salvataggio: responsabile punto vendita non trovato.';
        }
        return new ApiError(status, message);
      };
    return wrapFetch(
        fetch(URL, {
          method: 'DELETE',
            headers: {
              'Authorization' : authtoken
            },
            withCredentials: true,
            credentials: 'include'
        }), errorFunction
      );
}