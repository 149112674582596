import React, { Component } from "react";
import ReactTable from "react-table";
import Button from "@material-ui/core/Button";
import ActionButton from "../../../components/ActionButton";
import theme from "../../../theme";
import {
  Checkbox,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { getReparti, updateReparti } from "../../../utils/api/reparti";
import ModalResponsabiliPerReparto from "../manualecomponents/paragrafo4/ModalResponsabiliPerReparto";
import { Edit } from "@material-ui/icons";
import { ReactComponent as FrecciaSu } from "../../../frecciaSu.svg";
import { ReactComponent as FrecciaGiu } from "../../../frecciaGiu.svg";
import { getResponsabiliSicurezzaPerEsercizioDatoManualeId } from "../../../utils/api/responsabili_sicurezza_api";
import {
  getSingoloSottoparagrafo,
  putSingoloSottoparagrafo,
  updateSottoparagrafi,
} from "../../../utils/api/paragrafi_api";
import {
  getLocaliManuale,
  getTotaleLocali,
  updateLocaliManuale,
} from "../../../utils/api/locali_e_attrezzature_api";
import ModalSelezioneAttrezzatura from "./ModalSelezioneAttrezzatura";
import { ReactComponent as CalendarLogo } from "../../../Calendario.svg";
import ModalSelezioneModalitaAutocontrollo from "./ModalSelezioneModalitaAutocontrollo";
import GoToIcon from "./GoToIcon";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ReactComponent as SettingLogo } from "../../../Modifica.svg";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import itLocale from "date-fns/locale/it";
import ModalSceltaAzione from "../../../components/ModalSceltaAzione";
import PropTypes from "prop-types";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
  },
  header: {
    fontSize: 30,
    display: "flex",
    flexDirection: "row",
  },
};

export default class CustomTableForManualeForParti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      records: [],
      reparti: [],
      isBlocked: this.props.isParagrafoBlocked,
      isModello: true,
      selectedReparto: null,
      responsabiliSicurezza: [],
      sottoParagrafiDataAfterSave: this.props.sottoParagrafoData,
      selectProcedureAutocontrolloModalOpen: false,
      titoloInizialeSottoparagrafo: this.props.titoloInizialeSottoparagrafo,
      isOSATestOpen: false,
      osaName: "",
      firstElement: null,
      openModalResponsabili: false,
      selectedLocale: null,
      openModalAttrezzatura: false,
      indexTab: this.props.indexTab,
      isTitleEditable: false,
      totalPages: 0,
      title: this.props.titolo,
      rowWidth: 0,
      editMode: false,
      dataToPassForProcedure: this.props.sottoParagrafoData,
      mode: "",
      firstRender: true,
      id: 0,
    };
  }

  swapElements = (arr, i1, i2) => {
    let temp = arr[i1];

    arr[i1] = arr[i2];

    arr[i2] = temp;
  };

  swapElementsLocali = (arr, i1, i2) => {
    let temp = arr[i1];
    let tempIndice = arr[i1].indiceOrdinamento;
    let tempIndice2 = arr[i2].indiceOrdinamento;
    arr[i1] = arr[i2];
    arr[i2] = temp;
    arr[i2].indiceOrdinamento = tempIndice;
    arr[i1].indiceOrdinamento = tempIndice2;
  };

  getResponsabiliMailFromId = (ids) => {
    let mails = "";
    let responsabili = [...this.state.responsabiliSicurezza];
    ids.map((item, index) => {
      responsabili.map((item2, index2) => {
        if (item === item2.id) {
          if (ids.length - 1 === index) {
            mails += item2.email;
          } else {
            mails += item2.email + " , ";
          }
        }
      });
    });
    return mails;
  };

  moveElementUpwardLocali = (cellInfo) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let array = [...this.state.records];
      if (cellInfo.index > 0) {
        this.swapElementsLocali(array, cellInfo.index, cellInfo.index - 1);
        this.setState(
          {
            records: array,
          },
          () => {
            this.aggiornaLocali();
          }
        );
      }
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  moveElementUpwardOrganigramma = (cellInfo) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let array = [...this.state.records];
      if (cellInfo.index > 0) {
        this.swapElements(array, cellInfo.index, cellInfo.index - 1);
        this.setState(
          {
            records: array,
          },
          () => {
            this.aggiornaOrganigrammaAfterInput();
          }
        );
      }
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  moveElementUpwardAutocontrolli = (cellInfo) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let array = [...this.state.records];
      let sottoparagrafiArray = this.state.dataToPassForProcedure;
      let logicalArray = sottoparagrafiArray.componenti;
      if (cellInfo.index > 0) {
        this.swapElements(array, cellInfo.index, cellInfo.index - 1);
        this.swapElements(logicalArray, cellInfo.index, cellInfo.index - 1);
        sottoparagrafiArray.componenti = logicalArray;
        this.setState(
          {
            records: array,
            dataToPassForProcedure: sottoparagrafiArray,
          },
          () => {
            this.props.bringDataAfterExchange(
              sottoparagrafiArray.componenti,
              this.state.indexTab
            );
          }
        );
      }
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  moveElementDownWardAutocontrolli = (cellInfo) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let array = [...this.state.records];
      let sottoparagrafiArray = this.state.dataToPassForProcedure;
      let logicalArray = sottoparagrafiArray.componenti;
      if (cellInfo.index < array.length - 1) {
        this.swapElements(array, cellInfo.index, cellInfo.index + 1);
        this.swapElements(logicalArray, cellInfo.index, cellInfo.index + 1);
        sottoparagrafiArray.componenti = logicalArray;
        this.setState(
          {
            records: array,
            dataToPassForProcedure: sottoparagrafiArray,
          },
          () => {
            this.props.bringDataAfterExchange(
              sottoparagrafiArray.componenti,
              this.state.indexTab
            );
          }
        );
      }
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  moveElementDownWardLocali = (cellInfo) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let array = [...this.state.records];
      if (cellInfo.index < array.length - 1) {
        this.swapElementsLocali(array, cellInfo.index, cellInfo.index + 1);
        this.setState(
          {
            records: array,
          },
          () => {
            this.aggiornaLocali();
          }
        );
      }
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  moveElementDownWardOrganigramma = (cellInfo) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let array = [...this.state.records];
      if (cellInfo.index < array.length - 1) {
        this.swapElements(array, cellInfo.index, cellInfo.index + 1);
        this.setState(
          {
            records: array,
          },
          () => {
            this.aggiornaOrganigrammaAfterInput();
          }
        );
      }
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  onAssociaButtonClicked = (reparto) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      this.setState({
        openModalResponsabili: true,
        selectedReparto: reparto,
      });
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  onCloseModalResponsabili = () => {
    this.setState({
      openModalResponsabili: false,
    });
  };

  onCloseModalSelezioneModalitaAutocontrollo = () => {
    this.setState({
      selectProcedureAutocontrolloModalOpen: false,
    });
  };

  onCloseOSAModal = () => {
    this.setState({
      isOSATestOpen: false,
    });
  };

  handleOkSelected = () => {
    let records = [...this.state.records];
    let objectToAdd = {
      NomeECognome: "Inserire delegato",
      Ruolo: "Responsabile piano autocontrollo",
      Reparto: "Nessun Reparto",
      Data: moment(new Date()).toISOString(),
    };
    records.push(objectToAdd);
    this.setState({
      records: records,
      isOSATestOpen: false,
    });
    let newOrganigrammaRecord = [...this.state.records];
    newOrganigrammaRecord.push(objectToAdd);
    let dataToPass = this.props.sottoParagrafoData;
    let object = JSON.parse(dataToPass.componenti[0].contenuto);
    let objToPassToSave = {
      columns: ["Nome e cognome", "Ruolo", "Reparto", "Data"],
      rows: [
        ...object.rows,
        [
          "Inserire delegato",
          "Responsabile piano autocontrollo",
          "Nessun Reparto",
          "",
        ],
      ],
    };
    dataToPass.componenti[0].contenuto = JSON.stringify(objToPassToSave);
  };

  handleIndietroSelected = () => {
    let records = [...this.state.records];
    let objectToAdd = {
      NomeECognome:
        this.state.osaName === ""
          ? "Inserire nome per l'OSA"
          : this.state.osaName,
      Ruolo: "Responsabile piano autocontrollo",
      Reparto: "Nessun Reparto",
      Data: moment(new Date()).toISOString(),
    };
    records.push(objectToAdd);
    this.setState({
      records: records,
      isOSATestOpen: false,
    });
    let newOrganigrammaRecord = [...this.state.records];
    newOrganigrammaRecord.push(objectToAdd);
    let dataToPass = this.props.sottoParagrafoData;
    let object = JSON.parse(dataToPass.componenti[0].contenuto);
    let objToPassToSave = {
      columns: ["Nome e cognome", "Ruolo", "Reparto", "Data"],
      rows: [
        ...object.rows,
        [
          this.state.osaName === ""
            ? "Inserire nome per l'OSA"
            : this.state.osaName,
          "Responsabile piano autocontrollo",
          "Nessun Reparto",
          "",
        ],
      ],
    };
    dataToPass.componenti[0].contenuto = JSON.stringify(objToPassToSave);
  };

  onAttrezzaturaButtonClicked = (locale) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      this.setState({
        openModalAttrezzatura: true,
        selectedLocale: locale,
      });
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  onCloseModalAttrezzatura = (isEdited) => {
    if (isEdited) {
      var r = window.confirm("Modifiche non salvate, andare indietro?");
      if (r) {
        this.setState({
          openModalAttrezzatura: false,
        });
      } else {
      }
    } else {
      this.setState({
        openModalAttrezzatura: false,
      });
    }
  };

  getNomeRepartoFromId = (array, id) => {
    for (let i = 0; i < array.length; i++) {
      let record = array[i];
      if (record.id === id) {
        return record.nome;
      }
    }
  };

  fetchResponsabiliSicurezza = (manualeInCostruzioneId) => {
    getResponsabiliSicurezzaPerEsercizioDatoManualeId(manualeInCostruzioneId)
      .then((result) => {
        this.setState({
          responsabiliSicurezza: result,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  // onResponsabiliSelected = (responsabiliIds) => {
  //   // Create a new array of reparti with responsabili ids:
  //   let arrayReparti = [];
  //   let reparti = this.state.records;
  //   if (reparti !== undefined) {
  //     for (let i = 0; i < reparti.length; i++) {
  //       let record = reparti[i];
  //       if (this.state.selectedReparto.id === reparti[i].id) {
  //         record.responsabili = responsabiliIds;
  //       } else {
  //         let arrayResponsabili = [];
  //         for (let j = 0; j < record.responsabili.length; j++) {
  //           arrayResponsabili.push(record.responsabili[j]);
  //         }
  //         record.responsabili = arrayResponsabili;
  //       }
  //       arrayReparti.push(record);
  //     }
  //     updateReparti(this.props.idManuale, arrayReparti)
  //       .then((result) => {
  //         this.getRepartiData();
  //         // this.onCloseModalResponsabili();
  //       })
  //       .catch((error) => {
  //         if (error.status === 403) {
  //           this.props.handleInvalidToken();
  //         } else {
  //           this.props.handleError(true, error.message);
  //         }
  //       });
  //   } else {
  //     alert("Attenzione, selezionare almeno un consulente");
  //     this.setState({
  //       loading: false,
  //     });
  //   }
  // };

  aggiornaLocali = () => {
    let arrayToPass = [...this.state.records];
    /*arrayToPass.map(item => {
      delete item['nomeReparto']
      delete item['repartiToSelect']
    })*/
    this.props.aggiornaLocali(arrayToPass);
  };

  aggiungiDefinizioneProcedureAutocontrollo = (body) => {
    let dataToPass = this.state.dataToPassForProcedure;
    dataToPass.componenti.push(body);
    putSingoloSottoparagrafo(dataToPass)
      .then((result) => {
        this.getSchedeHCCP();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  aggiornaDefinizioneProcedureAutocontrollo = () => {
    let dataToPass = this.state.dataToPassForProcedure;
    updateSottoparagrafi(dataToPass)
      .then((result) => {
        this.getSchedeHCCP();
      })
      .catch((error) => {});
  };

  handleCustomTableRow = (item) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let array = [...this.state.records];
      array.splice(item._index, 1);
      this.setState(
        {
          records: array,
        },
        () => {
          this.aggiornaTabellaCustomHandler();
          this.props.checkboxCustomButtonUpdate();
        }
      );
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  aggiornaTabellaCustomHandler = () => {
    let array = [...this.state.records];
    let arrayOfValues = [];
    array.map((item) => {
      delete item["Elimina"];
      arrayOfValues.push(Object.values(item));
    });
    let sottoParagrafiToPass = this.state.sottoParagrafiDataAfterSave;
    sottoParagrafiToPass.componenti[0].contenuto =
      JSON.stringify(arrayOfValues);
    sottoParagrafiToPass.componenti[0].titolo =
      this.state.titoloInizialeSottoparagrafo;
    this.props.updateCustomTable(sottoParagrafiToPass);
  };

  aggiornaOrganigrammaAfterInput = () => {
    let array = [...this.state.records];
    let arrayToPass = [];
    array.map((item) => {
      let objectArray = [];
      objectArray.push(item.NomeECognome);
      objectArray.push(item.Ruolo);
      objectArray.push(item.Reparto);
      objectArray.push(item.Data);
      arrayToPass.push(objectArray);
    });
    let dataToPass = this.props.sottoParagrafoData;
    let objToPassToSave = {
      columns: ["Nome e cognome", "Ruolo", "Reparto", "Data"],
      rows: arrayToPass,
    };
    dataToPass.componenti[0].contenuto = JSON.stringify(objToPassToSave);

    this.props.changeEditMode();
  };

  componentDidMount = () => {
    this.initializeTable();
  };

  componentDidUpdate(prevProps) {
    if (this.state.mode === "Custom") {
      if (this.props.contenuto !== prevProps.contenuto) {
        let contentToSave = JSON.parse(this.props.contenuto);
        let passedColumns = this.props.columns;

        let recordsToSave = [];
        contentToSave.map((item2, index2) => {
          let objectToSaveInArray = {};
          passedColumns.map((item, index) => {
            objectToSaveInArray[item] = contentToSave[index2][index];
          });
          recordsToSave.push(objectToSaveInArray);
        });
        this.setState(
          {
            records: recordsToSave,
          },
          () => {
            this.aggiornaTabellaCustomHandler();
          }
        );
      }
    }
  }

  initializeTable = () => {
    let a = null;
    this.setState({
      mode: this.props.mode,
    });
    if (this.props.kind === "modello") {
      this.setState({
        isModello: true,
      });
    } else {
      this.setState({
        isModello: false,
      });
    }
    if (this.props.mode === "Organigramma") {
      a = this.getColumnsForOrganigramma();
    } else if (this.props.mode === "Definizione_Reparto") {
      a = this.getColumnsForDefinizioneReparto();
    } else if (this.props.mode === "Descrizione_Reparto") {
      a = this.getColumnsForDescrizioneReparti();
    } else if (this.props.mode === "Definizione_Procedure_Autocontrollo") {
      a = this.getColumnsForElencoProcedureAutocontrollo();
    } else {
      a = this.getColumns();
    }
    this.setState({
      columns: a,
    });
    this.setState({
      rowWidth: 100 / this.state.columns.length,
    });
  };

  getColumns = () => {
    let passedColumns = this.props.columns;
    let content = this.props.contenuto;
    if (content !== undefined) {
      let contentToSave = JSON.parse(content);
      let recordsToSave = [];
      contentToSave.map((item2, index2) => {
        let objectToSaveInArray = {};
        passedColumns.map((item, index) => {
          objectToSaveInArray[item] = contentToSave[index2][index];
        });
        recordsToSave.push(objectToSaveInArray);
      });
      this.setState(
        {
          records: recordsToSave,
        },
        () => {}
      );
    }
    let generatedColumns = [];
    if (passedColumns[0].header === undefined) {
      passedColumns.map((item, index) => {
        generatedColumns.push({
          Header: item,
          id: item,
          accessor: (d) => d[item],
          Cell: (props) => (
            <TextField
              style={{ width: "100%" }}
              InputProps={{ disableUnderline: true }}
              inputProps={{ style: { textAlign: "center", width: "100%" } }}
              placeholder="Inserisci qui i dati..."
              disabled={!this.state.editMode}
              onClick={(e) => {
                this.props.setCursorPosition(e.target.selectionStart);
                this.props.setSelectedCell(index, props.row._index);
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 39) {
                  if (this.props.cursorPosition <= props.row[item].length - 1) {
                    this.props.setCursorPosition(e.target.selectionEnd);
                  }
                } else if (e.keyCode === 37) {
                  if (this.props.cursorPosition >= 0) {
                    this.props.setCursorPosition(e.target.selectionEnd);
                  }
                } else if (e.keyCode === 38) {
                  this.props.setCursorPosition(e.target.selectionEnd);
                } else if (e.keyCode === 40) {
                  this.props.setCursorPosition(e.target.selectionEnd);
                }
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 39) {
                  if (this.props.cursorPosition <= props.row[item].length - 1) {
                    this.props.setCursorPosition(e.target.selectionEnd);
                  }
                } else if (e.keyCode === 37) {
                  if (this.props.cursorPosition >= 0) {
                    this.props.setCursorPosition(e.target.selectionEnd);
                  }
                }
              }}
              onBlur={this.aggiornaTabellaCustomHandler}
              value={props.row[item]}
              onInput={(event) => {
                let records = [...this.state.records];
                let positionToSave = event.target.selectionStart;
                records.map((object, index) => {
                  if (index === props.row._index) {
                    records[index][item] = event.target.value;
                    const caret = event.target.selectionStart;
                    const element = event.target;
                    window.requestAnimationFrame(() => {
                      element.selectionStart = caret;
                      element.selectionEnd = caret;
                    });
                    this.setState(
                      {
                        records: records,
                      },
                      () => {
                        this.props.setCursorPosition(positionToSave);
                      }
                    );
                  }
                });
              }}
            />
          ),
        });
      });
    } else {
      passedColumns.map((item, index) => {
        generatedColumns.push({
          Header: item.header,
          id: item.id,
          accessor: (d) => d[item.header],
          Cell: (props) => (
            <TextField
              value={props.row[item.id]}
              placeholder="Inserisci qui i dati..."
              disabled={!this.state.editMode}
              onBlur={this.aggiornaTabellaCustomHandler}
              style={{ width: "100%" }}
              InputProps={{ disableUnderline: true }}
              inputProps={{ min: 0, style: { textAlign: "center" } }}
              onClick={(e) => {
                this.props.setCursorPosition(e.target.selectionStart);
                this.props.setSelectedCell(index, props.row._index);
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 39) {
                  if (this.props.cursorPosition <= props.row[item].length - 1) {
                    this.props.setCursorPosition(e.target.selectionEnd);
                  }
                } else if (e.keyCode === 37) {
                  if (this.props.cursorPosition >= 0) {
                    this.props.setCursorPosition(e.target.selectionEnd);
                  }
                } else if (e.keyCode === 38) {
                  this.props.setCursorPosition(e.target.selectionEnd);
                } else if (e.keyCode === 40) {
                  this.props.setCursorPosition(e.target.selectionEnd);
                }
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 39) {
                  if (this.props.cursorPosition <= props.row[item].length - 1) {
                    this.props.setCursorPosition(e.target.selectionEnd);
                  }
                } else if (e.keyCode === 37) {
                  if (this.props.cursorPosition >= 0) {
                    this.props.setCursorPosition(e.target.selectionEnd);
                  }
                }
              }}
              onInput={(event) => {
                let positionToSave = event.target.selectionStart;
                let records = [...this.state.records];
                records.map((object, index) => {
                  if (index === props.row._index) {
                    records[index][item.id] = event.target.value;
                    const caret = event.target.selectionStart;
                    const element = event.target;
                    window.requestAnimationFrame(() => {
                      element.selectionStart = caret;
                      element.selectionEnd = caret;
                    });
                    this.setState(
                      {
                        records: records,
                      },
                      () => {
                        this.props.setCursorPosition(positionToSave);
                      }
                    );
                  }
                });
              }}
            />
          ),
        });
      });
    }
    generatedColumns.push({
      Header: "Elimina",
      id: "Elimina",
      accessor: (d) => d,
      width: 120,
      Cell: (props) => (
        <ActionButton
          disabled={false}
          grayVersion={false}
          label="Elimina"
          onClick={() => this.handleCustomTableRow(props.row)}
        />
      ),
    });
    return generatedColumns;
  };

  getColumnsForOrganigramma = () => {
    this.getOrganigrammaData();
    return [
      {
        Header: "Nome e Cognome",
        id: "NomeECognome",
        //width:300,
        accessor: (d) => d.NomeECognome,
        Cell: (props) => (
          <TextField
            onBlur={this.aggiornaOrganigrammaAfterInput}
            InputProps={{ disableUnderline: true }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            disabled={!this.state.editMode}
            onChange={(event) => {
              props.row.NomeECognome = event.target.value;
              var OrganigrammaToChange = [...this.state.records];
              OrganigrammaToChange[props.index].NomeECognome =
                event.target.value;
              const caret = event.target.selectionStart;
              const element = event.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });
              this.setState({
                records: OrganigrammaToChange,
              });
            }}
            style={{
              textAlign: "center",
              padding: 20,
              width:'100%',
              outline: `0 solid transparent`,
            }}
            value={props.row.NomeECognome}
          ></TextField>
        ),
      },
      {
        Header: "Ruolo",
        id: "Ruolo",
        //width:300,
        accessor: (d) => d.Ruolo,
        Cell: (props) => (
          <Select
            displayEmpty
            variant="outlined"
            style={{
              width: "100%",
              height: 30,
              borderRadius: 30,
              marginTop: "1.188em",
            }}
            value={props.row.Ruolo}
            onChange={(event) => {
              props.row.Ruolo = event.target.value;
              var OrganigrammaToChange = [...this.state.records];
              OrganigrammaToChange[props.index].Ruolo = event.target.value;
              this.setState(
                {
                  records: OrganigrammaToChange,
                },
                () => {
                  this.aggiornaOrganigrammaAfterInput();
                  if (event.target.value === "OSA") {
                    this.setState({
                      isOSATestOpen: true,
                      osaName: props.row.NomeECognome,
                    });
                  }
                }
              );
            }}
          >
            <MenuItem value={"Responsabile piano autocontrollo"}>
              Responsabile piano autocontrollo
            </MenuItem>
            <MenuItem value={"OSA"}>OSA</MenuItem>
            <MenuItem value={"Responsabile punto vendita"}>
              Responsabile punto vendita
            </MenuItem>
            <MenuItem value={"Responsabile reparto"}>
              Responsabile reparto
            </MenuItem>
            <MenuItem value={"Viceresponsabile reparto"}>
              Viceresponsabile reparto
            </MenuItem>
          </Select>
        ),
      },
      {
        Header: "Reparto",
        id: "Reparto",
        //width:300,
        accessor: (d) => d.Reparto,
        Cell: (props) => (
          <Select
            variant="outlined"
            displayEmpty
            style={{
              width: "100%",
              height: 30,
              borderRadius: 30,
              marginTop: "1.188em",
            }}
            value={props.row.Reparto}
            onChange={(event) => {
              props.row.Reparto = event.target.value;
              var OrganigrammaToChange = [...this.state.records];
              OrganigrammaToChange[props.index].Reparto = event.target.value;
              this.setState(
                {
                  records: OrganigrammaToChange,
                },
                () => {
                  this.aggiornaOrganigrammaAfterInput();
                }
              );
            }}
          >
            <MenuItem value={"Generi vari"}>Generi vari</MenuItem>
            <MenuItem value={"Ortofrutta"}>Ortofrutta</MenuItem>
            <MenuItem value={"Bar"}>Bar</MenuItem>
            <MenuItem value={"Gastronomia"}>Gastronomia</MenuItem>
            <MenuItem value={"Macelleria"}>Macelleria</MenuItem>
            <MenuItem value={"Parafarmacia"}>Parafarmacia</MenuItem>
            <MenuItem value={"Panetteria"}>Panetteria</MenuItem>
            <MenuItem value={"Pescheria"}>Pescheria</MenuItem>
            <MenuItem value={"Somministrazione"}>Somministrazione</MenuItem>
            <MenuItem value={"Nessun Reparto"}>Nessun Reparto</MenuItem>
          </Select>
        ),
      },
      {
        Header: "Data",
        id: "Data",
        width: 250,
        accessor: (d) => d.Data,
        Cell: (props) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
            <KeyboardDatePicker
              keyboardIcon={
                <CalendarLogo
                  width={23}
                  height={23}
                  fill={theme.palette.cndColors.yellow}
                />
              }
              autoOk
              style={{ marginTop: "1.188em", marginLeft: "2em", width: "100%" }}
              variant="inline"
              inputVariant="standard"
              format="dd/MM/yyyy"
              value={props.row.Data === "" ? null : props.row.Data}
              InputAdornmentProps={{
                position: "start",
                style: { marginBottom: 4 },
              }}
              InputProps={{ disableUnderline: true }}
              onChange={(date) => {
                props.row.Data = date;
                var OrganigrammaToChange = [...this.state.records];
                OrganigrammaToChange[props.index].Data = date;
                this.setState(
                  {
                    records: OrganigrammaToChange,
                  },
                  () => {
                    this.aggiornaOrganigrammaAfterInput();
                  }
                );
              }}
            />
          </MuiPickersUtilsProvider>
        ),
      },
      {
        Header: "Sposta",
        id: "SpostaOrganigramma",
        width: 150,
        accessor: (d) => d,
        Cell: (props) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginTop: 10,
            }}
          >
            <IconButton
              style={{ width: 30, alignSelf: "center" }}
              size="small"
              onClick={() => {
                this.moveElementUpwardOrganigramma(props);
              }}
            >
              <FrecciaSu height={18} width={18} fill={"grey"} />
            </IconButton>
            <IconButton
              style={{ width: 30, alignSelf: "center" }}
              size="small"
              onClick={() => {
                this.moveElementDownWardOrganigramma(props);
              }}
            >
              <FrecciaGiu height={18} width={18} fill={"grey"} />
            </IconButton>
          </div>
        ),
      },
      {
        Header: "Elimina",
        id: "EliminaOrganigramma",
        width: 150,
        accessor: (d) => d,
        Cell: (props) => (
          <Button
            type="button"
            variant="contained"
            size="medium"
            disabled={props.value.nome === "intera struttura"}
            onClick={() => this.deleteElementOrganigramma(props)}
            style={{
              marginTop: 15,
              color: theme.palette.secondary.main,
              borderRadius: 30,
              backgroundColor:
                props.value.nome === "intera struttura"
                  ? theme.palette.disabled.main
                  : theme.palette.cndColors.red,
            }}
          >
            ELIMINA
          </Button>
        ),
      },
    ];
  };

  getOrganigrammaData = () => {
    getSingoloSottoparagrafo(this.props.idSottoparagrafo)
      .then((result) => {
        let arrayOfRecords = [];
        let organigrammaDataToUse = JSON.parse(result.componenti[0].contenuto);
        if (organigrammaDataToUse.rows.length > 0) {
          organigrammaDataToUse.rows.map((itemToPass) => {
            arrayOfRecords.push({
              NomeECognome: itemToPass[0],
              Ruolo: itemToPass[1],
              Reparto: itemToPass[2],
              Data: itemToPass[3],
            });
          });
        }
        this.setState({
          records: arrayOfRecords,
          firstRender: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  getSchedeHCCP = () => {
    getSingoloSottoparagrafo(this.props.idParagrafo)
      .then((result) => {
        let recordsToSave = [];
        this.setState({
          dataToPassForProcedure: result,
        });
        //let indexToUseToKeepTrack = result.componenti.findIndex(item => item.tipoContenuto === "TABELLA_PROCEDURE");
        result.componenti.map((item) => {
          let objectToSave = {};
          if (item.tipo === "PROCEDURA_HACCP") {
            objectToSave = {
              titolo: item.titolo,
              isScheda: false,
              isProceduraHaccp: true,
              id: item.id,
              idParagrafo: item.paragrafoId,
            };
          } else if (item.tipo === "SCHEDA") {
            objectToSave = {
              titolo: item.titolo,
              isScheda: true,
              isProceduraHaccp: false,
              id: item.id,
              idParagrafo: item.paragrafoId,
            };
          }
          recordsToSave.push(objectToSave);
        });
        this.setState(
          {
            records: recordsToSave,
            firstRender: false,
          },
          () => {
            this.props.bringDataToParent(result);
          }
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  getRepartiData = () => {
    getReparti(this.props.idManuale, 0, 100, "id")
      .then((result) => {
        let arrayToSave = [];
        result.map((item) => {
          let arrayResponsabiliToSave = [];
          item.responsabili.map((ids) => {
            arrayResponsabiliToSave.push(ids.id);
          });
          arrayToSave.push({
            id: item.id,
            manualeId: item.manualeId,
            nome: item.nome,
            repartoDefault: item.repartoDefault,
            responsabileSicurezzaId: item.responsabileSicurezzaId,
            responsabileSicurezzaEmail: item.responsabileSicurezzaEmail,
            responsabileSicurezzaNome: item.responsabileSicurezzaNome,
            responsabileSicurezzaCognome: item.responsabileSicurezzaCognome,
            responsabili: [this.state.responsabiliSicurezza.id],
          });
        });
        this.setState(
          {
            records: arrayToSave,
            selectedReparto: result[0],
            firstElement: result[0],
          },
          () => {
            this.props.aggiornaReparti(this.state.records);
          }
        );
      })
      .then((result) => {
        this.fetchResponsabiliSicurezza(this.props.idManuale);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  updateResposabileReparto = (arrayReparti) => {
    updateReparti(this.props.idManuale, arrayReparti)
      .then((response) => {
        console.log("Reparti aggiornati con successo");
        // Aggiungi qui eventuali azioni post-aggiornamento, come mostrare un messaggio di successo all'utente
        // o aggiornare lo stato del componente per riflettere le modifiche.
      })
      .catch((error) => {
        // Gestisci gli errori, ad esempio mostrando un messaggio all'utente
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  getColumnsForDefinizioneReparto = () => {
    if (this.state.firstRender) {
      getReparti(this.props.idManuale, 0, 100, "id")
        .then((result) => {
          let arrayToSave = [];
          result.map((item) => {
            let arrayResponsabiliToSave = [];
            item.responsabili.map((ids) => {
              arrayResponsabiliToSave.push(ids.id);
            });
            arrayToSave.push({
              id: item.id,
              manualeId: item.manualeId,
              nome: item.nome,
              repartoDefault: item.repartoDefault,
              responsabileSicurezzaId: item.responsabileSicurezzaId,
              responsabileSicurezzaEmail: item.responsabileSicurezzaEmail,
              responsabileSicurezzaNome: item.responsabileSicurezzaNome,
              responsabileSicurezzaCognome: item.responsabileSicurezzaCognome,
              responsabili: arrayResponsabiliToSave,
            });
          });
          this.setState({
            records: arrayToSave,
            selectedReparto: result[0],
            firstElement: result[0],
            firstRender: false,
          });
        })
        .then((result) => {
          this.fetchResponsabiliSicurezza(this.props.idManuale);
        })
        .catch((error) => {
          if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
        });
    }
    return [
      {
        Header: "Reparto",
        id: "Reparto",
        width: this.state.rowWidth.toString + "%",
        accessor: (d) => d.nome,
        Cell: (props) => (
          <TextField
            onBlur={() =>
              this.props.aggiornaReparti(this.state.records, props.row._index)
            }
            InputProps={{ disableUnderline: true }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            disabled={!this.state.editMode}
            onChange={(event) => {
              props.row.Reparto = event.target.value;
              var repartiToChange = [...this.state.records];
              repartiToChange[props.index].nome = event.target.value;
              const caret = event.target.selectionStart;
              const element = event.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });
              this.setState({
                records: repartiToChange,
              });
            }}
            style={{
              padding: 10,
              width:'100%',
              outline: `0 solid transparent`,
            }}
            value={props.row.Reparto}
          />
        ),
      },
      {
        Header: "Email Utenza",
        id: "EmailUtente",
        width: this.state.rowWidth.toString + "%",
        accessor: (d) => d,
        Cell: (props) => (
          <TextField
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            disabled={true}
            style={{
              padding: 10,
              outline: `0 solid transparent`,
              width:'100%',
            }}
            // value={this.getResponsabiliMailFromId(
            //   props.row.EmailUtente.responsabili
            // )}
            value={this.state?.responsabiliSicurezza[0]?.email || ""}
          />
        ),
      },
      // {
      //   Header: "Associa Utenza",
      //   id: "AssociaUtente",
      //   width: 200,
      //   accessor: (d) => d,
      //   Cell: (props) => (
      //     <Button
      //       type="button"
      //       variant="contained"
      //       size="medium"
      //       disabled={this.state.isModello}
      //       onClick={() => this.onAssociaButtonClicked(props.value)}
      //       style={{
      //         marginTop: 5,
      //         color: theme.palette.secondary.main,
      //         borderRadius: 30,
      //         backgroundColor: this.state.isModello
      //           ? theme.palette.disabled.main
      //           : theme.palette.cndColors.red,
      //       }}
      //     >
      //       Associa utente
      //     </Button>
      //   ),
      // },
      {
        Header: "Elimina",
        id: "EliminaDefinizioneReparto",
        width: 120,
        accessor: (d) => d,
        Cell: (props) => (
          <Button
            type="button"
            variant="contained"
            size="medium"
            disabled={props.value.repartoDefault}
            onClick={() => this.deleteElementReparto(props)}
            style={{
              margin: 5,
              color: theme.palette.secondary.main,
              borderRadius: 30,
              backgroundColor: props.value.repartoDefault
                ? theme.palette.disabled.main
                : theme.palette.cndColors.red,
            }}
          >
            ELIMINA
          </Button>
        ),
      },
    ];
  };

  getLocaliForTabella = () => {
    let a = 0;
    getTotaleLocali(this.props.idManuale)
      .then((result) => {
        a = result;
        this.setState({
          totalPages: Math.ceil(a / 10),
        });
      })
      .then((result) => {
        getLocaliManuale(this.props.idManuale, 0, 100000, "indiceOrdinamento")
          .then(
            (result) => {
              let arrayOfLocali = result;
              getReparti(this.props.idManuale, 0, 100, "id").then((result) => {
                let arrayOfReparti = result;
                arrayOfLocali.map((item) => {
                  item.repartiToSelect = arrayOfReparti;
                });
                this.setState(
                  {
                    records: arrayOfLocali,
                  },
                  () => {}
                );
              });
            },
            () => {}
          )
          .catch((error) => {
            if (error.status === 403) {
              this.props.handleInvalidToken();
            } else {
              this.props.handleError(true, error.message);
            }
          });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  getColumnsForDescrizioneReparti = () => {
    this.getLocaliForTabella();
    return [
      {
        Header: "Reparti",
        id: "RepartiDescrizione",
        //width:200,
        accessor: (d) => d,
        Cell: (props) => (
          <>
            {props.row.RepartiDescrizione.repartiToSelect !== undefined ? (
              <Select
                disabled={
                  !(
                    !this.state.isBlocked ||
                    localStorage.getItem("superconsulente") === "true" ||
                    localStorage.getItem("consulenteId") === "null"
                  )
                }
                variant="outlined"
                onChange={(event) => {
                  //props.row.RepartiDescrizione.repartoId = event.target.value;
                  var LocaliToChange = [...this.state.records];
                  LocaliToChange[props.index].repartoId = event.target.value;
                  LocaliToChange[props.index].nomeReparto =
                    this.getNomeRepartoFromId(
                      props.row.RepartiDescrizione.repartiToSelect,
                      event.target.value
                    );
                  this.setState(
                    {
                      records: LocaliToChange,
                    },
                    () => {
                      this.aggiornaLocali();
                    }
                  );
                }}
                value={props.row.RepartiDescrizione.repartoId}
                style={{ paddingTop: 0, width: "100%" }}
              >
                {props.row.RepartiDescrizione.repartiToSelect !== undefined
                  ? props.row.RepartiDescrizione.repartiToSelect.map(
                      (item, index) => (
                        <MenuItem
                          key={index}
                          value={item.id}
                          onClick={() => {}}
                        >
                          {item.nome}
                        </MenuItem>
                      )
                    )
                  : null}
              </Select>
            ) : (
              <Select></Select>
            )}
          </>
        ),
      },
      {
        Header: "Locale",
        id: "Locale",
        //width:200,
        accessor: (d) => d.nome,
        Cell: (props) => (
          <TextField
            InputProps={{ disableUnderline: true }}
            onBlur={this.aggiornaLocali}
            onChange={(event) => {
              props.row.Locale = event.target.value;
              var LocaliToChange = [...this.state.records];
              LocaliToChange[props.index].nome = event.target.value;
              const caret = event.target.selectionStart;
              const element = event.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });
              this.setState({
                records: LocaliToChange,
              });
            }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            disabled={!this.state.editMode}
            style={{
              textAlign: "center",
              padding: 10,
              width:'100%',
              outline: `0 solid transparent`,
            }}
            value={props.row.Locale}
          />
        ),
      },
      {
        Header: "Dimensioni",
        id: "Dimensioni",
        //width:200,
        accessor: (d) => d.dimensione,
        Cell: (props) => (
          <TextField
            onBlur={this.aggiornaLocali}
            onChange={(event) => {
              props.row.Dimensioni = event.target.value;
              var DimensioniToChange = [...this.state.records];
              DimensioniToChange[props.index].dimensione = event.target.value;
              this.setState({
                records: DimensioniToChange,
              });
            }}
            type="number"
            InputProps={{ disableUnderline: true }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            disabled={!this.state.editMode}
            style={{
              textAlign: "center",
              padding: 10,
              outline: `0 solid transparent`,
            }}
            value={props.row.Dimensioni}
          />
        ),
      },
      {
        Header: "Attrezzature",
        id: "Attrezzature",
        width: 200,
        accessor: (d) => d,
        Cell: (props) => (
          <IconButton
            style={{ color: theme.palette.cndColors.yellow }}
            onClick={() =>
              this.onAttrezzaturaButtonClicked(props.row.Attrezzature)
            }
          >
            <SettingLogo
              width={23}
              height={23}
              fill={theme.palette.cndColors.yellow}
            />
          </IconButton>
        ),
      },
      {
        Header: "Sposta",
        id: "SpostaDescrizioneReparto",
        width: 200,
        accessor: (d) => d,
        Cell: (props) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginTop: 5,
            }}
          >
            <IconButton
              size="small"
              style={{ width: 30, alignSelf: "center" }}
              onClick={() => {
                this.moveElementUpwardLocali(props);
              }}
            >
              <FrecciaSu width={15} height={15} fill="grey" />
            </IconButton>
            <IconButton
              size="small"
              style={{ width: 30, alignSelf: "center" }}
              onClick={() => {
                this.moveElementDownWardLocali(props);
              }}
            >
              <FrecciaGiu width={15} height={15} fill="grey" />
            </IconButton>
          </div>
        ),
      },
      {
        Header: "Elimina",
        id: "EliminaDescrizioneReparto",
        width: 200,
        accessor: (d) => d,
        Cell: (props) => (
          <Button
            type="button"
            variant="contained"
            size="medium"
            onClick={() => this.deleteElementDescrizioneReparto(props)}
            style={{
              margin: 5,
              color: theme.palette.secondary.main,
              borderRadius: 30,
              backgroundColor:
                props.value.nome === "intera struttura"
                  ? theme.palette.disabled.main
                  : theme.palette.cndColors.red,
            }}
          >
            ELIMINA
          </Button>
        ),
      },
    ];
  };

  getColumnsForElencoProcedureAutocontrollo = () => {
    this.getSchedeHCCP();
    return [
      {
        Header: "Titolo",
        id: "TitoloProcedureAutocontrollo",
        //width:500,
        accessor: (d) => d.titolo,
        Cell: (props) => (
          <TextField
            disabled={!this.state.editMode}
            InputProps={{ disableUnderline: true }}
            style={{
              textAlign: "center",
              width: "100%",
              padding: 10,
              outline: `0 solid transparent`,
            }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={props.row.TitoloProcedureAutocontrollo}
            //onBlur={() => this.aggiornaDefinizioneProcedureAutocontrollo()}
            onChange={(event) => {
              let newValues = [...this.state.records];
              newValues[props.index].titolo = event.target.value;
              const caret = event.target.selectionStart;
              const element = event.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });
              this.setState({
                records: newValues,
              });
              let dataToPass = this.state.dataToPassForProcedure;
              var item = dataToPass.componenti.findIndex(
                (item) => newValues[props.index].id === item.id
              );
              dataToPass.componenti[item].titolo = event.target.value;
              this.setState({
                dataToPassForProcedure: dataToPass,
              });
              this.props.bringTextToParent(
                dataToPass,
                this.state.indexTab,
                props.row._index
              );
            }}
          />
        ),
      },
      {
        Header: "Controllo",
        id: "ControlloProcedureAutocontrollo",
        width: 200,
        accessor: (d) => d,
        Cell: (props) => (
          <Checkbox
            disabled
            checked={props.row.ControlloProcedureAutocontrollo.isProceduraHaccp}
          />
        ),
      },
      /*{
      Header:"Scheda",
      id:"SchedaProcedureAutocontrollo",
      width:200,
      accessor:d => d,
      Cell : props => <Checkbox disabled checked={props.row.SchedaProcedureAutocontrollo.isScheda}></Checkbox>
    },*/
      {
        Header: "Modifica",
        id: "ModificaProcedureAutocontrollo",
        width: 150,
        accessor: (d) => d,
        Cell: (
          props //<ActionButton onClick = {() => console.log(props.row.ModificaProcedureAutocontrollo)}></ActionButton>
        ) =>
          props.row.ModificaProcedureAutocontrollo.isScheda ? (
            <GoToIcon
              mode="Scheda"
              idManuale={this.props.idManuale}
              id={props.row.ModificaProcedureAutocontrollo.id}
              idParagrafo={props.row.ModificaProcedureAutocontrollo.idParagrafo}
            />
          ) : props.row.ModificaProcedureAutocontrollo.isProceduraHaccp ? (
            <GoToIcon
              mode="Procedura"
              idManuale={this.props.idManuale}
              id={props.row.ModificaProcedureAutocontrollo.id}
              idParagrafo={props.row.ModificaProcedureAutocontrollo.idParagrafo}
            />
          ) : (
            <ActionButton
              disabled={false}
              grayVersion={false}
              onClick={() => console.log("")}
            />
          ),
      },
      {
        Header: "Sposta",
        id: "ModificaProcedureAutocontrollo",
        width: 150,
        accessor: (d) => d,
        Cell: (props) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginTop: 3,
            }}
          >
            <IconButton
              size="small"
              style={{ width: 30, alignSelf: "center" }}
              onClick={() => {
                this.moveElementUpwardAutocontrolli(props);
              }}
            >
              <FrecciaSu width={15} height={15} fill="grey" />
            </IconButton>
            <IconButton
              size="small"
              style={{ width: 30, alignSelf: "center" }}
              onClick={() => {
                this.moveElementDownWardAutocontrolli(props);
              }}
            >
              <FrecciaGiu width={15} height={15} fill="grey" />
            </IconButton>
          </div>
        ),
      },
      {
        Header: "Elimina",
        id: "EliminaProcedureAutocontrollo",
        width: 200,
        accessor: (d) => d,
        Cell: (props) => (
          <Button
            type="button"
            variant="contained"
            size="medium"
            onClick={() => this.deleteProcedureAutocontrollo(props)}
            style={{
              margin: 5,
              color: theme.palette.secondary.main,
              borderRadius: 30,
              backgroundColor:
                props.value.nome === "intera struttura"
                  ? theme.palette.disabled.main
                  : theme.palette.cndColors.red,
            }}
          >
            ELIMINA
          </Button>
        ),
      },
    ];
  };

  deleteProcedureAutocontrollo = (cellInfo) => {
    let array = Array.from(this.state.records);
    array.splice(cellInfo.index, 1);
    let itemToPass = this.state.dataToPassForProcedure.componenti;
    let dataToPassForSottoparagrafi = this.state.dataToPassForProcedure;
    itemToPass.splice(cellInfo.index, 1);
    dataToPassForSottoparagrafi.componenti = itemToPass;
    putSingoloSottoparagrafo(dataToPassForSottoparagrafi)
      .then((result) => {
        this.getSchedeHCCP();
        this.props.bringDataToParent(
          dataToPassForSottoparagrafi,
          this.state.indexTab
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  deleteElementReparto = (cellInfo) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let array = Array.from(this.state.records);
      array.splice(cellInfo.index, 1);
      this.setState(
        {
          records: array,
        },
        () => {
          this.props.aggiornaReparti(array);
        }
      );
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  deleteElementOrganigramma = (cellInfo) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let array = Array.from(this.state.records);
      array.splice(cellInfo.index, 1);
      this.setState({ records: array });
      let arrayToPass = [];
      array.map((item) => {
        let objectArray = [];
        objectArray.push(item.NomeECognome);
        objectArray.push(item.Ruolo);
        objectArray.push(item.Reparto);
        objectArray.push(item.Data);
        arrayToPass.push(objectArray);
      });
      let dataToPass = this.props.sottoParagrafoData;

      let objToPassToSave = {
        columns: ["Nome e cognome", "Ruolo", "Reparto", "Data"],
        rows: arrayToPass,
      };

      dataToPass.componenti[0].contenuto = JSON.stringify(objToPassToSave);
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  deleteElementDescrizioneReparto = (cellInfo) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let array = Array.from(this.state.records);
      array.splice(cellInfo.index, 1);
      this.setState(
        {
          records: array,
        },
        () => {
          this.aggiornaLocali();
        }
      );
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  handleNewRow = () => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let newRowRecords = Array.from(this.state.records);

      if (this.props.mode === "Definizione_Reparto") {
        let objToAppend = {
          id: null,
          manualeId: this.state.records[0].manualeId,
          nome: "Nuovo reparto",
          repartoDefault: false,
          responsabileSicurezzaCognome: null,
          responsabileSicurezzaEmail: null,
          responsabileSicurezzaId: null,
          responsabileSicurezzaNome: null,
          responsabili: [],
        };
        newRowRecords.push(objToAppend);
        this.setState(
          {
            records: newRowRecords,
          },
          () => {
            this.props.aggiornaReparti(this.state.records);
          }
        );
      } else if (this.props.mode === "Organigramma") {
        let records = [...this.state.records];
        let objectToAdd = {
          NomeECognome: "",
          Ruolo: "",
          Reparto: "",
          Data: "",
        };
        records.push(objectToAdd);
        this.setState({
          records: records,
        });
        let newOrganigrammaRecord = [...this.state.records];
        newOrganigrammaRecord.push(objectToAdd);
        let dataToPass = this.props.sottoParagrafoData;
        let object = JSON.parse(dataToPass.componenti[0].contenuto);
        let objToPassToSave = {
          columns: ["Nome e cognome", "Ruolo", "Reparto", "Data"],
          rows: [...object.rows, ["", "", "", ""]],
        };
        dataToPass.componenti[0].contenuto = JSON.stringify(objToPassToSave);
      } else if (this.props.mode === "Descrizione_Reparto") {
        getReparti(this.props.idManuale, 0, 100, "id").then((result) => {
          let objToAppend = {
            id: null,
            manualeId: this.props.idManuale,
            repartoId: result[0].id,
            nome: "Nuovo Locale",
            dimensione: 0,
            indiceOrdinamento: 0,
          };
          newRowRecords.push(objToAppend);
          let body = {
            manualeId: this.props.idManuale,
            locali: newRowRecords,
          };
          updateLocaliManuale(body)
            .then((result) => {
              this.getLocaliForTabella();
              this.props.aggiornaLocali(newRowRecords);
            })
            .catch((error) => {
              if (error.status === 403) {
                this.props.handleInvalidToken();
              } else {
                this.props.handleError(true, error.message);
              }
            });
        });
      } else if (this.props.mode === "Definizione_Procedure_Autocontrollo") {
        /*this.setState({
      selectProcedureAutocontrolloModalOpen:true,
    }) link*/
        if (
          !this.state.isBlocked ||
          localStorage.getItem("superconsulente") === "true" ||
          localStorage.getItem("consulenteId") === "null"
        ) {
          let objToPass = {};
          let sottoparagrafoIdToPass = this.state.dataToPassForProcedure.id;
          objToPass = {
            tipo: "PROCEDURA_HACCP",
            id: null,
            paragrafoId: this.props.idParagrafo,
            parentComponentId: sottoparagrafoIdToPass,
            titolo: "Nuova Procedura Haccp",
          };
          this.setState({
            selectProcedureAutocontrolloModalOpen: false,
          });
          this.aggiungiDefinizioneProcedureAutocontrollo(objToPass);
        } else {
          alert(
            "Attenzione,operazione non permessa poichè il paragrafo è bloccato"
          );
        }
      } else {
        let columnsOfTable = [...this.state.columns];
        let rowsOfTable = [...this.state.records];
        let newObject = {};
        columnsOfTable.map((item, index) => {
          newObject["" + item.id] = "";
        });
        rowsOfTable.push(newObject);
        this.setState(
          {
            records: rowsOfTable,
          },
          () => {
            this.aggiornaTabellaCustomHandler();
          }
        );
      }
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  handleNewRowProcedureAutocontrollo = (tipologia) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let objToPass = {};
      let sottoparagrafoIdToPass = this.state.dataToPassForProcedure.id;
      if (tipologia === "Scheda") {
        objToPass = {
          tipo: "SCHEDA",
          id: null,
          paragrafoId: this.props.idParagrafo,
          parentComponentId: sottoparagrafoIdToPass,
          titolo: "Nuova Scheda",
        };
      } else if (tipologia === "Controllo") {
        objToPass = {
          tipo: "PROCEDURA_HACCP",
          id: null,
          paragrafoId: this.props.idParagrafo,
          parentComponentId: sottoparagrafoIdToPass,
          titolo: "Nuova Procedura Haccp",
        };
      }
      this.setState({
        selectProcedureAutocontrolloModalOpen: false,
      });
      this.aggiungiDefinizioneProcedureAutocontrollo(objToPass);
    } else {
      alert(
        "Attenzione,operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  render() {
    return (
      <div
        style={{
          alignSelf: "center",
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
          color: "black",
          paddingBottom: "1%",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minWidth: "90vw",
          maxWidth: "90vw",
        }}
      >
        <div style={styles.header}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <IconButton
              size="medium"
              onClick={() => {
                if (
                  !this.state.isBlocked ||
                  localStorage.getItem("superconsulente") === "true" ||
                  localStorage.getItem("consulenteId") === "null"
                ) {
                  this.setState({
                    isTitleEditable: !this.state.isTitleEditable,
                  });
                } else {
                  alert(
                    "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
                  );
                }
              }}
            >
              {this.state.isTitleEditable ? (
                <Edit htmlColor={"#f7a616"} />
              ) : (
                <Edit />
              )}
            </IconButton>
          </div>
          <Grid
            style={{
              width: "100%",
              justifyContent: "flex-start",
              display: "flex",
              marginTop: 4,
            }}
          >
            <TextField
              InputProps={{ disableUnderline: true, style: { fontSize: 30 } }}
              style={{ width: "100%" }}
              InputLabelProps={{ style: { fontSize: 30 } }}
              disabled={!this.state.isTitleEditable}
              onInput={(e) => {
                this.setState({
                  titoloInizialeSottoparagrafo: e.target.value,
                });
              }}
              onBlur={() => {
                this.props.bringTitleToParent(
                  this.state.titoloInizialeSottoparagrafo,
                  this.props.indexValue
                );
              }}
              value={this.state.titoloInizialeSottoparagrafo}
            />
          </Grid>
          <div
            style={{ display: "flex", justifyContent: "right", width: "100%" }}
          >
            <ActionButton
              disabled={false}
              grayVersion={false}
              label="Aggiungi"
              onClick={this.handleNewRow}
            />
            <Button
              type="button"
              onClick={() => {
                if (
                  !this.state.isBlocked ||
                  localStorage.getItem("superconsulente") === "true" ||
                  localStorage.getItem("consulenteId") === "null"
                ) {
                  this.setState(
                    {
                      editMode: !this.state.editMode,
                    },
                    () => {
                      if (
                        !this.state.editMode &&
                        this.state.mode === "Custom"
                      ) {
                        this.props.checkboxCustomButtonUpdate();
                      }
                    }
                  );
                } else {
                  alert(
                    "Attenzione, operazione non permessa perchè il paragrafo è bloccato"
                  );
                }
              }}
              variant="contained"
              size="medium"
              style={{
                color: theme.palette.secondary.main,
                margin: "10px",
                borderRadius: 30,
                backgroundColor: this.state.editMode
                  ? "green"
                  : theme.palette.cndColors.yellow,
              }}
            >
              {this.state.editMode ? "edit on" : "edit off"}
            </Button>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          {this.props.mode !== "Descrizione_Reparto" ? (
            <ReactTable
              filterable={false}
              resizable={true}
              enableEditing={true}
              showPageSizeOptions={false}
              showPageJump={true}
              defaultPageSize={10}
              style={{
                //height:400,
                width: "100%",
              }}
              //pages={this.state.pages}
              data={this.state.records}
              columns={this.state.columns}
              //manual // informs React Table that you'll be handling sorting and pagination server-side
              //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
              previousText="Precedente"
              nextText="Successivo"
              noDataText="Nessun record"
              pageText="Pagina"
              ofText="di"
              rowsText="righe"
              pageJumpText="Vai a pagina"
              rowsSelectorText="righe per pagina"
            />
          ) : (
            <ReactTable
              filterable={false}
              resizable={true}
              enableEditing={true}
              showPageSizeOptions={false}
              showPageJump={true}
              defaultPageSize={10}
              style={{
                // height:400,
                width: "100%",
              }}
              //pages={this.state.pages}
              data={this.state.records}
              columns={this.state.columns}
              //manual // informs React Table that you'll be handling sorting and pagination server-side
              //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
              previousText="Precedente"
              nextText="Successivo"
              noDataText="Nessun record"
              pageText="Pagina"
              ofText="di"
              rowsText="righe"
              pageJumpText="Vai a pagina"
              rowsSelectorText="righe per pagina"
            />
          )}
        </div>
        {/* {this.state.openModalResponsabili && (
          <ModalResponsabiliPerReparto
            open={
              !this.state.errorDialogVisible && this.state.openModalResponsabili
            }
            onClose={this.onCloseModalResponsabili}
            onSubmit={this.onResponsabiliSelected}
            reparto={this.state.selectedReparto}
            responsabiliEsercizio={this.state.responsabiliSicurezza}
          />
        )} */}
        {this.state.openModalAttrezzatura && (
          <ModalSelezioneAttrezzatura
            manualeId={this.props.idManuale}
            titolo={this.props.modalTitle}
            open={this.state.openModalAttrezzatura}
            onClose={this.onCloseModalAttrezzatura}
            setLocaliToTable={this.props.setLocaliToTable}
            handleInvalidToken={this.props.handleInvalidToken}
            handleError={this.props.handleError}
            selectedLocale={this.state.selectedLocale}
          />
        )}
        {this.state.selectProcedureAutocontrolloModalOpen && (
          <ModalSelezioneModalitaAutocontrollo
            open={this.state.selectProcedureAutocontrolloModalOpen}
            onClose={this.onCloseModalSelezioneModalitaAutocontrollo}
            handleNewRow={this.handleNewRowProcedureAutocontrollo}
          />
        )}
        {this.state.isOSATestOpen && (
          <ModalSceltaAzione
            open={this.state.isOSATestOpen}
            onClose={this.onCloseOSAModal}
            titolo={"Test OSA"}
            contentText={"E' stata formalizzata delega specifica in materia?"}
            onIndietroSelected={this.handleIndietroSelected}
            onOkSelected={this.handleOkSelected}
          />
        )}
      </div>
    );
  }
}

CustomTableForManualeForParti.propTypes = {
  isParagrafoBlocked: PropTypes.bool,
  sottoParagrafoData: PropTypes.object,
  handleInvalidToken: PropTypes.func,
  handleError: PropTypes.func,
  columns: PropTypes.array,
  mode: PropTypes.string,
  cursorPosition: PropTypes.number,
  setSelectedCell: PropTypes.func,
  setCursorPosition: PropTypes.func,
};
