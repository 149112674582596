import React, { Component } from "react";
import { Modal, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ReactTable from "react-table";
import ActionButton from "../../../components/ActionButton";
import PropTypes from "prop-types";

const styles = (theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    borderRadius: "6px",
    boxShadow: theme.shadows[6],
    //height: '85%'
    overflowY: "auto",
    width: "55%",
    maxWidth: "1000px",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
  modalSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    gap: "30px",
    width: "90%",
  },
  typography: {
    wordWrap: "break-word",
    align: "center",
    fontSize: 30,
  },
});

class ModalListaAttrezzatura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: this.props.attrezzatureToRead,
      columns: [],
    };
  }

  componentDidMount = () => {
    let a = this.getColumnsForListaAttrezzatura();
    this.setState({
      columns: a,
    });
  };
  getColumnsForListaAttrezzatura = () => {
    return [
      {
        Header: "Nome Attrezzatura",
        id: "attrezzatura",
        accessor: (d) => d,
      },
    ];
  };

  render() {
    const { classes } = this.props;

    return (
      <Modal
        className={classes.modal}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <div className={classes.modalContainer}>
          <div className={classes.modalSectionContainer}>
            <Typography className={classes.typography}>
              Attrezzature associate al controllo conformità
            </Typography>
            <div style={{ width: "100%" }}>
              <ReactTable
                data={this.state.records}
                columns={this.state.columns}
                defaultPageSize={5}
                previousText="Precedente"
                nextText="Successivo"
                noDataText="Nessun record"
                pageText="Pagina"
                ofText="di"
                rowsText="righe"
                pageJumpText="Vai a pagina"
                rowsSelectorText="righe per pagina"
              />
            </div>
            <div>
              <ActionButton label="Chiudi" onClick={this.props.onClose} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ModalListaAttrezzatura.propTypes = {
  attrezzatureToRead: PropTypes.array,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ModalListaAttrezzatura);
