import React from "react";
import Container from '@material-ui/core/Container';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SelectSearch from 'react-select-search';
import Moment from 'moment';
import validator from "validator";
import PropTypes from 'prop-types';

import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from '../../../components/IndietroButton';
import SpinnerComponent from '../../../components/SpinnerComponent';
import GiorniChiusuraEsercizio from "./GiorniChiusuraEsercizio";

import { clearToken } from '../../../utils/storage';
import { getConsulenti } from '../../../utils/api/consulenti_api';
import { registerEsercizioWithResponsabile } from '../../../utils/api/esercizi_commerciali_api';

import theme from '../../../theme';
import Background from '../../../Sfondo.png';
import { InputBase, MenuItem, Select, styled } from "@material-ui/core";

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        paddingTop: '10px'
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '30px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    fieldscontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        padding: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '250px'
    }
}
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      background: "linear-gradient(white, white) padding-box, linear-gradient(to right, #ff4600, #f7a616,#ff4600) border-box;",
      borderRadius: "50em",
      border: "1px solid transparent",
      padding: "1em 2em 1em 1em",
      marginTop:10,
    },
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }));

export default class NuovoEsercizioConsulente extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record: {
                esercizio:{
                    id: null,
                    ragioneSociale: null,
                    rappresentanteLegale: null,
                    eMailRappresentanteLegale: null,
                    telefonoRappresentanteLegale: null,
                    indirizzoSede: null,
                    frazione: null,
                    comuneSede: null,
                    provinciaSede: null,
                    regione: null,
                    codiceMuro: null,
                    codiceSocio: null,
                    numeroAuditAnnui: null,
                    telefono:null,
                    fax:null,
                    eMailEsercizio:"",
                    consulenteId:null,
                    numeroSede: null,
                    cittaSede: null,
                    terminiUsoAccettati: null,
                    privacyPolicyAccettata: null,
                    accessKey:null,
                    gruppoId: 101,
                    codiceSdi: null,
                    pec: null,
                    responsabiliRegionali: null,
                    responsabileArea: null,
                    eMailResponsabileArea:"",
                    direttoreVendite: null,
                    direttore2023: null,
                    consulente2023: null,
                    consulente2022: null,
                    canale2023:null,
                    responsabileSicurezza: {
                        email: '',
                        password: '',
                        confirmPassword: '',
                        nome: '',
                        cognome: '',
                        telefono: null,
                        terminiUsoAccettati: false,
                        privacyPolicyAccettata: false,
                        gruppoId: null
                    }
                },
                giorniChiusura:[]
            },
            validationErrors: {
                nome: '',
                cognome:'',
                ragioneSociale: '',
                email: '',
                password: '',
                confirmPassword: '',
                privacyPolicyAccettata: '',
                emailGestore: '',
                rappresentanteLegale: '',
                eMailRappresentanteLegale: '',
                emailResponsabile:'',
                passwordResponsabile:'',
                eMailResponsabileArea:'',
                eMailEsercizio:'',
            },
            consulenti:[],
            showPassword1: false,
            disabledButtons:false,
            showPassword2: false,
            errorDialogVisible: false,
            errorDialogMessage: '',
            errorDialogTitle: null,
            loading: true,
            saveButtonEnabled: false,
            saveMessageVisible: false,
            width:window.innerWidth,
            height:window.innerHeight,
        };
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        let record = this.state.record;
        record.esercizio.consulenteId = this.props.consulenteId;
        record.esercizio.gruppoId = this.props.gruppoId;
        record.esercizio.responsabileSicurezza.gruppoId = this.props.gruppoId;
        if (this.props.superconsulente) {
            this.setState({
                record
            });
            this.fetchConsulenti(this.props.gruppoId);
        } else {
            this.setState({
                record,
                loading: false
            });
        }
    }

    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    fetchConsulenti = (gruppoId) => {
        getConsulenti(null, gruppoId, 0, 1000, "id")
        .then(result => {
        this.setState({
            loading: false,
            consulenti: result
        });
        })
        .catch(error => {
        if (error.status === 403) {
            this.handleInvalidToken();
        } else {
            this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message
            });
        }
        });
    }

    updateParentState = (data) => {
        let record = this.state.record
        record.giorniChiusura = data
        this.setState({
            record: record, saveButtonEnabled: true
        })
    }

    validateForm = () => {
        let data = this.state.record.esercizio;
        Object.keys(data).forEach(key => {
            this.validateField(key, data[key]);
        });
        let responsabile = this.state.record.esercizio.responsabileSicurezza;
        Object.keys(responsabile).forEach(key => {
            this.validateFieldResponsabileSicurezza(key, responsabile[key]);
        });
        //Return false if there are validation errors:
        let valid = true;
        let validationErrors = this.state.validationErrors;
        Object.values(validationErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });
        return valid;
    }

    validateField = (key, value) => {
        let validationErrors = this.state.validationErrors;
        switch (key) {
            case "ragioneSociale":
                if (value === null || value.length === 0) {
                    validationErrors.ragioneSociale = "Inserire la ragione sociale del punto vendita";
                } else {
                    validationErrors.ragioneSociale = "";
                }
                break;
            case "eMailRappresentanteLegale":
                if (value === null || value.length === 0) {
                    validationErrors.eMailRappresentanteLegale = "Inserire email del rappresentante legale";
                } else {
                    if (validator.isEmail(value)) {
                        validationErrors.eMailRappresentanteLegale = "";
                    } else {
                        validationErrors.eMailRappresentanteLegale = "L'indirizzo email inserito non è valido";
                    }
                }
                break;
            case "rappresentanteLegale":
                if (value === null || value.length === 0) {
                    validationErrors.rappresentanteLegale = "Inserire nome rappresentante legale";
                } else {
                    validationErrors.rappresentanteLegale = "";
                }
                break;
            case "eMailEsercizio":
                if (value.length > 0 || value === null){
                    if (validator.isEmail(value)) {
                        validationErrors.eMailEsercizio = "";
                    } else {
                        validationErrors.eMailEsercizio = "L'indirizzo email inserito non è valido";
                    }
                }
                break;
            case "eMailResponsabileArea":
                if (value.length > 0 || value === null){
                    if (validator.isEmail(value)) {
                        validationErrors.eMailResponsabileArea = "";
                    } else {
                        validationErrors.eMailResponsabileArea = "L'indirizzo email inserito non è valido";
                    }
                }
                break;
            default:
                break;
        }
        this.setState({
            validationErrors
        });
    }
    handleConsulenteIdChange = (event) => {
        let record = this.state.record;
        record.esercizio.consulenteId = event.target.value;
        this.setState({
            record: record, 
            saveButtonEnabled: true
        })
    }

    validateFieldResponsabileSicurezza = (key, value) => {
        let insertedPsw = this.state.record.esercizio.responsabileSicurezza.password;
        let validationErrors = this.state.validationErrors;
        switch (key) {
            case "email":
                if (value.length === 0) {
                    validationErrors.email = "Inserire l'indirizzo email";
                } else {
                    if (validator.isEmail(value)) {
                        validationErrors.email = "";
                    } else {
                        validationErrors.email = "L'indirizzo email inserito non è valido";
                    }
                }
                break;
            case "password":
                if (value.length >= 8) {
                    validationErrors.password = "";
                } else {
                    validationErrors.password = "Inserire una password di almeno 8 caratteri";
                }
                break;
            case "confirmPassword":
                if (value.length === 0) {
                    validationErrors.confirmPassword = "Ripetere la password";
                } else {
                    if (value === insertedPsw) {
                        validationErrors.confirmPassword = "";
                    } else {
                        validationErrors.confirmPassword = "Le due password non corrispondono";
                    }
                }
                break;
                case "nome":
                    if(value.length === 0) {
                        validationErrors.nome = "Attenzione, occorre inserire il nome del responsabile"
                    }
                    break;
                case "cognome":
                    if(value.length === 0) {
                        validationErrors.cognome = "Attenzione, occorre inserire il cognome del responsabile"
                    }
                    break;
            default:
                break;
        }
        this.setState({
            validationErrors
        });
    }

    onItemSelected = (value) => {
        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        const consulenti = this.state.consulenti;
        let selectedConsulente = null;
        for (let i=0; i<consulenti.length; i++) {
            if (consulenti[i].id === value) {
                selectedConsulente = consulenti[i];
                break;
            }
        }
        if (selectedConsulente !== null) {
            let record = this.state.record;
            record.esercizio.consulenteId = selectedConsulente.id;
            this.setState({
                record
            });
        }
    }

    showErrorDialog = (title, message) => {
        this.setState({
            errorDialogVisible: true,
            errorDialogTitle: title,
            errorDialogMessage: message
        });
    }

    handleChange = event => {
        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let validationErrors = this.state.validationErrors;
        let name = event.target.name;
        let value = event.target.value;
        let data = this.state.record;
        data.esercizio[name] = value;
        if (name === 'nome' || name === 'ragioneSociale' || name === 'eMailEsercizio' || name === 'eMailResponsabileArea' || name === 'passwordResponsabile' || name === 'emailResponsabile' || name === 'eMailRappresentanteLegale' || name === 'email' || name === 'password' || name === 'confirmPassword' || name === 'rappresentanteLegale') {
            validationErrors[name] = "";
        }
        this.setState({
            data,
            validationErrors
        });
    }

    handleChangeResponsabileSicurezza = event => {
        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let name = event.target.name;
        let value = event.target.value;
        let validationErrors = this.state.validationErrors;
        let data = this.state.record.esercizio.responsabileSicurezza;
        data[name] = value;
        //Reset validation :
        if (name === 'email' || name === 'password' || name === 'confirmPassword' || name === 'nome' || name === 'cognome') {
            validationErrors[name] = "";
        }
        this.setState({
            data,
            validationErrors
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let isFormValid = this.validateForm();
        if (isFormValid) {
            this.setState({
                loading: true
            });
            registerEsercizioWithResponsabile(this.state.record)
            .then(result => {
                this.setState({
                    loading: false,
                    saveButtonEnabled: false,
                    disabledButtons: true,
                    saveMessageVisible: true
                });
            })
            .catch(error => {
                if (error.status === 403) {
                   this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message
                    });
                } 
            });
        }else{
           window.scrollTo({
            top: 0,
            left:0,
            behavior: "smooth",
          })
        } // no-op if data is not valid
    }

    handleClickShowPassword1 = () => {
        let flag = this.state.showPassword1;
        this.setState({
            showPassword1: !flag
        });
    }

    handleClickShowPassword2 = () => {
        let flag = this.state.showPassword2;
        this.setState({
            showPassword2: !flag
        });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    render() {
        Moment.locale('it-IT');
        const disabledSubmitbutton = this.state.loading || (!this.state.saveButtonEnabled) || this.state.saveMessageVisible;
        let textFieldsDisabled = this.state.saveMessageVisible;
        return (
            <div style={{
                textAlign: 'center',
                color: 'black',
                paddingTop: '10px',
                backgroundImage: this.state.width > 1160 ? `url("${Background}"),url("${Background}")` : `url(""),url("")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '',
                    backgroundAttachment: 'fixed',
                    backgroundPositionX: this.state.width > 1799 ? "140%, -40%" : this.state.width > 1599 ? "160%, -60%" : this.state.width > 1399 ? "180%, -80%" : this.state.width > 1299 ? "200%, -100%" : "220%, -120%"
            }} >
                <Typography variant="h4" >Nuovo punto vendita</Typography>
                <Grid container justify="center" style={{ marginTop: '26px', paddingBottom: '26px' }}>
                    <div style={{  minHeight: '500px' }} >

                        {this.state.loading ?
                            <SpinnerComponent size={24} />
                            : 
                            <form autoComplete="off" onSubmit={this.handleSubmit}>
                                <Container style={{ maxWidth: '700px' }}>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>

                                        <Typography variant="h6" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Dati punto vendita</Typography>
                                        <Grid container style={styles.fieldscontainer}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Grid container direction="column" alignItems="center" style={{ paddingRight: '10px', paddingLeft: '10px', paddingBottom: '10px' }} >
                                                <TextField
                                                                    label="Ragione sociale PdV*"
                                                                    name="ragioneSociale"
                                                                    value={this.state.record.esercizio.ragioneSociale === null ? "" : this.state.record.esercizio.ragioneSociale}
                                                                    style={styles.textfield}
                                                                    error={this.state.validationErrors.ragioneSociale.length > 0 ? true : false}
                                                                    helperText={this.state.validationErrors.ragioneSociale}
                                                                    onChange={this.handleChange}
                                                                />
                                                                <TextField
                                                                    label="Socio/Legale rappresentante*"
                                                                    name="rappresentanteLegale"
                                                                    value={this.state.record.esercizio.rappresentanteLegale === null ? "" : this.state.record.esercizio.rappresentanteLegale}
                                                                    style={styles.textfield}
                                                                    error={this.state.validationErrors.rappresentanteLegale.length > 0 ? true : false}
                                                                    helperText={this.state.validationErrors.rappresentanteLegale}
                                                                    onChange={this.handleChange}
                                                                />
                                                                <TextField
                                                                    label="Email Legale rappresentante*"
                                                                    value={this.state.record.esercizio.eMailRappresentanteLegale === null ? "" : this.state.record.esercizio.eMailRappresentanteLegale}
                                                                    name="eMailRappresentanteLegale"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    error={this.state.validationErrors.eMailRappresentanteLegale.length > 0 ? true : false}
                                                                    helperText={this.state.validationErrors.eMailRappresentanteLegale}
                                                                    //helperText={this.state.validationErrors.nome}
                                                                    //error={this.state.validationErrors.nome.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Cell. Legale rappresentante"
                                                                    value={this.state.record.esercizio.telefonoRappresentanteLegale === null ? "" : this.state.record.esercizio.telefonoRappresentanteLegale}
                                                                    name="telefonoRappresentanteLegale"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    //helperText={this.state.validationErrors.ragioneSociale}
                                                                    //error={this.state.validationErrors.ragioneSociale.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Indirizzo"
                                                                    value={this.state.record.esercizio.indirizzoSede === null ? "" : this.state.record.esercizio.indirizzoSede}
                                                                    name="indirizzoSede"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Frazione"
                                                                    value={this.state.record.esercizio.frazione === null ? "" : this.state.record.esercizio.frazione}
                                                                    name="frazione"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Comune"
                                                                    value={this.state.record.esercizio.comuneSede === null ? "" : this.state.record.esercizio.comuneSede}
                                                                    name="comuneSede"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Provincia"
                                                                    value={this.state.record.esercizio.provinciaSede === null ? "" : this.state.record.esercizio.provinciaSede}
                                                                    name="provinciaSede"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Regione"
                                                                    value={this.state.record.esercizio.regione === null ? "" : this.state.record.esercizio.regione}
                                                                    name="regione"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Codice MURO"
                                                                    value={this.state.record.esercizio.codiceMuro === null ? "" : this.state.record.esercizio.codiceMuro}
                                                                    name="codiceMuro"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Codice socio"
                                                                    value={this.state.record.esercizio.codiceSocio === null ? "" : this.state.record.esercizio.codiceSocio}
                                                                    name="codiceSocio"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="N.Audit annuali"
                                                                    value={this.state.record.esercizio.numeroAuditAnnui === null ? "" : this.state.record.esercizio.numeroAuditAnnui}
                                                                    name="numeroAuditAnnui"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Grid container direction="column" alignItems="center" style={{ paddingRight: '10px', paddingLeft: '10px', paddingBottom: '10px' }} >
                                                   
                                                <TextField
                                                                    label="Telefono"
                                                                    value={this.state.record.esercizio.telefono === null ? "" : this.state.record.esercizio.telefono}
                                                                    name="telefono"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    //helperText={this.state.validationErrors.telefono}
                                                                    //error={this.state.validationErrors.emailGestore.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Fax"
                                                                    value={this.state.record.esercizio.fax === null ? "" : this.state.record.esercizio.fax}
                                                                    name="fax"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Email"
                                                                    value={this.state.record.esercizio.eMailEsercizio === null ? "" : this.state.record.esercizio.eMailEsercizio}
                                                                    name="eMailEsercizio"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    helperText={this.state.validationErrors.eMailEsercizio}
                                                                    error={this.state.validationErrors.eMailEsercizio.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Responsabili regionali"
                                                                    value={this.state.record.esercizio.responsabiliRegionali === null ? "" : this.state.record.esercizio.responsabiliRegionali}
                                                                    name="responsabiliRegionali"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />

                                                                <TextField
                                                                    label="Responsabili area"
                                                                    value={this.state.record.esercizio.responsabileArea === null ? "" : this.state.record.esercizio.responsabileArea}
                                                                    name="responsabileArea"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Email responsabile area"
                                                                    value={this.state.record.esercizio.eMailResponsabileArea === null ? "" : this.state.record.esercizio.eMailResponsabileArea}
                                                                    name="eMailResponsabileArea"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    helperText={this.state.validationErrors.eMailResponsabileArea}
                                                                    error={this.state.validationErrors.eMailResponsabileArea.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Direttore vendite"
                                                                    defaultValue={this.state.record.esercizio.direttoreVendite === null ? "" : this.state.record.esercizio.direttoreVendite}
                                                                    name="direttoreVendite"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Dir/Socio 2023"
                                                                    defaultValue={this.state.record.esercizio.direttore2023 === null ? "" : this.state.record.esercizio.direttore2023}
                                                                    name="direttore2023"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Consulente socio HACCP 2023"
                                                                    defaultValue={this.state.record.esercizio.consulente2023 === null ? "" : this.state.record.esercizio.consulente2023}
                                                                    name="consulente2023"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Canale 2023"
                                                                    defaultValue={this.state.record.esercizio.canale2023 === null ? "" : this.state.record.esercizio.canale2023}
                                                                    name="canale2023"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Consulente socio HACCP 2022"
                                                                    defaultValue={this.state.record.esercizio.consulente2022 === null ? "" : this.state.record.esercizio.consulente2022}
                                                                    name="consulente2022"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                     
                                                   
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        this.props.superconsulente ? 
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                            <Typography variant="h6" style={{ paddingTop: '6px', paddingBottom: '6px' }} >
                                                Selezionare il consulente associato al nuovo punto vendita:
                                            </Typography>
                                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Select
                                    variant="outlined"
                                    defaultValue={null}
                                    displayEmpty={true}
                                    input={<BootstrapInput></BootstrapInput>}
                                    value={this.state.record.esercizio.consulenteId}
                                    onChange={this.handleConsulenteIdChange}
                                    >
                                        {/*<MenuItem key={0} value={null}>Nessun consulente</MenuItem>*/}
                                        {this.state.consulenti.map((consulente,index) => (
                                            <MenuItem key={index+1} value={consulente.id}>{consulente.email}</MenuItem>
                                        ))}
                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        : null
                                    }  
                                    
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                        <Typography variant="h6" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Aggiungi responsabile associato al nuovo punto vendita</Typography>
                                        <Grid container style={styles.fieldscontainer}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Grid container direction="column" alignItems="center" style={{ paddingRight: '10px', paddingLeft: '10px', paddingBottom: '10px' }} >
                                                    <TextField
                                                        label="Email*"
                                                        value={this.state.record.esercizio.responsabileSicurezza.email === null ? "" : this.state.record.esercizio.responsabileSicurezza.email}
                                                        name="email"
                                                        onChange={this.handleChangeResponsabileSicurezza}
                                                        style={styles.textfield}
                                                        disabled={textFieldsDisabled}
                                                        helperText={this.state.validationErrors.email}
                                                        error={this.state.validationErrors.email.length > 0 ? true : false}
                                                    />
                                                   <TextField
                                                        label="Password*"
                                                        value={this.state.record.esercizio.responsabileSicurezza.password === null ? "" : this.state.record.esercizio.responsabileSicurezza.password}
                                                        name="password"
                                                        onChange={this.handleChangeResponsabileSicurezza}
                                                        style={styles.textfield}
                                                        disabled={textFieldsDisabled}
                                                        helperText={this.state.validationErrors.password}
                                                        error={this.state.validationErrors.password.length > 0 ? true : false}
                                                        type={this.state.showPassword1 ? 'text' : 'password'}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Tooltip title="Mostra password">
                                                                        <IconButton
                                                                            label="Mostra password"
                                                                            onClick={this.handleClickShowPassword1}
                                                                        >
                                                                            {this.state.showPassword1 ? <Visibility /> : <VisibilityOff />}
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <TextField
                                                        label="Ripeti password*"
                                                        value={this.state.record.esercizio.responsabileSicurezza.confirmPassword}
                                                        name="confirmPassword"
                                                        onChange={this.handleChangeResponsabileSicurezza}
                                                        style={styles.textfield}
                                                        disabled={textFieldsDisabled}
                                                        helperText={this.state.validationErrors.confirmPassword}
                                                        error={this.state.validationErrors.password.length > 0 ? true : false}
                                                        type={this.state.showPassword2 ? 'text' : 'password'}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Tooltip title="Mostra password">
                                                                        <IconButton
                                                                            label="Mostra password"
                                                                            onClick={this.handleClickShowPassword2}
                                                                        >
                                                                            {this.state.showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Grid container direction="column" alignItems="center" style={{ paddingRight: '10px', paddingLeft: '10px'}} >
                                                
                                                </Grid>
                                                <TextField
                                                        label="Nome*"
                                                        value={this.state.record.esercizio.responsabileSicurezza.nome === null ? "" : this.state.record.esercizio.responsabileSicurezza.nome}
                                                        name="nome"
                                                        onChange={this.handleChangeResponsabileSicurezza}
                                                        style={styles.textfield}
                                                        disabled={textFieldsDisabled}
                                                        helperText={this.state.validationErrors.nome}
                                                        error={this.state.validationErrors.nome.length > 0 ? true : false}
                                                        
                                                    />
                                                    <TextField
                                                        label="Cognome*"
                                                        value={this.state.record.esercizio.responsabileSicurezza.cognome === null ? "" : this.state.record.esercizio.responsabileSicurezza.cognome}
                                                        name="cognome"
                                                        onChange={this.handleChangeResponsabileSicurezza}
                                                        style={styles.textfield}
                                                        disabled={textFieldsDisabled}
                                                        helperText={this.state.validationErrors.cognome}
                                                        error={this.state.validationErrors.cognome.length > 0 ? true : false}
                                                        
                                                    />
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                                </Container>

                                <GiorniChiusuraEsercizio
                                            esercizioId={this.state.record.esercizio.id}
                                            giorniChiusura={this.state.record.giorniChiusura}
                                            updateParentState={this.updateParentState}
                                            disabledButtons={this.state.disabledButtons}

                                        />
                                <Grid container justify="center" style={{ paddingTop: '10px' }} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }} >
                                        <Typography
                                            variant="body1"
                                            style={{
                                                color: theme.palette.primary.main,
                                                visibility: this.state.saveMessageVisible ? 'inherit' : 'hidden'
                                            }}
                                        >
                                            Dati registrati con successo.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                                        <IndietroButton isDisabled={this.state.loading} />
                                        <Button
                                            type="submit"
                                            onClick={this.handleSubmit}
                                            variant="contained"
                                            size="medium"
                                            disabled={disabledSubmitbutton}
                                            style={{
                                                color: theme.palette.secondary.main, 
                                                margin: '10px',
                                                borderRadius:30,
                                                backgroundColor: disabledSubmitbutton ?  theme.palette.disabled.main :  theme.palette.cndColors.yellow,
                                            }}
                                        >
                                            Salva
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        }

                    </div>
                </Grid>

                <ErrorDialog
                    open={this.state.errorDialogVisible}
                    title={this.state.errorDialogTitle}
                    message={this.state.errorDialogMessage}
                    onCloseButtonClicked={this.closeErrorDialog}
                />
            </div>
        );
    }

}

NuovoEsercizioConsulente.propTypes = {
    consulenteId: PropTypes.number.isRequired,
    gruppoId: PropTypes.number.isRequired,
    superconsulente: PropTypes.bool.isRequired
}