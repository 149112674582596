import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { salvataggioCodiceValidazione } from "../../utils/api/gestione_validazioni_api";
import ErrorDialog from "../../components/ErrorDialog";
import PropTypes from "prop-types";
import { Height } from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    color: "black",
    padding: "20px 10px 10px 10px",
    minHeight: "85vh",
  },
  mainContainer: {
    maxWidth: "700px",
    // minHeight: "480px",
  },
  paper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow: theme.shadows[6],
    borderRadius: "12px",
  },
  sectionContainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "30px",
  },
  textfieldsContainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    justifyContent: "center",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
  formcontrol: {
    marginBottom: "30px",
    width: "250px",
  },
  button: {
    color: theme.palette.secondary.main,
    margin: "10px",
    borderRadius: 30,
    backgroundColor: theme.palette.cndColors.yellow,
    "&:hover": {
      backgroundColor: theme.palette.cndColors.yellow,
    },
  },
});

class CodiceValidazioneForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codiceValidazione: "",
      codiceValidazioneConferma: "",
      isEqualCodeError: false,
      isInsertedCodeError: false,
      isModalOpen: false,
      modalTitle: "",
      modalMessage: "",
    };
  }

  validateFields = () => {
    if (
      !this.state.codiceValidazione ||
      !this.state.codiceValidazioneConferma
    ) {
      this.setState({
        isInsertedCodeError: true,
        isEqualCodeError: false,
      });
      return false;
    }
    if (this.state.codiceValidazione !== this.state.codiceValidazioneConferma) {
      this.setState({
        isInsertedCodeError: false,
        isEqualCodeError: true,
      });
      return false;
    }
    return true;
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (!this.validateFields()) return;

    const body = {
      nuovaPassword: this.state.codiceValidazione,
      confermaNuovaPassword: this.state.codiceValidazioneConferma,
    };
    this.setState({
      isEqualCodeError: false,
      isInsertedCodeError: false,
    });
    salvataggioCodiceValidazione(this.props.idEsercizio, body)
      .then((response) => {
        if (response.status === 200) {
          //   this.setState({
          //     isModalOpen: true,
          //     modalTitle: "Successo",
          //     modalMessage: "Passcode modificato con successo",
          //   });
          this.props.history.push("/successPasscode");
        }
      })
      .catch((error) => {
        const errorMessage =
          error.status === 500
            ? "Servizio non disponibile, riprovare più tardi."
            : error.status === 404
            ? "Richiesta non valida: il punto vendita indicato non esiste"
            : error.status === 400
            ? "Richiesta non valida: i codici inseriti non coincidono"
            : "Servizio non disponibile, riprovare più tardi.";

        this.setState({
          isModalOpen: true,
          modalTitle: "Errore",
          modalMessage: errorMessage,
        });
      });
  };

  handleCloseDialog = () => {
    this.setState({ isModalOpen: false });
    // this.setState(
    //   {
    //     isModalOpen: false,
    //   },
    //   () => {
    //     if (this.state.modalTitle !== "Errore") {
    //       this.props.history.push("/");
    //     }
    //   }
    // );
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div className={classes.root}>
          <Typography variant="h4">Reset Passcode</Typography>
          <Paper className={classes.paper}>
            <Container id="mainContainer" className={classes.mainContainer}>
              <form autoComplete="off" onSubmit={this.onSubmit}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.sectionContainer}
                >
                  <Grid container className={classes.textfieldsContainer}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography>Inserire il nuovo passcode</Typography>
                      {this.state.isEqualCodeError &&
                        !this.state.isInsertedCodeError && (
                          <Typography color="error">
                            Attenzione, i passcode devono essere identici
                          </Typography>
                        )}
                      {!this.state.isEqualCodeError &&
                        this.state.isInsertedCodeError && (
                          <Typography color="error">
                            Attenzione, compilare entrambi i campi
                          </Typography>
                        )}
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        style={{ paddingBottom: "10px" }}
                      >
                        <TextField
                          label="Passcode"
                          name="code"
                          value={this.state.codiceValidazione}
                          onInput={(e) =>
                            this.setState({ codiceValidazione: e.target.value })
                          }
                          InputProps={{}}
                          className={classes.textfield}
                        />
                        <TextField
                          label="Conferma Passcode"
                          name="confirm"
                          value={this.state.codiceValidazioneConferma}
                          onInput={(e) =>
                            this.setState({
                              codiceValidazioneConferma: e.target.value,
                            })
                          }
                          InputProps={{}}
                          className={classes.textfield}
                        />
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            type="button"
                            onClick={this.onSubmit}
                            variant="contained"
                            size="medium"
                            disabled={false}
                            className={classes.button}
                          >
                            Salva
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </Paper>
        </div>
        <ErrorDialog
          title={this.state.modalTitle}
          message={this.state.modalMessage}
          onCloseButtonClicked={this.handleCloseDialog}
          open={this.state.isModalOpen}
        />
      </Fragment>
    );
  }
}

CodiceValidazioneForm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  idEsercizio: PropTypes.string.isRequired,
};

export default withRouter(withStyles(styles)(CodiceValidazioneForm));
