import React from "react";
import { Route, BrowserRouter, Switch, useParams } from "react-router-dom";

import ResetPswSuccessPage from "./scenes/resetpsw/ResetPswSuccessPage";
import AppMainComponent from "./AppMainComponent";

import "./App.css";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import CodiceValidazioneForm from "./scenes/codiceValidazione/CodiceValidazioneForm";
import SuccessPasscode from "./pages/SuccessPasscode";

export default function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div>
          <Switch>
            <Route
              path="/reset_password_success"
              component={ResetPswSuccessPage}
            />
            <Route
              path="/codicevalidazione/:idEsercizio"
              component={WrapperCodiceValidazione}
            />
            <Route path="/successPasscode" component={SuccessPasscode} />
            <Route path="/" render={() => <AppMainComponent />} />
          </Switch>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

function WrapperCodiceValidazione() {
  let { idEsercizio } = useParams();
  return <CodiceValidazioneForm idEsercizio={idEsercizio} />;
}
