import React, { Component, Fragment } from "react";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import SpinnerComponent from "./SpinnerComponent";
import { ReactComponent as DiagnosticaArrow } from "../../src/DiagnosticaArrow.svg";
import ReactTable from "react-table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { getResponsabiliSicurezzaDiagnostica } from "../utils/api/responsabili_sicurezza_api";
import IndietroButton from "./IndietroButton";
import theme from "../theme";

const styles = {
  modalcontainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //width: "560",
    //height: 400,
    width: 1000,
    height: 600,
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    boxShadow: 24,
    padding: "20px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  text: {
    padding: "0px 0px 10px 0px",
  },
};

export default class ModalDiagnostica extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      success: false,
      showMessage: false,
      error: false,
      message: "",
      show: true,
      openDialog: false,
      data: [],
      diagnosticaData: null,
      cleanData: false,
    };
  }

  componentDidMount() {
    getResponsabiliSicurezzaDiagnostica(this.props.responsabileId)
      .then((response) => {
        // console.log(response);
        const sortedResponse = response.sort(
          (a, b) => new Date(b.dataInserimento) - new Date(a.dataInserimento)
        );
        this.setState({
          data: sortedResponse,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });

        console.log(error);
      });
  }

  handleClose = () => {
    this.props.onClose();
  };

  onChange = () => {
    this.setState({
      showMessage: false,
      error: false,
    });
  };

  handleClickOpenDialog = (id) => {
    let data = this.findById(id).risultato;
    try {
      const parsedData = JSON.parse(data);
      //console.log(parsedData);
      this.setState({
        openDialog: true,
        diagnosticaData: JSON.parse(data),
        cleanData: true,
      });
    } catch (error) {
      //console.log("Errore nel parsing JSON:", error);
      this.setState({
        openDialog: true,
        diagnosticaData: data,
        cleanData: false,
      });
    }
  };

  findById(id) {
    return this.state.data.find((obj) => obj.id === id);
  }
  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  formattaData(dataString) {
    const data = new Date(dataString);

    const giorno = ("0" + data.getDate()).slice(-2);
    const mese = ("0" + (data.getMonth() + 1)).slice(-2);
    const anno = data.getFullYear();
    const ora = ("0" + data.getHours()).slice(-2);
    const minuto = ("0" + data.getMinutes()).slice(-2);

    return `${giorno}-${mese}-${anno} ${ora}:${minuto}`;
  }

  getColumns = () => {
    return [
      {
        Header: "ID responsabile",
        id: "responsabileId",
        accessor: (d) => d.responsabileId,
      },
      {
        Header: "Data",
        id: "dataInserimento",
        accessor: (d) => {
          return {
            id: d.id,
            value: d.dataInserimento,
          };
        },
        Cell: (props) => (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography> {this.formattaData(props.value.value)}</Typography>
            <IconButton
              onClick={() => this.handleClickOpenDialog(props.value.id)}
              size="small"
            >
              <DiagnosticaArrow width={13} height={13} />
            </IconButton>
          </Box>
        ),
      },
    ];
  };

  render() {
    const columns = this.getColumns();
    return (
      <>
        <Modal open={this.props.open} onClose={this.handleClose}>
          <Box style={styles.modalcontainer}>
            <Fragment>
              <Typography variant="h5">Diagnostica</Typography>
              {this.state.loading ? (
                <SpinnerComponent size={30} />
              ) : (
                <ReactTable
                  showPageSizeOptions={false}
                  defaultPageSize={10}
                  columns={columns}
                  data={this.state.data}
                  previousText="Precedente"
                  nextText="Successivo"
                  noDataText="Nessun record"
                  pageText="Pagina"
                  ofText="di"
                />
              )}
            </Fragment>

            {/*    */}
            <Box style={{ alignSelf: "center" }}>
              <Button
                onClick={() => this.handleClose()}
                style={{
                  color: "white",
                  margin: "10px",
                  paddingLeft:"20px",
                  paddingRight:"20px",
                  borderRadius: 30,
                  backgroundColor: theme.palette.cndColors.red,
                }}
              >
                Chiudi
              </Button>
            </Box>
          </Box>
        </Modal>
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          BackdropProps={{ invisible: true }}
        >
          {this.state.cleanData ? (
            <DialogContent>
              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>firmato:</span>
                {this.state.diagnosticaData?.firmato ? "true" : "false"}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>email:</span>
                {this.state.diagnosticaData?.email}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}> id:</span>
                {this.state.diagnosticaData?.id}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>revisioneId:</span>
                {this.state.diagnosticaData?.revisioneId}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>
                  esercizioCommercialeId:
                </span>
                {this.state.diagnosticaData?.esercizioCommercialeId}
              </Typography>
              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>nomeEsercizio:</span>
                {this.state.diagnosticaData?.nomeEsercizio}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>revisioneDate:</span>
                {this.state.diagnosticaData?.revisioneDate}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>manualeId:</span>
                {this.state.diagnosticaData?.manualeId}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>isOperatore:</span>
                {this.state.diagnosticaData?.isOperatore ? "true" : "false"}
              </Typography>
              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>isCapoNegozio:</span>
                {this.state.diagnosticaData?.isCapoNegozio ? "true" : "false"}
              </Typography>
              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>device:</span>
                <span style={{ wordWrap: "break-word" }}>
                  {JSON.stringify(this.state.diagnosticaData?.device)}
                </span>
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>versioneApp:</span>
                {this.state.diagnosticaData?.versioneApp}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>esitoChiamate:</span>
                {this.state.diagnosticaData?.esitoChiamate}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>
                  chiamate non raggiunte:
                </span>
                <span style={{ wordWrap: "break-word" }}>
                  {this.state.diagnosticaData &&
                  this.state.diagnosticaData.chiamateNonRaggiunte
                    ? this.state.diagnosticaData.chiamateNonRaggiunte.join(", ")
                    : " []"}
                </span>
              </Typography>
            </DialogContent>
          ) : null}
          {!this.state.cleanData ? (
            <DialogContent>
              <Typography style={{ fontSize: 12, wordWrap: "break-word" }}>
                {this.state.diagnosticaData
                  ? this.state.diagnosticaData
                  : "Nessun dato"}
              </Typography>
            </DialogContent>
          ) : null}
          <DialogActions>
            <Button onClick={this.handleCloseDialog} autoFocus>
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

ModalDiagnostica.propTypes = {
  //  email: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
