import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import PropTypes from 'prop-types';

const styles = {
    root: {
        minHeight: '126px',
        margin: '10px 0px 10px 0px',
    },
    texts: {
        marginBottom: '20px'
    },
    formcontrol: {
        marginBottom: '30px',
        width: '320px',
    },
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '146px',
            width: '320px',
        },
    },
};

export default function ComponenteSelezioneBrand(props) {
    return (
        <Container style={styles.root}>
            <Typography variant="h5" style={styles.texts} >{props.title}</Typography>
            <Typography variant="body1" style={styles.texts} >
                {props.description}
            </Typography>
            <FormControl
                style={styles.formcontrol}
                error={props.validationMessage.length > 0 ? true : false}
            >
                <InputLabel id="brand-form-label">
                    Seleziona un brand
                </InputLabel>
                <Select
                    value={props.brandId}
                    name="id"
                    onChange={props.onSelectionChanged}
                    disabled={props.disabled}
                    MenuProps={MenuProps}
                >
                    <MenuItem disabled value={-1}>
                        <em>Nessuno</em>
                    </MenuItem>
                    <MenuItem value={''} >
                        <em>Crea un nuovo brand</em>
                    </MenuItem>
                    { props.brands.map((value, index) => 
                        (<MenuItem key={index} value={value.id}>{value.nome}</MenuItem>))
                    }
                </Select>
                {/* <FormHelperText>{props.validationMessage}</FormHelperText> */}
            </FormControl>
        </Container>
    );
}

ComponenteSelezioneBrand.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    brands: PropTypes.array.isRequired,
    brandId: PropTypes.number,
    disabled: PropTypes.bool.isRequired,
    validationMessage: PropTypes.string.isRequired,
    onSelectionChanged: PropTypes.func.isRequired,
}