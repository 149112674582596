import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import theme from "../theme.js";

export default function ActionButton(props) {
  return (
    <Button
      type="button"
      onClick={props.onClick}
      variant="contained"
      size="medium"
      disabled={props.disabled}
      startIcon={props.startIcon !== null ? props.startIcon : null}
      style={{
        color: theme.palette.secondary.main,
        margin:
          props.margin !== undefined && props.margin !== null
            ? props.margin
            : 10,
        borderRadius: 30,
        backgroundColor: props.disabled
          ? theme.palette.disabled.main
          : props.grayVersion
          ? theme.palette.secondary.other
          : !props.isRed
          ? theme.palette.cndColors.yellow
          : theme.palette.cndColors.red,
      }}
    >
      {props.label}
    </Button>
  );
}

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  grayVersion: PropTypes.bool,
  margin: PropTypes.string,
};
