import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';

import validator from "validator";
import SpinnerComponent from '../../components/SpinnerComponent';

import theme from '../../theme.js';

const styles = {
    // header: {
    //     backgroundColor: '#183780',
    //     padding: '10px',
    //     color: 'white'
    // },
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        padding: '10px'
    },
}

export default class RecoverPswPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                email: ""
            },
            validationErrors: {
                email: ""
            },
            loading: false,
            receivedResponse: false,
            message: ""
        }
    }

    validateForm = () => {
        //Validate every property (only email here):
        let data = this.state.data;
        Object.keys(data).forEach(key => {
            this.validateField(key, data[key]);
        });
        //Return false if there are validation errors:
        let valid = true;
        let errorMessages = this.state.validationErrors;
        Object.values(errorMessages).forEach(val => {
            val.length > 0 && (valid = false);
        });
        return valid;
    }

    validateField = (key, value) => {
        let validationErrors = this.state.validationErrors;
        switch (key) {
            case "email":
                if (value.length === 0) {
                    validationErrors.email = "Inserire l'indirizzo email";
                } else {
                    if (validator.isEmail(value)) {
                        validationErrors.email = "";
                    } else {
                        validationErrors.email = "L'indirizzo email inserito non è valido";
                    }
                }
                break;
            default:
                break;
        }
        this.setState({
            validationErrors
        });
    }

    recoverPassword = () => {
        let isFormValid = this.validateForm();
        if (isFormValid) {
            this.setState({
                loading: true,
                sentRequest: true,
                receivedResponse: false
            });
            let requestBody = {
                email: this.state.data.email
            };
            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            fetch(ENDPOINT + '/api/account/reimposta_password_init', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            }).then(response => {
                let statusCode = response.status;
                let msg = "";
                if (statusCode === 400) {
                    msg = "Richiesta non valida.";
                } else if (statusCode === 404) {
                    msg = "Utente non trovato.";
                } else if (statusCode === 200) {
                    msg = "Un'email per il recupero della password è stata inviata all'indirizzo fornito.";
                } else {
                    msg = "Errore di servizio. Per favore, riprovare più tardi.";
                }
                this.setState({
                    loading: false,
                    receivedResponse: true,
                    message: msg
                });
            }).catch(err => {
                this.setState({
                    loading: false,
                    receivedResponse: true,
                    message: "Servizio non raggiungibile. Per favore, riprovare più tardi."
                });
            });
        }
    }

    handleChangeEvent = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        let validationErrors = this.state.validationErrors;
        //this.validateField(name, value);
        let data = this.state.data;
        data[name] = value;
        validationErrors[name] = "";

        this.setState({
            data,
            validationErrors
        });
    }

    render() {
        return (
            <div style={styles.mainContainer}>
                <ThemeProvider theme={theme}>
                {/* <header style={styles.header}>
                    <h1>{'Benvenuto'}</h1>
                </header> */}
                <Paper style={{ padding: '10px', minHeight: '100vh' }}>
                    <Typography variant="h4" style={{ padding: '10px' }} >
                        Recupero password
                    </Typography>
                    <Typography variant="h6" style={{ padding: '10px' }} >
                        Inserisci qui il tuo indirizzo di posta elettronica.
                    </Typography>
                    <div style={{ margin: '20px' }}>
                        <TextField
                            label="Email"
                            placeholder=""
                            value={this.state.data.email}
                            name="email"
                            onChange={this.handleChangeEvent}
                            variant="outlined"
                            fullWidth
                            helperText={this.state.validationErrors.email}
                            error={this.state.validationErrors.email.length > 0 ? true : false}
                        />
                    </div>
                    <Grid
                        container
                        spacing={8}
                        direction="row"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12} sm={12} ml={12} >
                            <Button variant="contained" 
                                style={{
                                    color: theme.palette.secondary.main, 
                                    backgroundColor: theme.palette.cndColors.yellow, 
                                    borderRadius: 30,
                                }} 
                                onClick={this.recoverPassword} 
                                >Ripristina password</Button>
                        </Grid>
                        <Grid item xs={12} sm={12} ml={12} >
                            { this.state.loading ? 
                             <SpinnerComponent size={24}/> 
                             : 
                             ''
                            }
                            {this.state.receivedResponse ? <label style={{ color: 'black', fontSize: 16, wordBreak: 'break-word' }}>{this.state.message}</label> : ''}
                        </Grid>
                    </Grid>
                </Paper>
            </ThemeProvider>
            </div>
        );
    }

}