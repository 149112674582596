import { Component } from "react";
import React from "react";
import Modal from '@material-ui/core/Modal';
import { Button, IconButton, TextField, Typography } from "@material-ui/core";
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { addNewTipologiaStrumentazione, getAttrezzature, getAttrezzatureCount, getTipologiaStrumentazione, updateAttrezzatureLocale } from "../../../utils/api/locali_e_attrezzature_api";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import theme from '../../../theme';
import ReactTable from "react-table";
import ActionButton from "../../../components/ActionButton";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

const styles = {
    modalcontainer: {
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        marginLeft: '30%',
        marginRight: '30%',
        //height: '85%'
        overflowY: 'scroll',
        height: '200px',
        display:"flex",
    },
    miniModalContainer:{
      flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '300px',
        display:"flex",
    },
    modalsectioncontainer: {
        flex: 1,
        display: "flex",
        //marginTop: 20,
        //marginBottom: 20,
        flexDirection: "column",
        justifyContent: 'space-evely',

    },
    buttoncontainer: {
      justifyContent: 'center',
      padding: '10px 0px',
    },
    modalTitle:{
      fontSize:20,
      alignSelf:"center",
    }
}

export default class ModalChangeChapterName extends Component{
    constructor(props){
        super(props);
        this.state = {
            chapter:this.props.chapter,
        }
    }
    render(){
    return(
        <Modal 
        open={this.props.isChangeChapterNameModalOpen}
        onClose={this.props.closeModalToChangeChapterName}
        >
        <div style={styles.modalcontainer}>
            <div style={styles.modalsectioncontainer}>
            <Typography
                variant="h6"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
              >
                Modifica titolo capitolo
              </Typography>
              <TextField value={this.state.chapter.name} onInput={(e) => {
                let chapter = this.state.chapter;
                chapter.name = e.target.value;
                this.setState({
                    chapter: chapter,
                })
              }}></TextField>
            </div>
            <div style={styles.buttoncontainer}>
                <ActionButton label="Indietro" onClick = {() => {
                    this.props.closeModalToChangeChapterName();
                }}></ActionButton>
                <ActionButton label = "Salva" onClick={() => {
                    this.props.saveChapterName(this.state.chapter);
                }}></ActionButton>
            </div>
        </div>
        </Modal>
    )}
}