import React, { useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import { useHistory } from "react-router-dom";

import theme from '../../../theme.js';
import {ReactComponent as ModificaContenuto} from "../../../ModificaContenuto.svg";

import PropTypes from 'prop-types';
import { setChapterIndex, setOpenParti, setParagraphIndex } from "../../../utils/storage.js";

export default function ModificaModelloManualeButton(props) {
  //const [chapters, setChapters] = useState("false");
  let history = useHistory();

  //const hasChapters = (modello) => {
    /*getCapitoliCount(modello).then((result) => {
      if(result > 0) {
        setChapters("true");
      }
      else{
        setChapters("false");
      }
    }).catch((error) => {
      if (error.status === 403) {
        this.props.handleInvalidToken();
      } else {
        this.props.handleError(true, error.message);
      }
    });*/
  //}
  useEffect(() => {
    //hasChapters(props.modelloId);
  })
  

  return (
    <IconButton
      size="small"
      disabled={props.disabled}
      style={{
        color: props.disabled ? theme.palette.disabled.main : theme.palette.cndColors.yellow,
      }}
      onClick={() => { 
        setChapterIndex(0);
        setParagraphIndex(0);
        setOpenParti(JSON.stringify({
          parti:[],
          capitoli:[],
          paragrafi:[]
        }))
        history.push("/amministrazione/manuali/nuovomanuale/" + props.modelloId + "/modello/"+true+"/null")
        }} >
      <ModificaContenuto height={23} width={23} fill={props.disabled ? theme.palette.disabled.main : theme.palette.cndColors.yellow} />
    </IconButton>
  );

}

ModificaModelloManualeButton.propTypes = {
  modelloId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired
}