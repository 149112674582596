import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import GoToButton from "../components/GoToButton";
import ActionButton from "../../../components/ActionButton";

import PropTypes from 'prop-types';

const styles = {
    sectioncontainer: {
        //border: '1px solid #e0e0e0',
        //borderRadius: '6px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    innercontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '320px'
    },
    buttonContainer: {
        margin: '38px 8px 8px 8px',
    },
}

export default class FormRegistrazioneConsulenteGruppo extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showPassword1: false,
            showPassword2: false,
        };
    }

    handleClickShowPassword1 = () => {
        let flag = this.state.showPassword1;
        this.setState({
            showPassword1: !flag
        });
    }

    handleClickShowPassword2 = () => {
        let flag = this.state.showPassword2;
        this.setState({
            showPassword2: !flag
        });
    }

    render () {
        const errors = this.props.validationErrors.email.length > 0
            || this.props.validationErrors.password.length > 0
            || this.props.validationErrors.confirmPassword.length > 0 ;
        return (
            <form autoComplete="off" onSubmit={this.props.onSubmit}>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                        <Grid container style={styles.innercontainer}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                    <TextField
                                        label="Email*"
                                        value={this.props.consulente.email}
                                        name="email"
                                        onChange={this.props.onChange}
                                        disabled={this.props.disabled}
                                        style={styles.textfield}
                                        helperText={this.props.validationErrors.email}
                                        error={this.props.validationErrors.email.length > 0 ? true : false}
                                    />
                                    <TextField
                                        label="Password*"
                                        value={this.props.consulente.password}
                                        name="password"
                                        onChange={this.props.onChange}
                                        disabled={this.props.disabled}
                                        style={styles.textfield}
                                        helperText={this.props.validationErrors.password}
                                        error={this.props.validationErrors.password.length > 0 ? true : false}
                                        type={this.state.showPassword1 ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip title="Mostra password">
                                                        <IconButton
                                                            label="Mostra password"
                                                            onClick={this.handleClickShowPassword1}
                                                        >
                                                            {this.state.showPassword1 ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        label="Ripeti password*"
                                        value={this.props.consulente.confirmPassword}
                                        name="confirmPassword"
                                        onChange={this.props.onChange}
                                        disabled={this.props.disabled}
                                        style={styles.textfield}
                                        helperText={this.props.validationErrors.confirmPassword}
                                        error={this.props.validationErrors.confirmPassword.length > 0 ? true : false}
                                        type={this.state.showPassword2 ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip title="Mostra password">
                                                        <IconButton
                                                            label="Mostra password"
                                                            onClick={this.handleClickShowPassword2}
                                                        >
                                                            {this.state.showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        label="Nome"
                                        value={this.props.consulente.nome}
                                        name="nome"
                                        onChange={this.props.onChange}
                                        disabled={this.props.disabled}
                                        style={styles.textfield}
                                    />
                                    <TextField
                                        label="Cognome"
                                        value={this.props.consulente.cognome}
                                        name="cognome"
                                        onChange={this.props.onChange}
                                        disabled={this.props.disabled}
                                        style={styles.textfield}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                    <TextField
                                        label="Telefono"
                                        value={this.props.consulente.telefono}
                                        name="telefono"
                                        onChange={this.props.onChange}
                                        disabled={this.props.disabled}
                                        style={styles.textfield}
                                    />
                                    <TextField
                                        label="Partita IVA"
                                        value={this.props.consulente.partitaIva}
                                        name="partitaIva"
                                        onChange={this.props.onChange}
                                        disabled={this.props.disabled}
                                        style={styles.textfield}
                                    />
                                    <TextField
                                        label="Codice SDI"
                                        value={this.props.consulente.codiceSdi}
                                        name="codiceSdi"
                                        onChange={this.props.onChange}
                                        disabled={this.props.disabled}
                                        style={styles.textfield}
                                    />
                                    <TextField
                                        label="PEC"
                                        value={this.props.consulente.pec}
                                        name="pec"
                                        onChange={this.props.onChange}
                                        disabled={this.props.disabled}
                                        style={styles.textfield}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justify="center" >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                        <GoToButton
                            showAlert={this.props.changesNotSaved} 
                            path={"/amministrazione/gruppi/" + this.props.consulente.gruppoId}
                            label="Torna alla pagina di modifica del gruppo"
                            disabled={false}
                            redMode={true}
                            grayVersion={false}
                        />
                        <ActionButton 
                            label="Registra"
                            disabled={this.props.disabled || errors || this.props.submitDisabled}
                            onClick={this.props.onSubmit}
                            grayVersion={false}
                        />
                    </Grid>
                </Grid>
            </form>
        );
    }
}

FormRegistrazioneConsulenteGruppo.propTypes = {
    consulente: PropTypes.object.isRequired,
    validationErrors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitDisabled: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    changesNotSaved: PropTypes.bool.isRequired,
}