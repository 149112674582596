import React, { Component } from 'react';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import EditRecordButton from '../components/EditRecordButton';
import CustomTable from '../components/CustomTable';
import NewRecordButton from '../components/NewRecordButton';

import { clearToken } from '../../../utils/storage.js';
import { getGruppi } from '../../../utils/api/gruppi_api';

const styles = {
    root: {
        textAlign: 'center',
        color: 'black',
        paddingTop: "30px",
        minHeight: '100%'
    }
};

export default class GruppiTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gruppi: [],
            loading: true,
            errorDialogVisible: false,
            errorDialogTitle: null,
            errorDialogMessage: '',
        };
    }

    componentDidMount() {
        this.fetchGruppi();
    }

    openErrorDialog = (errorMessage) => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: errorMessage
        });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    handleInvalidToken = () => {
        const errorMessage = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        this.openErrorDialog(errorMessage);
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    fetchGruppi = () => {
        this.setState({
            loading: true,
        });
        getGruppi(101, null, 0, 1000, "id")
            .then(result => {
                this.setState({
                    gruppi: result,
                    loading: false
                });
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                    });
                    this.openErrorDialog(error.message);
                }
            });
    }

    render() {
        const columns = [
            {
                Header: 'Numero',
                id: 'numero',
                accessor: row => row,
                width: 120,
                Cell: props => props.index
            },
            {
                Header: 'ID gruppo',
                id: 'id',
                accessor: d => d.id,
                filterable: true,
                width: 120,
            },
            {
                Header: 'Nome',
                id: 'nome',
                accessor: d => d.nome,
                filterable: true,
                minWidth: 120,
            },
            {
                Header: 'Brand',
                id: 'brand',
                accessor: d => d.brand === null ? ' - ' : d.brand.nome,
                minWidth: 90,
            },
            {
                Header: 'Modifica',
                id: 'modifica',
                accessor: row => {
                    return (
                        <EditRecordButton recordId={row.id} disabled={false} />
                    )
                },
                width: 80,
            }
        ];
        return (
            <div style={styles.root}>
                {this.state.loading ?
                    <SpinnerComponent size={24} />
                    :
                    <div>
                        <div style={{ paddingTop: '10px', paddingBottom: '10px', textAlign: 'right' }}>
                            <NewRecordButton disabled={false} label='gruppo' />
                        </div>
                        <CustomTable
                            columns={columns}
                            records={this.state.gruppi}
                            loading={false}
                            filterable={false}
                            resizable={true}
                            sortable={false}
                            defaultPageSize={10}
                        />
                    </div>
                }
                <ErrorDialog
                    open={this.state.errorDialogVisible}
                    title={this.state.errorDialogTitle}
                    message={this.state.errorDialogMessage}
                    onCloseButtonClicked={this.closeErrorDialog}
                />
            </div>
        );
    }

}