import React, { Fragment } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import "./App.css";
import CustomHeader from "./components/CustomHeader";
import withAuthentication from "./components/withAuthentication";
import AccettaEsercizioCommerciale from "./scenes/controlpanel/AccettaEsercizioCommerciale";
import ControlPanel from "./scenes/controlpanel/ControlPanel";
import DettaglioConsulente from "./scenes/controlpanel/dettaglio_consulente/DettaglioConsulente";
import DettaglioEsercizioCommerciale from "./scenes/controlpanel/dettaglio_eserciziocommerciale/DettaglioEsercizioCommerciale";
import DettaglioManualeSicurezza from "./scenes/controlpanel/dettaglio_manuale/DettaglioManualeSicurezza";
import DettaglioResponsabileSicurezza from "./scenes/controlpanel/dettaglio_responsabilesicurezza/DettaglioResponsabileSicurezza";

import NuovoEsercizioCommerciale from "./scenes/controlpanel/dettaglio_eserciziocommerciale/NuovoEsercizioCommerciale";
import NuovoEsercizioConsulente from "./scenes/controlpanel/dettaglio_eserciziocommerciale/NuovoEsercizioConsulente";

import HomePage from "./scenes/home/HomePage";
import LoginPage from "./scenes/login/LoginPage";
import LogoutPage from "./scenes/logout/LogoutPage";
import PagamentoPage from "./scenes/pagamento_abbonamento/PagamentoPage";
import RecoverPswPage from "./scenes/resetpsw/RecoverPswPage";
import ResetPswPage from "./scenes/resetpsw/ResetPswPage";

import NuovoGruppoPage from "./scenes/controlpanel/dettaglio_gruppo/NuovoGruppoPage";
import ModificaGruppoPage from "./scenes/controlpanel/dettaglio_gruppo/ModificaGruppoPage";
import RegistraConsulenteGruppoPage from "./scenes/controlpanel/dettaglio_consulente/RegistraConsulenteGruppoPage";
import BrandPage from "./scenes/controlpanel/dettaglio_brand/BrandPage";

import {
  getConsulenteId,
  isSuperconsulente,
  getGruppoId,
} from "./utils/storage.js";

import CreazioneNuovoManuale from "./scenes/controlpanel/manuale_personalizzato/CreazioneNuovoManuale";
import DettaglioSchedaNuovoManuale from "./scenes/controlpanel/manuale_personalizzato/DettaglioSchedaNuovoManuale";
import DettaglioProceduraNuovoManuale from "./scenes/controlpanel/manuale_personalizzato/DettaglioProceduraNuovoManuale";
import CreazionePartiNuovoManuale from "./scenes/controlpanel/manuale_personalizzato/CreazionePartiNuovoManuale";
import ModificaSottoparagrafoManualeConParti from "./scenes/controlpanel/manuale_personalizzato/ModificaSottoparagrafoManualeConParti";
import CodiceValidazioneForm from "./scenes/codiceValidazione/CodiceValidazioneForm";
import DownloadPDFPage from "./scenes/downloadPDF/DownloadPDFPage";

export default function AppMainComponent() {
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <div className="App">
          <CustomHeader></CustomHeader>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/abbonamenti" component={PagamentoPage} />
            <Route path="/logout" component={LogoutPage} />
            <Route path="/recover_password" component={RecoverPswPage} />
            <Route path="/reset_password" component={ResetPswPage} />
            <Route
              path="/esercizio_conferma/:activationKey"
              component={WrapperAccettaEsercizio}
            />
            <Route
              path="/codicevalidazione/:idEsercizio"
              component={WrapperCodiceValidazione}
            />
            <Route
              path="/download-pdf-firmato/:revisioneId"
              component={WrapperDownloadPDF}
            />
            <Route
              path="/amministrazione/gruppi/:gruppoId/consulenti/nuovo"
              component={withAuthentication(WrapperRegistraConsulenteGruppo)}
            />
            <Route
              path="/amministrazione/gruppi/:gruppoId"
              component={withAuthentication(WrapperDettaglioGruppo)}
            />
            <Route
              path="/amministrazione/brand/:brandId"
              component={withAuthentication(WrapperDettaglioBrand)}
            />
            <Route
              path="/amministrazione/responsabili_sicurezza/:responsabileId"
              component={withAuthentication(
                WrapperDettaglioResponsabileSicurezza
              )}
            />
            <Route
              path="/amministrazione/consulenti/:consulenteId"
              component={withAuthentication(WrapperDettaglioConsulente)}
            />
            <Route
              path="/amministrazione/esercizi_commerciali/:esercizioId"
              component={withAuthentication(
                WrapperDettaglioEsercizioCommerciale
              )}
            />
            <Route
              path="/amministrazione/manuali/dettagli/:manualeId"
              component={withAuthentication(WrapperDettaglioRevisioneManuale)}
            />
            <Route
              path="/amministrazione/manuali/nuovomanuale/:manualeId/:mode/:hasChapters/:revisioneId"
              component={withAuthentication(WrapperNuovoManualeTest)}
            />
            <Route
              path="/amministrazione/modificaSottoparagrafo/:manualeId/:paragrafo/:sottoparagrafo/:mode"
              component={withAuthentication(WrapperNuovoManualeParagrafi)}
            />
            <Route
              path="/amministrazione/dettaglioProcedura/:proceduraId/:idManuale/:idParagrafo"
              component={withAuthentication(WrapperNuovaProcedura)}
            />
            <Route
              path="/amministrazione/schedaProcedura/:schedaId/:idManuale/:idParagrafo"
              component={withAuthentication(WrapperSchedaId)}
            />

            {/* <Route path="/amministrazione/modelli/clona/:modelloId" component={withAuthentication(WrapperClonazioneModello)} />
            <Route path="/amministrazione/modelli_manuali/:modelloId/:manualeId" component={withAuthentication(WrapperDettaglioModelloDiManuale)} />
            <Route path="/amministrazione/manuali/:manualeId/procedure/:proceduraId" component={withAuthentication(WrapperDettaglioProcedura)} />
            <Route path="/amministrazione/manuali/:manualeId/paragrafi/:paragrafoId/schede/:componenteId" component={withAuthentication(WrapperDettaglioScheda)} />
            <Route path="/amministrazione/manuali/:modelloId/:manualeId/:parentId?" component={withAuthentication(WrapperDettaglioManuale)} />
            <Route path="/amministrazione/modifica_schede_haccp/:manualeBaseId/:schedaId" component={withAuthentication(WrapperSchedaHaccp)} />
            <Route path="/amministrazione/modifica_procedure/:modelId?/:schedaId?" component={withAuthentication(WrapperProcedure)} />
            <Route path="/amministrazione/modifica_contenutop5/:modelId?/:schedaId?" component={withAuthentication(WrapperContenuto)} />
            <Route path="/amministrazione/aggiungi_controllo/:schedaId?" component={withAuthentication(WrapperAggiuntaControllo)} />
            <Route path="/amministrazione/attrezzature/:manualeId/:localeId" component={withAuthentication(WrapperAttrezzature)} /> */}
            <Route
              path="/amministrazione"
              component={withAuthentication(ControlPanel)}
            />
            {/* If none of the previous routes render anything,
            this route acts as a fallback.

            Important: A route with path="/" will *always* match
            the URL because all URLs begin with a /. So that's
            why we put this one last of all */}
            <Route path="/" render={() => <HomePage />} />
          </Switch>
        </div>
      </LastLocationProvider>
    </BrowserRouter>
  );
}

function WrapperAccettaEsercizio() {
  let { activationKey } = useParams();
  return <AccettaEsercizioCommerciale activationKey={activationKey} />;
}

function WrapperDettaglioGruppo() {
  const { gruppoId } = useParams();
  let component =
    gruppoId === "nuovo" ? (
      <NuovoGruppoPage />
    ) : (
      <ModificaGruppoPage gruppoId={gruppoId} />
    );
  return component;
}

function WrapperRegistraConsulenteGruppo() {
  const { gruppoId } = useParams();
  return <RegistraConsulenteGruppoPage gruppoId={gruppoId} />;
}
function WrapperNuovaProcedura() {
  const { proceduraId, idManuale, idParagrafo } = useParams();
  return (
    <DettaglioProceduraNuovoManuale
      id={proceduraId}
      idManuale={idManuale}
      idParagrafo={idParagrafo}
    />
  );
}
function WrapperSchedaId() {
  const { schedaId, idManuale, idParagrafo } = useParams();
  return (
    <DettaglioSchedaNuovoManuale
      id={schedaId}
      idManuale={idManuale}
      idParagrafo={idParagrafo}
    />
  );
}
function WrapperDownloadPDF() {
  const { revisioneId } = useParams();

  return <DownloadPDFPage revisioneId={revisioneId} />;
}

function WrapperDettaglioResponsabileSicurezza() {
  let history = useHistory();
  let { responsabileId } = useParams();
  const consulenteId =
    getConsulenteId() === null || getConsulenteId() === "null"
      ? null
      : Number.parseInt(getConsulenteId(), 10);
  const consulenteView = consulenteId !== null;
  const superconsulenteView = isSuperconsulente() === "true";
  const gruppoId =
    getGruppoId() === null || getGruppoId() === "null"
      ? null
      : Number.parseInt(getGruppoId(), 10);
  return (
    <DettaglioResponsabileSicurezza
      responsabileId={responsabileId}
      consulenteId={consulenteId}
      gruppoId={gruppoId}
      consulenteView={consulenteView}
      superconsulenteView={superconsulenteView}
      onClose={() => {
        history.goBack();
      }}
    />
  );
}

function WrapperDettaglioConsulente() {
  let history = useHistory();
  let { consulenteId } = useParams();
  const consulenteView =
    getConsulenteId() !== null && getConsulenteId() !== "null";
  const superconsulenteView = isSuperconsulente() === "true";
  const gruppoId =
    getGruppoId() === null || getGruppoId() === "null"
      ? null
      : Number.parseInt(getGruppoId(), 10);
  return (
    <DettaglioConsulente
      consulenteId={consulenteId}
      gruppoId={gruppoId}
      consulenteView={consulenteView}
      superconsulenteView={superconsulenteView}
      onClose={() => {
        history.goBack();
      }}
    />
  );
}

function WrapperDettaglioEsercizioCommerciale() {
  let { esercizioId } = useParams();
  let component = null;
  const consulenteId =
    getConsulenteId() === null || getConsulenteId() === "null"
      ? null
      : Number.parseInt(getConsulenteId(), 10);
  const gruppoId =
    getGruppoId() === null || getGruppoId() === "null"
      ? null
      : Number.parseInt(getGruppoId(), 10);
  const superconsulenteView = isSuperconsulente() === "true";
  if (esercizioId === "nuovo") {
    component =
      consulenteId !== null ? (
        <NuovoEsercizioConsulente
          consulenteId={consulenteId}
          gruppoId={gruppoId}
          superconsulente={superconsulenteView}
        />
      ) : (
        <NuovoEsercizioCommerciale />
      );
  } else {
    component = (
      <DettaglioEsercizioCommerciale
        esercizioId={esercizioId}
        consulenteId={consulenteId}
        gruppoId={gruppoId}
      />
    );
  }
  return <Fragment>{component}</Fragment>;
}

function WrapperDettaglioRevisioneManuale() {
  const { manualeId } = useParams();
  const gruppoId =
    getGruppoId() === null || getGruppoId() === "null"
      ? null
      : Number.parseInt(getGruppoId(), 10);
  const consulenteId =
    getConsulenteId() === null || getConsulenteId() === "null"
      ? null
      : Number.parseInt(getConsulenteId(), 10);
  const superconsulenteView = isSuperconsulente() === "true";
  return (
    <DettaglioManualeSicurezza
      revisioneManualeId={manualeId}
      gruppoId={gruppoId}
      consulenteId={consulenteId}
      superconsulenteView={superconsulenteView}
    />
  );
}
function WrapperCodiceValidazione() {
  let { idEsercizio } = useParams();
  return <CodiceValidazioneForm idEsercizio={idEsercizio} />;
}

// function WrapperSchedaHaccp() {

//   let history = useHistory();
//   let { manualeBaseId, schedaId } = useParams();
//   return (
//     <SchedaHaccp modelId={manualeBaseId} schedaId={schedaId} onClose={() => { history.goBack() }} />
//   );
// }

// function WrapperProcedure() {

//   let history = useHistory();
//   let { schedaId, modelId } = useParams();
//   return (
//     <Procedure modelId={modelId} schedaId={schedaId} onClose={() => { history.goBack() }} />
//   );
// }

// function WrapperContenuto() {
//   let history = useHistory();
//   let { schedaId, modelId } = useParams();
//   return (
//     // <ContenutoP5 modelId={modelId} schedaId={schedaId} onClose={() => { history.goBack() }} />
//     <ContenutoParagrafo5 paragrafoBaseId={modelId} paragrafoInCostruzioneId={schedaId} onClose={() => { history.goBack() }} />
//   );
// }
// function WrapperAggiuntaControllo() {

//   let history = useHistory();
//   let { schedaId } = useParams();
//   return (
//     <AggiuntaControlli schedaId={schedaId} onClose={() => { history.goBack() }} />
//   );
// }
// function WrapperAttrezzature() {
//   let history = useHistory();
//   let { manualeId, localeId } = useParams();
//   return (
//     <Attrezzature manualeId={manualeId} localeId={localeId} onClose={() => { history.goBack() }} />
//   );
// }

// function WrapperDettaglioProcedura() {
//   let { manualeId, proceduraId } = useParams();
//   return (
//     <DettaglioProcedura manualeId={manualeId} proceduraId={proceduraId} />
//   );
// }

function WrapperNuovoManualeTest() {
  let { manualeId, mode, hasChapters, revisioneId } = useParams();
  if (hasChapters === "true") {
    return (
      <CreazionePartiNuovoManuale
        manualeId={manualeId}
        mode={mode}
        revisioneId={revisioneId}
      />
      //<CreazioneNuovoManuale tabValue="Empty" manualeId={manualeId} mode={mode}></CreazioneNuovoManuale>
    );
  } else {
    return (
      <CreazioneNuovoManuale
        tabValue="Empty"
        manualeId={manualeId}
        mode={mode}
      />
    );
  }
}
function WrapperNuovoManualeParagrafi() {
  let { manualeId, paragrafo, sottoparagrafo, mode } = useParams();
  return (
    <ModificaSottoparagrafoManualeConParti
      manualeId={manualeId}
      paragrafo={paragrafo}
      sottoparagrafo={sottoparagrafo}
      mode={mode}
    />
    //<ModificaParagrafiManualeConParti manualeId = {manualeId} paragrafo = {paragrafo}></ModificaParagrafiManualeConParti>
  );
}

// function WrapperDettaglioScheda() {
//   let { manualeId, paragrafoId, componenteId } = useParams();
//   return (
//     <DettaglioScheda
//       manualeId={manualeId}
//       paragrafoId={paragrafoId}
//       componenteId={componenteId}
//     />
//   );
// }

function WrapperDettaglioBrand() {
  const { brandId } = useParams();
  return <BrandPage brandId={brandId} />;
}
