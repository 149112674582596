import React from "react";
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import ActionButton from '../../../components/ActionButton';
import CustomTable from "./CustomTable";
import SpinnerComponent from "../../../components/SpinnerComponent";

import theme from '../../../theme.js';
import PropTypes from 'prop-types';

const styles = {
    modalcontainer: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '6px',
        //paddingBottom: '6px',
        //paddingLeft: '10px',
        //paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
        marginLeft: '20%',
        marginRight: '20%',
        overflowY: 'scroll',
        height: '564px'
    },
    title: {
        paddingTop: '20px',
        paddingBottom: '20px'
    },
}

export default function ModalResponsabiliEsercizio(props) {

    let columns = [
        {
            Header: 'ID',
            id: 'id',
            accessor: r => r.id,
            minWidth: 80,
        },
        {
            Header: 'Email',
            id: 'email',
            accessor: r => r.email,
            minWidth: 180,
        },
        {
            Header: 'Nome',
            id: 'nome',
            accessor: r => r.nome,
            minWidth: 180,
        },
        {
            Header: 'Cognome',
            id: 'cognome',
            accessor: r => r.cognome,
            minWidth: 180,
        }
    ];

    return (
        <Modal
            open={props.open}
            onClose={props.onCloseModal}
            aria-labelledby="modal_responsabili_esercizio"
            aria-describedby="modal_responsabili_esercizio"
        >
            <div style={styles.modalcontainer}>
                <Container maxWidth='xl'>
                    <Typography variant="h6" style={styles.title} >
                        {"Responsabili collegati al punto vendita " + props.nomeEsercizio}
                    </Typography>
                    <Grid container justify="center" alignItems="center">
                        {props.loading ?
                            <SpinnerComponent size={24} />
                            :
                            (props.errorLabel === "" ?
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CustomTable
                                        columns={columns}
                                        records={props.responsabili}
                                        loading={false}
                                        filterable={false}
                                        resizable={false}
                                        sortable={false}
                                        defaultPageSize={10}
                                    />
                                </Grid>
                                :
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="body2" style={styles.title} >
                                        {props.errorLabel}
                                    </Typography>
                                </Grid>
                            )
                        }
                        < Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ActionButton
                                label="Chiudi"
                                onClick={props.onCloseModal}
                                disabled={false}
                                isRed={true}
                                grayVersion={false}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Modal >
    );

}

ModalResponsabiliEsercizio.propTypes = {
    open: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    responsabili: PropTypes.array.isRequired,
    nomeEsercizio: PropTypes.string.isRequired,
    errorLabel: PropTypes.string.isRequired,
}
