import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "85vh",
  },
  content: {
    flexGrow: 1, // Occupa lo spazio disponibile tra header e footer
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "20px",
  },
  paper: {
    padding: "40px",
    width: "50%",
    maxWidth: "600px",
    boxShadow: theme.shadows[6],
  },
  button: {
    marginTop: "20px",
  },
}));

const SuccessPasscode = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <Typography variant="h4">Operazione completata</Typography>
          <Typography style={{ marginTop: 10 }}>
            Il passcode è stato generato con successo
          </Typography>
        </Paper>
      </div>
    </div>
  );
};

SuccessPasscode.propTypes = {};

export default SuccessPasscode;
