import React, { Component, Fragment } from "react";
import { Box, Button, Grid, Tab, Tabs, Typography } from "@material-ui/core";

import PropTypes from "prop-types";
import validator from "validator";

import SpinnerComponent from "../../../components/SpinnerComponent";
import StoricoAbbonamentiEsercizio from "./StoricoAbbonamentiEsercizio";
import ErrorDialog from "../../../components/ErrorDialog";
import IndietroButton from "../../../components/IndietroButton";
import GiorniChiusuraEsercizio from "./GiorniChiusuraEsercizio";
import { getConsulenteId, isLoggedUserAConsulente, isSuperconsulente } from "../../../utils/storage";
import { clearToken } from "../../../utils/storage";
import { getAbbonamenti } from "../../../utils/api/abbonamenti_api";
import {
  getEsercizioCommerciale,
  updateEsercizioCommerciale,
  sendEmailToConfirmRegistration,
  updateResponsabileSicurezzaEsercizio,
} from "../../../utils/api/esercizi_commerciali_api";
import Background from "../../../Sfondo.png";
import theme from "../../../theme.js";
import { withStyles } from "@material-ui/styles";
import { getResponsabiliSicurezzaAssociabili } from "../../../utils/api/responsabili_sicurezza_api.js";
import SelezioneResponsabileEsercizioCommerciale from "./SelezioneResponsabileEsercizioCommerciale.js";
import DatiEsercizioCommerciale from "./DatiEsercizioCommerciale.js";

const MuiTabs = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

const MuiTab = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    borderRadius: "50px",
    border: `1px solid ${theme.palette.disabled.main}`,
    backgroundColor: "transparent",
    minWidth: "45%",
    "&$selected": {
      border: `1px solid ${theme.palette.cndColors.red}`,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selected: {},
}))(Tab);

export default class DettaglioEsercizioCommerciale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      record: {
        esercizio: {
          id: null,
          ragioneSociale: null,
          rappresentanteLegale: null,
          eMailRappresentanteLegale: null,
          telefonoRappresentanteLegale: null,
          indirizzoSede: null,
          frazione: null,
          comuneSede: null,
          provinciaSede: null,
          regione: null,
          codiceMuro: null,
          codiceSocio: null,
          numeroAuditAnnui: null,
          telefono: null,
          fax: null,
          eMailEsercizio: null,
          numeroSede: null,
          cittaSede: null,
          terminiUsoAccettati: null,
          privacyPolicyAccettata: null,
          accessKey: null,
          gruppoId: 101,
          codiceSdi: null,
          pec: null,
          responsabiliRegionali: null,
          responsabileArea: null,
          eMailResponsabileArea: null,
          direttoreVendite: null,
          direttore2023: null,
          consulente2023: null,
          consulente2022: null,
          canale2023: null,
        },
        giorniChiusura: [],
      },
      abbonamenti: [],
      validationErrors: {
        nome: "",
        ragioneSociale: "",
        email: "",
        password: "",
        confirmPassword: "",
        privacyPolicyAccettata: "",
        emailGestore: "",
        rappresentanteLegale: "",
        eMailRappresentanteLegale: "",
        eMailResponsabile: "",
        passwordResponsabile: "",
        eMailResponsabileArea: "",
        eMailEsercizio: "",
      },
      errorDialogVisible: false,
      errorDialogMessage: "",
      loading: false,
      notfoundRecord: false,
      saveButtonEnabled: false,
      saveMessageVisible: false,
      width: window.innerWidth,
      height: window.innerHeight,

      responsabiliSicurezza: [],
      currentResponsabileId: null,
      selectedResponsabileId: null,
      isAssociaButtonEnabled: false,
      isResponsabileMessageVisible: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.fetchRecord(this.props.esercizioId);
  }

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  handleTabChange = (e, newValue) => {
    this.setState({
      tabValue: newValue,
    });
    if (newValue === 1) {
      this.fetchResponsabiliAssociabili();
    }
  };

  fetchRecord = (esercizioId) => {
    this.setState({
      loading: true,
    });
    getEsercizioCommerciale(esercizioId)
      .then((result) => {
        this.setState({
          notfoundRecord: false,
          record: result,
        });
        this.fetchAbbonamenti(esercizioId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
            notfoundRecord: true,
          });
        }
      });
  };

  fetchAbbonamenti = (esercizioId) => {
    getAbbonamenti(null, null, esercizioId, 0, 1000, "id")
      .then((result) => {
        this.setState({
          loading: false,
          abbonamenti: result,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  fetchResponsabiliAssociabili = () => {
    this.setState({
      loading: true,
    });
    getResponsabiliSicurezzaAssociabili(this.props.esercizioId)
      .then((response) => {
        const currentResponsabile = response.find(
          (resp) => resp.esercizioId !== null
        );
        if (currentResponsabile) {
          this.setState({
            responsabiliSicurezza: response,
            currentResponsabileId: currentResponsabile.id,
            selectedResponsabileId: currentResponsabile.id,
          });
        } else {
          this.setState({
            responsabiliSicurezza: response,
            currentResponsabileId: null,
            selectedResponsabileId: null,
          });
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  handleResponsabileChange = (id) => {
    this.setState((prevState) => ({
      selectedResponsabileId: id,
      isAssociaButtonEnabled: id !== prevState.currentResponsabileId, // impedisce di riassociare il resp. corrente
    }));
  };

  handleAssociation = () => {
    updateResponsabileSicurezzaEsercizio(
      this.state.selectedResponsabileId,
      this.props.esercizioId
    )
      .then((_response) => {
        this.setState((prevState) => ({
          currentResponsabileId: prevState.selectedResponsabileId,
          isAssociaButtonEnabled: false,
          isResponsabileMessageVisible: true,
        }));
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  validateForm = () => {
    let data = this.state.record.esercizio;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    //Return false if there are validation errors:
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "nome":
        if (value === null || value.length === 0) {
          validationErrors.nome = "Inserire nome";
        } else {
          validationErrors.nome = "";
        }
        break;
      case "ragioneSociale":
        if (value === null || value.length === 0) {
          validationErrors.ragioneSociale = "Inserire ragione sociale";
        } else {
          validationErrors.ragioneSociale = "";
        }
        break;
      case "rappresentanteLegale":
        if (value === null || value.length === 0) {
          validationErrors.rappresentanteLegale =
            "Inserire nome rappresentante legale";
        } else {
          validationErrors.rappresentanteLegale = "";
        }
        break;
      case "eMailRappresentanteLegale":
        if (value === null || value.length === 0) {
          validationErrors.eMailRappresentanteLegale =
            "Inserire email rappresentante legale";
        } else {
          if (!validator.isEmail(value)) {
            validationErrors.eMailRappresentanteLegale = "Email non valida";
          } else {
            validationErrors.eMailRappresentanteLegale = "";
          }
        }
        break;
      case "eMailEsercizio":
        if (value === null || value.length > 0) {
          if (value === null) {
            validationErrors.eMailEsercizio = "";
          } else if (validator.isEmail(value)) {
            validationErrors.eMailEsercizio = "";
          } else {
            validationErrors.eMailEsercizio =
              "L'indirizzo email inserito non è valido";
          }
        }
        break;
      case "eMailResponsabileArea":
        if (value === null || value.length > 0) {
          if (value === null) {
            validationErrors.eMailResponsabileArea = "";
          } else if (validator.isEmail(value)) {
            validationErrors.eMailResponsabileArea = "";
          } else {
            validationErrors.eMailResponsabileArea =
              "L'indirizzo email inserito non è valido";
          }
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleChange = (event) => {
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    if (this.state.saveMessageVisible) {
      this.setState({
        saveMessageVisible: false,
      });
    }
    let name = event.target.name;
    let value = event.target.value;
    let validationErrors = this.state.validationErrors;
    let data = this.state.record;
    let esercizio = this.state.record.esercizio;

    esercizio[name] = value;
    //Reset validations :
    if (
      name === "nome" ||
      name === "eMailResponsabileArea" ||
      name === "eMailEsercizio" ||
      name === "rappresentanteLegale" ||
      name === "ragioneSociale" ||
      name === "eMailRappresentanteLegale"
    ) {
      validationErrors[name] = "";
    }
    this.setState({
      data,
      validationErrors,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let isFormValid = this.validateForm();
    if (isFormValid) {
      this.setState({
        loading: true,
      });
      let bodyToPass = this.state.record;
      updateEsercizioCommerciale(bodyToPass)
        .then((result) => {
          this.setState({
            loading: false,
            saveButtonEnabled: false,
            saveMessageVisible: true,
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: error.message,
            });
          }
        });
    } // no-op if data is not valid
  };

  inviomail = () => {
    let isFormValid = this.validateForm();
    if (isFormValid) {
      this.setState({
        loading: true,
      });
      sendEmailToConfirmRegistration(this.state.record.esercizio.id)
        .then((result) => {
          this.setState({
            loading: false,
          });
          alert("Email inviata");
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: error.message,
            });
          }
        });
    }
  };

  updateParentState = (data) => {
    let record = this.state.record;
    record.giorniChiusura = data;
    this.setState({
      record: record,
      saveButtonEnabled: true,
      loading: true,
    });
    updateEsercizioCommerciale(record)
      .then((result) => {
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const disabledSubmitbutton =
      this.state.loading || !this.state.saveButtonEnabled;
      const consulenteId = isLoggedUserAConsulente()
      ? Number.parseInt(getConsulenteId(), 10)
      : null;
      const superconsulenteView = isSuperconsulente() === "true";
    return (
      <div
        style={{
          textAlign: "center",
          color: "black",
          padding: "26px",
          backgroundImage:
            this.state.width > 1240
              ? `url("${Background}"),url("${Background}")`
              : `url(""),url("")`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPositionX:
            this.state.width > 1799
              ? "140%, -40%"
              : this.state.width > 1599
              ? "160%, -60%"
              : this.state.width > 1399
              ? "180%, -80%"
              : this.state.width > 1299
              ? "200%, -100%"
              : "220%, -120%",
        }}
      >
        <Typography variant="h4">Dettagli punto vendita</Typography>
        <MuiTabs
          value={this.state.tabValue}
          onChange={this.handleTabChange}
          centered
        >
          <MuiTab label="Dati identificativi punto vendita" value={0} />
          <MuiTab label="Dati responsabile punto vendita" value={1} />
        </MuiTabs>
        <Grid
          container
          justify="center"
          style={{ marginTop: "26px", marginBottom: "26px" }}
        >
          <div style={{ minHeight: "700px" }}>
            {this.state.loading ? (
              <SpinnerComponent size={24} />
            ) : this.state.notfoundRecord ? (
              <Typography
                variant="h6"
                style={{ paddingTop: "6px", paddingBottom: "6px" }}
              >
                Nessun record trovato
              </Typography>
            ) : (
              <Fragment>
                {this.state.record.esercizio.terminiUsoAccettati === true &&
                this.state.record.esercizio.privacyPolicyAccettata ===
                  true ? null : (
                  this.state.tabValue === 0 ? <Button
                    type="submit"
                    onClick={this.inviomail}
                    variant="contained"
                    size="medium"
                    style={{
                      color: theme.palette.secondary.main,
                      margin: "10px",
                      borderRadius: "30px",
                      backgroundColor: theme.palette.cndColors.yellow,
                    }}
                  >
                    Invia mail di attivazione
                  </Button> : null
                )}
                <div hidden={this.state.tabValue !== 0}>
                  <Box>
                    <form autoComplete="off" onSubmit={this.handleSubmit}>
                      <DatiEsercizioCommerciale
                        record={this.state.record}
                        handleChange={this.handleChange}
                        validationErrors={this.state.validationErrors}
                      />
                      <StoricoAbbonamentiEsercizio
                        abbonamenti={this.state.abbonamenti}
                      />
                      <GiorniChiusuraEsercizio
                        esercizioId={this.state.record.esercizio.id}
                        giorniChiusura={this.state.record.giorniChiusura}
                        updateParentState={this.updateParentState}
                      />
                      <Grid
                        container
                        justify="center"
                        style={{ paddingTop: "10px" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{ textAlign: "center" }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              color: theme.palette.primary.main,
                              visibility: this.state.saveMessageVisible
                                ? "inherit"
                                : "hidden",
                            }}
                          >
                            Dati aggiornati con successo.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ textAlign: "right" }}
                      >
                        <IndietroButton isDisabled={this.state.loading} />
                        <Button
                          type="submit"
                          onClick={this.handleSubmit}
                          variant="contained"
                          size="medium"
                          disabled={disabledSubmitbutton}
                          style={{
                            color: theme.palette.secondary.main,
                            margin: "10px",
                            borderRadius: "30px",
                            backgroundColor: disabledSubmitbutton
                              ? theme.palette.disabled.main
                              : theme.palette.cndColors.yellow,
                          }}
                        >
                          Salva modifiche
                        </Button>
                      </Grid>
                    </form>
                  </Box>
                </div>
                <div hidden={this.state.tabValue !== 1}>
                  <Box>
                    <SelezioneResponsabileEsercizioCommerciale
                      responsabiliSicurezza={this.state.responsabiliSicurezza}
                      onSelectionChange={this.handleResponsabileChange}
                      selectedRowId={this.state.selectedResponsabileId}
                    />
                    <Grid
                      container
                      justify="center"
                      style={{ paddingTop: "10px" }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ textAlign: "center" }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            color: theme.palette.primary.main,
                            visibility: this.state.isResponsabileMessageVisible
                              ? "inherit"
                              : "hidden",
                          }}
                        >
                          Responsabile aggiornato con successo.
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ textAlign: "right" }}
                    >
                      <IndietroButton isDisabled={this.state.loading} />
                     {(consulenteId === null || superconsulenteView) && <Button
                        type="submit"
                        onClick={this.handleAssociation}
                        variant="contained"
                        size="medium"
                        disabled={!this.state.isAssociaButtonEnabled}
                        style={{
                          color: theme.palette.secondary.main,
                          margin: "10px",
                          borderRadius: "30px",
                          backgroundColor: !this.state.isAssociaButtonEnabled
                            ? theme.palette.disabled.main
                            : theme.palette.cndColors.yellow,
                        }}
                      >
                        Salva associazione
                      </Button>}
                    </Grid>
                  </Box>
                </div>
              </Fragment>
            )}
          </div>
        </Grid>

        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    );
  }
}

DettaglioEsercizioCommerciale.propTypes = {
  esercizioId: PropTypes.string.isRequired,
};
