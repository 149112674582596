import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AddCircle,
  ArrowBack,
  CancelRounded,
  ChevronRight,
  Edit,
  ExpandMore,
} from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import itLocale from "date-fns/locale/it";
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import ErrorDialog from "../../../components/ErrorDialog";
import SpinnerComponentWithText from "../../../components/SpinnerComponentWithText";
import { ReactComponent as CalendarLogo } from "../../../Calendario.svg";
import { ReactComponent as FrecciaSu } from "../../../frecciaSu.svg";
import { ReactComponent as FrecciaGiu } from "../../../frecciaGiu.svg";
import theme from "../../../theme.js";
import {
  deleteCapitoli,
  postCreaCapitoli,
  putCapitoli,
} from "../../../utils/api/capitoli_api";
import {
  deleteParagrafoFromManualeConParti,
  deleteSottoparagrafoFromManualeConParti,
  getSingoloSottoparagrafo,
  getSottoparagrafoIntroduttivo,
  postAggiuntaParagrafoManualeConParti,
  postAggiuntaSottoparagrafoManualeConParti,
  putSingoloSottoparagrafo,
  putSottoparagrafoIntroduttivo,
} from "../../../utils/api/paragrafi_api";
import {
  deletePartiManuale,
  getIndice,
  postAggiuntaParteManuale,
  postDaStampareIndice,
  putIndiceTableValues,
  putPartiManuale,
  putSortingIndice,
} from "../../../utils/api/parti_api";
import { clearToken, getOpenParti, setOpenParti } from "../../../utils/storage";
import AggiuntaSottoparagrafoModal from "./AggiuntaSottoparagrafoModal";
import CapitoloParti from "./CapitoloParti";
import ModalCreazioneSottoparagrafoTabella from "./ModalCreazioneSottoparagrafoTabella";
import ModalDoppiaConfermaDelete from "./ModalDoppiaConfermaDelete";
import LoadingOverlay from "react-loading-overlay";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "26px 10px",
    minHeight: "90vh",
  },
};

function createDataForCapitoli(
  numero,
  titolo,
  base,
  sv,
  ul,
  area1,
  area2,
  daEditare,
  data,
  button
) {
  return {
    numero,
    titolo,
    base,
    sv,
    ul,
    area1,
    area2,
    daEditare,
    data,
    button,
  };
}
function createDataForParagrafi(
  numero,
  titolo,
  base,
  sv,
  ul,
  area1,
  area2,
  daEditare,
  data,
  button
) {
  return {
    numero,
    titolo,
    base,
    sv,
    ul,
    area1,
    area2,
    daEditare,
    data,
    button,
  };
}
function createDataForSottoparagrafi(
  numero,
  titolo,
  base,
  sv,
  ul,
  area1,
  area2,
  daEditare,
  data,
  button
) {
  return {
    numero,
    titolo,
    base,
    sv,
    ul,
    area1,
    area2,
    daEditare,
    data,
    button,
  };
}
function swapElements(arr, i1, i2) {
  let temp = arr[i1];

  arr[i1] = arr[i2];

  arr[i2] = temp;
}
function sortAllElements(arr) {
  arr.map((element, index) => {
    element.posizione = index + 1;
  });
}
function sortAllElementsForSottoparagrafi(arr) {
  arr.map((element, index) => {
    element.posizione = index;
  });
}
export const Component = withRouter(({ history, location }) => {});

class CreazionePartiNuovoManuale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manualeData: {
        id: 0,
        parti: [
          {
            id: 0,
            posizione: 0,
            titolo: "",
            manualeId: this.props.id,
            isTitoloEditable: false,
            capitoli: [],
            isSelectedForPDF: false,
          },
        ],
      },
      base: null,
      data: null,
      sv: null,
      ul: null,
      area1: null,
      area2: null,
      indiceId: null,
      timeStamp: null,
      isTabOpen: false,
      firstRefresh: true,
      errorDialogVisible: false,
      errorDialogMessage: "",
      errorDialogTitle: null,
      successDialogVisible: false,
      activateMovement: false,
      loading: true,
      loadingChapters: false,
      loadingParagraph: false,
      loadingSubParagraph: false,
      isLoadingOverlay: false,
      overlayText: "",
      isDoppiaConfermaVisible: false,
      isModalCreazioneSottoparagrafoTabellaVisible: false,
      isAggiuntaSottoparagrafoVisible: false,
      isModello: this.props.mode === "modello" ? true : false,
      isCheckboxStateChanged: false,
      selectedParteIndex: null,
      selectedCapitoloIndex: null,
      selectedParagrafoIndex: null,
      selectedSottoparagrafoIndex: null,
      selectedParagrafo: null,
    };
  }

  componentDidMount() {
    document.body.style.minWidth = "1900px";
    window.scrollTo((document.body.scrollWidth - window.screen.width) / 2, 0);
    window.addEventListener('beforeunload', this.onRefreshMethod);
    this.getManualeData();
    if (this.state.firstRefresh) {
      this.handleRefresh();
    }
  }
  componentWillUnmount() {
    window.removeEventListener('beforeunload',this.onRefreshMethod);
    localStorage.setItem('indexScrollPosition', window.scrollY);
    document.body.style.minWidth = "0px";
  }

  onRefreshMethod = () => {
    let partiData = [];
                                  let capitoliData = [];
                                  let paragrafiData = [];
                                  this.state.manualeData.parti.map(
                                    (item, index) => {
                                      if (item.isTabOpen) {
                                        let parteObject = {
                                          id: item.id,
                                        };
                                        item.capitoli.map((item2, index2) => {
                                          if (item2.isTabOpen) {
                                            let capitoloObject = {
                                              id: item2.id,
                                            };
                                            item2.paragrafi.map(
                                              (item3, index3) => {
                                                if (item3.isTabOpen) {
                                                  let paragrafoObject = {
                                                    id: item3.id,
                                                  };
                                                  paragrafiData.push(
                                                    paragrafoObject
                                                  );
                                                }
                                              }
                                            );
                                            capitoliData.push(capitoloObject);
                                          }
                                        });
                                        partiData.push(parteObject);
                                      }
                                    }
                                  );
                                  let dataToUse = {
                                    parti: partiData,
                                    capitoli: capitoliData,
                                    paragrafi: paragrafiData,
                                  };
                                  setOpenParti(JSON.stringify(dataToUse));
  }

  handleRefresh = () => {
    this.setState({ firstRefresh: false });
  };

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };
  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };
  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };
  closeSuccessDialog = () => {
    this.setState({
      successDialogVisible: false,
    });
  };
  handleOpenCreazioneSottoparagrafoTabellaModal = () => {
    this.setState({
      isAggiuntaSottoparagrafoVisible: false,
      isModalCreazioneSottoparagrafoTabellaVisible: true,
    });
  };
  handleCloseCreazioneSottoparagrafoTabellaModal = () => {
    this.setState({
      isModalCreazioneSottoparagrafoTabellaVisible: false,
    });
  };

  handleOpenDoppiaConfermaModal = (index, index2, index3, index4) => {
    if (!this.state.isCheckboxStateChanged) {
      this.setState({
        isDoppiaConfermaVisible: true,
        selectedParteIndex: index,
        selectedCapitoloIndex: index2,
        selectedParagrafoIndex: index3,
        selectedSottoparagrafoIndex: index4,
      });
    } else {
      let confirmation = window.confirm(
        "Attenzione: modifiche alle checkbox non salvate! Se si procede all'eliminazione dell'elemento, tali modifiche verranno scartate. Procedere?"
      );
      if (confirmation) {
        this.setState({
          isDoppiaConfermaVisible: true,
          selectedParteIndex: index,
          selectedCapitoloIndex: index2,
          selectedParagrafoIndex: index3,
          selectedSottoparagrafoIndex: index4,
        });
      } else {
      }
    }
  };
  handleCloseDoppiaConfermaModal = () => {
    this.setState({
      isDoppiaConfermaVisible: false,
    });
  };
  handleOpenAggiuntaSottoparagrafoModal = (index, index2, index3) => {
    this.setState({
      isAggiuntaSottoparagrafoVisible: true,
      selectedParteIndex: index,
      selectedCapitoloIndex: index2,
      selectedParagrafoIndex: index3,
      selectedParagrafo:
        this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3],
    });
  };
  handleCloseAggiuntaSottoparagrafoModal = () => {
    this.setState({
      isAggiuntaSottoparagrafoVisible: false,
    });
  };

  proseguiToPubblicazione = () => {
    const { history } = this.props;
    if (this.props.revisioneId !== "null" && !this.state.isModello) {
      let partiData = [];
      let capitoliData = [];
      let paragrafiData = [];
      this.state.manualeData.parti.map((item, index) => {
        if (item.isTabOpen) {
          let parteObject = {
            id: item.id,
          };
          item.capitoli.map((item2, index2) => {
            if (item2.isTabOpen) {
              let capitoloObject = {
                id: item2.id,
              };
              item2.paragrafi.map((item3, index3) => {
                if (item3.isTabOpen) {
                  let paragrafoObject = {
                    id: item3.id,
                  };
                  paragrafiData.push(paragrafoObject);
                }
              });
              capitoliData.push(capitoloObject);
            }
          });
          partiData.push(parteObject);
        }
      });
      let dataToUse = {
        parti: partiData,
        capitoli: capitoliData,
        paragrafi: paragrafiData,
      };
      setOpenParti(JSON.stringify(dataToUse));
      history.push(
        "/amministrazione/manuali/dettagli/" + this.props.revisioneId
      );
    }
  };

  handleIndietroCommand = () => {
    let partiData = [];
    let capitoliData = [];
    let paragrafiData = [];
    this.state.manualeData.parti.map((item, index) => {
      if (item.isTabOpen) {
        let parteObject = {
          id: item.id,
        };
        item.capitoli.map((item2, index2) => {
          if (item2.isTabOpen) {
            let capitoloObject = {
              id: item2.id,
            };
            item2.paragrafi.map((item3, index3) => {
              if (item3.isTabOpen) {
                let paragrafoObject = {
                  id: item3.id,
                };
                paragrafiData.push(paragrafoObject);
              }
            });
            capitoliData.push(capitoloObject);
          }
        });
        partiData.push(parteObject);
      }
    });
    let dataToUse = {
      parti: partiData,
      capitoli: capitoliData,
      paragrafi: paragrafiData,
    };
    setOpenParti(JSON.stringify(dataToUse));
    const { history } = this.props;
    history.goBack();
  };

  handleDaStampareSave = () => {
    let body = {
      manualeId: this.state.manualeData.id,
      capitoli: [],
      paragrafi: [],
      sottoparagrafi: [],
    };
    this.state.manualeData.parti.map((parte, index) => {
      parte.capitoli.map((capitolo, index2) => {
        if (capitolo.isSelectedForPDF) {
          body.capitoli.push(capitolo.id);
        }
        capitolo.paragrafi.map((paragrafo, index3) => {
          if (paragrafo.isSelectedForPDF) {
            body.paragrafi.push(paragrafo.id);
          }
          paragrafo.sottoparagrafi.map((sottoparagrafo, index4) => {
            if (sottoparagrafo.isSelectedForPDF) {
              body.sottoparagrafi.push(sottoparagrafo.id);
            }
          });
        });
      });
    });
    postDaStampareIndice(body)
      .then((response) => {
        this.setState({
          //successDialogVisible:true,
          isCheckboxStateChanged: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleError(true, error.message);
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  handleCambioTitoloCapitolo = (e, index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].titolo = e.target.value;
    manualeData.parti[index].capitoli[index2].timeStamp = Date.now();
    this.setState(
      {
        manualeData: manualeData,
      },
      () => {}
    );
  };

  handleCambioBaseCapitolo = (e, index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].base = e.target.value;
    manualeData.parti[index].capitoli[index2].timeStamp = Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioSVCapitolo = (e, index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].sv = e.target.value;
    manualeData.parti[index].capitoli[index2].timeStamp = Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioULCapitolo = (e, index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].ul = e.target.value;
    manualeData.parti[index].capitoli[index2].timeStamp = Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioArea1Capitolo = (e, index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].area1 = e.target.value;
    manualeData.parti[index].capitoli[index2].timeStamp = Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioArea2Capitolo = (e, index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].area2 = e.target.value;
    manualeData.parti[index].capitoli[index2].timeStamp = Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioDaEditareCapitolo = (daEditare, index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].daEditare = daEditare;
    this.setState(
      {
        manualeData: manualeData,
      },
      () => {
        this.handleSalvataModificaCapitolo(
          manualeData.parti[index],
          manualeData.parti[index].capitoli[index2],
          index,
          index2
        );
      }
    );
  };
  handleCambioDataCapitolo = (data, index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].dataEditing = data;
    manualeData.parti[index].capitoli[index2].timeStamp = Date.now();
    this.setState(
      {
        manualeData: manualeData,
      },
      () => {
        this.handleSalvataModificaCapitolo(
          manualeData.parti[index],
          manualeData.parti[index].capitoli[index2],
          index,
          index2
        );
      }
    );
  };
  handleCambioIndiceCapitolo = (indice, index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].numeroIndice = indice;
    manualeData.parti[index].capitoli[index2].timeStamp = Date.now();
    this.setState(
      {
        manualeData: manualeData,
      },
      () => {
        this.handleSalvataModificaCapitolo(
          manualeData.parti[index],
          manualeData.parti[index].capitoli[index2],
          index,
          index2
        );
      }
    );
  };

  handleCambioTitoloParagrafo = (e, index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].titolo =
      e.target.value;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].timeStamp =
      Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };

  handleCambioBaseParagrafo = (e, index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].base =
      e.target.value;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].timeStamp =
      Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioSVParagrafo = (e, index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sv =
      e.target.value;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].timeStamp =
      Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioULParagrafo = (e, index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].ul =
      e.target.value;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].timeStamp =
      Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioArea1Paragrafo = (e, index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].area1 =
      e.target.value;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].timeStamp =
      Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioArea2Paragrafo = (e, index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].area2 =
      e.target.value;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].timeStamp =
      Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioDaEditareParagrafo = (daEditare, index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].daEditare =
      daEditare;
    this.setState(
      {
        manualeData: manualeData,
      },
      () => {
        this.handleSalvataModificaParagrafo(
          manualeData.parti[index].capitoli[index2].paragrafi[index3]
        );
      }
    );
  };
  handleCambioDataParagrafo = (data, index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].dataEditing =
      data;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].timeStamp =
      Date.now();
    manualeData.parti[index].capitoli[index2].paragrafi[
      index3
    ].sottoparagrafi.map((sottoparagrafo) => {
      if (
        sottoparagrafo.dataEditing === null ||
        sottoparagrafo.dataEditing === undefined
      ) {
        sottoparagrafo.dataEditing = data;
      }
    });
    this.setState(
      {
        manualeData: manualeData,
      },
      () => {
        this.handleSalvataModificaParagrafo(
          manualeData.parti[index].capitoli[index2].paragrafi[index3]
        );
      }
    );
  };

  handleCambioIndiceParagrafo = (indice, index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].numeroIndice =
      indice;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].timeStamp =
      Date.now();
    this.setState(
      {
        manualeData: manualeData,
      },
      () => {
        this.handleSalvataModificaParagrafo(
          manualeData.parti[index].capitoli[index2].paragrafi[index3]
        );
      }
    );
  };

  handleCambioBaseSottoparagrafo = (e, index, index2, index3, index4) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].base = e.target.value;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].timeStamp = Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };

  handleCambioSVSottoparagrafo = (e, index, index2, index3, index4) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].sv = e.target.value;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].timeStamp = Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioULSottoparagrafo = (e, index, index2, index3, index4) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].ul = e.target.value;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].timeStamp = Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioArea1Sottoparagrafo = (e, index, index2, index3, index4) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].area1 = e.target.value;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].timeStamp = Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioArea2Sottoparagrafo = (e, index, index2, index3, index4) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].area2 = e.target.value;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].timeStamp = Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioDaEditareSottoparagrafo = (
    daEditare,
    index,
    index2,
    index3,
    index4
  ) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].daEditare = daEditare;
    this.setState(
      {
        manualeData: manualeData,
      },
      () => {
        this.handleSalvataModificaSottoparagrafo(
          manualeData.parti[index].capitoli[index2].paragrafi[index3]
            .sottoparagrafi[index4]
        );
      }
    );
  };
  handleCambioDataSottoparagrafo = (data, index, index2, index3, index4) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].dataEditing = data;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].timeStamp = Date.now();
    this.setState(
      {
        manualeData: manualeData,
      },
      () => {
        this.handleSalvataModificaSottoparagrafo(
          manualeData.parti[index].capitoli[index2].paragrafi[index3]
            .sottoparagrafi[index4]
        );
      }
    );
  };
  handleCambioIndiceSottoparagrafo = (
    indice,
    index,
    index2,
    index3,
    index4
  ) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].numeroIndice = indice;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].timeStamp = Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };
  handleCambioTitoloSottoparagrafo = (
    titolo,
    index,
    index2,
    index3,
    index4
  ) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].titolo = titolo;
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].timeStamp = Date.now();
    this.setState({
      manualeData: manualeData,
    });
  };

  handleAggiuntaAlManualeCapitolo = (index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].isSelectedForPDF =
      !manualeData.parti[index].capitoli[index2].isSelectedForPDF;
    if (!manualeData.parti[index].capitoli[index2].isSelectedForPDF) {
      manualeData.parti[index].capitoli[index2].paragrafi.map((paragrafo) => {
        paragrafo.isSelectedForPDF = false;
        paragrafo.sottoparagrafi.map((sottoparagrafo) => {
          sottoparagrafo.isSelectedForPDF = false;
        });
      });
    }
    this.setState(
      {
        manualeData: manualeData,
        isCheckboxStateChanged: true,
      },
      () => {
        this.handleDaStampareSave();
      }
    );
  };
  handleAggiuntaAlManualeParagrafo = (index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[
      index3
    ].isSelectedForPDF =
      !manualeData.parti[index].capitoli[index2].paragrafi[index3]
        .isSelectedForPDF;
    if (
      manualeData.parti[index].capitoli[index2].paragrafi[index3]
        .isSelectedForPDF
    ) {
      if (
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi[0] !== undefined
      ) {
        manualeData.parti[index].capitoli[index2].paragrafi[
          index3
        ].sottoparagrafi[0].isSelectedForPDF = true;
      }
    }
    if (
      !manualeData.parti[index].capitoli[index2].paragrafi[index3]
        .isSelectedForPDF
    ) {
      manualeData.parti[index].capitoli[index2].paragrafi[
        index3
      ].sottoparagrafi.map((sottoparagrafo) => {
        sottoparagrafo.isSelectedForPDF = false;
      });
    }
    this.setState(
      {
        manualeData: manualeData,
        isCheckboxStateChanged: true,
      },
      () => {
        this.handleDaStampareSave();
      }
    );
  };
  handleAggiuntaAlManualeSottoparagrafo = (index, index2, index3, index4) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[
      index4
    ].isSelectedForPDF =
      !manualeData.parti[index].capitoli[index2].paragrafi[index3]
        .sottoparagrafi[index4].isSelectedForPDF;
    this.setState(
      {
        manualeData: manualeData,
        isCheckboxStateChanged: true,
      },
      () => {
        this.handleDaStampareSave();
      }
    );
  };

  handleSottoparagrafoMovementDownward = (index, index2, index3, index4) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    //if(!(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].titolo === "Organigramma" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_LOCALI" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_REPARTI" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_PROCEDURE")){
    if (
      index4 <
      manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi
        .length -
        1
    ) {
      swapElements(
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi,
        index4,
        index4 + 1
      );
      sortAllElementsForSottoparagrafi(
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi
      );
      this.setState({
        manualeData: manualeData,
      });
    }
    if (
      index4 >=
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi.length -
          1 &&
      index3 < manualeData.parti[index].capitoli[index2].paragrafi.length - 1
    ) {
      let elementToUse =
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi[index4];
      manualeData.parti[index].capitoli[index2].paragrafi[
        index3
      ].sottoparagrafi.splice(index4, 1);
      manualeData.parti[index].capitoli[index2].paragrafi[
        index3 + 1
      ].sottoparagrafi.splice(1, 0, elementToUse);
      sortAllElementsForSottoparagrafi(
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi
      );
      sortAllElementsForSottoparagrafi(
        manualeData.parti[index].capitoli[index2].paragrafi[index3 + 1]
          .sottoparagrafi
      );
      this.setState({
        manualeData: manualeData,
      });
    }
    if (
      index4 >=
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi.length -
          1 &&
      index3 >=
        manualeData.parti[index].capitoli[index2].paragrafi.length - 1 &&
      index2 < manualeData.parti[index].capitoli.length - 1
    ) {
      let moved = false;
      let elementToUse =
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi[index4];
      manualeData.parti[index].capitoli[index2].paragrafi[
        index3
      ].sottoparagrafi.splice(index4, 1);
      for (
        let i = index2 + 1;
        i <= manualeData.parti[index].capitoli.length - 1;
        i++
      ) {
        if (manualeData.parti[index].capitoli[i].paragrafi.length > 0) {
          moved = true;
          manualeData.parti[index].capitoli[
            i
          ].paragrafi[0].sottoparagrafi.splice(1, 0, elementToUse);
          sortAllElementsForSottoparagrafi(
            manualeData.parti[index].capitoli[index2].paragrafi[index3]
              .sottoparagrafi
          );
          sortAllElementsForSottoparagrafi(
            manualeData.parti[index].capitoli[i].paragrafi[0].sottoparagrafi
          );
          this.setState({
            manualeData: manualeData,
          });
          break;
        }
      }
      if (!moved) {
        for (let i = index + 1; i <= manualeData.parti.length - 1; i++) {
          if (manualeData.parti[i].capitoli.length > 0) {
            for (let j = 0; j < manualeData.parti[i].capitoli.length; j++) {
              if (manualeData.parti[i].capitoli[j].paragrafi.length > 0) {
                manualeData.parti[i].capitoli[
                  j
                ].paragrafi[0].sottoparagrafi.splice(1, 0, elementToUse);
                sortAllElementsForSottoparagrafi(
                  manualeData.parti[index].capitoli[index2].paragrafi[index3]
                    .sottoparagrafi
                );
                sortAllElementsForSottoparagrafi(
                  manualeData.parti[i].capitoli[j].paragrafi[0].sottoparagrafi
                );
                this.setState({
                  manualeData: manualeData,
                });
                break;
              }
            }
          }
        }
      }
    }
    if (
      index4 >=
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi.length -
          1 &&
      index3 >=
        manualeData.parti[index].capitoli[index2].paragrafi.length - 1 &&
      index2 >= manualeData.parti[index].capitoli.length - 1 &&
      index < manualeData.parti.length - 1
    ) {
      let stop = false;
      for (
        let i = index + 1;
        i <= manualeData.parti.length - 1 && stop === false;
        i++
      ) {
        if (manualeData.parti[index].capitoli.length > 0) {
          for (
            let j = 0;
            j < manualeData.parti[i].capitoli.length && stop === false;
            j++
          ) {
            if (manualeData.parti[i].capitoli[j].paragrafi.length > 0) {
              let elementToUse =
                manualeData.parti[index].capitoli[index2].paragrafi[index3]
                  .sottoparagrafi[index4];
              manualeData.parti[index].capitoli[index2].paragrafi[
                index3
              ].sottoparagrafi.splice(index4, 1);
              manualeData.parti[i].capitoli[
                j
              ].paragrafi[0].sottoparagrafi.splice(1, 0, elementToUse);
              sortAllElementsForSottoparagrafi(
                manualeData.parti[index].capitoli[index2].paragrafi[index3]
                  .sottoparagrafi
              );
              sortAllElementsForSottoparagrafi(
                manualeData.parti[i].capitoli[j].paragrafi[0].sottoparagrafi
              );
              this.setState({
                manualeData: manualeData,
              });
              stop = true;
            }
          }
        }
      }
    }

    /*else if(index4 >= this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length - 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3+1] !== undefined){
                    let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
                    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                    manualeData.parti[index].capitoli[index2].paragrafi[index3+1].sottoparagrafi.splice(1,0,elementToUse);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3+1].sottoparagrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                    }
                  else if(manualeData.parti[index].capitoli[index2+1] !== undefined){
                    if(index4 >= this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length - 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3+1] === undefined && manualeData.parti[index].capitoli[index2+1].paragrafi[0] !== undefined){
                    let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
                    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                    manualeData.parti[index].capitoli[index2+1].paragrafi[0].sottoparagrafi.splice(1,0,elementToUse);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2+1].paragrafi[0].sottoparagrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                  }
                  else if(manualeData.parti[index].capitoli[index2+1] === undefined && manualeData.parti[index+1] !== undefined && manualeData.parti[index+1].capitoli[0] !== undefined){
                    if(index4 >= this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length - 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3+1] === undefined && manualeData.parti[index].capitoli[0].paragrafi[0] !== undefined && manualeData.parti[index+1].capitoli[0].paragrafi[0] !== undefined){
                      let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
                      manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                      manualeData.parti[index+1].capitoli[0].paragrafi[0].sottoparagrafi.splice(1,0,elementToUse);
                      sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                      sortAllElementsForSottoparagrafi(manualeData.parti[index+1].capitoli[0].paragrafi[0].sottoparagrafi);
                      this.setState({
                        manualeData:manualeData,
                      })
                    }
                    else if(index4 >= this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length - 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3+1] === undefined && manualeData.parti[index].capitoli[0].paragrafi[0] !== undefined && manualeData.parti[index+1].capitoli[0].paragrafi[0] === undefined){
                      /*let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
                      manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                      manualeData.parti[index+1].capitoli[0].paragrafi[0].sottoparagrafi.unshift(elementToUse);
                      this.setState({
                        manualeData:manualeData,
                      })
                      
                    }
                  }
                  /*}else{
                    if(index4 < this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length - 1){
                    swapElements(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi,index4,index4+1);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                  }*/
  };

  handleSottoparagrafoMovementUpward = (index, index2, index3, index4) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    //if(!(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].titolo === "Organigramma" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_LOCALI" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_REPARTI" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_PROCEDURE")){
    if (index4 > 1) {
      swapElements(
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi,
        index4,
        index4 - 1
      );
      sortAllElementsForSottoparagrafi(
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi
      );
      this.setState({
        manualeData: manualeData,
      });
    } else if (index4 === 1 && index3 - 1 >= 0) {
      let elementToUse =
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi[index4];
      manualeData.parti[index].capitoli[index2].paragrafi[
        index3
      ].sottoparagrafi.splice(index4, 1);
      manualeData.parti[index].capitoli[index2].paragrafi[
        index3 - 1
      ].sottoparagrafi.push(elementToUse);
      sortAllElementsForSottoparagrafi(
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi
      );
      sortAllElementsForSottoparagrafi(
        manualeData.parti[index].capitoli[index2].paragrafi[index3 - 1]
          .sottoparagrafi
      );
      this.setState({
        manualeData: manualeData,
      });
    } else if (index4 === 1 && index3 - 1 < 0 && index2 - 1 >= 0) {
      let moved = false;
      let elementToUse =
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi[index4];
      manualeData.parti[index].capitoli[index2].paragrafi[
        index3
      ].sottoparagrafi.splice(index4, 1);
      for (let i = index2 - 1; i >= 0; i--) {
        if (manualeData.parti[index].capitoli[i].paragrafi.length > 0) {
          moved = true;
          manualeData.parti[index].capitoli[i].paragrafi[
            manualeData.parti[index].capitoli[i].paragrafi.length - 1
          ].sottoparagrafi.push(elementToUse);
          sortAllElementsForSottoparagrafi(
            manualeData.parti[index].capitoli[index2].paragrafi[index3]
              .sottoparagrafi
          );
          sortAllElementsForSottoparagrafi(
            manualeData.parti[index].capitoli[i].paragrafi[
              manualeData.parti[index].capitoli[i].paragrafi.length - 1
            ].sottoparagrafi
          );
          this.setState({
            manualeData: manualeData,
          });
          break;
        }
      }
      if (!moved) {
        for (let i = index - 1; i >= 0; i--) {
          if (manualeData.parti[i].capitoli.length > 0) {
            for (
              let j = manualeData.parti[i].capitoli.length - 1;
              j >= 0;
              j--
            ) {
              if (manualeData.parti[i].capitoli[j].paragrafi.length > 0) {
                manualeData.parti[i].capitoli[j].paragrafi[
                  manualeData.parti[i].capitoli[j].paragrafi.length - 1
                ].sottoparagrafi.push(elementToUse);
                sortAllElementsForSottoparagrafi(
                  manualeData.parti[index].capitoli[index2].paragrafi[index3]
                    .sottoparagrafi
                );
                sortAllElementsForSottoparagrafi(
                  manualeData.parti[i].capitoli[j].paragrafi[
                    manualeData.parti[i].capitoli[j].paragrafi.length - 1
                  ].sottoparagrafi
                );
                this.setState({
                  manualeData: manualeData,
                });
                break;
              }
            }
          }
        }
      }
    } else if (
      index4 === 1 &&
      index3 - 1 < 0 &&
      index2 - 1 < 0 &&
      index - 1 >= 0
    ) {
      let stop = false;
      for (let i = index - 1; i >= 0 && stop === false; i--) {
        if (manualeData.parti[i].capitoli.length > 0) {
          for (
            let j = manualeData.parti[i].capitoli.length - 1;
            j >= 0 && stop === false;
            j--
          ) {
            if (manualeData.parti[i].capitoli[j].paragrafi.length > 0) {
              let elementToUse =
                manualeData.parti[index].capitoli[index2].paragrafi[index3]
                  .sottoparagrafi[index4];
              manualeData.parti[index].capitoli[index2].paragrafi[
                index3
              ].sottoparagrafi.splice(index4, 1);
              manualeData.parti[i].capitoli[j].paragrafi[
                manualeData.parti[i].capitoli[j].paragrafi.length - 1
              ].sottoparagrafi.push(elementToUse);
              this.setState({
                manualeData: manualeData,
              });
              stop = true;
            }
          }
        }
      }
    }

    /*else if(manualeData.parti[index].capitoli[index2-1] !== undefined){ 
      if(index4 === 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3-1] === undefined && manualeData.parti[index].capitoli[index2-1].paragrafi[0] !== undefined){
        let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
        manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
        manualeData.parti[index].capitoli[index2-1].paragrafi[manualeData.parti[index].capitoli[index2-1].paragrafi.length-1].sottoparagrafi.push(elementToUse);
        sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
        sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2-1].paragrafi[manualeData.parti[index].capitoli[index2-1].paragrafi.length-1].sottoparagrafi);
        this.setState({
          manualeData:manualeData,
        })
        }
      }
      else if(manualeData.parti[index].capitoli[index2-1] === undefined && manualeData.parti[index-1] !== undefined){ 
        if(index4 === 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3-1] === undefined && manualeData.parti[index].capitoli[index2-1] === undefined){
          let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
          manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
          manualeData.parti[index-1].capitoli[manualeData.parti[index-1].capitoli.length-1].paragrafi[manualeData.parti[index-1].capitoli[manualeData.parti[index-1].capitoli.length-1].paragrafi.length-1].sottoparagrafi.push(elementToUse);
          sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
          sortAllElementsForSottoparagrafi(manualeData.parti[index-1].capitoli[manualeData.parti[index-1].capitoli.length-1].paragrafi[manualeData.parti[index-1].capitoli[manualeData.parti[index-1].capitoli.length-1].paragrafi.length-1].sottoparagrafi);
          this.setState({
            manualeData:manualeData,
          })
        }
        if(index4 === 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3-1] === undefined && manualeData.parti[index].capitoli[index2-1] !== undefined && manualeData.parti[index].capitoli[index2-1].paragrafi[index3-1] === undefined){
          let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
          manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
          manualeData.parti[index-1].capitoli[manualeData.parti[index].capitoli.length-1].paragrafi[manualeData.parti[index].capitoli[index2-1].paragrafi.length-1].sottoparagrafi.push(elementToUse);
          sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
          sortAllElementsForSottoparagrafi(manualeData.parti[index-1].capitoli[manualeData.parti[index].capitoli.length-1].paragrafi[manualeData.parti[index].capitoli[index2-1].paragrafi.length-1].sottoparagrafi);
          this.setState({
            manualeData:manualeData,
          })
        }
      }*/
  }; /*else{
        if(index4 > 0){
          if(index4-1 !== 0){
        swapElements(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi,index4,index4-1);
        sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
        this.setState({
          manualeData:manualeData,
        })
          }else{

          }
      }
      }
    }*/

  handleSottoparagrafoDelete = (index, index2, index3, index4) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    if (
      !(
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi[index4].tipologia === "TABELLA_LOCALI" ||
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi[index4].tipologia === "TABELLA_REPARTI" ||
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi[index4].tipologia === "TABELLA_PROCEDURE" ||
        manualeData.parti[index].capitoli[index2].paragrafi[index3]
          .sottoparagrafi[index4].hasOrganigramma
      )
    ) {
      if (index4 !== 0) {
        deleteSottoparagrafoFromManualeConParti(
          manualeData.parti[index].capitoli[index2].paragrafi[index3]
            .sottoparagrafi[index4].id
        )
          .then((response) => {
            manualeData.parti[index].capitoli[index2].paragrafi[
              index3
            ].sottoparagrafi.splice(index4, 1);
            if (
              manualeData.parti[index].capitoli[index2].paragrafi[index3]
                .sottoparagrafi.length === 1
            ) {
              manualeData.parti[index].capitoli[index2].paragrafi[
                index3
              ].isTabOpen = false;
            }
            this.setState(
              {
                manualeData: manualeData,
                isCheckboxStateChanged: false,
              },
              () => {
                //this.getManualeDataAfterSave(index, index2, index3, index4);
              }
            );
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleError(true, error.message);
            } else {
              this.handleError(true, error.message);
            }
          });
      } else {
        alert(
          "Attenzione, questo sottoparagrafo è obbligatorio e non può essere cancellato!"
        );
      }
    } else {
      alert(
        "Attenzione, questo sottoparagrafo è obbligatorio e non può essere cancellato!"
      );
    }
  };

  handleParagrafoDelete = (item3, index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    if (item3.sottoparagrafi.length > 1) {
      alert(
        "Attenzione, non è possibile eliminare un paragrafo se questo contiene altri paragrafi oltre a quello introduttivo"
      );
    } else {
      deleteParagrafoFromManualeConParti(
        manualeData.parti[index].capitoli[index2].paragrafi[index3].id
      )
        .then((response) => {
          manualeData.parti[index].capitoli[index2].paragrafi.splice(index3, 1);
          if (
            manualeData.parti[index].capitoli[index2].paragrafi.length === 0
          ) {
            manualeData.parti[index].capitoli[index2].isTabOpen = false;
          }
          this.setState(
            {
              manualeData: manualeData,
              isCheckboxStateChanged: false,
            }
            //this.getManualeDataAfterSave(index, index2, index3)
          );
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleError(true, error.message);
          } else {
            this.handleError(true, error.message);
          }
        });
    }
  };
  handleParagrafoMovementUpward = (index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    if (index3 > 0) {
      swapElements(
        manualeData.parti[index].capitoli[index2].paragrafi,
        index3,
        index3 - 1
      );
      sortAllElements(manualeData.parti[index].capitoli[index2].paragrafi);
      this.setState(
        {
          manualeData: manualeData,
        },
        () => {}
      );
    } else if (
      index3 === 0 &&
      manualeData.parti[index].capitoli[index2 - 1] !== undefined
    ) {
      let elementToUse =
        manualeData.parti[index].capitoli[index2].paragrafi[index3];
      manualeData.parti[index].capitoli[index2].paragrafi.splice(index3, 1);
      manualeData.parti[index].capitoli[index2 - 1].paragrafi.push(
        elementToUse
      );
      sortAllElements(manualeData.parti[index].capitoli[index2].paragrafi);
      sortAllElements(manualeData.parti[index].capitoli[index2 - 1].paragrafi);
      this.setState(
        {
          manualeData: manualeData,
        },
        () => {}
      );
    } else if (
      index3 === 0 &&
      manualeData.parti[index].capitoli[index2 - 1] === undefined &&
      manualeData.parti[index - 1] !== undefined
    ) {
      if (
        manualeData.parti[index - 1].capitoli !== undefined &&
        manualeData.parti[index - 1].capitoli.length > 0
      ) {
        let elementToUse =
          manualeData.parti[index].capitoli[index2].paragrafi[index3];
        manualeData.parti[index].capitoli[index2].paragrafi.splice(index3, 1);
        let indexThatIWillUse = manualeData.parti[index - 1].capitoli.length;
        manualeData.parti[index - 1].capitoli[
          indexThatIWillUse - 1
        ].paragrafi.push(elementToUse);
        sortAllElements(manualeData.parti[index].capitoli[index2].paragrafi);
        sortAllElements(
          manualeData.parti[index - 1].capitoli[indexThatIWillUse - 1].paragrafi
        );
        this.setState({
          manualeData: manualeData,
        });
      } else {
        let elementToUse =
          manualeData.parti[index].capitoli[index2].paragrafi[index3];
        manualeData.parti[index].capitoli[index2].paragrafi.splice(index3, 1);
        for (let i = index - 1; i >= 0; i--) {
          if (manualeData.parti[i].capitoli.length > 0) {
            manualeData.parti[i].capitoli[
              manualeData.parti[i].capitoli.length - 1
            ].paragrafi.push(elementToUse);
            this.setState({
              manualeData: manualeData,
            });
          }
        }
      }
    }
  };
  handleParagrafoMovementDownward = (index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    if (
      index3 <
      manualeData.parti[index].capitoli[index2].paragrafi.length - 1
    ) {
      swapElements(
        manualeData.parti[index].capitoli[index2].paragrafi,
        index3,
        index3 + 1
      );
      sortAllElements(manualeData.parti[index].capitoli[index2].paragrafi);
      this.setState({
        manualeData: manualeData,
      });
    } else if (
      index3 >=
        manualeData.parti[index].capitoli[index2].paragrafi.length - 1 &&
      manualeData.parti[index].capitoli[index2 + 1] !== undefined
    ) {
      let elementToUse =
        manualeData.parti[index].capitoli[index2].paragrafi[index3];
      manualeData.parti[index].capitoli[index2].paragrafi.splice(index3, 1);
      manualeData.parti[index].capitoli[index2 + 1].paragrafi.unshift(
        elementToUse
      );
      sortAllElements(manualeData.parti[index].capitoli[index2].paragrafi);
      sortAllElements(manualeData.parti[index].capitoli[index2 + 1].paragrafi);
      this.setState({
        manualeData: manualeData,
      });
    } else if (
      index3 >=
        manualeData.parti[index].capitoli[index2].paragrafi.length - 1 &&
      manualeData.parti[index].capitoli[index2 + 1] === undefined &&
      manualeData.parti[index + 1] !== undefined
    ) {
      if (
        manualeData.parti[index + 1].capitoli[0] !== undefined &&
        manualeData.parti[index + 1].capitoli.length > 0
      ) {
        let elementToUse =
          manualeData.parti[index].capitoli[index2].paragrafi[index3];
        manualeData.parti[index].capitoli[index2].paragrafi.splice(index3, 1);
        manualeData.parti[index + 1].capitoli[0].paragrafi.unshift(
          elementToUse
        );
        sortAllElements(manualeData.parti[index].capitoli[index2].paragrafi);
        sortAllElements(manualeData.parti[index + 1].capitoli[0].paragrafi);
        this.setState({
          manualeData: manualeData,
        });
      } else {
        let elementToUse =
          manualeData.parti[index].capitoli[index2].paragrafi[index3];
        manualeData.parti[index].capitoli[index2].paragrafi.splice(index3, 1);
        for (let i = index + 1; i < manualeData.parti.length; i++) {
          if (manualeData.parti[i].capitoli.length > 0) {
            manualeData.parti[i].capitoli[0].paragrafi.unshift(elementToUse);
            this.setState({
              manualeData: manualeData,
            });
            break;
          }
        }
      }
    }
  };

  handleParagrafoRedirect = (paragrafo, idSottoparagrafo) => {
    const { history } = this.props;
    if (idSottoparagrafo === null) {
      getSottoparagrafoIntroduttivo(paragrafo.id)
        .then((response) => {
          response.revBase = paragrafo.base;
          response.id = paragrafo.id;
          response.titolo = paragrafo.titolo;
          response.data = paragrafo.dataEditing;
          response.sv = paragrafo.sv;
          response.daEditare = paragrafo.daEditare;
          response.indice = paragrafo.numeroIndice;
          response.ul = paragrafo.ul;
          response.area1 = paragrafo.area1;
          response.area2 = paragrafo.area2;
          response.timeStamp = paragrafo.timeStamp;
          putSottoparagrafoIntroduttivo(response)
            .then((response2) => {
              let partiData = [];
              let capitoliData = [];
              let paragrafiData = [];
              this.state.manualeData.parti.map((item, index) => {
                if (item.isTabOpen) {
                  let parteObject = {
                    id: item.id,
                  };
                  item.capitoli.map((item2, index2) => {
                    if (item2.isTabOpen) {
                      let capitoloObject = {
                        id: item2.id,
                      };
                      item2.paragrafi.map((item3, index3) => {
                        if (item3.isTabOpen) {
                          let paragrafoObject = {
                            id: item3.id,
                          };
                          paragrafiData.push(paragrafoObject);
                        }
                      });
                      capitoliData.push(capitoloObject);
                    }
                  });
                  partiData.push(parteObject);
                }
              });
              let dataToUse = {
                parti: partiData,
                capitoli: capitoliData,
                paragrafi: paragrafiData,
              };
              setOpenParti(JSON.stringify(dataToUse));
              history.push(
                "/amministrazione/modificaSottoparagrafo/" +
                  this.state.manualeData.id +
                  "/" +
                  paragrafo.id +
                  "/" +
                  idSottoparagrafo +
                  "/" +
                  this.state.isModello
              );
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.handleError(true, error.message);
              }
            });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.handleError(true, error.message);
          }
        });
    } else if (idSottoparagrafo !== null) {
      getSingoloSottoparagrafo(idSottoparagrafo)
        .then((response) => {
          let elementToUse = paragrafo.sottoparagrafi.find(
            (element) => element.id === idSottoparagrafo
          );
          response.revBase = elementToUse.base;
          response.indice = elementToUse.numeroIndice;
          response.titolo = elementToUse.titolo;
          response.data = elementToUse.dataEditing;
          response.sv = elementToUse.sv;
          response.daEditare = elementToUse.daEditare;
          response.ul = elementToUse.ul;
          response.area1 = elementToUse.area1;
          response.area2 = elementToUse.area2;
          response.id = elementToUse.id;
          response.timeStamp = elementToUse.timeStamp;
          putSingoloSottoparagrafo(response)
            .then((response2) => {
              let partiData = [];
              let capitoliData = [];
              let paragrafiData = [];
              this.state.manualeData.parti.map((item, index) => {
                if (item.isTabOpen) {
                  let parteObject = {
                    id: item.id,
                  };
                  item.capitoli.map((item2, index2) => {
                    if (item2.isTabOpen) {
                      let capitoloObject = {
                        id: item2.id,
                      };
                      item2.paragrafi.map((item3, index3) => {
                        if (item3.isTabOpen) {
                          let paragrafoObject = {
                            id: item3.id,
                          };
                          paragrafiData.push(paragrafoObject);
                        }
                      });
                      capitoliData.push(capitoloObject);
                    }
                  });
                  partiData.push(parteObject);
                }
              });
              let dataToUse = {
                parti: partiData,
                capitoli: capitoliData,
                paragrafi: paragrafiData,
              };
              setOpenParti(JSON.stringify(dataToUse));
              history.push(
                "/amministrazione/modificaSottoparagrafo/" +
                  this.state.manualeData.id +
                  "/" +
                  paragrafo.id +
                  "/" +
                  idSottoparagrafo +
                  "/" +
                  this.state.isModello
              );
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.handleError(true, error.message);
              }
            });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.handleError(true, error.message);
          }
        });
    }
  };

  handleAddSottoparagrafoTabella = (colonne) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    let columnsToInsert = [];
    colonne.map((colonna) => {
      columnsToInsert.push(colonna.header);
    });
    //console.log(manualeData.parti[this.state.selectedParteIndex].capitoli[this.state.selectedCapitoloIndex].paragrafi[this.state.selectedParagrafoIndex]);
    let bodyToPass = {
      id: null,
      titolo: "Nuova tabella personalizzata",
      paragrafoId:
        manualeData.parti[this.state.selectedParteIndex].capitoli[
          this.state.selectedCapitoloIndex
        ].paragrafi[this.state.selectedParagrafoIndex].id,
      manualeId: this.state.manualeData.id,
      definizioniColonne: JSON.stringify(columnsToInsert),
      contenuto: JSON.stringify([]),
    };
    postAggiuntaSottoparagrafoManualeConParti(bodyToPass)
      .then((response) => {
        /*manualeData.parti[this.state.selectedParteIndex].capitoli[this.state.selectedCapitoloIndex].paragrafi[this.state.selectedParagrafoIndex].sottoparagrafi.push({
          id:null,
          base:0,
          posizione:manualeData.parti[this.state.selectedParteIndex].capitoli[this.state.selectedCapitoloIndex].paragrafi[this.state.selectedParagrafoIndex].sottoparagrafi.length,
          sv:0,
          ul:0,
          area1:0,
          area2:0,
          titolo:"Nuova Tabella Personalizzata",
          tipologia:"Tabella Personalizzata",
          dataEditing:null,
          isSelectedForPDF:manualeData.parti[this.state.selectedParteIndex].capitoli[this.state.selectedCapitoloIndex].paragrafi[this.state.selectedParagrafoIndex].isSelectedForPDF ? true : false,
        });*/
        let item3 =
          manualeData.parti[this.state.selectedParteIndex].capitoli[
            this.state.selectedCapitoloIndex
          ].paragrafi[this.state.selectedParagrafoIndex];
        let objectToPushForSottoparagrafi = {
          id: response.id,
          base: item3.base,
          posizione: response.numeroComponente,
          paragrafoId: item3.id,
          numeroIndice: null,
          sv: item3.sv,
          ul: item3.ul,
          daEditare: false,
          area1: item3.area1,
          area2: item3.area2,
          dataEditing: item3.dataEditing,
          hasOrganigramma: false,
          numeroComponente: response.numeroComponente,
          titolo: "Nuova tabella personalizzata",
          tipologia: response.tipoContenuto,
          timeStamp: response.lastUpdate,
          isSelectedForPDF: item3.isSelectedForPDF,
          indice: null,
          lastUpdate: null,
        };
        manualeData.parti[this.state.selectedParteIndex].capitoli[
          this.state.selectedCapitoloIndex
        ].paragrafi[this.state.selectedParagrafoIndex].sottoparagrafi.push(
          objectToPushForSottoparagrafi
        );
        manualeData.parti[this.state.selectedParteIndex].capitoli[
          this.state.selectedCapitoloIndex
        ].paragrafi[this.state.selectedParagrafoIndex].isTabOpen = true;
        this.setState(
          {
            manualeData: manualeData,
            isModalCreazioneSottoparagrafoTabellaVisible: false,
          },
          () => {
            let partiData = [];
            let capitoliData = [];
            let paragrafiData = [];
            this.state.manualeData.parti.map((item, index) => {
              if (item.isTabOpen) {
                let parteObject = {
                  id: item.id,
                };
                item.capitoli.map((item2, index2) => {
                  if (item2.isTabOpen) {
                    let capitoloObject = {
                      id: item2.id,
                    };
                    item2.paragrafi.map((item3, index3) => {
                      if (item3.isTabOpen) {
                        let paragrafoObject = {
                          id: item3.id,
                        };
                        paragrafiData.push(paragrafoObject);
                      }
                    });
                    capitoliData.push(capitoloObject);
                  }
                });
                partiData.push(parteObject);
              }
            });
            let dataToUse = {
              parti: partiData,
              capitoli: capitoliData,
              paragrafi: paragrafiData,
            };
            setOpenParti(JSON.stringify(dataToUse));
            /*this.getManualeDataAfterSave(
              this.state.selectedParteIndex,
              this.state.selectedCapitoloIndex,
              this.state.selectedParagrafoIndex
            );*/
          }
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  handleAddSottoparagrafo = (item3, index, index2, index3, tipologia) => {
    if (!this.state.isCheckboxStateChanged) {
      if (tipologia === "Testo") {
        let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
        let body = {
          id: null,
          titolo: "Nuovo sottoparagrafo",
          paragrafoId: item3.id,
          manualeId: this.state.manualeData.id,
          definizioniColonne: null,
          contenuto: null,
        };
        postAggiuntaSottoparagrafoManualeConParti(body)
          .then((response) => {
            /*manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.push({
                    id:null,
                    base:0,
                    posizione:manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length,
                    sv:0,
                    ul:0,
                    area1:0,
                    area2:0,
                    titolo:"Nuovo sottoparagrafo",
                    tipologia:"Testo",
                    dataEditing:null,
                    isSelectedForPDF:item3.isSelectedForPDF ? true : false,
                  })*/
            manualeData.parti[index].capitoli[index2].paragrafi[
              index3
            ].isTabOpen = true;
            let objectToPushForSottoparagrafi = {
              id: response.id,
              base: item3.base,
              posizione: response.numeroComponente,
              paragrafoId: item3.id,
              numeroIndice: null,
              sv: item3.sv,
              ul: item3.ul,
              daEditare: false,
              area1: item3.area1,
              area2: item3.area2,
              dataEditing: item3.dataEditing,
              hasOrganigramma: false,
              numeroComponente: response.numeroComponente,
              titolo: "Nuovo sottoparagrafo",
              tipologia: response.tipoContenuto,
              timeStamp: response.lastUpdate,
              isSelectedForPDF: item3.isSelectedForPDF,
              indice: null,
              lastUpdate: null,
            };
            manualeData.parti[this.state.selectedParteIndex].capitoli[
              this.state.selectedCapitoloIndex
            ].paragrafi[this.state.selectedParagrafoIndex].sottoparagrafi.push(
              objectToPushForSottoparagrafi
            );
            this.setState(
              {
                manualeData: manualeData,
                isAggiuntaSottoparagrafoVisible: false,
              },
              () => {
                let partiData = [];
                let capitoliData = [];
                let paragrafiData = [];
                this.state.manualeData.parti.map((item, index) => {
                  if (item.isTabOpen) {
                    let parteObject = {
                      id: item.id,
                    };
                    item.capitoli.map((item2, index2) => {
                      if (item2.isTabOpen) {
                        let capitoloObject = {
                          id: item2.id,
                        };
                        item2.paragrafi.map((item3, index3) => {
                          if (item3.isTabOpen) {
                            let paragrafoObject = {
                              id: item3.id,
                            };
                            paragrafiData.push(paragrafoObject);
                          }
                        });
                        capitoliData.push(capitoloObject);
                      }
                    });
                    partiData.push(parteObject);
                  }
                });
                let dataToUse = {
                  parti: partiData,
                  capitoli: capitoliData,
                  paragrafi: paragrafiData,
                };
                setOpenParti(JSON.stringify(dataToUse));
                //this.getManualeDataAfterSave(index, index2, index3);
              }
            );
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.handleError(true, error.message);
            }
          });
      } else {
        this.handleOpenCreazioneSottoparagrafoTabellaModal();
      }
    } else {
      let confirmation = window.confirm(
        "Attenzione: modifiche alle checkbox non salvate! Se si procede all'aggiunta del sottoparagrafo tali modifiche verranno scartate. Procedere?"
      );
      if (confirmation) {
        if (tipologia === "Testo") {
          let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
          let body = {
            id: null,
            titolo: "Nuovo sottoparagrafo",
            paragrafoId: item3.id,
            manualeId: this.state.manualeData.id,
          };
          postAggiuntaSottoparagrafoManualeConParti(body)
            .then((response) => {
              /*manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.push({
                                  id:null,
                                  base:0,
                                  posizione:manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length,
                                  sv:0,
                                  ul:0,
                                  area1:0,
                                  area2:0,
                                  titolo:"Nuovo sottoparagrafo",
                                  tipologia:"Testo",
                                  dataEditing:null,
                                  isSelectedForPDF:item3.isSelectedForPDF ? true : false,
                                })*/
              manualeData.parti[index].capitoli[index2].paragrafi[
                index3
              ].isTabOpen = true;
              let objectToPushForSottoparagrafi = {
                id: response.id,
                base: item3.base,
                posizione: response.numeroComponente,
                paragrafoId: item3.id,
                numeroIndice: null,
                sv: item3.sv,
                ul: item3.ul,
                daEditare: false,
                area1: item3.area1,
                area2: item3.area2,
                dataEditing: item3.dataEditing,
                hasOrganigramma: false,
                numeroComponente: response.numeroComponente,
                titolo: "Nuovo sottoparagrafo",
                tipologia: response.tipoContenuto,
                timeStamp: response.lastUpdate,
                isSelectedForPDF: item3.isSelectedForPDF,
                indice: null,
                lastUpdate: null,
              };
              manualeData.parti[this.state.selectedParteIndex].capitoli[
                this.state.selectedCapitoloIndex
              ].paragrafi[
                this.state.selectedParagrafoIndex
              ].sottoparagrafi.push(objectToPushForSottoparagrafi);
              this.setState(
                {
                  manualeData: manualeData,
                  isAggiuntaSottoparagrafoVisible: false,
                  isCheckboxStateChanged: false,
                },
                () => {
                  let partiData = [];
                  let capitoliData = [];
                  let paragrafiData = [];
                  this.state.manualeData.parti.map((item, index) => {
                    if (item.isTabOpen) {
                      let parteObject = {
                        id: item.id,
                      };
                      item.capitoli.map((item2, index2) => {
                        if (item2.isTabOpen) {
                          let capitoloObject = {
                            id: item2.id,
                          };
                          item2.paragrafi.map((item3, index3) => {
                            if (item3.isTabOpen) {
                              let paragrafoObject = {
                                id: item3.id,
                              };
                              paragrafiData.push(paragrafoObject);
                            }
                          });
                          capitoliData.push(capitoloObject);
                        }
                      });
                      partiData.push(parteObject);
                    }
                  });
                  let dataToUse = {
                    parti: partiData,
                    capitoli: capitoliData,
                    paragrafi: paragrafiData,
                  };
                  setOpenParti(JSON.stringify(dataToUse));
                  //this.getManualeDataAfterSave(index, index2, index3);
                }
              );
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.handleError(true, error.message);
              }
            });
        } else {
          this.handleOpenCreazioneSottoparagrafoTabellaModal();
        }
      } else {
        this.setState({
          isAggiuntaSottoparagrafoVisible: false,
        });
      }
    }
  };

  handleCapitoloOpen = (index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].isTabOpen =
      !manualeData.parti[index].capitoli[index2].isTabOpen;
    this.setState({
      manualeData: manualeData,
    });
  };
  handleParagrafoOpen = (index, index2, index3) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti[index].capitoli[index2].paragrafi[index3].isTabOpen =
      !manualeData.parti[index].capitoli[index2].paragrafi[index3].isTabOpen;
    this.setState({
      manualeData: manualeData,
    });
  };

  handleAddParagrafo = (item2, index, index2) => {
    if (!this.state.isCheckboxStateChanged) {
      let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
      let bodyToSave = {
        id: null,
        titolo: "Nuovo paragrafo",
        capitoloId: item2.id,
        manualeId: this.state.manualeData.id,
      };
      postAggiuntaParagrafoManualeConParti(bodyToSave)
        .then((response) => {
          getSottoparagrafoIntroduttivo(response.id)
            .then((data) => {
              let paragrafo = {
                id: response.id,
                base: response.revBase,
                isBloccato: response.bloccato,
                capitoloId: response.capitoloId,
                posizione: response.numeroParagrafo,
                isObbligatorio: response.obbligatorio,
                sv: response.sv,
                ul: response.ul,
                numeroIndice: response.indice,
                daEditare: response.daEditare,
                timeStamp: response.lastUpdate,
                area1: response.area1,
                area2: response.area2,
                dataEditing: response.data,
                isTabOpen: false,
                titolo: response.titolo,
                isSelectedForPDF: response.daStampare,
                sottoparagrafi: [
                  {
                    id: data.sottoparagrafoIntroduttivo.id,
                    base: data.sottoparagrafoIntroduttivo.base,
                    posizione: data.sottoparagrafoIntroduttivo.numeroComponente,
                    paragrafoId: data.sottoparagrafoIntroduttivo.id,
                    numeroIndice: null,
                    sv: data.sottoparagrafoIntroduttivo.sv,
                    ul: data.sottoparagrafoIntroduttivo.ul,
                    daEditare: false,
                    area1: data.sottoparagrafoIntroduttivo.area1,
                    area2: data.sottoparagrafoIntroduttivo.area2,
                    dataEditing: data.sottoparagrafoIntroduttivo.dataEditing,
                    hasOrganigramma: false,
                    numeroComponente:
                      data.sottoparagrafoIntroduttivo.numeroComponente,
                    titolo: data.sottoparagrafoIntroduttivo.titolo,
                    tipologia: data.sottoparagrafoIntroduttivo.tipoContenuto,
                    timeStamp: data.sottoparagrafoIntroduttivo.lastUpdate,
                    isSelectedForPDF:
                      data.sottoparagrafoIntroduttivo.isSelectedForPDF,
                    indice: null,
                    lastUpdate: null,
                  },
                ],
              };
              manualeData.parti[index].capitoli[index2].paragrafi.push(
                paragrafo
              );
              /*manualeData.parti[index].capitoli[index2].paragrafi.push({
                      id:null,
                      base:0,
                      posizione:manualeData.parti[index].capitoli[index2].paragrafi.length+1,
                      sv:0,
                      ul:0,
                      area1:0,
                      area2:0,
                      dataEditing:null,
                      isTabOpen:false,
                      titolo:"Nuovo paragrafo",
                      sottoparagrafi:[],
                      isSelectedForPDF:item2.isSelectedForPDF ? true : false,
                    })*/
              manualeData.parti[index].capitoli[index2].isTabOpen = true;
              this.setState(
                {
                  manualeData: manualeData,
                  isCheckboxStateChanged: false,
                },
                () => {
                  let partiData = [];
                  let capitoliData = [];
                  let paragrafiData = [];
                  this.state.manualeData.parti.map((item, index) => {
                    if (item.isTabOpen) {
                      let parteObject = {
                        id: item.id,
                      };
                      item.capitoli.map((item2, index2) => {
                        if (item2.isTabOpen) {
                          let capitoloObject = {
                            id: item2.id,
                          };
                          item2.paragrafi.map((item3, index3) => {
                            if (item3.isTabOpen) {
                              let paragrafoObject = {
                                id: item3.id,
                              };
                              paragrafiData.push(paragrafoObject);
                            }
                          });
                          capitoliData.push(capitoloObject);
                        }
                      });
                      partiData.push(parteObject);
                    }
                  });
                  let dataToUse = {
                    parti: partiData,
                    capitoli: capitoliData,
                    paragrafi: paragrafiData,
                  };
                  setOpenParti(JSON.stringify(dataToUse));
                  //this.getManualeDataAfterSave(index, index2);
                }
              );
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.handleError(true, error.message);
              }
            });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.handleError(true, error.message);
          }
        });
    } else {
      let confirmation = window.confirm(
        "Attenzione: modifiche alle checkbox non salvate! Se si procede all'aggiunta del paragrafo tali modifiche verranno scartate. Procedere?"
      );
      if (confirmation) {
        let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
        let bodyToSave = {
          id: null,
          titolo: "Nuovo paragrafo",
          capitoloId: item2.id,
          manualeId: this.state.manualeData.id,
        };
        postAggiuntaParagrafoManualeConParti(bodyToSave)
          .then((response) => {
            getSottoparagrafoIntroduttivo(response.id).then((data) => {
              let paragrafo = {
                id: response.id,
                base: response.revBase,
                isBloccato: response.bloccato,
                capitoloId: response.capitoloId,
                posizione: response.numeroParagrafo,
                isObbligatorio: response.obbligatorio,
                sv: response.sv,
                ul: response.ul,
                numeroIndice: response.indice,
                daEditare: response.daEditare,
                timeStamp: response.lastUpdate,
                area1: response.area1,
                area2: response.area2,
                dataEditing: response.data,
                isTabOpen: false,
                titolo: response.titolo,
                isSelectedForPDF: response.daStampare,
                sottoparagrafi: [
                  {
                    id: data.sottoparagrafoIntroduttivo.id,
                    base: data.sottoparagrafoIntroduttivo.base,
                    posizione: data.sottoparagrafoIntroduttivo.numeroComponente,
                    paragrafoId: data.sottoparagrafoIntroduttivo.id,
                    numeroIndice: null,
                    sv: data.sottoparagrafoIntroduttivo.sv,
                    ul: data.sottoparagrafoIntroduttivo.ul,
                    daEditare: false,
                    area1: data.sottoparagrafoIntroduttivo.area1,
                    area2: data.sottoparagrafoIntroduttivo.area2,
                    dataEditing: data.sottoparagrafoIntroduttivo.dataEditing,
                    hasOrganigramma: false,
                    numeroComponente:
                      data.sottoparagrafoIntroduttivo.numeroComponente,
                    titolo: data.sottoparagrafoIntroduttivo.titolo,
                    tipologia: data.sottoparagrafoIntroduttivo.tipoContenuto,
                    timeStamp: data.sottoparagrafoIntroduttivo.lastUpdate,
                    isSelectedForPDF:
                      data.sottoparagrafoIntroduttivo.isSelectedForPDF,
                    indice: null,
                    lastUpdate: null,
                  },
                ],
              };
              manualeData.parti[index].capitoli[index2].paragrafi.push(
                paragrafo
              );
              manualeData.parti[index].capitoli[index2].isTabOpen = true;
              this.setState(
                {
                  manualeData: manualeData,
                  isCheckboxStateChanged: false,
                },
                () => {
                  let partiData = [];
                  let capitoliData = [];
                  let paragrafiData = [];
                  this.state.manualeData.parti.map((item, index) => {
                    if (item.isTabOpen) {
                      let parteObject = {
                        id: item.id,
                      };
                      item.capitoli.map((item2, index2) => {
                        if (item2.isTabOpen) {
                          let capitoloObject = {
                            id: item2.id,
                          };
                          item2.paragrafi.map((item3, index3) => {
                            if (item3.isTabOpen) {
                              let paragrafoObject = {
                                id: item3.id,
                              };
                              paragrafiData.push(paragrafoObject);
                            }
                          });
                          capitoliData.push(capitoloObject);
                        }
                      });
                      partiData.push(parteObject);
                    }
                  });
                  let dataToUse = {
                    parti: partiData,
                    capitoli: capitoliData,
                    paragrafi: paragrafiData,
                  };
                  setOpenParti(JSON.stringify(dataToUse));
                  //this.getManualeDataAfterSave(index, index2);
                }
              );
            });
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.handleError(true, error.message);
            }
          })
          /*manualeData.parti[index].capitoli[index2].paragrafi.push({
                    id:null,
                    base:0,
                    posizione:manualeData.parti[index].capitoli[index2].paragrafi.length+1,
                    sv:0,
                    ul:0,
                    area1:0,
                    area2:0,
                    dataEditing:null,
                    isTabOpen:false,
                    titolo:"Nuovo paragrafo",
                    sottoparagrafi:[],
                  })*/
          //manualeData.parti[index].capitoli[index2].isTabOpen = true;

          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.handleError(true, error.message);
            }
          });
      }
    }
  };
  handleCapitoloMovementUpward = (index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    if (index2 > 0) {
      swapElements(manualeData.parti[index].capitoli, index2, index2 - 1);
      sortAllElements(manualeData.parti[index].capitoli);
      this.setState({
        manualeData: manualeData,
      });
    } else if (index2 === 0 && manualeData.parti[index - 1] !== undefined) {
      let elementToUse = manualeData.parti[index].capitoli[index2];
      manualeData.parti[index].capitoli.splice(index2, 1);
      manualeData.parti[index - 1].capitoli.push(elementToUse);
      sortAllElements(manualeData.parti[index].capitoli);
      sortAllElements(manualeData.parti[index - 1].capitoli);
      manualeData.parti[index - 1].isTabOpen = true;
      if (manualeData.parti[index].capitoli.length === 0) {
        manualeData.parti[index].isTabOpen = false;
      }
      this.setState({
        manualeData: manualeData,
      });
    }
  };

  handleDeleteElement = (index, index2, index3, index4) => {
    if (
      index !== undefined &&
      index2 !== undefined &&
      index3 !== undefined &&
      index4 !== undefined
    ) {
      this.handleSottoparagrafoDelete(index, index2, index3, index4);
    } else if (
      index !== undefined &&
      index2 !== undefined &&
      index3 !== undefined &&
      index4 === undefined
    ) {
      this.handleParagrafoDelete(
        this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3],
        index,
        index2,
        index3
      );
    } else if (
      index !== undefined &&
      index2 !== undefined &&
      index3 === undefined &&
      index4 === undefined
    ) {
      this.handleDeleteCapitolo(index, index2);
    } else if (
      index !== undefined &&
      index2 === undefined &&
      index3 === undefined &&
      index4 === undefined
    ) {
      this.handleDeleteParte(index);
    }
    this.handleCloseDoppiaConfermaModal();
  };

  handleDeleteParte = (index) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    if (manualeData.parti[index].capitoli.length === 0) {
      deletePartiManuale(manualeData.parti[index].id)
        .then((response) => {
          manualeData.parti.splice(index, 1);
          this.setState({
            manualeData: manualeData,
            isCheckboxStateChanged: false,
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleError(true, error.message);
          } else {
            this.handleError(true, error.message);
          }
        });
    } else {
      alert(
        "Attenzione! Sono presenti dei capitoli associati alla parte selezionata. Eliminare prima i capitoli e poi la parte."
      );
    }
  };

  handleCapitoloMovementDownward = (index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    if (index2 < manualeData.parti[index].capitoli.length - 1) {
      swapElements(manualeData.parti[index].capitoli, index2, index2 + 1);
      sortAllElements(manualeData.parti[index].capitoli);
      this.setState({
        manualeData: manualeData,
      });
    } else if (
      index2 >= manualeData.parti[index].capitoli.length - 1 &&
      manualeData.parti[index + 1] !== undefined
    ) {
      let elementToUse = manualeData.parti[index].capitoli[index2];
      manualeData.parti[index].capitoli.splice(index2, 1);
      manualeData.parti[index + 1].capitoli.unshift(elementToUse);
      sortAllElements(manualeData.parti[index].capitoli);
      sortAllElements(manualeData.parti[index + 1].capitoli);
      manualeData.parti[index + 1].isTabOpen = true;
      if (manualeData.parti[index].capitoli.length === 0) {
        manualeData.parti[index].isTabOpen = false;
      }
      this.setState({
        manualeData: manualeData,
      });
    }
  };
  handleDeleteCapitolo = (index, index2) => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    if (manualeData.parti[index].capitoli[index2].paragrafi.length === 0) {
      deleteCapitoli(manualeData.parti[index].capitoli[index2].id)
        .then(() => {
          manualeData.parti[index].capitoli.splice(index2, 1);
          if (manualeData.parti[index].capitoli.length === 0) {
            manualeData.parti[index].isTabOpen = false;
          }
          this.setState(
            {
              manualeData: manualeData,
              isCheckboxStateChanged: false,
            },
            () => {
              //this.getManualeDataAfterSave(index, index2);
            }
          );
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleError(true, error.message);
          } else {
            this.handleError(true, error.message);
          }
        });
    } else {
      alert(
        "Attenzione! Sono presenti dei paragrafi associati a questo capitolo!"
      );
    }
  };
  handleSalvataModificaCapitolo = (item, item2, index, index2) => {
    let bodyToSave = {
      id: item2.id,
      titolo: item2.titolo,
      manualeId: this.state.manualeData.id,
      parteId: item.id,
      revBase: item2.base,
      data: item2.dataEditing,
      sv: item2.sv,
      daEditare: item2.daEditare,
      indice: item2.numeroIndice,
      ul: item2.ul,
      area1: item2.area1,
      area2: item2.area2,
      timeStamp: item2.timeStamp,
    };
    putCapitoli(bodyToSave)
      .then((response) => {})
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };
  handleSalvataModificaParagrafo = (item3) => {
    getSottoparagrafoIntroduttivo(item3.id)
      .then((response) => {
        response.revBase = item3.base;
        response.id = item3.id;
        response.titolo = item3.titolo;
        response.data = item3.dataEditing;
        response.sv = item3.sv;
        response.daEditare = item3.daEditare;
        response.indice = item3.numeroIndice;
        response.ul = item3.ul;
        response.area1 = item3.area1;
        response.area2 = item3.area2;
        response.timeStamp = item3.timeStamp;
        putSottoparagrafoIntroduttivo(response)
          .then((response2) => {
            item3.sottoparagrafi.map((sottoparagrafo) => {
              this.handleSalvataModificaSottoparagrafo(sottoparagrafo);
            });
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.handleError(true, error.message);
            }
          });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };
  handleSalvataModificaSottoparagrafo = (item) => {
    getSingoloSottoparagrafo(item.id)
      .then((response) => {
        response.revBase = item.base;
        response.indice = item.numeroIndice;
        response.titolo = item.titolo;
        response.data = item.dataEditing;
        response.sv = item.sv;
        response.daEditare = item.daEditare;
        response.ul = item.ul;
        response.area1 = item.area1;
        response.area2 = item.area2;
        response.id = item.id;
        response.timeStamp = item.timeStamp;
        putSingoloSottoparagrafo(response)
          .then((response2) => {})
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.handleError(true, error.message);
            }
          });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  saveParts = () => {
    let statusOfIndex = this.state.manualeData;
    let bodyToSave = {
      manualeId: this.props.manualeId,
      parti: [],
    };
    statusOfIndex.parti.map((parte, index1) => {
      let parteToPush = {
        id: parte.id,
        capitoli: [],
      };
      parte.capitoli.map((capitolo, index2) => {
        let capitoloToPush = {
          id: capitolo.id,
          paragrafi: [],
        };
        capitolo.paragrafi.map((paragrafo, index3) => {
          let paragrafoToPush = {
            id: paragrafo.id,
            componenti: [],
          };
          paragrafo.sottoparagrafi.map((sottoparagrafo, index4) => {
            paragrafoToPush.componenti.push(sottoparagrafo.id);
          });
          capitoloToPush.paragrafi.push(paragrafoToPush);
        });
        parteToPush.capitoli.push(capitoloToPush);
      });
      bodyToSave.parti.push(parteToPush);
    });
    putSortingIndice(bodyToSave)
      .then((response) => {
        this.handleMovementActivation();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });

    //this.getManualeData();
  };
  handleMovementActivation = () => {
    let manualeData = JSON.parse(JSON.stringify(this.state.manualeData));
    manualeData.parti.map((item) => {
      item.isTitoloEditable = false;
    });
    this.setState({
      activateMovement: !this.state.activateMovement,
      manualeData: manualeData,
    });
  };

  getManualeData = () => {
    let manualeData = {};
    getIndice(this.props.manualeId)
      .then((response) => {
        manualeData = {
          id: response.manualeId,
          parti: [],
          titolo: response.titolo,
          isTabOpen: false,
        };
        response.parti.map((parteToInsert, index) => {
          let parte = {
            id: parteToInsert.id,
            posizione: parteToInsert.indiceOrdinamento,
            titolo: parteToInsert.titolo,
            manualeId: parteToInsert.manualeId,
            isTabOpen: false,
            isTitoloEditable: false,
            capitoli: [],
          };
          parteToInsert.capitoli.map((capitoliToInsert, index) => {
            let capitolo = {
              id: capitoliToInsert.id,
              titolo: capitoliToInsert.titolo,
              parteId: capitoliToInsert.parteId,
              base: capitoliToInsert.revBase,
              posizione: capitoliToInsert.numeroCapitolo,
              numeroIndice: capitoliToInsert.indice,
              sv: capitoliToInsert.sv,
              ul: capitoliToInsert.ul,
              area1: capitoliToInsert.area1,
              area2: capitoliToInsert.area2,
              daEditare: capitoliToInsert.daEditare,
              isDataEditable: false,
              isTabOpen: false,
              dataEditing: capitoliToInsert.data,
              paragrafi: [],
              isSelectedForPDF: capitoliToInsert.daStampare,
              timeStamp: capitoliToInsert.lastUpdate,
            };
            capitoliToInsert.paragrafi.map((paragrafiToInsert, index) => {
              let paragrafo = {
                id: paragrafiToInsert.id,
                base: paragrafiToInsert.revBase,
                isBloccato: paragrafiToInsert.bloccato,
                capitoloId: paragrafiToInsert.capitoloId,
                posizione: paragrafiToInsert.numeroParagrafo,
                isObbligatorio: paragrafiToInsert.obbligatorio,
                daEditare: paragrafiToInsert.daEditare,
                numeroIndice: paragrafiToInsert.indice,
                sv: paragrafiToInsert.sv,
                ul: paragrafiToInsert.ul,
                area1: paragrafiToInsert.area1,
                area2: paragrafiToInsert.area2,
                dataEditing: paragrafiToInsert.data,
                isTabOpen: false,
                titolo: paragrafiToInsert.titolo,
                isSelectedForPDF: paragrafiToInsert.daStampare,
                timeStamp: paragrafiToInsert.lastUpdate,
                sottoparagrafi: [],
              };
              paragrafiToInsert.componenti.map(
                (sottoparagrafiToInsert, index) => {
                  let sottoparagrafo = {
                    id: sottoparagrafiToInsert.id,
                    base: sottoparagrafiToInsert.revBase,
                    posizione: sottoparagrafiToInsert.numeroComponente,
                    paragrafoId: sottoparagrafiToInsert.paragrafoId,
                    numeroIndice: sottoparagrafiToInsert.indice,
                    sv: sottoparagrafiToInsert.sv,
                    ul: sottoparagrafiToInsert.ul,
                    daEditare: sottoparagrafiToInsert.daEditare,
                    area1: sottoparagrafiToInsert.area1,
                    area2: sottoparagrafiToInsert.area2,
                    titolo: sottoparagrafiToInsert.titolo,
                    tipologia: sottoparagrafiToInsert.tipoContenuto,
                    timeStamp: sottoparagrafiToInsert.lastUpdate,
                    isSelectedForPDF: sottoparagrafiToInsert.daStampare,
                    dataEditing: sottoparagrafiToInsert.data,
                    hasOrganigramma: sottoparagrafiToInsert.hasOrganigramma,
                  };
                  paragrafo.sottoparagrafi.push(sottoparagrafo);
                }
              );
              capitolo.paragrafi.push(paragrafo);
            });
            parte.capitoli.push(capitolo);
          });
          manualeData.parti.push(parte);
        });
        let openData = JSON.parse(getOpenParti());
        for (let i = 0; i < manualeData.parti.length; i++) {
          for (let j = 0; j < openData.parti.length; j++) {
            if (manualeData.parti[i].id === openData.parti[j].id) {
              if (manualeData.parti[i].capitoli.length > 0) {
                manualeData.parti[i].isTabOpen = true;
              } else {
                manualeData.parti[i].isTabOpen = false;
              }
            }
          }
          for (let k = 0; k < manualeData.parti[i].capitoli.length; k++) {
            for (let m = 0; m < openData.capitoli.length; m++) {
              if (
                manualeData.parti[i].capitoli[k].id === openData.capitoli[m].id
              ) {
                if (manualeData.parti[i].capitoli[k].paragrafi.length > 0) {
                  manualeData.parti[i].capitoli[k].isTabOpen = true;
                } else {
                  manualeData.parti[i].capitoli[k].isTabOpen = false;
                }
              }
            }
            for (
              let n = 0;
              n < manualeData.parti[i].capitoli[k].paragrafi.length;
              n++
            ) {
              for (let o = 0; o < openData.paragrafi.length; o++) {
                if (
                  manualeData.parti[i].capitoli[k].paragrafi[n].id ===
                  openData.paragrafi[o].id
                ) {
                  if (
                    manualeData.parti[i].capitoli[k].paragrafi[n]
                      .sottoparagrafi.length > 0
                  ) {
                    manualeData.parti[i].capitoli[k].paragrafi[
                      n
                    ].isTabOpen = true;
                  } else {
                    manualeData.parti[i].capitoli[k].paragrafi[
                      n
                    ].isTabOpen = false;
                  }
                }
              }
            }
          }
        }
        //getPartiManuale(this.props.manualeId,0,999,"indiceOrdinamento","ASC").then((response) => {

        /*let capitolo = {
              id:1,
              titolo:"Test capitolo numero 1",
              base:0,
              posizione:1,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              daEditare:false,
              isTabOpen:false,
              dataEditing:Date.now(),
              paragrafi:[],
              isSelectedForPDF:false,
          }
          let capitolo1 = {
              id:2,
              titolo:"Test capitolo numero 2",
              base:0,
              posizione:2,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              daEditare:false,
              isTabOpen:false,
              dataEditing:Date.now(),
              paragrafi:[],
              isSelectedForPDF:false,
          }
          let paragrafo = {
              id:1,
              base:0,
              posizione:1,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              dataEditing:Date.now(),
              isTabOpen:false,
              titolo:"Test paragrafo",
              isSelectedForPDF:false,
              sottoparagrafi:[],
          }
          let paragrafo1 = {
            id:2,
            base:0,
            posizione:2,
            sv:0,
            ul:0,
            area1:0,
            area2:0,
            dataEditing:Date.now(),
            isTabOpen:false,
            titolo:"Test paragrafo 1",
            isSelectedForPDF:false,
            sottoparagrafi:[],
        }
        let paragrafo2 = {
          id:3,
          base:0,
          posizione:3,
          sv:0,
          ul:0,
          area1:0,
          area2:0,
          dataEditing:Date.now(),
          isTabOpen:false,
          titolo:"Test paragrafo 2",
          isSelectedForPDF:false,
          sottoparagrafi:[],
      }
          let sottoparagrafo= {
              id:1,
              base:0,
              posizione:1,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              titolo:"Test sottoparagrafo",
              tipologia:"Testo",
              isSelectedForPDF:false,
              dataEditing:Date.now(),
          }
          let sottoparagrafo2= {
            id:2,
            base:0,
            posizione:2,
            sv:0,
            ul:0,
            area1:0,
            area2:0,
            titolo:"Test sottoparagrafo 2",
            tipologia:"Testo",
            isSelectedForPDF:false,
            dataEditing:Date.now(),
        }
        manualeData.parti.push(parte);
        parte.capitoli.push(capitolo);
        parte.capitoli.push(capitolo1);
        capitolo.paragrafi.push(paragrafo);
        capitolo.paragrafi.push(paragrafo1);
        capitolo1.paragrafi.push(paragrafo2);
        paragrafo.sottoparagrafi.push(sottoparagrafo);
        paragrafo.sottoparagrafi.push(sottoparagrafo2);
        paragrafo2.sottoparagrafi.push(sottoparagrafo);*/
        this.setState(
          {
            manualeData: manualeData,
          },
          () => {
            this.getIndiceData(
              response.revBase,
              response.sv,
              response.ul,
              response.area1,
              response.area2,
              response.data,
              response.lastUpdate,
              response.indiceId
            );
            //this.getIndiceData(0,0,0,0,0,Date.now());
          }
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        else if (error.status === 409) {
          const { history } = this.props;
          history.goBack();
        }
        else {
          this.handleError(true, error.message);
        }
      });
    /*}).catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.handleError(true, error.message);
          }
        })*/
  };

  getManualeDataAfterSave = (
    indexParte,
    indexCapitolo,
    indexParagrafo,
    indexSottoparagrafo
  ) => {
    let manualeData = {};
    getIndice(this.props.manualeId)
      .then((response) => {
        manualeData = {
          id: response.manualeId,
          parti: [],
          titolo: response.titolo,
          isTabOpen: false,
        };
        response.parti.map((parteToInsert, index) => {
          let parte = {
            id: parteToInsert.id,
            posizione: parteToInsert.indiceOrdinamento,
            titolo: parteToInsert.titolo,
            manualeId: parteToInsert.manualeId,
            isTitoloEditable: false,
            isTabOpen: false,
            capitoli: [],
          };
          if (index === indexParte) {
            parte.isTabOpen = true;
          }
          parteToInsert.capitoli.map((capitoliToInsert, index2) => {
            let capitolo = {
              id: capitoliToInsert.id,
              titolo: capitoliToInsert.titolo,
              parteId: capitoliToInsert.parteId,
              base: capitoliToInsert.revBase,
              posizione: capitoliToInsert.numeroCapitolo,
              sv: capitoliToInsert.sv,
              ul: capitoliToInsert.ul,
              numeroIndice: capitoliToInsert.indice,
              area1: capitoliToInsert.area1,
              area2: capitoliToInsert.area2,
              daEditare: capitoliToInsert.daEditare,
              isDataEditable: false,
              isTabOpen: false,
              timeStamp: capitoliToInsert.lastUpdate,
              dataEditing: capitoliToInsert.data,
              paragrafi: [],
              isSelectedForPDF: capitoliToInsert.daStampare,
            };
            if (index2 === indexCapitolo) {
              capitolo.isTabOpen = true;
            }
            capitoliToInsert.paragrafi.map((paragrafiToInsert, index3) => {
              let paragrafo = {
                id: paragrafiToInsert.id,
                base: paragrafiToInsert.revBase,
                isBloccato: paragrafiToInsert.bloccato,
                capitoloId: paragrafiToInsert.capitoloId,
                posizione: paragrafiToInsert.numeroParagrafo,
                isObbligatorio: paragrafiToInsert.obbligatorio,
                sv: paragrafiToInsert.sv,
                ul: paragrafiToInsert.ul,
                numeroIndice: paragrafiToInsert.indice,
                daEditare: paragrafiToInsert.daEditare,
                timeStamp: paragrafiToInsert.lastUpdate,
                area1: paragrafiToInsert.area1,
                area2: paragrafiToInsert.area2,
                dataEditing: paragrafiToInsert.data,
                isTabOpen: false,
                titolo: paragrafiToInsert.titolo,
                isSelectedForPDF: paragrafiToInsert.daStampare,
                sottoparagrafi: [],
              };
              if (index3 === indexParagrafo) {
                paragrafo.isTabOpen = true;
              }
              paragrafiToInsert.componenti.map(
                (sottoparagrafiToInsert, index4) => {
                  let sottoparagrafo = {
                    id: sottoparagrafiToInsert.id,
                    base:
                      sottoparagrafiToInsert.revBase === null
                        ? paragrafiToInsert.revBase
                        : sottoparagrafiToInsert.revBase,
                    posizione: sottoparagrafiToInsert.numeroComponente,
                    paragrafoId: sottoparagrafiToInsert.paragrafoId,
                    sv:
                      sottoparagrafiToInsert.sv === null
                        ? paragrafiToInsert.sv
                        : sottoparagrafiToInsert.sv,
                    ul:
                      sottoparagrafiToInsert.ul === null
                        ? paragrafiToInsert.ul
                        : sottoparagrafiToInsert.ul,
                    numeroIndice: sottoparagrafiToInsert.indice,
                    area1:
                      sottoparagrafiToInsert.area1 === null
                        ? paragrafiToInsert.area1
                        : sottoparagrafiToInsert.area1,
                    area2:
                      sottoparagrafiToInsert.area2 === null
                        ? paragrafiToInsert.area2
                        : sottoparagrafiToInsert.area2,
                    titolo: sottoparagrafiToInsert.titolo,
                    timeStamp: sottoparagrafiToInsert.lastUpdate,
                    daEditare: sottoparagrafiToInsert.daEditare,
                    tipologia: sottoparagrafiToInsert.tipoContenuto,
                    isSelectedForPDF: sottoparagrafiToInsert.daStampare,
                    hasOrganigramma: sottoparagrafiToInsert.hasOrganigramma,
                    dataEditing:
                      sottoparagrafiToInsert.data === null
                        ? paragrafiToInsert.data
                        : sottoparagrafiToInsert.data,
                  };
                  if (index4 === indexSottoparagrafo) {
                    sottoparagrafo.isTabOpen = true;
                  }
                  paragrafo.sottoparagrafi.push(sottoparagrafo);
                }
              );
              capitolo.paragrafi.push(paragrafo);
            });
            parte.capitoli.push(capitolo);
          });
          manualeData.parti.push(parte);
        });
        //getPartiManuale(this.props.manualeId,0,999,"indiceOrdinamento","ASC").then((response) => {

        /*let capitolo = {
              id:1,
              titolo:"Test capitolo numero 1",
              base:0,
              posizione:1,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              daEditare:false,
              isTabOpen:false,
              dataEditing:Date.now(),
              paragrafi:[],
              isSelectedForPDF:false,
          }
          let capitolo1 = {
              id:2,
              titolo:"Test capitolo numero 2",
              base:0,
              posizione:2,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              daEditare:false,
              isTabOpen:false,
              dataEditing:Date.now(),
              paragrafi:[],
              isSelectedForPDF:false,
          }
          let paragrafo = {
              id:1,
              base:0,
              posizione:1,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              dataEditing:Date.now(),
              isTabOpen:false,
              titolo:"Test paragrafo",
              isSelectedForPDF:false,
              sottoparagrafi:[],
          }
          let paragrafo1 = {
            id:2,
            base:0,
            posizione:2,
            sv:0,
            ul:0,
            area1:0,
            area2:0,
            dataEditing:Date.now(),
            isTabOpen:false,
            titolo:"Test paragrafo 1",
            isSelectedForPDF:false,
            sottoparagrafi:[],
        }
        let paragrafo2 = {
          id:3,
          base:0,
          posizione:3,
          sv:0,
          ul:0,
          area1:0,
          area2:0,
          dataEditing:Date.now(),
          isTabOpen:false,
          titolo:"Test paragrafo 2",
          isSelectedForPDF:false,
          sottoparagrafi:[],
      }
          let sottoparagrafo= {
              id:1,
              base:0,
              posizione:1,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              titolo:"Test sottoparagrafo",
              tipologia:"Testo",
              isSelectedForPDF:false,
              dataEditing:Date.now(),
          }
          let sottoparagrafo2= {
            id:2,
            base:0,
            posizione:2,
            sv:0,
            ul:0,
            area1:0,
            area2:0,
            titolo:"Test sottoparagrafo 2",
            tipologia:"Testo",
            isSelectedForPDF:false,
            dataEditing:Date.now(),
        }
        manualeData.parti.push(parte);
        parte.capitoli.push(capitolo);
        parte.capitoli.push(capitolo1);
        capitolo.paragrafi.push(paragrafo);
        capitolo.paragrafi.push(paragrafo1);
        capitolo1.paragrafi.push(paragrafo2);
        paragrafo.sottoparagrafi.push(sottoparagrafo);
        paragrafo.sottoparagrafi.push(sottoparagrafo2);
        paragrafo2.sottoparagrafi.push(sottoparagrafo);*/
        let openData = JSON.parse(getOpenParti());
        for (let i = 0; i < manualeData.parti.length; i++) {
          for (let j = 0; j < openData.parti.length; j++) {
            if (manualeData.parti[i].id === openData.parti[j].id) {
              manualeData.parti[i].isTabOpen = true;
            }
          }
          for (let k = 0; k < manualeData.parti[i].capitoli.length; k++) {
            for (let m = 0; m < openData.capitoli.length; m++) {
              if (
                manualeData.parti[i].capitoli[k].id === openData.capitoli[m].id
              ) {
                manualeData.parti[i].capitoli[k].isTabOpen = true;
              }
            }
            for (
              let n = 0;
              n < manualeData.parti[i].capitoli[k].paragrafi.length;
              n++
            ) {
              for (let o = 0; o < openData.paragrafi.length; o++) {
                if (
                  manualeData.parti[i].capitoli[k].paragrafi[n].id ===
                  openData.paragrafi[o].id
                ) {
                  manualeData.parti[i].capitoli[k].paragrafi[
                    n
                  ].isTabOpen = true;
                }
              }
            }
          }
        }
        this.setState(
          {
            manualeData: manualeData,
          },
          () => {
            this.getIndiceData(
              response.revBase,
              response.sv,
              response.ul,
              response.area1,
              response.area2,
              response.data,
              response.lastUpdate,
              response.indiceId
            );
            //this.getIndiceData(0,0,0,0,0,Date.now());
          }
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
    /*}).catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.handleError(true, error.message);
          }
        })*/
  };
  getIndiceData = (
    revbase,
    sv,
    ul,
    area1,
    area2,
    data,
    timeStamp,
    idIndice
  ) => {
    this.setState({
      loading: false,
      base: revbase === null ? "" : revbase,
      sv: sv === null ? "" : sv,
      ul: ul === null ? "" : ul,
      area1: area1 === null ? "" : area1,
      area2: area2 === null ? "" : area2,
      data: data,
      indiceId: idIndice,
      timeStamp: timeStamp,
    },() => {
      if(localStorage.getItem('indexScrollPosition'))
      window.scrollTo((document.body.scrollWidth - window.screen.width) / 2,parseInt(localStorage.getItem('indexScrollPosition')))
    });
  };
  //Note: timestamp is an alias for lastUpdate
  handleIndexSave = () => {
    let objToSave = {};
    if (this.state.isModello) {
      objToSave = {
        id: this.state.indiceId,
        manualeId: this.state.manualeData.id,
        revBase: this.state.base,
        data: this.state.data,
        lastUpdate: this.state.timeStamp,
      };
    } else {
      objToSave = {
        id: this.state.indiceId,
        manualeId: this.state.manualeData.id,
        revBase: this.state.base,
        sv: this.state.sv,
        ul: this.state.ul,
        area1: this.state.area1,
        area2: this.state.area2,
        data: this.state.data,
        lastUpdate: this.state.timeStamp,
      };
    }
    putIndiceTableValues(objToSave)
      .then((response) => {})
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  render() {
    return (
      <LoadingOverlay
        spinner
        text={this.state.overlayText}
        active={this.state.isLoadingOverlay}
      >
        <>
          <div style={styles.mainContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography style={styles.typo} variant="h4">
                {this.state.manualeData.titolo}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 50,
              }}
            >
              <div style={{ display: "flex", justifyContent: "left" }}>
                {!this.state.loading ? (
                  <IconButton
                    onClick={() => {
                      this.handleIndietroCommand();
                    }}
                    style={{ backgroundColor: theme.palette.cndColors.red }}
                  >
                    <ArrowBack style={{ color: "white" }}></ArrowBack>
                  </IconButton>
                ) : null}
              </div>
              <div style={{ display: "flex", justifyContent: "right" }}>
                {!this.state.activateMovement ? (
                  !this.state.loading ? (
                    <Button
                      type="button"
                      onClick={() => {
                        this.handleMovementActivation();
                      }}
                      variant="contained"
                      size="medium"
                      disabled={false}
                      style={{
                        color: theme.palette.secondary.main,
                        margin: "5px",
                        borderRadius: 30,
                        backgroundColor: theme.palette.cndColors.yellow,
                      }}
                    >
                      Attiva spostamento
                    </Button>
                  ) : null
                ) : (
                  <Button
                    type="button"
                    onClick={() => {
                      this.saveParts();
                    }}
                    variant="contained"
                    size="medium"
                    disabled={false}
                    style={{
                      color: theme.palette.secondary.main,
                      margin: "5px",
                      borderRadius: 30,
                      backgroundColor: theme.palette.cndColors.red,
                    }}
                  >
                    Salva Spostamento
                  </Button>
                )}
                {!this.state.loading && !this.state.isModello ? (
                  <Button
                    type="button"
                    onClick={() => {
                      this.proseguiToPubblicazione();
                    }}
                    variant="contained"
                    size="medium"
                    disabled={false}
                    style={{
                      color: theme.palette.secondary.main,
                      margin: "5px",
                      borderRadius: 30,
                      backgroundColor: theme.palette.cndColors.yellow,
                    }}
                  >
                    Prosegui per la pubblicazione
                  </Button>
                ) : null}
              </div>
            </div>
            {!this.state.loading ? (
              <div style={{ display: "flex", justifyContent: "left" }}>
                <List style={{ width: "100%" }}>
                  <div style={{ display: "flex" }}>
                    <Typography
                      style={{ marginTop: 40, marginLeft: 40 }}
                      variant="h4"
                      align="left"
                    >
                      Indice
                    </Typography>
                    <IconButton
                      style={{
                        width: 50,
                        height: 50,
                        marginTop: 40,
                        marginLeft: 40,
                      }}
                      onClick={() => {
                        if (!this.state.isCheckboxStateChanged) {
                          let bodyToPass = {
                            manualeId: this.state.manualeData.id,
                            titolo: "Nuova parte",
                            id: null,
                          };
                          postAggiuntaParteManuale(bodyToPass)
                            .then((response) => {
                              let manualeData = JSON.parse(
                                JSON.stringify(this.state.manualeData)
                              );
                              manualeData.parti.push({
                                id: response.id,
                                posizione: response.posizione,
                                titolo: response.titolo,
                                isTitoloEditable: false,
                                capitoli: [],
                              });
                              this.setState(
                                {
                                  manualeData: manualeData,
                                  isCheckboxStateChanged: false,
                                },
                                () => {
                                  let partiData = [];
                                  let capitoliData = [];
                                  let paragrafiData = [];
                                  this.state.manualeData.parti.map(
                                    (item, index) => {
                                      if (item.isTabOpen) {
                                        let parteObject = {
                                          id: item.id,
                                        };
                                        item.capitoli.map((item2, index2) => {
                                          if (item2.isTabOpen) {
                                            let capitoloObject = {
                                              id: item2.id,
                                            };
                                            item2.paragrafi.map(
                                              (item3, index3) => {
                                                if (item3.isTabOpen) {
                                                  let paragrafoObject = {
                                                    id: item3.id,
                                                  };
                                                  paragrafiData.push(
                                                    paragrafoObject
                                                  );
                                                }
                                              }
                                            );
                                            capitoliData.push(capitoloObject);
                                          }
                                        });
                                        partiData.push(parteObject);
                                      }
                                    }
                                  );
                                  let dataToUse = {
                                    parti: partiData,
                                    capitoli: capitoliData,
                                    paragrafi: paragrafiData,
                                  };
                                  setOpenParti(JSON.stringify(dataToUse));
                                  //this.getManualeDataAfterSave();
                                }
                              );
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.handleInvalidToken();
                              } else {
                                this.handleError(true, error.message);
                              }
                            });
                        } else {
                          let confirmation = window.confirm(
                            "Attenzione: modifiche alle checkbox non salvate! Se si procede all'aggiunta della parte tali modifiche verranno scartate. Procedere?"
                          );
                          if (confirmation) {
                            let bodyToPass = {
                              manualeId: this.state.manualeData.id,
                              titolo: "Nuova parte",
                              id: null,
                            };
                            postAggiuntaParteManuale(bodyToPass)
                              .then((response) => {
                                let manualeData = JSON.parse(
                                  JSON.stringify(this.state.manualeData)
                                );
                                manualeData.parti.push({
                                  id: response.id,
                                  posizione: response.posizione,
                                  titolo: response.titolo,
                                  isTitoloEditable: false,
                                  capitoli: [],
                                });
                                this.setState(
                                  {
                                    manualeData: manualeData,
                                    isCheckboxStateChanged: false,
                                  },
                                  () => {
                                    let partiData = [];
                                    let capitoliData = [];
                                    let paragrafiData = [];
                                    this.state.manualeData.parti.map(
                                      (item, index) => {
                                        if (item.isTabOpen) {
                                          let parteObject = {
                                            id: item.id,
                                          };
                                          item.capitoli.map((item2, index2) => {
                                            if (item2.isTabOpen) {
                                              let capitoloObject = {
                                                id: item2.id,
                                              };
                                              item2.paragrafi.map(
                                                (item3, index3) => {
                                                  if (item3.isTabOpen) {
                                                    let paragrafoObject = {
                                                      id: item3.id,
                                                    };
                                                    paragrafiData.push(
                                                      paragrafoObject
                                                    );
                                                  }
                                                }
                                              );
                                              capitoliData.push(capitoloObject);
                                            }
                                          });
                                          partiData.push(parteObject);
                                        }
                                      }
                                    );
                                    let dataToUse = {
                                      parti: partiData,
                                      capitoli: capitoliData,
                                      paragrafi: paragrafiData,
                                    };
                                    setOpenParti(JSON.stringify(dataToUse));
                                    // this.getManualeDataAfterSave();
                                  }
                                );
                              })
                              .catch((error) => {
                                if (error.status === 403) {
                                  this.handleInvalidToken();
                                } else {
                                  this.handleError(true, error.message);
                                }
                              });
                          }
                        }
                      }}
                      disabled={this.state.activateMovement}
                      size="medium"
                    >
                      <AddCircle
                        style={{ width: 50, height: 50 }}
                        htmlColor={
                          this.state.activateMovement ? "#e0e0e0" : "#f7a616"
                        }
                      />
                    </IconButton>

                    {/*!this.state.isModello ? <Button
          type="button"
            onClick={() => {
              this.handleDaStampareSave();
            }}
            variant="contained"
            size="medium"
            disabled={false}
            style={{
                color: theme.palette.secondary.main, 
                margin:"5px",
                backgroundColor: theme.palette.primary.main,
            }}
          >
            Salva elementi da stampare selezionati
          </Button> : null*/}
                    {!this.state.isModello ? (
                      <div style={{ width: "100%", marginTop: 50 }}>
                        <TableContainer>
                          <Table
                            style={
                              {
                                //borderCollapse: 'separate',
                                //borderSpacing: '0px 20px'
                              }
                            }
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ border: 0 }}></TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: "2px solid lightgray",
                                    width: "15%",
                                  }}
                                  align="center"
                                >
                                  Base&nbsp;
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: 0,
                                    width: "15%",
                                  }}
                                  align="center"
                                >
                                  SV
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: 0,
                                    width: "15%",
                                  }}
                                  align="center"
                                >
                                  UL
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: 0,
                                    width: "15%",
                                  }}
                                  align="center"
                                >
                                  AREA 1
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: 0,
                                    width: "15%",
                                  }}
                                  align="center"
                                >
                                  AREA 2
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: 0,
                                    width: "15%",
                                  }}
                                  align="center"
                                >
                                  Data&nbsp;
                                </TableCell>
                                <TableCell style={{ border: 0 }}></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell style={{ border: 0 }}></TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: "2px solid lightgray",
                                  }}
                                  align="center"
                                >
                                  <TextField
                                    style={{ width: "100%" }}
                                    inputProps={{
                                      style: {
                                        textAlign: "center",
                                        color: "black",
                                      },
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(e) => {
                                      let base = this.state.base;
                                      base = e.target.value;
                                      this.setState({
                                        base: base,
                                      });
                                    }}
                                    disabled={this.state.activateMovement}
                                    value={this.state.base}
                                    onBlur={this.handleIndexSave}
                                  />
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: 0,
                                  }}
                                  align="center"
                                >
                                  <TextField
                                    style={{ width: "100%" }}
                                    inputProps={{
                                      style: {
                                        textAlign: "center",
                                        color: "black",
                                      },
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(e) => {
                                      let sv = this.state.sv;
                                      sv = e.target.value;
                                      this.setState({
                                        sv: sv,
                                      });
                                    }}
                                    disabled={this.state.activateMovement}
                                    value={this.state.sv}
                                    onBlur={this.handleIndexSave}
                                  />
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: 0,
                                  }}
                                  align="center"
                                >
                                  <TextField
                                    style={{ width: "100%" }}
                                    inputProps={{
                                      style: {
                                        textAlign: "center",
                                        color: "black",
                                      },
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(e) => {
                                      let ul = this.state.ul;
                                      ul = e.target.value;
                                      this.setState({
                                        ul: ul,
                                      });
                                    }}
                                    value={this.state.ul}
                                    disabled={this.state.activateMovement}
                                    onBlur={this.handleIndexSave}
                                  />
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: 0,
                                  }}
                                  align="center"
                                >
                                  <TextField
                                    style={{ width: "100%" }}
                                    inputProps={{
                                      style: {
                                        textAlign: "center",
                                        color: "black",
                                      },
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(e) => {
                                      let area1 = this.state.area1;
                                      area1 = e.target.value;
                                      this.setState({
                                        area1: area1,
                                      });
                                    }}
                                    disabled={this.state.activateMovement}
                                    value={this.state.area1}
                                    onBlur={this.handleIndexSave}
                                  />
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: 0,
                                  }}
                                  align="center"
                                >
                                  <TextField
                                    style={{ width: "100%" }}
                                    inputProps={{
                                      style: {
                                        textAlign: "center",
                                        color: "black",
                                      },
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(e) => {
                                      let area2 = this.state.area2;
                                      area2 = e.target.value;
                                      this.setState({
                                        area2: area2,
                                      });
                                    }}
                                    disabled={this.state.activateMovement}
                                    value={this.state.area2}
                                    onBlur={this.handleIndexSave}
                                  />
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: 0,
                                  }}
                                  align="center"
                                >
                                  {
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                      locale={itLocale}
                                    >
                                      <KeyboardDatePicker
                                        keyboardIcon={
                                          <CalendarLogo
                                            width={21}
                                            height={21}
                                            fill={
                                              this.state.activateMovement
                                                ? "#e0e0e0"
                                                : theme.palette.cndColors.yellow
                                            }
                                          />
                                        }
                                        autoOk
                                        variant="inline"
                                        inputVariant="standard"
                                        format="dd/MM/yyyy"
                                        value={this.state.data}
                                        disabled={this.state.activateMovement}
                                        InputAdornmentProps={{
                                          position: "start",
                                          style: { marginBottom: 4 },
                                        }}
                                        InputProps={{
                                          disableUnderline: true,
                                          readOnly: true,
                                        }}
                                        onChange={(date) => {
                                          this.setState(
                                            {
                                              data: date,
                                            },
                                            () => {
                                              this.handleIndexSave();
                                            }
                                          );
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  }
                                </TableCell>
                                <TableCell style={{ border: 0 }}></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    ) : (
                      <div style={{ width: "100%", marginTop: 50 }}>
                        <TableContainer>
                          <Table
                            style={
                              {
                                //borderCollapse: 'separate',
                                //borderSpacing: '0px 20px'
                              }
                            }
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ border: 0 }}></TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: "2px solid lightgray",
                                    width: "45%",
                                  }}
                                  align="center"
                                >
                                  Base&nbsp;
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: 0,
                                    width: "45%",
                                  }}
                                  align="center"
                                >
                                  Data&nbsp;
                                </TableCell>
                                <TableCell style={{ border: 0 }}></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell style={{ border: 0 }}></TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: "2px solid lightgray",
                                  }}
                                  align="center"
                                >
                                  <TextField
                                    style={{ width: "100%" }}
                                    inputProps={{
                                      style: {
                                        textAlign: "center",
                                        color: "black",
                                      },
                                    }}
                                    disabled={this.state.activateMovement}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(e) => {
                                      let base = this.state.base;
                                      base = e.target.value;
                                      this.setState({
                                        base: base,
                                      });
                                    }}
                                    value={this.state.base}
                                    onBlur={this.handleIndexSave}
                                  ></TextField>
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid lightgray",
                                    borderTop: "2px solid lightgray",
                                    borderBottom: "2px solid lightgray",
                                    borderLeft: 0,
                                  }}
                                  align="center"
                                >
                                  {
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                      locale={itLocale}
                                    >
                                      <KeyboardDatePicker
                                        keyboardIcon={
                                          <CalendarLogo
                                            width={21}
                                            height={21}
                                            fill={
                                              this.state.activateMovement
                                                ? "#e0e0e0"
                                                : theme.palette.cndColors.yellow
                                            }
                                          ></CalendarLogo>
                                        }
                                        autoOk
                                        variant="inline"
                                        inputVariant="standard"
                                        format="dd/MM/yyyy"
                                        value={this.state.data}
                                        disabled={this.state.activateMovement}
                                        InputAdornmentProps={{
                                          position: "start",
                                          style: { marginBottom: 4 },
                                        }}
                                        InputProps={{
                                          disableUnderline: true,
                                          readOnly: true,
                                        }}
                                        onChange={(date) => {
                                          this.setState(
                                            {
                                              data: date,
                                            },
                                            () => {
                                              this.handleIndexSave();
                                            }
                                          );
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  }
                                </TableCell>
                                <TableCell style={{ border: 0 }}></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      border: "1px solid #e0e0e0",
                      borderRadius: 50,
                      marginTop: 30,
                    }}
                  >
                    {this.state.manualeData.parti.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          {" "}
                          <ListItem key={index}>
                            <IconButton
                              onClick={() => {
                                let manualeData = JSON.parse(
                                  JSON.stringify(this.state.manualeData)
                                );
                                if (
                                  manualeData.parti[index].capitoli.length > 0
                                ) {
                                  manualeData.parti[index].isTabOpen =
                                    !manualeData.parti[index].isTabOpen;
                                  this.setState({
                                    manualeData: manualeData,
                                  });
                                }
                              }}
                            >
                              {item.isTabOpen && item.capitoli.length > 0 ? (
                                <ExpandMore htmlColor="#f7a616" />
                              ) : (
                                <ChevronRight htmlColor={item.capitoli.length === 0 ? "#e0e0e0" : "#f7a616"} />
                              )}
                            </IconButton>
                            <TextField
                              onBlur={() => {
                                let bodyToPass = {
                                  id: item.id,
                                  titolo: item.titolo,
                                  manualeId: this.state.manualeData.id,
                                  indiceOrdinamento: item.posizione,
                                  stato: "BOZZA",
                                };
                                putPartiManuale(bodyToPass)
                                  .then((response) => {})
                                  .catch((error) => {
                                    if (error.status === 403) {
                                      this.handleInvalidToken();
                                    } else {
                                      this.handleError(true, error.message);
                                    }
                                  });
                              }}
                              InputProps={{ disableUnderline: true }}
                              onChange={(event) => {
                                let manualeData = JSON.parse(
                                  JSON.stringify(this.state.manualeData)
                                );
                                manualeData.parti[index].titolo =
                                  event.target.value;
                                this.setState({
                                  manualeData: manualeData,
                                });
                              }}
                              inputProps={{
                                style: {
                                  fontSize: "2.125rem",
                                  textAlign: "center",
                                  color: "black",
                                },
                              }}
                              disabled={
                                !item.isTitoloEditable ||
                                this.state.activateMovement
                              }
                              value={item.titolo}
                              style={{ fontSize: 30 }}
                            />
                            <IconButton
                              disabled={this.state.activateMovement}
                              onClick={() => {
                                let manualeData = JSON.parse(
                                  JSON.stringify(this.state.manualeData)
                                );
                                manualeData.parti[index].isTitoloEditable =
                                  !manualeData.parti[index].isTitoloEditable;
                                this.setState({
                                  manualeData: manualeData,
                                });
                              }}
                            >
                              {this.state.manualeData.parti[index]
                                .isTitoloEditable &&
                              !this.state.activateMovement ? (
                                <Edit htmlColor="#f7a616" />
                              ) : (
                                <Edit />
                              )}
                            </IconButton>
                            <IconButton
                              disabled={this.state.activateMovement}
                              onClick={() => {
                                if (!this.state.isCheckboxStateChanged) {
                                  let manualeData = JSON.parse(
                                    JSON.stringify(this.state.manualeData)
                                  );
                                  let objectToPass = {
                                    id: null,
                                    manualeId: manualeData.id,
                                    parteId: manualeData.parti[index].id,
                                    titolo: "Nuovo capitolo",
                                  };
                                  postCreaCapitoli(objectToPass)
                                    .then((response) => {
                                      let capitolo = {
                                        id: response.id,
                                        titolo: response.titolo,
                                        parteId: response.parteId,
                                        base: response.revBase,
                                        posizione: response.numeroCapitolo,
                                        numeroIndice: response.indice,
                                        sv: response.sv,
                                        ul: response.ul,
                                        area1: response.area1,
                                        area2: response.area2,
                                        daEditare: response.daEditare,
                                        isDataEditable: false,
                                        isTabOpen: false,
                                        dataEditing: response.data,
                                        paragrafi: [],
                                        isSelectedForPDF: response.daStampare,
                                        timeStamp: response.lastUpdate,
                                      };
                                      manualeData.parti[index].capitoli.push(
                                        capitolo
                                      );
                                      manualeData.parti[index].isTabOpen = true;
                                      /*manualeData.parti[index].capitoli.push({
                      id:null,
                      titolo:"Nuovo capitolo",
                      base:"",
                      posizione:manualeData.parti[index].capitoli.length+1,
                      sv:0,
                      ul:0,
                      area1:0,
                      area2:0,
                      daEditare:false,
                      isTabOpen:false,
                      dataEditing:null,
                      paragrafi:[],
                      isSelectedForPDF:true,
                    })*/
                                      this.setState(
                                        {
                                          manualeData: manualeData,
                                          isCheckboxStateChanged: false,
                                        },
                                        () => {
                                          //this.getManualeDataAfterSave(index);
                                        }
                                      );
                                    })
                                    .catch((error) => {
                                      if (error.status === 403) {
                                        this.handleInvalidToken();
                                      } else {
                                        this.handleError(true, error.message);
                                      }
                                    });
                                } else {
                                  let confirmation = window.confirm(
                                    "Attenzione: modifiche alle checkbox non salvate! Se si procede all'aggiunta del capitolo tali modifiche verranno scartate. Procedere?"
                                  );
                                  if (confirmation) {
                                    let manualeData = JSON.parse(
                                      JSON.stringify(this.state.manualeData)
                                    );
                                    let objectToPass = {
                                      id: null,
                                      manualeId: manualeData.id,
                                      parteId: manualeData.parti[index].id,
                                      titolo: "Nuovo capitolo",
                                    };
                                    postCreaCapitoli(objectToPass)
                                      .then((response) => {
                                        /*manualeData.parti[index].capitoli.push({
                      id:null,
                      titolo:"Nuovo capitolo",
                      base:0,
                      posizione:manualeData.parti[index].capitoli.length+1,
                      sv:0,
                      ul:0,
                      area1:0,
                      area2:0,
                      daEditare:false,
                      isTabOpen:false,
                      dataEditing:Date.now(),
                      paragrafi:[],
                    })*/ let capitolo = {
                                          id: response.id,
                                          titolo: response.titolo,
                                          parteId: response.parteId,
                                          base: response.revBase,
                                          posizione: response.numeroCapitolo,
                                          numeroIndice: response.indice,
                                          sv: response.sv,
                                          ul: response.ul,
                                          area1: response.area1,
                                          area2: response.area2,
                                          daEditare: response.daEditare,
                                          isDataEditable: false,
                                          isTabOpen: false,
                                          dataEditing: response.data,
                                          paragrafi: [],
                                          isSelectedForPDF: response.daStampare,
                                          timeStamp: response.lastUpdate,
                                        };
                                        manualeData.parti[index].capitoli.push(
                                          capitolo
                                        );
                                        manualeData.parti[
                                          index
                                        ].isTabOpen = true;
                                        this.setState(
                                          {
                                            manualeData: manualeData,
                                            isCheckboxStateChanged: false,
                                          },
                                          () => {
                                            // this.getManualeDataAfterSave(index);
                                          }
                                        );
                                      })
                                      .catch((error) => {
                                        if (error.status === 403) {
                                          this.handleInvalidToken();
                                        } else {
                                          this.handleError(true, error.message);
                                        }
                                      });
                                  }
                                }
                              }}
                            >
                              <AddCircle
                                htmlColor={
                                  this.state.activateMovement
                                    ? "#e0e0e0"
                                    : "#f7a616"
                                }
                              />
                            </IconButton>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <IconButton
                                disabled={!this.state.activateMovement}
                                size="small"
                                onClick={async () => {
                                  if (index > 0) {
                                    this.setState({
                                      isLoadingOverlay: true,
                                      overlayText:
                                        "Spostamento in corso! Attendere prego...",
                                    });
                                    await new Promise((r) =>
                                      setTimeout(r, 600)
                                    );
                                    let manualeData = JSON.parse(
                                      JSON.stringify(this.state.manualeData)
                                    );
                                    swapElements(
                                      manualeData.parti,
                                      index,
                                      index - 1
                                    );
                                    this.setState({
                                      manualeData: manualeData,
                                      isLoadingOverlay: false,
                                    });
                                  }
                                }}
                              >
                                <FrecciaSu
                                  width={15}
                                  height={15}
                                  fill={
                                    this.state.activateMovement
                                      ? "#f7a616"
                                      : "#e0e0e0"
                                  }
                                />
                              </IconButton>
                              <IconButton
                                disabled={!this.state.activateMovement}
                                size="small"
                                onClick={async () => {
                                  if (
                                    index <
                                    this.state.manualeData.parti.length - 1
                                  ) {
                                    let manualeData = JSON.parse(
                                      JSON.stringify(this.state.manualeData)
                                    );
                                    this.setState({
                                      isLoadingOverlay: true,
                                      overlayText:
                                        "Spostamento in corso! Attendere prego...",
                                    });
                                    await new Promise((r) =>
                                      setTimeout(r, 600)
                                    );
                                    swapElements(
                                      manualeData.parti,
                                      index,
                                      index + 1
                                    );
                                    this.setState({
                                      manualeData: manualeData,
                                      isLoadingOverlay: false,
                                    });
                                  }
                                }}
                              >
                                <FrecciaGiu
                                  width={15}
                                  height={15}
                                  fill={
                                    this.state.activateMovement
                                      ? "#f7a616"
                                      : "#e0e0e0"
                                  }
                                />
                              </IconButton>
                            </div>
                            <IconButton
                              disabled={this.state.activateMovement}
                              onClick={() => {
                                this.handleOpenDoppiaConfermaModal(index);
                              }}
                            >
                              <CancelRounded
                                htmlColor={
                                  this.state.activateMovement
                                    ? "#e0e0e0"
                                    : "#ff4600"
                                }
                              />
                            </IconButton>
                          </ListItem>
                          <Collapse unmountOnExit={true} in={item.isTabOpen}>
                            <TableContainer>
                              <Table
                                style={
                                  {
                                    //borderCollapse: 'separate',
                                    //borderSpacing: '0px 20px'
                                  }
                                }
                                size="medium"
                              >
                                {item.capitoli.map((item2, index2) => {
                                  return (
                                    <CapitoloParti
                                      key={index2}
                                      base={this.state.base}
                                      data={this.state.data}
                                      sv={this.state.sv}
                                      ul={this.state.ul}
                                      area1={this.state.area1}
                                      area2={this.state.area2}
                                      manualeData={this.state.manualeData}
                                      item2={item2}
                                      index2={index2}
                                      index={index}
                                      item={item}
                                      activateMovement={
                                        this.state.activateMovement
                                      }
                                      handleParagrafoDelete={
                                        this.handleOpenDoppiaConfermaModal
                                      }
                                      handleParagrafoMovementUpward={
                                        this.handleParagrafoMovementUpward
                                      }
                                      handleParagrafoMovementDownward={
                                        this.handleParagrafoMovementDownward
                                      }
                                      handleAddParagrafo={
                                        this.handleAddParagrafo
                                      }
                                      handleSottoparagrafoDelete={
                                        this.handleOpenDoppiaConfermaModal
                                      }
                                      handleSottoparagrafoMovementDownward={
                                        this
                                          .handleSottoparagrafoMovementDownward
                                      }
                                      handleSottoparagrafoMovementUpward={
                                        this.handleSottoparagrafoMovementUpward
                                      }
                                      handleAddSottoparagrafo={
                                        this
                                          .handleOpenAggiuntaSottoparagrafoModal
                                      }
                                      handleParagrafoRedirect={
                                        this.handleParagrafoRedirect
                                      }
                                      handleCapitoloMovementDownward={
                                        this.handleCapitoloMovementDownward
                                      }
                                      handleCapitoloMovementUpward={
                                        this.handleCapitoloMovementUpward
                                      }
                                      handleDeleteCapitolo={
                                        this.handleOpenDoppiaConfermaModal
                                      }
                                      handleCambioTitoloCapitolo={
                                        this.handleCambioTitoloCapitolo
                                      }
                                      handleCambioTitoloParagrafo={
                                        this.handleCambioTitoloParagrafo
                                      }
                                      handleCambioTitoloSottoparagrafo={
                                        this.handleCambioTitoloSottoparagrafo
                                      }
                                      handleCambioBaseCapitolo={
                                        this.handleCambioBaseCapitolo
                                      }
                                      handleCambioBaseParagrafo={
                                        this.handleCambioBaseParagrafo
                                      }
                                      handleCambioBaseSottoparagrafo={
                                        this.handleCambioBaseSottoparagrafo
                                      }
                                      handleCambioSVCapitolo={
                                        this.handleCambioSVCapitolo
                                      }
                                      handleCambioSVParagrafo={
                                        this.handleCambioSVParagrafo
                                      }
                                      handleCambioSVSottoparagrafo={
                                        this.handleCambioSVSottoparagrafo
                                      }
                                      handleCambioULCapitolo={
                                        this.handleCambioULCapitolo
                                      }
                                      handleCambioULParagrafo={
                                        this.handleCambioULParagrafo
                                      }
                                      handleCambioULSottoparagrafo={
                                        this.handleCambioULSottoparagrafo
                                      }
                                      handleCambioArea1Capitolo={
                                        this.handleCambioArea1Capitolo
                                      }
                                      handleCambioArea1Paragrafo={
                                        this.handleCambioArea1Paragrafo
                                      }
                                      handleCambioArea1Sottoparagrafo={
                                        this.handleCambioArea1Sottoparagrafo
                                      }
                                      handleCambioArea2Capitolo={
                                        this.handleCambioArea2Capitolo
                                      }
                                      handleCambioArea2Paragrafo={
                                        this.handleCambioArea2Paragrafo
                                      }
                                      handleCambioArea2Sottoparagrafo={
                                        this.handleCambioArea2Sottoparagrafo
                                      }
                                      handleCambioDaEditareCapitolo={
                                        this.handleCambioDaEditareCapitolo
                                      }
                                      handleCambioDaEditareParagrafo={
                                        this.handleCambioDaEditareParagrafo
                                      }
                                      handleCambioDaEditareSottoparagrafo={
                                        this.handleCambioDaEditareSottoparagrafo
                                      }
                                      handleCambioDataCapitolo={
                                        this.handleCambioDataCapitolo
                                      }
                                      handleCambioDataParagrafo={
                                        this.handleCambioDataParagrafo
                                      }
                                      handleCambioDataSottoparagrafo={
                                        this.handleCambioDataSottoparagrafo
                                      }
                                      handleCambioIndiceCapitolo={
                                        this.handleCambioIndiceCapitolo
                                      }
                                      handleCambioIndiceParagrafo={
                                        this.handleCambioIndiceParagrafo
                                      }
                                      handleCambioIndiceSottoparagrafo={
                                        this.handleCambioIndiceSottoparagrafo
                                      }
                                      saveCapitoloData={
                                        this.handleSalvataModificaCapitolo
                                      }
                                      saveParagrafoData={
                                        this.handleSalvataModificaParagrafo
                                      }
                                      saveSottoparagrafoData={
                                        this.handleSalvataModificaSottoparagrafo
                                      }
                                      handleAggiuntaAlManualeCapitolo={
                                        this.handleAggiuntaAlManualeCapitolo
                                      }
                                      handleAggiuntaAlManualeParagrafo={
                                        this.handleAggiuntaAlManualeParagrafo
                                      }
                                      handleAggiuntaAlManualeSottoparagrafo={
                                        this
                                          .handleAggiuntaAlManualeSottoparagrafo
                                      }
                                      handleCapitoloOpen={
                                        this.handleCapitoloOpen
                                      }
                                      handleParagrafoOpen={
                                        this.handleParagrafoOpen
                                      }
                                      isModello={this.state.isModello}
                                    ></CapitoloParti>
                                  );
                                })}
                              </Table>
                            </TableContainer>
                            {/*<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {item.capitoli.map((item2,index2) => {
                    return <TableContainer>
                    <Table sx={{ }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
            <TableCell align="left">Capitolo</TableCell>
            <TableCell align="center">Titolo</TableCell>
            <TableCell align="center">Base&nbsp;</TableCell>
            <TableCell align="center">Da editare&nbsp;</TableCell>
            <TableCell align="center">Data&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.rows.map((row) => (
            <>
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.calories}</TableCell>
              <TableCell align="center">{row.fat}</TableCell>
              <TableCell align="center">{row.carbs}</TableCell>
              <TableCell align="center">{row.protein}</TableCell>
              <IconButton  onClick = {() => {
                this.setState({
                    isSubTabOpen:!this.state.isSubTabOpen,
                })
              }}><ExpandMore></ExpandMore></IconButton>
            </TableRow>
            <Collapse in = {this.state.isSubTabOpen}>
              <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Paragrafo</TableCell>
            <TableCell align="center">Titolo</TableCell>
            <TableCell align="center">Base</TableCell>
            <TableCell align="center">Da editare</TableCell>
            <TableCell align="center">Data</TableCell>
            <TableCell align="center">Modifica contenuto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.rows2.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.calories}</TableCell>
              <TableCell align="center">{row.fat}</TableCell>
              <TableCell align="center">{row.carbs}</TableCell>
              <TableCell align="center">{row.protein}</TableCell>
              <TableCell align="center">{row.button}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
              </Collapse>
              </>
            ))}
            </TableBody>
            </Table>
            </TableContainer>
                })}
              </Grid>*/}
                          </Collapse>
                        </Fragment>
                      );
                    })}
                  </div>
                </List>
              </div>
            ) : (
              <SpinnerComponentWithText
                isModello={this.state.isModello}
                size={36}
              />
            )}
            <ErrorDialog
              open={this.state.errorDialogVisible}
              message={this.state.errorDialogMessage}
              onCloseButtonClicked={this.closeErrorDialog}
            />
            <ErrorDialog
              open={this.state.successDialogVisible}
              title={"Successo"}
              message={
                "Modifica degli elementi da stampare salvata con successo"
              }
              onCloseButtonClicked={this.closeSuccessDialog}
            />
            {this.state.isDoppiaConfermaVisible && (
              <ModalDoppiaConfermaDelete
                open={this.state.isDoppiaConfermaVisible}
                onClose={this.handleCloseDoppiaConfermaModal}
                handleDeleteElement={this.handleDeleteElement}
                index={this.state.selectedParteIndex}
                index2={this.state.selectedCapitoloIndex}
                index3={this.state.selectedParagrafoIndex}
                index4={this.state.selectedSottoparagrafoIndex}
              />
            )}
            {this.state.isAggiuntaSottoparagrafoVisible && (
              <AggiuntaSottoparagrafoModal
                open={this.state.isAggiuntaSottoparagrafoVisible}
                onClose={this.handleCloseAggiuntaSottoparagrafoModal}
                addSottoparagrafo={this.handleAddSottoparagrafo}
                index={this.state.selectedParteIndex}
                index2={this.state.selectedCapitoloIndex}
                index3={this.state.selectedParagrafoIndex}
                paragrafo={this.state.selectedParagrafo}
              />
            )}
            {this.state.isModalCreazioneSottoparagrafoTabellaVisible && (
              <ModalCreazioneSottoparagrafoTabella
                open={this.state.isModalCreazioneSottoparagrafoTabellaVisible}
                onClose={this.handleCloseCreazioneSottoparagrafoTabellaModal}
                handleAggiuntaSottoparagrafoTabella={
                  this.handleAddSottoparagrafoTabella
                }
              />
            )}
          </div>
          {/*<div style={{position:'fixed',bottom:0,width:"100%",zIndex:5,backgroundColor:'rgb(225, 116, 20)'}}>
            <BottomNavigation
            style={{height:70,backgroundColor:'rgb(225, 116, 20)'}}
            showLabels
            >
              <BottomNavigationAction label={<span style={{color:"white", fontSize:16}}>Indietro</span>} style={{color:"white"}} onClick={() => {
                this.handleIndietroCommand();
              }} icon={<ArrowBack style={{color:"white"}}></ArrowBack>}></BottomNavigationAction>
            </BottomNavigation>
            </div>*/}
        </>
      </LoadingOverlay>
    );
  }
}
export default withRouter(CreazionePartiNuovoManuale);
