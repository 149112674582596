import React from "react";
import { Button } from "@material-ui/core";
import theme from "../theme";
import PropTypes from "prop-types";

const boldButtonStyle = (theme, disabled) => ({
  color: disabled ? theme.palette.text.secondary : theme.palette.secondary.main,
  marginBottom: "30px",
  marginTop: "10px",
  borderRadius: "10px",
  backgroundColor: disabled
    ? theme.palette.disabled.main
    : theme.palette.cndColors.yellow,
  fontWeight: "bold",
});

const BoldButton = ({ onClick, disabled }) => {
  return (
    <Button
      variant="contained"
      title="Grassetto"
      onClick={onClick}
      style={boldButtonStyle(theme, disabled)}
      disabled={disabled}
    >
      B
    </Button>
  );
};

BoldButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

BoldButton.defaultProps = {
  disabled: false,
};

export default BoldButton;
