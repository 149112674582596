import React from "react";
import {
  InputBase,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ActionButton from "../../../components/ActionButton";
import { getLocaliManuale } from "../../../utils/api/locali_e_attrezzature_api";
import PropTypes from "prop-types";

const styles = (theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    borderRadius: "6px",
    boxShadow: theme.shadows[6],
    //height: '85%'
    overflowY: "auto",
    height: "350px",
    width: "75%",
    maxWidth: "800px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  expandedModalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    boxShadow: theme.shadows[6],
    //height: '85%'
    overflowY: "auto",
    height: "900px",
    width: "75%",
    maxWidth: "800px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  modalSectionContainer: {
    flex: 1,
    display: "flex",
    marginTop: 25,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  valueContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  rowWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    margin: 10,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    background:
      "linear-gradient(white, white) padding-box, linear-gradient(to right, #ff4600, #f7a616,#ff4600) border-box;",
    borderRadius: "50em",
    border: "1px solid transparent",
    width: "32em",
    padding: "1em 2em 1em 1em",
  },
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
}));

class ModalSelezioneLimiti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controlloconformita: this.props.controlloconformita,
      nuoviControlliNonConformita: this.props.controlloconformita.nonConformita,
      expandedAccordion: false,
      arrayTabella: [],
      temperaturaMinima: this.props.controlloconformita.temperaturaMinima,
      temperaturaMassima: this.props.controlloconformita.temperaturaMassima,
      temperaturaConsigliata:
        this.props.controlloconformita.temperaturaConsigliata,
      temperaturaBuonFunzionamentoMinima:
        this.props.controlloconformita.temperaturaMinima,
      temperaturaBuonFunzionamentoMassima:
        this.props.controlloconformita.temperaturaMassima,
      temperaturaBuonFunzionamentoConsigliata:
        this.props.controlloconformita.temperaturaConsigliata,
      salinitaBuonFunzionamentoMinima:
        this.props.controlloconformita.salinitaMinima,
      salinitaBuonFunzionamentoMassima:
        this.props.controlloconformita.salinitaMassima,
      salinitaBuonFunzionamentoConsigliata:
        this.props.controlloconformita.salinitaStandard,
      salinitaMinima: this.props.controlloconformita.salinitaMinima,
      salinitaMassima: this.props.controlloconformita.salinitaMassima,
      salinitaStandard: this.props.controlloconformita.salinitaStandard,
      isBuonFunzionamentoSalinita:
        this.props.controlloconformita.salinitaMinima,
    };
  }

  handleAccordionChange = (event) => {
    const flag = !this.state.expandedAccordion;
    this.setState({
      expandedAccordion: flag,
    });
  };

  createTableRecords = (controlliNonConformita) => {
    let arrayTabella = [];
    if (controlliNonConformita.length > 0) {
      let controlloNonConf = {};
      let index = 0;
      for (let i = 0; i < controlliNonConformita.length; i++) {
        if (controlliNonConformita[i].controlliCause.length === 0) {
          controlloNonConf = {
            indice: index,
            id: controlliNonConformita[i].id,
            controlloConformitaId: this.state.controlloconformita.id,
            limite: controlliNonConformita[i].limite,
            controlloCausaId: null,
            limiteCausa: null,
            azioneCorrettivaId: null,
            descrizioneAzione: null,
          };
          arrayTabella.push(controlloNonConf);
          index = index + 1;
        } else {
          let controllicause = controlliNonConformita[i].controlliCause;
          for (let j = 0; j < controllicause.length; j++) {
            if (controllicause[j].azioniCorrettive.length === 0) {
              controlloNonConf = {
                indice: index,
                id: controlliNonConformita[i].id,
                controlloConformitaId: this.state.controlloconformita.id,
                limite: controlliNonConformita[i].limite,
                controlloCausaId: controllicause[j].id,
                limiteCausa: controllicause[j].limite,
                azioneCorrettivaId: null,
                descrizioneAzione: null,
              };
              arrayTabella.push(controlloNonConf);
              index = index + 1;
            } else {
              let azionicorrettive = controllicause[j].azioniCorrettive;
              for (let k = 0; k < azionicorrettive.length; k++) {
                controlloNonConf = {
                  indice: index,
                  id: controlliNonConformita[i].id,
                  controlloConformitaId: this.state.controlloconformita.id,
                  limite: controlliNonConformita[i].limite,
                  controlloCausaId: controllicause[j].id,
                  limiteCausa: controllicause[j].limite,
                  azioneCorrettivaId: azionicorrettive[k].id,
                  descrizioneAzione: azionicorrettive[k].descrizione,
                };
                arrayTabella.push(controlloNonConf);
                index = index + 1;
              }
            }
          }
        } //end else-if
      } //end loop on controlli non conformità
    } //end if

    return arrayTabella;
  };

  addControlloNonConformita = (nuovoControlloNonConformita) => {
    let arrayOfControlliNonConformita = [
      ...this.state.nuoviControlliNonConformita,
    ];
    arrayOfControlliNonConformita.push(nuovoControlloNonConformita);
    this.setState({
      nuoviControlliNonConformita: arrayOfControlliNonConformita,
    });
    let arrayTabella = this.state.arrayTabella.map((a) => Object.assign({}, a));
    let index = arrayTabella.length;
    let controlloNonConf = {};
    if (nuovoControlloNonConformita.controlliCause.length === 0) {
      controlloNonConf = {
        indice: index,
        id: nuovoControlloNonConformita.id,
        controlloConformitaId: this.state.controlloconformita.id,
        limite: nuovoControlloNonConformita.limite,
        controlloCausaId: null,
        limiteCausa: null,
        azioneCorrettivaId: null,
        descrizioneAzione: null,
      };
      arrayTabella.push(controlloNonConf);
      index = index + 1;
    } else {
      let controllicause = nuovoControlloNonConformita.controlliCause.map((a) =>
        Object.assign({}, a)
      );
      for (let j = 0; j < controllicause.length; j++) {
        if (controllicause[j].azioniCorrettive.length === 0) {
          controlloNonConf = {
            indice: index,
            id: nuovoControlloNonConformita.id,
            controlloConformitaId: this.state.controlloconformita.id,
            limite: nuovoControlloNonConformita.limite,
            controlloCausaId: null,
            limiteCausa: controllicause[j].limite,
            azioneCorrettivaId: null,
            descrizioneAzione: null,
          };
          arrayTabella.push(controlloNonConf);
          index = index + 1;
        } else {
          let azionicorrettive = controllicause[j].azioniCorrettive.map((a) =>
            Object.assign({}, a)
          );
          for (let k = 0; k < azionicorrettive.length; k++) {
            controlloNonConf = {
              indice: index,
              id: nuovoControlloNonConformita.id,
              controlloConformitaId: this.state.controlloconformita.id,
              limite: nuovoControlloNonConformita.limite,
              controlloCausaId: null,
              limiteCausa: controllicause[j].limite,
              azioneCorrettivaId: null,
              descrizioneAzione: azionicorrettive[k].descrizione,
            };
            arrayTabella.push(controlloNonConf);
            index = index + 1;
          }
        }
      }
    } //end else-if

    this.setState({
      expandedAccordion: false,
      hasChanges: true,
      arrayTabella: arrayTabella,
    });
  };

  deleteControlloNonConformita = (record) => {
    let arrayTabella = [...this.state.arrayTabella];
    for (let i = 0; i < arrayTabella.length; i++) {
      if (arrayTabella[i].indice === record.indice) {
        arrayTabella.splice(i, 1);
      }
    }
    let newArrayOfControlliNonConformita = [
      ...this.state.nuoviControlliNonConformita,
    ];
    let controlloIndex = null;
    let controlloCausaIndex = null;
    let azioneCorrettivaIndex = null;
    newArrayOfControlliNonConformita.map((item, index) => {
      if (record.id === item.id) {
        controlloIndex = index;
        item.controlliCause.map((item2, index2) => {
          if (item2.id === record.controlloCausaId) {
            controlloCausaIndex = index2;
            item2.azioniCorrettive.map((item3, index3) => {
              azioneCorrettivaIndex = index3;
            });
          }
        });
      }
    });
    if (
      controlloIndex !== null &&
      controlloCausaIndex !== null &&
      azioneCorrettivaIndex !== null
    ) {
      newArrayOfControlliNonConformita[controlloIndex].controlliCause[
        controlloCausaIndex
      ].azioniCorrettive.splice(azioneCorrettivaIndex, 1);
    }
    if (
      controlloIndex !== null &&
      controlloCausaIndex !== null &&
      azioneCorrettivaIndex === null
    ) {
      newArrayOfControlliNonConformita[controlloIndex].controlliCause.splice(
        controlloCausaIndex,
        1
      );
    }
    if (
      controlloIndex !== null &&
      controlloCausaIndex === null &&
      azioneCorrettivaIndex !== null
    ) {
      newArrayOfControlliNonConformita.splice(controlloIndex, 1);
    }
    if (
      controlloIndex !== null &&
      controlloCausaIndex === null &&
      azioneCorrettivaIndex === null
    ) {
      newArrayOfControlliNonConformita.splice(controlloIndex, 1);
    }
    this.setState({
      nuoviControlliNonConformita: newArrayOfControlliNonConformita,
    });

    if (arrayTabella.length === 0) {
      this.setState({
        nuoviControlliNonConformita: [],
      });
    }
    this.setState({
      hasChanges: true,
      arrayTabella: arrayTabella,
      //nuoviControlliNonConformita:newArrayOfControlliNonConformita
    });
  };

  fetchLocali = (tipologiaStrumentazioneId) => {
    getLocaliManuale(this.props.manualeId, 0, 300, "indiceOrdinamento")
      .then((result) => {
        this.setState({
          locali: result,
        });
        this.fetchAttrezzatureForTipologia(tipologiaStrumentazioneId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  componentDidMount = () => {
    let repartiControllo = [];
    let reparti = this.props.reparti;
    for (let i = 0; i < reparti.length; i++) {
      if (reparti[i].repartoDefault) {
        repartiControllo.push(reparti[i].id);
        break;
      }
    }
    let checkboxSelected = false;
    if (reparti.length === 1) {
      checkboxSelected = true;
    }
    let tipologieStrumentazioni = this.props.tipologieStrumentazioni;
    let controlloConformita = this.state.controlloconformita;
    if (this.props.controlloconformita === null) {
      controlloConformita.proceduraHaccpId = this.props.proceduraId;
      controlloConformita.reparti = repartiControllo;
      //controlloConformita.orarioNotifica = moment(controlloConformita.orarioNotifica, [moment.ISO_8601, 'HH:mm:ss']);
      this.setState({
        controlloConformita,
        tipologieStrumentazioni,
        isNewRecord: true,
        checkboxSelected,
      });
    } else {
      const arrayTabella = this.createTableRecords(
        this.props.controlloconformita.nonConformita
      );
      let repartiIsNullOrEmpty =
        this.props.controlloconformita.reparti === null ||
        this.props.controlloconformita.reparti === undefined ||
        this.props.controlloconformita.reparti.length === 0;
      controlloConformita = {
        indice: this.props.controlloconformita.indice,
        id: this.props.controlloconformita.id,
        proceduraHaccpId: this.props.controlloconformita.proceduraHaccpId,
        descrizioneOsservabile:
          this.props.controlloconformita.descrizioneOsservabile !== null
            ? this.props.controlloconformita.descrizioneOsservabile
            : "",
        categoriaControllo:
          this.props.controlloconformita.categoriaControllo !== null
            ? this.props.controlloconformita.categoriaControllo
            : "CONTROLLO_GENERICO",
        categoriaOsservabile:
          this.props.controlloconformita.categoriaOsservabile,
        tipologiaStrumentazioneId:
          this.props.controlloconformita.tipologiaStrumentazioneId === null
            ? -2
            : this.props.controlloconformita.tipologiaStrumentazioneId,
        cadenza:
          this.props.controlloconformita.cadenza === null
            ? "ALTRO"
            : this.props.controlloconformita.cadenza,
        altraCadenza: this.props.controlloconformita.altraCadenza,
        limiti:
          this.props.controlloconformita.limiti === null
            ? ""
            : this.props.controlloconformita.limiti,
        bloccante:
          this.props.controlloconformita.bloccante === null
            ? false
            : this.props.controlloconformita.bloccante,
        periodico: this.props.controlloconformita.periodico,
        controlliNonConformita: [],
        cadenze:
          this.props.controlloconformita.cadenze === null ||
          this.props.controlloconformita.cadenze === undefined
            ? []
            : this.props.controlloconformita.cadenze,
        attrezzature:
          this.props.controlloconformita.attrezzature === null ||
          this.props.controlloconformita.attrezzature === undefined
            ? []
            : this.props.controlloconformita.attrezzature,
        reparti: repartiIsNullOrEmpty
          ? repartiControllo
          : this.props.controlloconformita.reparti,
        temperaturaMinima:
          this.props.controlloconformita.temperaturaMinima === null
            ? ""
            : this.props.controlloconformita.temperaturaMinima,
        temperaturaMassima:
          this.props.controlloconformita.temperaturaMassima === null
            ? ""
            : this.props.controlloconformita.temperaturaMassima,
        temperaturaConsigliata:
          this.props.controlloconformita.temperaturaConsigliata === null
            ? ""
            : this.props.controlloconformita.temperaturaConsigliata,
      };
      // if (controlloConformita.cadenza === null) {
      //     controlloConformita.cadenza = 'ALTRO';
      // }
      // if (controlloConformita.tipologiaStrumentazioneId === null) {
      //     controlloConformita.tipologiaStrumentazioneId = 1;
      // }
      // if (controlloConformita.orarioNotifica === null) {
      //     controlloConformita.orarioNotifica = '08:00:00';
      // }
      // if (controlloConformita.secondoOrarioNotifica === null) {
      //     controlloConformita.secondoOrarioNotifica = '18:00:00';
      // }
      // if (controlloConformita.giornoNotifica === null) {
      //     controlloConformita.giornoNotifica = 'MONDAY';
      // }
      this.setState({
        controlloConformita,
        tipologieStrumentazioni,
        isNewRecord: false,
        arrayTabella: arrayTabella,
        checkboxSelected,
      });
    }
    this.fetchLocali(controlloConformita.tipologiaStrumentazioneId);
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const regexTemperatura = /^-?\d+(\.\d)?$/;
    if (regexTemperatura.test(value) || value === "") {
      this.setState({
        [name]: value,
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Modal
        className={classes.modal}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <div
          className={
            this.state.expandedAccordion
              ? classes.expandedModalContainer
              : classes.modalContainer
          }
        >
          {this.props.mode === "VERIFICA_BUON_FUNZIONAMENTO" && (
            <Select
              style={{
                marginTop: 20,
              }}
              value={this.state.isBuonFunzionamentoSalinita}
              input={<BootstrapInput />}
              onChange={(e) => {
                this.setState({
                  isBuonFunzionamentoSalinita: e.target.value,
                });
              }}
            >
              <MenuItem value={false}>Temperatura</MenuItem>
              <MenuItem value={true}>Salinità</MenuItem>
            </Select>
          )}
          {this.props.mode === "CONTROLLO_TEMPERATURE" && (
            <div className={classes.modalSectionContainer}>
              <div className={classes.valueContainer}>
                <div className={classes.rowWrapper}>
                  <Typography>Temperatura minima di conformità</Typography>
                  <TextField
                    name="temperaturaMinima"
                    value={this.state.temperaturaMinima}
                    onInput={this.handleInputChange}
                    size="small"
                    type="number"
                  />
                  <Typography>°C</Typography>
                </div>
                <div className={classes.rowWrapper}>
                  <Typography>Temperatura massima di conformità</Typography>
                  <TextField
                    name="temperaturaMassima"
                    onInput={this.handleInputChange}
                    value={this.state.temperaturaMassima}
                    size="small"
                    type="number"
                  />
                  <Typography>°C</Typography>
                </div>
                <div className={classes.rowWrapper}>
                  <Typography>Temperatura Standard</Typography>
                  <TextField
                    name="temperaturaConsigliata"
                    onInput={this.handleInputChange}
                    value={this.state.temperaturaConsigliata}
                    type="number"
                    size="small"
                  />
                  <Typography>°C</Typography>
                </div>
              </div>
            </div>
          )}
          {this.props.mode === "VERIFICA_BUON_FUNZIONAMENTO" &&
            !this.state.isBuonFunzionamentoSalinita && (
              <div className={classes.modalSectionContainer}>
                <div className={classes.valueContainer}>
                  <div className={classes.rowWrapper}>
                    <Typography>Temperatura minima di conformità</Typography>
                    <TextField
                      name="temperaturaBuonFunzionamentoMinima"
                      value={this.state.temperaturaBuonFunzionamentoMinima}
                      onInput={this.handleInputChange}
                      size="small"
                      type="number"
                    />
                    <Typography>°C</Typography>
                  </div>
                  <div className={classes.rowWrapper}>
                    <Typography>Temperatura massima di conformità</Typography>
                    <TextField
                      name="temperaturaBuonFunzionamentoMassima"
                      onInput={this.handleInputChange}
                      value={this.state.temperaturaBuonFunzionamentoMassima}
                      size="small"
                      type="number"
                    />
                    <Typography>°C</Typography>
                  </div>
                  <div className={classes.rowWrapper}>
                    <Typography>Temperatura Standard</Typography>
                    <TextField
                      name="temperaturaBuonFunzionamentoConsigliata"
                      onInput={this.handleInputChange}
                      value={this.state.temperaturaBuonFunzionamentoConsigliata}
                      type="number"
                      size="small"
                    />
                    <Typography>°C</Typography>
                  </div>
                </div>
              </div>
            )}
          {this.props.mode === "CONTROLLO_SALINITA" && (
            <div className={classes.modalSectionContainer}>
              <div className={classes.valueContainer}>
                <div className={classes.rowWrapper}>
                  <Typography>Salinità minima di conformità</Typography>
                  <TextField
                    value={this.state.salinitaMinima}
                    onInput={(e) => {
                      const re = /^-?\d+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        this.setState({
                          salinitaMinima: e.target.value,
                        });
                      }
                    }}
                    size="small"
                    type="number"
                  />
                </div>
                <div className={classes.rowWrapper}>
                  <Typography>Salinità massima di conformità</Typography>
                  <TextField
                    onInput={(e) => {
                      const re = /^-?\d+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        this.setState({
                          salinitaMassima: e.target.value,
                        });
                      }
                    }}
                    value={this.state.salinitaMassima}
                    size="small"
                    type="number"
                  />
                </div>
                <div className={classes.rowWrapper}>
                  <Typography>Salinità Standard</Typography>
                  <TextField
                    onInput={(e) => {
                      const re = /^-?\d+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        this.setState({
                          salinitaStandard: e.target.value,
                        });
                      }
                    }}
                    value={this.state.salinitaStandard}
                    type="number"
                    size="small"
                  />
                </div>
              </div>
            </div>
          )}
          {this.props.mode === "VERIFICA_BUON_FUNZIONAMENTO" &&
            this.state.isBuonFunzionamentoSalinita && (
              <div className={classes.modalSectionContainer}>
                <div className={classes.valueContainer}>
                  <div className={classes.rowWrapper}>
                    <Typography>Salinità minima di conformità</Typography>
                    <TextField
                      value={this.state.salinitaBuonFunzionamentoMinima}
                      onInput={(e) => {
                        const re = /^-?\d+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          this.setState({
                            salinitaBuonFunzionamentoMinima: e.target.value,
                          });
                        }
                      }}
                      size="small"
                      type="number"
                    />
                  </div>
                  <div className={classes.rowWrapper}>
                    <Typography>Salinità massima di conformità</Typography>
                    <TextField
                      onInput={(e) => {
                        const re = /^-?\d+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          this.setState({
                            salinitaBuonFunzionamentoMassima: e.target.value,
                          });
                        }
                      }}
                      value={this.state.salinitaBuonFunzionamentoMassima}
                      size="small"
                      type="number"
                    />
                  </div>
                  <div className={classes.rowWrapper}>
                    <Typography>Salinità Standard</Typography>
                    <TextField
                      onInput={(e) => {
                        const re = /^-?\d+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          this.setState({
                            salinitaBuonFunzionamentoConsigliata:
                              e.target.value,
                          });
                        }
                      }}
                      value={this.state.salinitaBuonFunzionamentoConsigliata}
                      type="number"
                      size="small"
                    />
                  </div>
                </div>
              </div>
            )}
          {/*<div style={{overflowY:"auto"}}>
                    <ControlliNonConformita
                    controlliNonConformita={this.state.arrayTabella}
                    controlloConformitaId={this.state.controlloconformita.id}
                    expandedAccordion={this.state.expandedAccordion}
                    onChangeAccordion={this.handleAccordionChange}
                    onAggiungiButtonClicked={this.addControlloNonConformita}
                    onDeleteButtonClicked={this.deleteControlloNonConformita}
                    />
                    </div>*/}
          <div className={classes.buttonWrapper}>
            <ActionButton
              isRed={true}
              label="Chiudi"
              onClick={this.props.onClose}
            />
            <ActionButton
              label="Salva"
              onClick={() => {
                this.props.mode === "CONTROLLO_SALINITA"
                  ? this.props.passLimitiSalinita(
                      this.state.nuoviControlliNonConformita,
                      this.state.salinitaMinima,
                      this.state.salinitaMassima,
                      this.state.salinitaStandard
                    )
                  : this.props.mode === "VERIFICA_BUON_FUNZIONAMENTO"
                  ? this.props.passLimitiBuonFunzionamento(
                      this.state.nuoviControlliNonConformita,
                      this.state.temperaturaBuonFunzionamentoMinima,
                      this.state.temperaturaBuonFunzionamentoMassima,
                      this.state.temperaturaBuonFunzionamentoConsigliata,
                      this.state.salinitaBuonFunzionamentoMinima,
                      this.state.salinitaBuonFunzionamentoMassima,
                      this.state.salinitaBuonFunzionamentoConsigliata,
                      this.state.isBuonFunzionamentoSalinita
                    )
                  : this.props.passLimiti(
                      this.state.nuoviControlliNonConformita,
                      this.state.temperaturaMinima,
                      this.state.temperaturaMassima,
                      this.state.temperaturaConsigliata
                    );
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

ModalSelezioneLimiti.propTypes = {
  controlloconformita: PropTypes.object.isRequired,
  reparti: PropTypes.arrayOf(PropTypes.object).isRequired,
  manualeId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  passLimiti: PropTypes.func.isRequired,
  passLimitiSalinita: PropTypes.func.isRequired,
  passLimitiBuonFunzionamento: PropTypes.func.isRequired,
};

export default withStyles(styles)(ModalSelezioneLimiti);
