import { retrieveToken } from '../storage';
import { wrapFetch, ApiError } from './api';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getIndice = (manualeId) => {
    const token = retrieveToken();
    const authtoken = 'Bearer '.concat(token);
    const PATH = "/api/cnd/indice"
    const query = PATH + "?manualeId="+manualeId;
    const URL = BASE_URL + query;
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
            message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        }
        return new ApiError(status, message);
    };
    return wrapFetch(
        fetch(URL, {
            method: 'GET',
            headers: {
                'Authorization': authtoken
            },
            withCredentials: true,
            credentials: 'include'
        }), errorFunction
    );
}
export const putSortingIndice = (body) => {
  const token = retrieveToken();
    const PATH = '/api/cnd/indice/ordinamento';
    const URL = BASE_URL + PATH;
    const authtoken = 'Bearer '.concat(token);
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
          message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        } else if (status === 400) {
          message = 'Errore salvataggio: i dati non sono corretti.';
        } else if (status === 404) {
          message = 'Errore salvataggio: responsabile punto vendita non trovato.';
        }
        return new ApiError(status, message);
    };
    return wrapFetch(
        fetch(URL, {
          method: 'PUT',
          headers: {
            'Authorization' : authtoken,
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          credentials: 'include',
          body: JSON.stringify(body)
        }), errorFunction
      );
}

export const putIndiceTableValues = (body) => {
  const token = retrieveToken();
    const PATH = '/api/cnd/indice';
    const URL = BASE_URL + PATH;
    const authtoken = 'Bearer '.concat(token);
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
          message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        } else if (status === 400) {
          message = 'Errore salvataggio: i dati non sono corretti.';
        } else if (status === 404) {
          message = 'Errore salvataggio: responsabile punto vendita non trovato.';
        }
        return new ApiError(status, message);
    };
    return wrapFetch(
        fetch(URL, {
          method: 'PUT',
          headers: {
            'Authorization' : authtoken,
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          credentials: 'include',
          body: JSON.stringify(body)
        }), errorFunction
      );
}

export const postAggiuntaParteManuale = (body) => {
    const token = retrieveToken();
    const authtoken = 'Bearer '.concat(token);
    const PATH = "/api/cnd/parti";
    const URL = BASE_URL + PATH;
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
          message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        } else if (status === 400 || status === 404) {
          message = 'Operazione non riuscita: dati non corretti.';
        }
        return new ApiError(status, message);
    };
      return wrapFetch(
        fetch(URL, {
          method: 'POST',
          headers: {
            'Authorization' : authtoken,
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          credentials: 'include',
          body: JSON.stringify(body)
        }), errorFunction
      );
}
export const putPartiManuale = (body) => {
    const token = retrieveToken();
    const PATH = '/api/cnd/parti';
    const URL = BASE_URL + PATH;
    const authtoken = 'Bearer '.concat(token);
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
          message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        } else if (status === 400) {
          message = 'Errore salvataggio: i dati non sono corretti.';
        } else if (status === 404) {
          message = 'Errore salvataggio: responsabile punto vendita non trovato.';
        }
        return new ApiError(status, message);
    };
    return wrapFetch(
        fetch(URL, {
          method: 'PUT',
          headers: {
            'Authorization' : authtoken,
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          credentials: 'include',
          body: JSON.stringify(body)
        }), errorFunction
      );
}
export const deletePartiManuale = (parteId) => {
    const token = retrieveToken();
    const PATH = "/api/cnd/parti/"+parteId;
    const URL = BASE_URL + PATH ;
    const authtoken = 'Bearer '.concat(token);
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
          message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        } else if (status === 400) {
          message = 'Errore salvataggio: i dati non sono corretti.';
        } else if (status === 404) {
          message = 'Errore salvataggio: responsabile punto vendita non trovato.';
        }
        return new ApiError(status, message);
      };
    return wrapFetch(
        fetch(URL, {
          method: 'DELETE',
            headers: {
              'Authorization' : authtoken
            },
            withCredentials: true,
            credentials: 'include'
        }), errorFunction
      );
}
export const getNumeroPartiManuale = (manualeId) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = "/api/parti/count?";
  let query = PATH+"manualeId.equals="+manualeId;
  const URL = BASE_URL + query;
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
            message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        }
        return new ApiError(status, message);
    };
    return wrapFetch(
        fetch(URL, {
            method: 'GET',
            headers: {
                'Authorization': authtoken
            },
            withCredentials: true,
            credentials: 'include'
        }), errorFunction
    );
}
export const getPartiManuale = (manualeId,page,size,sort,verso) => {
    const token = retrieveToken();
    const authtoken = 'Bearer '.concat(token);
    const PATH = "/api/parti";
    let query = PATH+"?manualeId.equals="+manualeId;
    /*if(page !== null || page !== undefined){
        query = query + "&page="+page;
    }
    if(size !== null || size !== undefined){
        query = query + "&size="+size;
    }
    if(sort !== null || sort !== undefined){
        query = query + "&sort="+sort;
    }
    if(verso !== null || verso !== undefined){
        query = query + ","+verso;
    }*/
    const URL = BASE_URL + query;
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
            message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        }
        return new ApiError(status, message);
    };
    return wrapFetch(
        fetch(URL, {
            method: 'GET',
            headers: {
                'Authorization': authtoken
            },
            withCredentials: true,
            credentials: 'include'
        }), errorFunction
    );
}
export const postDaStampareIndice = (body) => {
  const token = retrieveToken();
    const authtoken = 'Bearer '.concat(token);
    const PATH = "/api/cnd/indice/da_stampare";
    const URL = BASE_URL + PATH;
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
          message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        } else if (status === 400 || status === 404) {
          message = 'Operazione non riuscita: dati non corretti.';
        }
        return new ApiError(status, message);
    };
      return wrapFetch(
        fetch(URL, {
          method: 'POST',
          headers: {
            'Authorization' : authtoken,
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          credentials: 'include',
          body: JSON.stringify(body)
        }), errorFunction
      );
}